import React, { Component } from "react";
import MaquinaDeEstadosStore from "../../Stores/MaquinaDeEstadosStore";
import { logout } from "../../Services/auth";
import { inject, observer } from "mobx-react";
import { removeServiceWorkers } from "../../Stores/FirebaseStore";

@inject(
  "MaquinaDeEstadosStore",
  "FinanceiroStores",
  "SideStores",
  "LoginStores",
  "CadastroStores",
  "BuscaDeEditaisLicitacaoStores",
  "ModalNotificacoesStores",
  "MenuStores",
  "LicitacoesStore",
  "MonitoramentoStore",
  "ModalLoadingStore",
  "BuscaDeEditaisStores",
  "OcorrenciasDoDiaStores",
  "ConfiguracoesBuscaEditaisStores",
  "ConfiguracoesMonitoramentoStores",
  "ConfiguracoesGeraisStores",
  "ConfiguracoesGeraisModalStores",
  "ConfiguracoesGeraisModalEmpresaStores",
  "ConfiguracoesCredenciaisStores",
  "EditaisStores",
  "CollapseEditaisSelecionadosStores",
  "LicitacaoStores",
  "FunilStore",
  "ModalSincronizarStores",
  "ModalSincronizarPelosEditaisStores",
  "LicitacoesPaginadaStore",
  "MonitoramentoStorePaginada",
  "StatusConexaoStores",
  "PainelAvisoStores",
  "ModalLateralStore",
  "NewEditaisStores",
  "StoreSelecaoPortais",
  "StoreConfiguracoesGerais",
  "StoreConfiguracaoEmpresas",
  "StoreConfiguracaoCredenciais",
  "CompradoresStore",
  // NOVAS STORES
  "BoletimStore",
  "BuscaManualStore",
  "DetalhesEditalStore",
  "QualificadosStore",
  "ReprovadosStore",
  "DownloadEditaisStore",
  "AprovadosStore"
)
@observer
class Logout extends Component {
  limparDados = () => {
    this.props.MaquinaDeEstadosStore.limparVariaveis();
    this.props.FinanceiroStores.limparVariaveis();
    this.props.SideStores.limparVariaveis();
    this.props.LoginStores.limparVariaveis();
    this.props.CadastroStores.limparVariaveis();
    this.props.BuscaDeEditaisLicitacaoStores.limparVariaveis();
    this.props.ModalNotificacoesStores.limparVariaveis();
    this.props.MenuStores.limparVariaveis();
    this.props.LicitacoesStore.limparVariaveis();
    this.props.MonitoramentoStore.limparVariaveis();
    this.props.ModalLoadingStore.limparVariaveis();
    this.props.BuscaDeEditaisStores.limparVariaveis();
    this.props.OcorrenciasDoDiaStores.limparVariaveis();
    this.props.ConfiguracoesBuscaEditaisStores.limparVariaveis();
    this.props.ConfiguracoesMonitoramentoStores.limparVariaveis();
    this.props.ConfiguracoesGeraisStores.limparVariaveis();
    this.props.ConfiguracoesGeraisModalStores.limparVariaveis();
    this.props.ConfiguracoesGeraisModalEmpresaStores.limparVariaveis();
    this.props.ConfiguracoesCredenciaisStores.limparVariaveis();
    this.props.EditaisStores.limparVariaveis();
    this.props.LicitacaoStores.limparVariaveis();
    this.props.FunilStore.limparVariaveis();
    this.props.ModalSincronizarStores.limparVariaveis();
    this.props.ModalSincronizarPelosEditaisStores.limparVariaveis();
    this.props.LicitacoesPaginadaStore.limparVariaveis();
    this.props.MonitoramentoStorePaginada.limparVariaveis();
    this.props.StatusConexaoStores.limparVariaveis();
    this.props.PainelAvisoStores.limparVariaveis();
    this.props.ModalLateralStore.limparVariaveis();
    this.props.NewEditaisStores.limparVariaveis();
    this.props.StoreSelecaoPortais.limparVariaveis();
    this.props.StoreConfiguracoesGerais.limparVariaveis();
    this.props.StoreConfiguracaoEmpresas.limparVariaveis();
    this.props.StoreConfiguracaoCredenciais.limparVariaveis();
    this.props.CompradoresStore.limparVariaveis();
    this.props.BoletimStore.limparVariaveis();
    this.props.BuscaManualStore.limparVariaveis();
    this.props.DetalhesEditalStore.limparVariaveis();
    this.props.QualificadosStore.limparVariaveis();
    this.props.ReprovadosStore.limparVariaveis();
    this.props.DownloadEditaisStore.limparVariaveis();
    this.props.AprovadosStore.limparVariaveis();
  };

  onSubmit = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cnpj");
    localStorage.removeItem("id_fornecedor");
    localStorage.removeItem("licitacao_monitorada");
    localStorage.removeItem("tokenfirebase");
    localStorage.removeItem("user_id");
    localStorage.removeItem("super");
    removeServiceWorkers();
    logout();
    MaquinaDeEstadosStore.redireciona_login();
    this.limparDados();
    window.location.reload();
  };

  render() {
    return (
      <div className="list-item" onClick={this.onSubmit}>SAIR</div>
    );
  }
}

export default Logout;

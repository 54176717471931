import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import brl from 'moment/locale/pt-br'
import { DateRange } from 'react-date-range'

const Container = styled.div`
  .rdr-DateRange {
    border-radius: 5px;
    box-shadow: 1px 1px 3px black;
  }
  .rdr-Calendar {
    width: 260px !important;
  }
  .rdr-Days span {
    width: 34px !important;
    height: 31px !important;
    line-height: 32.1429px !important;
    font-size: 12px !important;
  }
  .rdr-WeekDay {
    font-size: 12px !important;
    width: 34px !important;
  }
`

const minDate = (() => {
  var date = new Date()
  date.setDate(date.getDate() - 7)
  return date
})()

export class DatePicker extends Component {
  render() {
    const { qualData, handleSelect, onClick } = this.props
    moment.locale('pt-BR', brl)
    moment().format('DD/MM/YYYY')
    return (
      <>
        {this.props.showing ? (
          <Container>
            <DateRange
              onChange={handleSelect}
              editableDateInputs={true}
              minDate={minDate}
            />
          </Container>
        ) : null}
      </>
    )
  }
}

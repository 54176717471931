import React, { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Prompt } from "react-router-dom";

import NotificacoesEmailEdital from "./NotificacaoEmailEdital";
import PalavraChaveClassificacao from "./PalavraChaveClassificacao";
import PalavraChaveExclusao from "./PalavraChaveExclusao";
import SelecaoDePortal from "./Itens/SelecaoDePortal";
import FiltroCompradores from "./FiltroCompradores";
import { AgendamentoDeEnvio } from "./AgendamentoDeEnvio";
import FiltroModalidade from "./FiltroModalidade"
import { MonitorarEstados } from "./MonitorarEstados";
import { NomeacaoDeArquivos } from "./Itens/NomeacaoDeArquivos";
import { TratamentoDiferenciado } from "./TratamentoDiferenciado";
import FiltroMateraisServico from "./Itens/FiltroMateraisServico";

import MaquinaDeEstadosStore from "../../Stores/MaquinaDeEstadosStore";
import { ConfiguracaoEditaisContext } from "./stores/store";
import { ModalConfirmacao } from "../../Components/NewModal/modalConfirmacao";

import {
  CardMsgWrap,
  ComponentHeader,
  ComponentHeaderOverflow,
  ContentBody,
  TabButton
} from "../../globalStyles";
import { CheckCircle, PlusCircle, Trash, FilePlus } from "react-feather";
import {
  ActionButtonsContainer,
  CriarPerfilContainer,
  PerfisContainer,
  FiltrosExclusivosTexto,
  SaveButton,
  LimparButton
} from "./styles";
import PerfilNameInput from "./PerfilNameInput";
import Nome from "./perfil/Nome";

const ConfiguracaoEditais = observer(() => {
  const {
    todosEstados,
    salva_api,
    limparVariaveis,
    consulta_api,
    listaDePerfis,
    mudarPerfil,
    weekDays,
    handleDay,
    hours,
    handleHour,
    changeState,
    estados,
    changeFileName,
    nomeacaoEditais,
    defaultValue,
    empresa_me,
    handleFiltroMe,
    onChangeFiltro,
    houveAlteracao,
    criarPerfil,
    novoPerfil,
    nomePerfil
  } = useContext(ConfiguracaoEditaisContext);

  const [selected, setSelected] = useState(null);
  console.log("LISTA DE PERFIS",listaDePerfis)

  useEffect(() => {
    const { id_fornecedor } = MaquinaDeEstadosStore;
    if (id_fornecedor) consulta_api();
    return limparVariaveis;
  }, [MaquinaDeEstadosStore.id_fornecedor]);

  useEffect(() => {
    if (listaDePerfis.length) {
      if (!selected || !listaDePerfis.find(perf => perf.id === selected))
        setSelected(listaDePerfis[0].id);
      mudarPerfil(selected || listaDePerfis[0].id);
    }
  }, [selected, listaDePerfis]);

  return (
    <>
      <ComponentHeader
        height="8vh"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <PerfisContainer>
          {listaDePerfis.map(perfil => (
            <TabButton
              ul={selected === perfil.id}
              onClick={() => setSelected(perfil.id)}
              textTransform="uppercase"
              margin="0 15px 0 0"
            >
              {perfil.nome_perfil}
            </TabButton>
          ))}
        </PerfisContainer>

        <CriarPerfilContainer>
          <ModalConfirmacao
            button={
              <div
                id="criar-perfil-button"
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  color: "#fff",
                  marginLeft: "auto"
                }}
              >
                <PlusCircle className="icon-scale" size="27px" />
                <div style={{ marginTop: "4px" }}>CRIAR PERFIL</div>
              </div>
            }
            width="700px"
            ico={<FilePlus color={"#3A566E"} size={"4rem"} />}
            title="CRIAR NOVO PERFIL"
            message={<PerfilNameInput />}
            confirmAction={() =>
              criarPerfil(document.getElementById("perf-name").value)
            }
          />
        </CriarPerfilContainer>
      </ComponentHeader>
      <ComponentHeaderOverflow height="8vh" />
      <ContentBody bRadius height="calc(92vh - 76px)" style={{ overflow: 'auto' }}>
        {listaDePerfis.length || novoPerfil ? (
          <>
            <Nome />
            <CardMsgWrap>
              <NotificacoesEmailEdital />
              <SelecaoDePortal />
            </CardMsgWrap>
            <CardMsgWrap>
              <PalavraChaveClassificacao />
              <PalavraChaveExclusao />
            </CardMsgWrap>
            <CardMsgWrap>
              <AgendamentoDeEnvio
                  weekDays={weekDays}
                  handleDay={handleDay}
                  hours={hours}
                  handleHour={handleHour}
                />

              <FiltroModalidade/>
            </CardMsgWrap>
            <CardMsgWrap>
            <MonitorarEstados
              changeState={changeState}
              todosEstados={todosEstados}
              estados={estados}
            />
              <FiltroCompradores />
            </CardMsgWrap>
            {/* <CardMsgWrap height="440px">
              <div
                style={{
                  display: "flex",
                  flexBasis: "49.8%",
                  flexDirection: "column"
                }}
              >
                <AgendamentoDeEnvio
                  weekDays={weekDays}
                  handleDay={handleDay}
                  hours={hours}
                  handleHour={handleHour}
                />

                <MonitorarEstados
                  changeState={changeState}
                  todosEstados={todosEstados}
                  estados={estados}
                />
              </div>
              <div
              style={{
                display: "flex",
                flexBasis: "49.8%",
                flexDirection: "column"
              }}
              >
                <FiltroModalidade/>
                <FiltroCompradores />
              </div>
            </CardMsgWrap> */}
            <NomeacaoDeArquivos
              changeFileName={changeFileName}
              nomeacaoEditais={nomeacaoEditais}
            />
            <FiltrosExclusivosTexto>
              Filtros exclusivos para o portal <span>ComprasNet</span>
            </FiltrosExclusivosTexto>
            <CardMsgWrap>
              <TratamentoDiferenciado
                defaultValue={defaultValue}
                empresa_me={empresa_me}
                handleFiltroMe={handleFiltroMe}
                onChangeFiltro={onChangeFiltro}
              />
              <FiltroMateraisServico />
            </CardMsgWrap>
            <ActionButtonsContainer>
              <ModalConfirmacao
                button={
                  <LimparButton className="limpar icon-scale-xs">
                    <Trash size="27px" />
                    LIMPAR
                  </LimparButton>
                }
                width="700px"
                title="LIMPAR DADOS"
                message="Tem certeza que deseja limpar os dados?"
                confirmAction={limparVariaveis}
              />
              <SaveButton className="icon-scale-xs" onClick={salva_api}>
                <CheckCircle size="27px" />
                SALVAR
              </SaveButton>
            </ActionButtonsContainer>
          </>
        ) : (
            <h5
              style={{
                color: "#3a566e",
                marginBottom: "0",
                textAlign: "center"
              }}
            >
              Nenhum perfil encontrado,{" "}
              <ModalConfirmacao
                button={
                  <span
                    style={{
                      color: "#7390bd",
                      textDecoration: "undeline",
                      cursor: "pointer"
                    }}
                  >
                    clique aqui
                </span>
                }
                ico={<FilePlus color={"#3A566E"} size={"4rem"} />}
                width="700px"
                title="CRIAR NOVO PERFIL"
                message={<PerfilNameInput />}
                confirmAction={() =>
                  criarPerfil(document.getElementById("perf-name").value)
                }
              />{" "}
            para criar seu primeiro perfil
            </h5>
          )}
      </ContentBody>
      <Prompt
        when={houveAlteracao}
        message={lock =>
          `Suas alterações ainda não foram salvas, deseja realmente sair sem salvar?`
        }
      />
    </>
  );
});

export default ConfiguracaoEditais;

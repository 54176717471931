import React from 'react'
import { inject, observer } from 'mobx-react'
import { PortaisContainer, PortalContainer } from '../SelecaoPortal/styles'
import LogoBb from '../../../Images/bb_logo.jpeg'
import LogoComprasNet from '../../../Images/compras_net.png'

const PortalSelect = inject('LicitacoesExclusao')(
  observer(props => {
    const { portal, changePortal } = props.LicitacoesExclusao
    return (
      <PortaisContainer>
        <PortalContainer
          active={portal.includes(5)}
          onClick={() => changePortal(5)}
        >
          <img src={LogoComprasNet} alt="Logo ComprasNet" />
          <span>ComprasNet</span>
        </PortalContainer>
        <PortalContainer
          active={portal.includes(6)}
          onClick={() => changePortal(6)}
        >
          <img src={LogoBb} alt="Logo Banco do Brasil" />
          <span>Licitações-e</span>
        </PortalContainer>
      </PortaisContainer>
    )
  })
)

export default PortalSelect

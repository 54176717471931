import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import MenuStores from "./MenuStores";
import StoreConfiguracoesGerais from "./StoreConfiguracoesGerais";
import { cadastrar_topico_especifico } from "./FirebaseStore";
import $ from "jquery";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";

class StoreConfiguracaoEmpresas {
  @observable inputAdminPw = "";
  @observable adminValidation = false;

  // Exclusão de empresas
  @observable validarExcluir = false;

  // Dados da empresa atual
  @observable usuarios_id = [];
  @observable razao_social = "";
  @observable nome_fantasia = "";
  @observable cnpj = "";
  @observable phone = "";

  // ACTIONS
  @action onChange = e => {
    const field = e.target.id;
    this[field] = e.target.value;
  };

  @action popularEmpresa = empresa => {
    if (empresa === "closeModal") return this.limparDadosEmpresa();
    this.razao_social = empresa.razao_social;
    this.nome_fantasia = empresa.nome_fantasia;
    this.cnpj = empresa.cnpj;
    this.phone = empresa.phone;
    this.usuarios_id = empresa.usuarios.map(us => us.id);
  };

  @action openValidacaoAdmin = id => {
    if (MaquinaDeEstadosStore.getIdEmpresa() === Number(id))
      AlertaError(
        "Nào é possível excluir a empresa que está atualmente selecionada."
      );
    else this.validarExcluir = id;
  };

  @action fecharModal = () => (this.validarExcluir = false);

  @action validarAdmin = tipo => {
    if (tipo === "fecharModal") return (this.adminValidation = false);
    this.usuarios_id = $("#usuarios_id").val();
    if (this.validarInputs(tipo)) {
      this.adminValidation = true;
    }
  };

  @action verificaAdmin = async (action, toggleModal, id) => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.inputAdminPw);

    try {
      await Api.post("/login/", login, {});
      this[action](toggleModal, id);
      this.adminValidation = false;
    } catch (err) {
      AlertaError("Senha inválida!");
      console.log("err", err);
    } finally {
      this.inputAdminPw = "";
    }
  };

  @action adicionarEmpresa = toggleModal => {
    const data = new FormData();
    this.usuarios_id.forEach(item => {
      data.append("usuarios[]", item);
    });
    data.append("razao_social", this.razao_social);
    data.set("nome_fantasia", this.nome_fantasia);
    data.set("cnpj", this.cnpj);
    data.set("phone", this.phone);

    Api.post("cad_empresa/", data, {})
      .then(res => {
        AlertaSuccess("Empresa adicionada com sucesso!");
        this.adicionarAoStore(res.data.message);
        cadastrar_topico_especifico(this.cnpj);
        this.limparDadosEmpresa();
        toggleModal(false);
        // MenuStores.consulta_api();
      })
      .catch(err => {
        console.log("err", err);
        AlertaError(err.response.data.error);
      });
  };

  @action excluirEmpresa = () => {
    Api.post("cad_empresa/" + this.validarExcluir + "/delete/")
      .then(res => {
        AlertaSuccess("Empresa excluída com sucesso!");
      })
      .catch(err => {
        console.log("err", err);
      })
      .finally(() => {
        this.removerEmpresaDoStore(this.validarExcluir);
        this.validarExcluir = false;
      });
  };

  @action editarEmpresa = (toggleModal, id) => {
    const data = new FormData();
    this.usuarios_id.forEach(item => {
      data.append("usuarios[]", item);
    });
    data.append("razao_social", this.razao_social);
    data.set("nome_fantasia", this.nome_fantasia);
    data.set("cnpj", this.cnpj);
    data.set("phone", this.phone);
    Api.post("cad_empresa/" + id + "/", data, {})
      .then(res => {
        AlertaSuccess("Empresa editada com sucesso!");
        cadastrar_topico_especifico(this.cnpj);
        this.atualizarNoStore(res.data.message);
        this.limparDadosEmpresa();
        toggleModal(false);
      })
      .catch(error => {
        if ((error.response.status = 401))
          AlertaError("Erro ao editar empresa!");
        if (global.debug) {
          console.log("falhou SALVAR API", error);
        }
      });
  };

  adicionarAoStore = empresa => {
    const { empresas, usuarios } = MaquinaDeEstadosStore;
    const newEmpresas = [
      ...empresas,
      {
        ...empresa,
        usuarios: usuarios.filter(us =>
          this.usuarios_id.includes(String(us.id))
        ),
        credenciais: []
      }
    ];
    MaquinaDeEstadosStore.usuarios = usuarios.map(user =>
      this.usuarios_id.includes(String(user.id))
        ? { ...user, empresas: [...user.empresas, empresa] }
        : user
    );
    MaquinaDeEstadosStore.empresas = newEmpresas;
    MenuStores.empresas = newEmpresas;
    StoreConfiguracoesGerais.popularStore();
  };

  atualizarNoStore = empresa => {
    const { empresas, usuarios } = MaquinaDeEstadosStore;
    // MONTAR O ARRAY COM OS USUÁRIOS EDITADOS
    const newUsers = usuarios.map(us => {
      if (!this.usuarios_id.includes(String(us.id)))
        return {
          ...us,
          empresas: us.empresas.filter(emp => emp.id !== empresa.id)
        };
      return {
        ...us,
        empresas: us.empresas.map(empr => empr.id).includes(empresa.id)
          ? us.empresas.map(empre =>
              empre.id === empresa.id ? { ...empre, ...empresa } : empre
            )
          : [...us.empresas, empresa]
      };
    });
    const newCompany = newUsers.filter(us =>
      us.empresas.map(e => e.id).includes(empresa.id)
    );

    const newEmpresas = empresas.map(emp =>
      emp.id === empresa.id ? { ...emp, ...empresa, usuarios: newCompany } : emp
    );

    MaquinaDeEstadosStore.empresas = newEmpresas;
    MenuStores.empresas = newEmpresas;
    MaquinaDeEstadosStore.usuarios = newUsers;
    StoreConfiguracoesGerais.popularStore();
  };

  removerEmpresaDoStore = id => {
    const { empresas } = MaquinaDeEstadosStore;
    const newEmpresas = empresas.filter(el => el.id !== Number(id));
    MaquinaDeEstadosStore.empresas = newEmpresas;
    MenuStores.empresas = newEmpresas;
    StoreConfiguracoesGerais.empresas = newEmpresas;
  };

  limparDadosEmpresa = () => {
    this.usuarios_id = [];
    this.razao_social = "";
    this.nome_fantasia = "";
    this.cnpj = "";
    this.phone = "";
  };

  validarInputs = () => {
    this.adminValidation = false;
    this.usuarios_empresa = $("#usuarios_id").val();
    if (!this.adminValidation) {
      if (!this.usuarios_id.length) {
        AlertaError("Selecione ao menos um usuario!");
        return false;
      }
      if (!this.razao_social) {
        AlertaError("Campo razão social em branco!");
        return false;
      }
      if (!this.nome_fantasia) {
        AlertaError("Campo nome fantasia em branco!");
        return false;
      }
      if (!this.cnpj) {
        AlertaError("Campo CNPJ em branco!");
        return false;
      }
      if (!this.cnpj.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/)) {
        AlertaError("Campo de CNPJ inválido");
        return false;
      }
      if (!this.phone) {
        AlertaError("Campo de telefone em branco!");
        return false;
      }
      return true;
    }
  };

  @action limparVariaveis = () => {
    this.inputAdminPw = "";
    this.adminValidation = false;

    // Exclusão de empresas
    this.validarExcluir = false;

    // Dados da empresa atual
    this.usuarios_id = [];
    this.razao_social = "";
    this.nome_fantasia = "";
    this.cnpj = "";
    this.phone = "";
  };
}

const storeConfiguracaoEmpresas = new StoreConfiguracaoEmpresas();
export default storeConfiguracaoEmpresas;

import { CardFunil, CardHeaderFunil } from "./StyleFunil";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setLicitacaoId } from "../../../Services/licitacao";
import LicitacaoStores from "../../../Stores/LicitacaoStores";
import LicitacoesExclusao from "../../../Stores/LicitacoesExclusao";
import MaquinaDeEstadosStore from "../../../Stores/MaquinaDeEstadosStore";
import { Calendar, Clock, Trash2 } from "react-feather";

import moment from "moment";
moment.locale("pr-br");

function redireciona(id) {
  setLicitacaoId(id);
  LicitacaoStores.licitacao_vista(id);
  MaquinaDeEstadosStore.redireciona_licitacao();
}

const compararAno = (filtroAno, date) => {
  let data = date.substr(0, 4);

  if (data === filtroAno) {
    return true;
  }

  if (filtroAno === "todos") {
    return "todos";
  }
};

const Card = ({ portal, codigo, data_disputa, id, color }) => {
  const { excluirLicitacao } = LicitacoesExclusao;

  const handleExclusao = e => {
    e.stopPropagation();
    excluirLicitacao(id);
  };
  return (
    <div
      className={"icon-scale-xs"}
      style={{
        display: "flex",
        alignItems: "center",
        boxShadow: "0px 1px 2px 0.1px #00000073",
        fontSize: "12px",
        minHeight: "60px",
        padding: "10px",
        marginBottom: "5px"
      }}
      onClick={() => redireciona(id)}
    >
      <div style={{ width: "20px" }}>
        <FontAwesomeIcon
          icon="circle"
          style={{ color: color, fontSize: "7px", marginBottom: "1px" }}
        />
      </div>

      <div
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{portal}</div>
          <div>Nº {codigo}</div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontSize: "10px",
            textAlign: "center"
          }}
        >
          <div>
            <Calendar size={"13px"} />
            &nbsp;
            {moment(data_disputa).format("L")}
            &nbsp;&nbsp;
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "10px",
              textAlign: "center"
            }}
          >
            <Clock size={"10px"} />
            &nbsp;
            {moment(data_disputa).format("LT")}
          </div>
        </div>
        <button
          className="icon-scale-xs"
          data-tip="EXCLUIR LICITAÇÃO"
          data-place="left"
          data-type="error"
          data-effect="solid"
          style={{
            padding: 0,
            background: "transparent",
            border: "none"
          }}
          onClick={handleExclusao}
        >
          <Trash2 color="#f00" />
        </button>
      </div>
    </div>
  );
};

export const CardLicitacoes = ({ title, icon, color, dados, filtroAno }) => {
  return (
    <CardFunil>
      <CardHeaderFunil background={color}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon}
          &nbsp;&nbsp;
          <b>{title}</b>
        </div>
      </CardHeaderFunil>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "600px",
          margin: "20px 0px 20px 0px"
        }}
      >
        {dados.map(({ portal, codigo, id, data_disputa }) => {
          return (
            <>
              {compararAno(filtroAno, data_disputa) === "todos" ? (
                <Card
                  portal={portal}
                  codigo={codigo}
                  data_disputa={data_disputa}
                  id={id}
                  color={color}
                />
              ) : (
                compararAno(filtroAno, data_disputa) && (
                  <Card
                    portal={portal}
                    codigo={codigo}
                    data_disputa={data_disputa}
                    id={id}
                    color={color}
                  />
                )
              )}
            </>
          );
        })}
      </div>
    </CardFunil>
  );
};

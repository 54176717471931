import $ from 'jquery'
import { observable, action } from 'mobx'

import MaquinaDeEstadosStore from '../../../../Stores/MaquinaDeEstadosStore'
import Api from '../../../../Stores/Conexao/conexao'
import { tratarDadosEditais } from '../../Components/tratarDadosEditais'

class BuscaManualStore {
  @observable isFetching = false

  @observable total_paginas = 0
  @observable total_registros = 0
  @observable editais = []

  @observable ordenacao = 'data_disputa'
  @observable crescente = true

  @action changeEditais = (id, status) =>
    (this.editais = this.editais.map(ed =>
      ed.id === id ? { ...ed, status: status } : ed
    ))
  @observable pagination = {
    page: 1,
    changePage: page => (this.pagination.page = page)
  }

  @observable filterOpen = true
  @action toggleFilter = () => (this.filterOpen = !this.filterOpen)

  @observable filtros = {
    dmin: new Date().toLocaleDateString('pt-br'),
    dmax: new Date().toLocaleDateString('pt-br'),
    qualData: 'inicial',
    resetDate: () => {
      this.filtros.dmin = new Date().toLocaleDateString('pt-br')
      this.filtros.dmax = new Date().toLocaleDateString('pt-br')
    },
    changeDate: range => {
      const { qualData } = this.filtros

      this.filtros.dmin = new Date(range.startDate).toLocaleDateString('pt-br')
      this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

      return (this.filtros.qualData =
        qualData === 'inicial' ? 'final' : 'inicial')
    },
    onInputChange: e => {
      this.filtros[e.target.name] = e.target.value
    }
  }

  @observable estado = []
  @action setEstados = estado => {
    this.estado = Array.isArray(estado)
      ? estado
      : $('#selecionar-estados-busca').val()
  }

  @observable portal = {
    portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
    changePortais: portais => (this.portal.portais = portais)
  }

  @observable comprador = {
    codComprador: '',
    onChangeComprador: ({ target: { value } }) =>
      (this.comprador.codComprador = value)
  }

  @observable pregao = {
    codPregao: '',
    onChangePregao: ({ target: { value } }) => (this.pregao.codPregao = value)
  }

  @observable palavrasChave = {
    palavras: [],
    onChangePalavras: tags => {
      this.palavrasChave.palavras = tags
    },
    palavraAtual: '',
    onChangeInput: palavra => {
      this.palavrasChave.palavraAtual = palavra
    }
  }

  @observable lastTypeOfSearch = "";
  @observable typesOfSearch = ["ADVANCED", "BASIC"];

  getData = page => {
    const { dmin, dmax } = this.filtros
    return {
      pagina: page || this.pagination.page,
      dmax,
      dmin,
      pregao: this.pregao.codPregao,
      comprador: this.comprador.codComprador,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      palavra: this.palavrasChave.palavras.join(','),
      estado: this.estado,
      ordenacao: this.ordenacao,
      crescente: this.crescente
    }
  }

  getDataFiltro = (data, page) => {
    const {
      date: { dmin, dmax },
      estados,
      palavra,
      modalidade,
      tipoDate,
      portais,
      tipoItem,
      meEpp,
      palavraExclusao
    } = data
    this.filtros.dmin = dmin
    this.filtros.dmax = dmax
    let query = `/portal/pesquisa_editais/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmax=${dmax}&dmin=${dmin}`
    query += `&pagina=${page || this.pagination.page}`
    query += `&status=todos`
    query += `&tipo_data=${tipoDate}`
    query += `&portal=${portais}`
    query += `&estados=${estados}`
    query += `&palavras=${palavra}`
    query += `&palavras_exclusao=${palavraExclusao}`
    query += `&modalidade=${modalidade}`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    query += `&tipo_item=${tipoItem.join(',')}`
    query += `&empresa_me=${meEpp.join(',')}`
    return query
  }
  
  getDataFiltroSimple = (data) => {
    const { 
      comprador,
      pregao,
      page
    } = data
    let query = `/portal/pesquisa_editais_basico/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}` 
    query += `&comprador=${comprador}`
    query += `&pregao=${pregao}`
    query += `&pagina=${ page ? page : 1}`
    
    return query
  }

  @action fetchEditaisNovo = async data => {
    this.pagination.changePage(1)
    const param = this.getDataFiltro(data)
    this.lastTypeOfSearch = this.typesOfSearch[0];
    this.isFetching = true
    try {
      const res = await Api.get(param)
      const { editais, total_registros } = res.data
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
      if (this.filterOpen) this.toggleFilter()
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }
  
  @action fetchEditaisNovoSimple = async data => {
    this.pagination.changePage(1)
    const param = this.getDataFiltroSimple(data)
    this.lastTypeOfSearch = this.typesOfSearch[1];
    this.isFetching = true
    try {
      const res = await Api.get(param)
      const { editais, total_registros } = res.data
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
      if (this.filterOpen) this.toggleFilter()
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  @action fetchEditaisPagination = async data => {

    const param = this.lastTypeOfSearch === this.typesOfSearch[1] ? this.getDataFiltroSimple({...data, page : this.pagination.page}) : this.getDataFiltro(data);
    this.isFetching = true
    try {
      const res = await Api.get(param)
      const { editais, total_registros } = res.data
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
      if (this.filterOpen) this.toggleFilter()
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  @action fetchEditais = async () => {
    this.isFetching = true
    if (this.filterOpen) this.pagination.page = 1
    try {
      const res = await Api.post('/portal/pesquisa_editais/', this.getData())
      const { editais, total_registros } = res.data
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
      if (this.filterOpen) this.toggleFilter()
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  fetchMore = async (page, data) => {
    try {
      const res = await Api.get(this.getDataFiltro(data, page))
      return res.data.editais
    } catch (err) {
      console.log('err', err)
    }
  }

  trocouDeEmpresa = () => {
    this.limparVariaveis()
  }

  limparVariaveis = () => {
    this.isFetching = false

    this.total_paginas = 0
    this.total_registros = 0
    this.editais = []
    this.pagination = {
      page: 1,
      changePage: page => (this.pagination.page = page)
    }
    this.filterOpen = true

    this.filtros = {
      dmin: new Date().toLocaleDateString('pt-br'),
      dmax: new Date().toLocaleDateString('pt-br'),
      qualData: 'inicial',
      resetDate: () => {
        this.filtros.dmin = new Date().toLocaleDateString('pt-br')
        this.filtros.dmax = new Date().toLocaleDateString('pt-br')
      },
      changeDate: range => {
        const { qualData } = this.filtros

        this.filtros.dmin = new Date(range.startDate).toLocaleDateString(
          'pt-br'
        )
        this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

        return (this.filtros.qualData =
          qualData === 'inicial' ? 'final' : 'inicial')
      },
      onInputChange: e => {
        this.filtros[e.target.name] = e.target.value
      }
    }

    this.estado = []

    this.portal = {
      portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
      changePortais: portais => (this.portal.portais = portais)
    }

    this.palavrasChave = {
      palavras: [],
      onChangePalavras: tags => {
        this.palavrasChave.palavras = tags
      },
      palavraAtual: '',
      onChangeInput: palavra => {
        this.palavrasChave.palavraAtual = palavra
      }
    }
  }
}

export default new BuscaManualStore()

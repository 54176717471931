import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { format } from 'date-fns'
import { Bookmark, Star } from 'react-feather';
import DataTable from 'react-data-table-component';
import { Pagination } from '@material-ui/lab';
import Spinner from '../../../UsefulComponents/Spinner';
import SubHeader from '../SubHeader/SubHeader';
import SectionTitle from '../SubHeader/SectionTitle';
import BotaoFiltrar from "../Filter/botaoFiltro";
import { columnsAprovados } from './Column';
import { sortEditais } from '../Components/sortEditais';
import { Body, Container, ContainerPagination, ContainerTable } from '../Style';
import StatusModal from '../StatusNotice';
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext';
import FiltroQualificados from "../Filter/filtroQualificados";
import { ExclamationWarning } from '../../../Components/Notifications/ExclamationWarning'


const Aprovados = inject(
  'AprovadosStore',
  'MaquinaDeEstadosStore',
  'DetalhesEditalStore'
)(
  observer(({ AprovadosStore, MaquinaDeEstadosStore, DetalhesEditalStore }) => {
    // NECESSÁRIO CHAMAR AS VARIÁVEIS QUE VÃO SER OBSERVADAS AQUI.
    const {
      isFetching,
      fetchEdital,
      editais,
      total_registros,
      total_paginas,
      pagination,
      crescente
    } = AprovadosStore
    const { toggleModal } = DetalhesEditalStore
    const { id_fornecedor, getStatus } = MaquinaDeEstadosStore
    return (
      <AprovadosComStore
        toggleModal={toggleModal}
        data={{ isFetching, fetchEdital }}
        idEmpresa={id_fornecedor}
        editais={editais}
        total_registros={total_registros}
        total_paginas={total_paginas}
        pagination={pagination}
        crescente={crescente}
        getStatus={getStatus}
      />
    )
  })
)

const AprovadosComStore = ({
  data,
  idEmpresa,
  editais,
  total_paginas,
  pagination,
  crescente,
  toggleModal,
  getStatus
}) => {
  const { isFetching, fetchEdital } = data

  useEffect(() => {
    getStatus()
  }, [])

  useEffect(() => {
    if (idEmpresa) fetchEdital({
      date: { dmin : format(new Date(), 'dd/MM/yyyy'), },
      pregao : "",
      pregaoUASG : "",
      tipoDate : "abertura",
    })
  }, [idEmpresa])

  const { state, filtrarEditaisStatus } = useFiltroEdital()

  const handlePagina = (event, value) => {
    pagination.changePage(value)
    fetchEdital(state)
  }

  if (isFetching) return <Spinner />
  return (
    <Container>
      <SubHeader overflow>
        <SectionTitle title="APROVADOS" icon={<Bookmark />} />
        <BotaoFiltrar />
      </SubHeader>
      <Body>
        <ContainerTable>
        <FiltroQualificados status={'aprovado'} />
        {state.filtro && (
        <>
          <DataTable
            className="tabela-padrao"
            fixedHeader
            allowOverflow={true}
            overflowY={true}
            noHeader
            responsive={true}
            columns={columnsAprovados}
            onRowClicked={edital => toggleModal(edital.id, state)}
            data={editais}
            onSort={sortEditais}
            sortServer={true}
            defaultSortAsc={crescente}
            highlightOnHover
            striped={true}
            noDataComponent={
              <ExclamationWarning
                title="NENHUM EDITAL ENCONTRADO"
                height="75vh"
              />
            }
          />
          <ContainerPagination>
            <div>
              <Pagination
                count={total_paginas}
                color="primary"
                shape="rounded"
                page={pagination.page}
                onChange={(event, value) => handlePagina(event, value)}
                showFirstButton
                showLastButton
              ></Pagination>
            </div>
          </ContainerPagination>
          </>
      )}
        </ContainerTable>
      </Body>
      <StatusModal />
    </Container>
  )
}

export default Aprovados
import { action, observable } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";

class FunilStore {
  @observable todosPortais = [
    { name: "ComprasNet", id: 5 },
    { name: "LicitacoesE", id: 6 }
  ];
  @observable portal = this.todosPortais.map(el => el.id);
  // QUANTIDADE DE LICITAÇÕES
  @observable licitacoes_total = 0;
  @observable total_licitacoes_e = 0;
  @observable total_comprasnet = 0;

  @observable carregando = false;

  @observable agendadosLicitacoesE = [];
  @observable andamentosLicitacoesE = [];
  @observable anuladosLicitacoesE = [];
  @observable atasLicitacoesE = [];
  @observable realizadosLicitacoesE = [];
  @observable totalLicitacoesE = 0;

  @observable agendadosComprasNet = [];
  @observable andamentosComprasNet = [];
  @observable anuladosComprasNet = [];
  @observable atasComprasNet = [];
  @observable realizadosComprasNet = [];
  @observable totalComprasNet = 0;

  // @observable agendados = [];
  // @observable andamentos = [];
  // @observable anulados = [];
  // @observable atas = [];
  // @observable realizados = [];

  @observable ano = [];
  @observable filtroAno = "todos";
  @observable totalFiltrado = 0;

  @action
  incrementaTotalFiltrado = () => {
    this.totalFiltrado = this.totalFiltrado + 1;
  };

  @action
  handleChange = ({ target: { value } }) => {
    this.totalFiltrado = 0;
    this.filtroAno = value;
  };

  tratamentoDateAno = date => {
    let dados;
    dados = date.map(item => {
      return item.data_disputa.substr(0, 4);
    });
    this.ano = [...this.ano, ...dados];
    this.ano = [...new Set(this.ano)];
  };

  handleDateAno = () => {
    this.tratamentoDateAno(this.agendadosLicitacoesE);
    this.tratamentoDateAno(this.andamentosLicitacoesE);
    this.tratamentoDateAno(this.anuladosLicitacoesE);
    this.tratamentoDateAno(this.atasLicitacoesE);
    this.tratamentoDateAno(this.realizadosLicitacoesE);
    this.tratamentoDateAno(this.agendadosComprasNet);
    this.tratamentoDateAno(this.andamentosComprasNet);
    this.tratamentoDateAno(this.anuladosComprasNet);
    this.tratamentoDateAno(this.atasComprasNet);
    this.tratamentoDateAno(this.realizadosComprasNet);
  };

  @action changePortal = portal => {
    this.portal = this.portal.includes(portal)
      ? this.portal.filter(el => el !== portal)
      : [...this.portal, portal];
  };

  handleResponse = (portal, name) => {
    const { agendados, andamentos, realizadas, atas, anulados, total } = portal;
    this[`agendados${name}`] = agendados;
    this[`andamentos${name}`] = andamentos;
    this[`anulados${name}`] = anulados;
    this[`atas${name}`] = atas;
    this[`realizados${name}`] = realizadas;
    this[`total${name}`] = total;
  };

  @action consulta_api = () => {
    ModalLoadingStore.handleOpenModal();
    this.carregando = true;
    Api.get(`licitacoes/funil_novo/${MaquinaDeEstadosStore.getIdEmpresa()}`)
      .then(({ data }) => {
        const { total_licitacoes, licitacoes_e, comprasnet } = data;
        this.licitacoes_total = total_licitacoes;
        this.handleResponse(licitacoes_e, "LicitacoesE");
        this.handleResponse(comprasnet, "ComprasNet");
        this.handleDateAno();
      })
      .catch(err => {
        console.log("err", err);
      })
      .finally(() => {
        this.carregando = false;
        ModalLoadingStore.handleCloseModal();
      });
  };

  @action limparVariaveis = () => {
    this.carregando = false;
    this.licitacoes_total = 0;
    this.agendados = [];
    this.andamentos = [];
    this.anulados = [];
    this.atas = [];
    this.realizados = [];
    this.ano = [];
    this.filtroAno = "todos";
    this.totalFiltrado = 0;
  };
}

const funilStore = new FunilStore();
export default funilStore;

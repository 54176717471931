import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import { AlertaVerde, AlertaVermelho } from "./Funcoes/FuncoesGerais";
import LicitacoesPaginadaStore from "./LicitacoesPaginadaStore";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import FunilStore from "./FunilStore";

export const checkPathname = path => window.location.pathname === path;

class LicitacoesExclusao {
  @observable todosPortais = [
    { name: "ComprasNet", id: 5 },
    { name: "Licitações-e", id: 6 }
  ];
  @observable portal = this.todosPortais.map(el => el.id);

  @observable listaExcluidas = [];
  @observable totalExcluidas = 0;


  @action changePortal = portal => {
    this.portal = this.portal.includes(portal)
      ? this.portal.filter(el => el !== portal)
      : [...this.portal, portal];
  };


  @action consultaExcluidas = async () => {
    const { getIdEmpresa } = MaquinaDeEstadosStore;

    ModalLoadingStore.handleOpenModal();
    try {
      const res = await Api.get(`/licitacoes/licita_lixeira/${getIdEmpresa()}`);
      const { licitacoes, licitacoes_total } = res.data;
      this.listaExcluidas = licitacoes;
      this.totalExcluidas = licitacoes_total;
    } catch (err) {
      console.log("err", err);
      AlertaVermelho("Não foi possível listar suas licitações excluídas", 3000);
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action excluirLicitacao = async id => {
    ModalLoadingStore.handleOpenModal();
    try {
      const { licitacoes } = LicitacoesPaginadaStore;
      const res = await Api.get(`/licitacoes/delete_licitacao/${id}`);
      const newLicitacoes = licitacoes.filter(lic => lic.id !== id);
      if (checkPathname("/licitacoes")) {
        LicitacoesPaginadaStore.licitacoes = newLicitacoes;
        LicitacoesPaginadaStore.licitacoesFilter = newLicitacoes;
      }
      if (checkPathname("/funil")) await FunilStore.consulta_api();
      AlertaVerde("Licitação excluída com sucesso.", 3000);
    } catch (err) {
      console.log("err", err);
      AlertaVermelho("Erro ao excluír licitação", 3000);
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action restaurarLicitacao = async id => {
    ModalLoadingStore.handleOpenModal();
    try {
      const res = await Api.get(`/licitacoes/restore_licitacao/${id}`);
      this.listaExcluidas = this.listaExcluidas.filter(lic => lic.id !== id);
      AlertaVerde("Licitação restaurada com sucesso", 3000);
    } catch (err) {
      console.log("err", err);
      AlertaVermelho("Erro ao restaurar licitação.", 3000);
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };
}

export default new LicitacoesExclusao();

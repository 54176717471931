import React from 'react'
import { Users } from 'react-feather'

import { useSetting } from './Context/index.js'
import { SelectInput } from '../../../UsefulComponents/Inputs'

import { List, ListItem } from './Style/styles'

const ListOfOptions = () => {
  const {
    settings,
    toggleSettings,
    profiles,
    toggleIndex,
    index, idProfile
  } = useSetting()

  const selectProfile = e => {
    console.log(e.target.value)
    toggleIndex(e.target.value)
  }

  const handleSelection = title => {
    toggleSettings(
      settings.map(item =>
        item.title === title
          ? { ...item, active: true }
          : { ...item, active: false }
      )
    )
  }

  return (
    <List>
      <div className="header">
        <div className="title">SELECIONE O PERFIL</div>
        <SelectInput
          color={'#3a566e'}
          icon={<Users />}
          id="select-perfil-config"
          width="80%"
          bgColor="#fff"
          value={index}
          onChange={selectProfile}
        >
          {profiles.map((profile, index) => (
            <option value={index} key={index}>{profile.nome_perfil}</option>
          ))}
        </SelectInput>
      </div>
      <div className="body">
        {settings.map((item, index) => (
          <>
            <ListItem
              key={index}
              active={item.active}
              onClick={() => handleSelection(item.title)}
            >
              <div className="icon">{item.icon}</div>
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="description">{item.subTitle}</div>
              </div>
            </ListItem>
            {index === 0 && <hr></hr>}
          </>
        ))}
      </div>
    </List>
  )
}

export default ListOfOptions

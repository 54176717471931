import React, { useEffect, useState } from 'react';
import ReactDataGrid from "react-data-grid";
import { inject, observer } from "mobx-react";
import { Container } from "./styles";
import { Body, ContainerTabela } from "../../../styles";
import { ToastError } from "../../../../../Stores/Funcoes/FuncoesGerais";
import ItemDesabilitado from './ItemDesabilitado';
import CustomEditor from './CustomEditor';
import MiniModal from "./ModalConfirmarItens/contexto/MiniModal";
import Api from '../../../../../Stores/Conexao/conexao';

const ListaDeItens = inject("CadastrarPropostaStore", "MaquinaDeEstadosStore", 'ItensPropostaStore', 'ImprimirPropostaStore')
  (observer(({ CadastrarPropostaStore, MaquinaDeEstadosStore, ItensPropostaStore, ImprimirPropostaStore }) => {
    const { id_fornecedor } = MaquinaDeEstadosStore;
    const { toggleModalConfirmar, salvarDados } = CadastrarPropostaStore;
    const { declaracao } = ImprimirPropostaStore
    const { data, handleShowItens, colunas, keys, editalSelecionado } = ItensPropostaStore
    return <Lista data={data} colunas={colunas} id_fornecedor={id_fornecedor} handleShowItens={handleShowItens}
      toggleModalConfirmar={toggleModalConfirmar} keys={keys} salvarDados={salvarDados} editalSelecionado={editalSelecionado} declaracao={declaracao} />
  }));

const Lista = ({ data, toggleModalConfirmar, keys, handleShowItens, salvarDados, editalSelecionado, declaracao }) => {
  const [colluns, setColluns] = useState()
  const [rows, setRows] = useState(data)
  const [index, setIndex] = useState([])
  const [suspenso, setSuspenso] = useState(false);

  useEffect(() => {
    async function getData() {
      try {
        const res = await Api.get(`/portal/proposta_verifica_edital/?edital=${editalSelecionado}`);
        if (res.status === 404) setSuspenso(true);
        if (res.status === 200) setSuspenso(false);
      } catch (err) {
        if (err.status && (err.status === 404)) setSuspenso(true);
      }
    }
    getData();
  }, [])

  useEffect(() => {
    // Responsável por selecionar os itens que já estão preenchidos na tabela automaticamente após montar o componente.
    if (colluns) {
      const indexes = [];
      data.forEach((ed, i) => {
        if (ed.checked) indexes.push(i);
      })
      setIndex(indexes);
    }
  }, [colluns])

  const statusDesconto = ({ value }) => {
    if (value !== null) return (<div style={{ color: 'red' }} title={value}>{value}</div>)
    return (<ItemDesabilitado />)
  }

  const statusOfertada = ({ value, row }) => {
    const editavel = row.qtd_ofertada_editavel
    if (!editavel) return (<ItemDesabilitado value={value} status={true} />)
    return (<div title={value}>{value}</div>)
  }


  const CheckBox = ({ value, row }, type) => {
    const [dados, seDados] = useState(value)
    const handleClick = (dados, rows, values) => {
      seDados(!value)
      setRows((prev) => prev.map(r => r.item_edital_id === rows.item_edital_id ? { ...r, [type]: !r[type] } : r));
    }
    if (value === null) return <ItemDesabilitado />
    return (
      <div className="react-grid-checkbox-container checkbox-align">
        <input className="react-grid-checkbox" style={{ background: '#005295', }} type="checkbox" checked={dados} />
        <label htmlFor="checkbox0" className="react-grid-checkbox-label" onClick={() => handleClick('janio', row, value)} />
      </div>
    )
  };


  const columnsTabela = [
    { key: "item_edital_id", width: 8 },
    { key: "grupo", name: "GRUPO", editable: false, width: 75, sortable: true, resizable: true },
    { key: "item", name: "ITEM", editable: false, width: 75, sortable: true, resizable: true },
    {
      key: "descricao_completa",
      name: "OBJETO/DESCR.",
      editable: true,
      width: window.innerWidth / 4,
      sortable: true,
      resizable: true,
      editor: MiniModal
    },
    { key: "marca", name: "MARCA", editable: true, sortable: true, resizable: true, },
    { key: "fornecedor", name: "FORNECEDOR", editable: true, sortable: true, resizable: true, },
    { key: "modelo_versao", name: "MODELO", editable: true, sortable: true, resizable: true, },
    { key: "fabricante", name: "FABRIC.", editable: true, sortable: true, resizable: true, },
    { key: "qtd_estimada", name: "QTD ESTIMADA.", sortable: true, resizable: true },
    { key: "desconto", name: "DESCONTO", sortable: true, editable: true, resizable: true, formatter: statusDesconto },
    { key: "qtd_ofertada", name: "QTD. OFERTADA", editable: true, sortable: true, resizable: true, formatter: statusOfertada },
    { key: "valor_unit", name: "VALOR UNIT.", sortable: true, resizable: true, editor: CustomEditor },
    { key: "aliquota", name: "ALIQ.", sortable: true, resizable: true, },
    { key: "valor_total", name: "R$ TOTAL", sortable: true, resizable: true },
    { key: "valor_total_equal", name: "R$ TOTAL EQ", sortable: true, resizable: true },
    { key: "ppb", name: "7174 PPB", sortable: true, resizable: true, width: 65, formatter: (e) => CheckBox(e, 'ppb') },
    { key: "tp", name: "7174 TP", sortable: true, resizable: true, width: 65, formatter: (e) => CheckBox(e, 'tp') },
  ];

  useEffect(() => {
    setColluns(columnsTabela.filter(c => keys.includes(c.key)))
  }, [keys])

  const calculoValorTotal = (updatedRow, rowMultiplicadora) => {
    let newMultiplicadora = rowMultiplicadora
    if (typeof rowMultiplicadora === "string" && rowMultiplicadora.includes(',')) {
      newMultiplicadora = rowMultiplicadora.replace(',', '.')
    }
    const valorTotal = (updatedRow * newMultiplicadora).toLocaleString('pt-br', { minimumFractionDigits: 4 })
    return valorTotal
  }

  const totalEqualizado = (total, alicota) => {

    if (alicota == null) return null
    if (total.includes('.')) {
      total = total.replace('.', '')
    }
    const tratamentoAlicota = Number(alicota.replace(",", "."))
    const newTotal = Number(total.replace(/\./g, '').replace(",", "."))
    const totalEq = (tratamentoAlicota * newTotal).toLocaleString('pt-br', { minimumFractionDigits: 4 })
    return totalEq
  }

  const validationCaracters = (updated) => {
    const dados = Object.keys(updated)[0]
    switch (dados) {
      case ('marca'):
        if (updated.marca.length > 20) {
          ToastError('Marca não pode ter mais que 20 caracteres')
          return false
        }
        return true
      case ('modelo_versao'):
        if (updated.modelo_versao.length > 50) {
          ToastError('Modelo não pode ter mais que 50 caracteres')
          return false
        }
        return true
      case ('fabricante'):
        if (updated.fabricante.length > 50) {
          ToastError('Fabricante não pode ter mais que 50 caracteres')
          return false
        }
      default:
        return true
    }

  }

  const handleValueString = ({ updated, fromRow, toRow }) => {    
    if (!validationCaracters(updated)) return null

    const newArray = [...rows]
    if (fromRow !== toRow) {
      for (let i = fromRow; i <= toRow; i++) {
        newArray[i] = { ...newArray[i], ...updated }
      }
      setRows(newArray)
    } else {
      newArray[fromRow] = { ...newArray[fromRow], ...updated }
      setRows(newArray)
    }
  };

  const calculoValorTotalDesconto = (row, desconto) => {
    const multiplicador = row.qtd_ofertada === undefined ? 'qtd_estimada' : 'qtd_ofertada';
    const valorUnit = Number(row['valor_unit'].replace(/\./g, '').replace(',', '.'))
    const qtdOfertada = Number(row[multiplicador])
    const valorTotal = valorUnit * qtdOfertada
    const valorDesconto = (valorTotal / 100) * desconto

    const valorTotalDesconto = (valorTotal - valorDesconto)
    return valorTotalDesconto.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  const verificaStatus = (fromRowData, condicional, param) => {

    switch (condicional) {
      case 'desconto':
        let status = fromRowData[condicional]
        if (param) {
          if (status == null) {
            return false
          }
          if (status !== null) {
            return true
          }
        }
        if (status == null) {
          return true
        }
        if (status == '') {
          return false
        }
        break
      case 'qtd_editavel':
        return true
        break
      default:
        return false
    }
  }

  const transfValorNumero = (dados, type, param) => {


    // let numero = dados
    let numero = dados.toString().replace(/\./g, '');
    if (param) {
      const removePercentage = () => {
        const num = numero.replace('%', '').replace(',', '');
        return num.slice(0, num.length - 2) + '.' + num.slice(-2);
      }

      const tratamento = numero.slice(-1) === "%" ? removePercentage() : numero;
      const result = Number(tratamento.replace(",", ".")).toFixed(2);
      return result;
    }

    // if (type === 'desconto') {
    //   return Number(numero.replace(/\./g, '').replace(",", "."))
    // }

    if (type == 'qtd_ofertada') {
      if (numero.includes('.') || numero.includes(',')) {
        return Number(numero.replace(",", "."))
      }
      return numero
    }
    return Number(numero.replace(",", ".")).toFixed(4)
  }

  const handleValueDesconto = (row, type) => {
    const { updated, fromRow, toRow, fromRowData } = row
    const { aliquota } = fromRowData
    let status = verificaStatus(fromRowData, 'desconto', true)
    const newArray = [...rows]
    const updatedRow = transfValorNumero(updated[type], type, true)
    let dados = newArray[fromRow]
    let total = calculoValorTotalDesconto(dados, updatedRow)

    if (status) {
      newArray[fromRow] = {
        ...newArray[fromRow],
        desconto: updatedRow + '%',
        valor_total: total,
        valor_total_equal: totalEqualizado(total, aliquota)
      }
      setRows(newArray)
    }

    if (fromRow !== toRow) {
      for (let i = fromRow; i <= toRow; i++) {
        let { aliquota } = newArray[i]
        let status = verificaStatus(newArray[i], 'desconto', true)
        let total = calculoValorTotalDesconto(newArray[i], updatedRow)
        if (status) {
          newArray[i] = {
            ...newArray[i], ...updated,
            desconto: updatedRow + '%',
            valor_total: total,
            valor_total_equal: totalEqualizado(total, aliquota)
          }
        }
      }
      setRows(newArray)
    }
  };

  const handleUpdatedValuesUnitario = (row, type, multiplicador, condicional, param) => {
    const { updated, fromRow, toRow, fromRowData, } = row
    const { aliquota } = fromRowData

    let status = verificaStatus(fromRowData, condicional, param)
    const newArray = [...rows]

    const updatedRow = transfValorNumero(updated[type], type, param)
    const rowMultiplicadora = Number(newArray[fromRow][multiplicador])
    const total = calculoValorTotal(updatedRow, rowMultiplicadora)

    const validaRowNull = () => {
      if (updatedRow === '0.0000' || updatedRow === 'NaN') { return '' }
      return Number(updatedRow).toLocaleString('pt-br', { minimumFractionDigits: 4 })
    }

    // UPADET EM UM LINHA
    if (status) {
      newArray[fromRow] = {
        ...newArray[fromRow],
        [type]: validaRowNull(),
        valor_total: validaRowNull() ? calculoValorTotal(updatedRow, rowMultiplicadora) : '',
        valor_total_equal: validaRowNull() ? totalEqualizado(total, aliquota) : ''
      }
      setRows(newArray)
    }
    // PUXANDO PRA BAIXO ESTILO EXCEL
    if (fromRow !== toRow) {
      for (let i = fromRow; i <= toRow; i++) {
        let { aliquota } = newArray[i]
        let status = verificaStatus(newArray[i], condicional)
        let rowMultiplicadora = newArray[i][multiplicador]
        const total = calculoValorTotal(updatedRow, rowMultiplicadora)

        if (status) {
          newArray[i] = {
            ...newArray[i],
            [type]: validaRowNull(),
            valor_total: validaRowNull() ? total : '',
            valor_total_equal: validaRowNull() ? totalEqualizado(total, aliquota) : ''
          }
        }
      }
      setRows(newArray)
    }
  }

  const handleUpadetValues = (row, type, multiplicador, condicional, param) => {
    //qtd_ofertada_editavel


    const { updated, fromRow, toRow, fromRowData, } = row
    const { aliquota, qtd_ofertada_editavel } = fromRowData

    let status = verificaStatus(fromRowData, condicional, param)
    const newArray = [...rows]

    const updatedRow = transfValorNumero(updated[type], type, param)
    const rowMultiplicadora = newArray[fromRow][multiplicador].replace(',', '.')
    let total = calculoValorTotal(updatedRow, rowMultiplicadora);
    let dados = { ...newArray[fromRow], ...updated };



    if (dados.desconto) {
      const updatedDesc = transfValorNumero(dados.desconto, 'desconto', true);
      total = calculoValorTotalDesconto(dados, updatedDesc)
    }


    // UPADET EM UM LINHA
    if (status && qtd_ofertada_editavel) {
      newArray[fromRow] = {
        ...newArray[fromRow],
        [type]: updatedRow,
        valor_total: total,
        valor_total_equal: totalEqualizado(total, aliquota)
      }
      setRows(newArray)
    }
    // PUXANDO PRA BAIXO ESTILO EXCEL
    if (fromRow !== toRow) {
      for (let i = fromRow; i <= toRow; i++) {
        let { aliquota, desconto, qtd_ofertada_editavel } = newArray[i]
        let status = verificaStatus(newArray[i], condicional)
        let rowMultiplicadora = newArray[i][multiplicador].replace(',', '.');
        let total = calculoValorTotal(updatedRow, rowMultiplicadora);
        let dados = { ...newArray[i], ...updated };
        if (dados.desconto) {
          const updatedDesc = transfValorNumero(dados.desconto, 'desconto', true);
          total = calculoValorTotalDesconto(dados, updatedDesc)
        }
        // const total = calculoValorTotal(updatedRow, rowMultiplicadora)

        if (status && qtd_ofertada_editavel) {
          newArray[i] = {
            ...newArray[i],
            [type]: updatedRow,
            valor_total: total,
            valor_total_equal: totalEqualizado(total, aliquota)
          }
        }
      }
      setRows(newArray)
    }
  };

  const onGridRowsUpdated = (row) => {
    const multiplicador = row.fromRowData.qtd_ofertada === undefined ? 'qtd_estimada' : 'qtd_ofertada';    
    switch (row.cellKey) {
      case 'valor_unit':
        handleUpdatedValuesUnitario(row, 'valor_unit', multiplicador, 'desconto', false)
        break
      case 'qtd_ofertada':
        handleUpadetValues(row, 'qtd_ofertada', 'valor_unit', 'qtd_editavel', false)
        break
      case 'desconto':
        handleValueDesconto(row, 'desconto')
        break
      default:
        handleValueString(row)
    }
  };

  const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  const onRowsSelected = rows => {

    const validateTratDif = ({ row: { trat_diferenciado } }) => {
      const { participar } = declaracao
      if (trat_diferenciado === "-") return true

      if (participar) return true

      if (!participar) {
        ToastError(`Item reservado para empresas com tratamento diferenciado: ${trat_diferenciado}`)
        return false
      }
    }


    const newRow = rows.filter((row) => {
      if (row.row) {
        row.row.descricao_detalhada = row.row.descricao_completa
        const value = Object.values(row.row)
        const valid = value.every(elem => elem !== '')
        if (valid && validateTratDif(row)) {
          row.row.checked = true;
          return valid && row
        } else {
          return false
        }
      }
    })

    const dados = index.concat(newRow.map(r => r.rowIdx))
    setIndex(dados)

    const { row } = rows[0]

    // row.descricao_detalhada = row.descricao_completa
    const value = Object.values(row)
    const validation = value.every(elem => elem !== '');

    if (!validation && validateTratDif(rows[0])) {
      const keys = Object.keys(row);
      const value = Object.values(row);
      value.forEach((el, i) => {
        if (value[i]) keys.splice(i, 1)
      });

      ToastError('Item não pode ser selecionado preencha todos os campos')
    }
  };

  const onRowsDeselected = item => {
    const arr = [...index];
    item.forEach((x, y) => {
      const index = arr.findIndex(el => el === item[y].rowIdx);
      arr.splice(index, 1);
      item[y].row.checked = false;
    })
    setIndex(arr);
  };

  const handleListaSelect = async () => {


    if (index.length !== 0) {
      salvarDados(rows);

      let dados = rows.filter((item, i) => {
        if (index.indexOf(i) !== -1) {
          return item
        }
      })

      const array = dados.reduce((res, musicName) => {
        const { grupo } = musicName
        if (grupo !== null) {
          res.push(grupo);
        }
        return res;
      }, []);

      const setUnico = new Set(array);
      const novoDado = [...setUnico]

      const ArryGrupo = rows.filter(({ grupo }) => {
        if (!grupo) return false;
        return (novoDado.indexOf(grupo) != -1)
      })

      const verificaDadosRow = (items) => {
        const { grupo, item, descricao_completa, descricao_detalhada, descricao, qtd_estimada, valor_total_equal, decreto_7174,
          valor_total, item_edital_id, trat_diferenciado, aliquota, unidade_fornecimento, qtd_ofertada_editavel, ...keys } = items
        let valueKeys = Object.values(keys)
        return valueKeys.every(elem => (elem !== '') && (elem !== false))
      }

      const verificaDadosArrayGrupo = ArryGrupo.every((items) => {
        if (!verificaDadosRow(items)) {
          ToastError('Verifique os campos do grupo ' + items.grupo)
        }
        return verificaDadosRow(items)
      })


      const validation = dados.every((item) => {
        const value = Object.values(item)
        return value.every(elem => elem !== '')
      })

      if (validation && verificaDadosArrayGrupo) {
        suspenso ? ToastError('Edital suspenso.') : toggleModalConfirmar(dados);
      } else {
        ToastError('Preencha todos os campos')
      }

    } else {
      ToastError('Selecione um item, para prosseguir com envio da proposta')
    }
  }



  const handleSalvarDados = () => {

    const verificaDados = (items) => {
      const { grupo, item, descricao_completa, descricao_detalhada, descricao, qtd_estimada, valor_total_equal, decreto_7174,
        valor_total, item_edital_id, trat_diferenciado, aliquota, unidade_fornecimento, qtd_ofertada_editavel, ...keys } = items
      let valueKeys = Object.values(keys)
      return valueKeys.some(elem => (elem !== '') && (elem !== false))
    }

    const newArray = rows.filter((items) => {
      return verificaDados(items) && items
    })


    salvarDados(newArray);
  };

  return (
    <>
      <Body height="calc(100vh - 124px)" paddingTop="6px">
        <ContainerTabela>
          <Container>
            {colluns &&
              <ReactDataGrid
                rowKey="id"
                className="tabela-editar-itens"
                columns={colluns}
                rowGetter={i => rows[i]}
                rowsCount={rows.length}
                onGridRowsUpdated={(e) => onGridRowsUpdated(e)}
                enableCellSelect={true}
                onColumnResize={(idx, width) =>
                  console.log(`Column ${idx} has been resized to ${width}`)
                }
                onGridSort={(sortColumn, sortDirection) =>
                  setRows(sortRows(rows, sortColumn, sortDirection))
                }
                rowSelection={{
                  showCheckbox: true,
                  enableShiftSelect: true,
                  onRowsSelected: onRowsSelected,
                  onRowsDeselected: onRowsDeselected,
                  selectBy: { indexes: index }
                }}
              />
            }
          </Container>
          <div className="botoes-container">
            <button id="salvar-proposta" onClick={handleSalvarDados}>SALVAR</button>
            <button id="confirmar-proximo-passo" onClick={handleListaSelect}>PRÓXIMO PASSO</button>
            <button id="fechar-modal-confirmacao" onClick={handleShowItens}>FECHAR</button>
          </div>
        </ContainerTabela>
      </Body>

    </>

  );
};

export default ListaDeItens;

import React from 'react';
import { observable, action } from "mobx";
import Api from "../../../../../Stores/Conexao/conexao";
import MaquinaDeEstadosStore from "../../../../../Stores/MaquinaDeEstadosStore";
import ModalLoadingStore from "../../../../../Stores/ModalLoadingStore";
import { removeAcento, removeSpecialChar, ToastSuccess, ToastError, ToastInfo, ToastDark } from '../../../../../Stores/Funcoes/FuncoesGerais';
import Axios from 'axios';
import ImprimirPropostaStore from '../../../ImprimirProposta/store';
import CadastrarPropostaStore from '../../store';

class itensPropostaStore {
  @observable data = [];
  @action changeData = (data) => (this.data = data);
  @observable edital = '';
  @observable editalSelecionado = null;
  @observable showItens = false;
  @observable isFetching = false
  @observable colunas = []
  @observable keys = []
  @observable filename = "";

  @observable sentPDF = null;

  // DOCUMENTOS DE PROPOSTA / HABILITAÇÃO
  @observable loadingDocuments = false;
  @observable document_proposta = null;
  @observable document_habilitacao = null;
  @action changeDocument = (document, type) => {
    let jaExiste;
    let name;
    let file;
    if (document) {
      name = removeSpecialChar(removeAcento(document.name)); // Tratamento de caracteres especiais
      file = new File([document], name);
      jaExiste = this[`docs_${type}`].find(doc => doc.name === file.name);
    }
    if (jaExiste) return ToastError('Já existe um documento com esse nome.')
    this[`document_${type}`] = file
  };
  @observable docs_proposta = [];
  @observable docs_habilitacao = [];
  @action changeDocumentsArray = (arr, type) => (this[`docs_${type}`] = arr);
  @observable files_proposta = [];
  @observable files_habilitacao = [];

  @action deleteDocument = (id) => {
    this.loadingDocuments = true;
    Axios
      .delete(`${global.url}/portal/proposta_excluir_documento/${id}`)
      .then(res => {
        ToastSuccess("Documento excluído!", 1000);
        this.changeDocumentsArray(this.docs_habilitacao.filter(el => el.id !== id), 'habilitacao');
        this.changeDocumentsArray(this.docs_proposta.filter(el => el.id !== id), 'proposta');
      })
      .catch(err => {
        console.log("err", err);
        ToastError("Não foi possível excluir esse documento!", 3000);
      })
      .finally(() => {
        this.loadingDocuments = false;
      });
  }


  @action handleShowItens = () => {
    this.showItens = !this.showItens
  }

  @action fetchItensManual = async (edital, rows) => {
    const { comprador, data_disputa, descricao, id, numero, data_abertura, modalidade } = edital;
    const keys = Object.keys(rows[0]);
    this.edital = {
      comprador,
      data_disputa,
      descricao,
      id,
      numero,
      data_abertura,
      modalidade
    };
    this.editalSelecionado = id;
    this.keys = keys;
    this.data = rows;
    this.showItens = true;
  }


  @action fetchItensEditais = async (edital) => {
    const { comprador, data_disputa, descricao, id, numero, data_abertura, modalidade } = edital;
    ModalLoadingStore.handleOpenModal();
    this.isFetching = true;
    this.showItens = false
    let query = `/portal/proposta_itens/?edital=${id}&fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`;
    this.editalSelecionado = id;
    try {
      const res = await Api.get(query);
      const keys = Object.keys(res.data.rows[0]);
      const edital = {
        numero,
        data_disputa,
        comprador,
        descricao,
        data_abertura,
        modalidade
      }
      this.edital = edital;
      this.keys = keys
      // this.handleColuna(keys)
      this.data = res.data.rows
      this.showItens = true

    } catch (err) {
      console.log('err', err);
    } finally {
      ModalLoadingStore.handleCloseModal();
      this.isFetching = false
    };
  };

  // limparDocumentos = () => {
  //   this.docs_proposta.splice(1);
  //   this.files_proposta.splice(1);  // Deixar o PDF que foi gerado.
  //   this.docs_habilitacao = [];
  //   this.files_habilitacao = [];
  // }

  @action fetchDocuments = () => {
    // this.limparDocumentos();
    this.docs_proposta = [];
    this.docs_habilitacao = [];
    Api.get(`portal/proposta_documentos/?edital=${this.editalSelecionado}&fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`)
      .then((res) => {
        let { anexo_habilitacao, anexo_proposta } = res.data;
        anexo_habilitacao = anexo_habilitacao.map(el => ({ ...el, enviado: true }))
        anexo_proposta = anexo_proposta.map(el => ({ ...el, enviado: true }))
        this.docs_proposta = anexo_proposta;
        this.docs_habilitacao = anexo_habilitacao;
        const { comprador: { nome }, numero } = ItensPropostaStore.edital;
        const pos = nome.lastIndexOf('UASG:') + 6;
        const uasg = nome.slice(pos);
        this.filename = `Proposta_${numero}_${uasg}.pdf`;
        this.docs_proposta.push({ name: this.filename, loading: true });
        this.files_habilitacao = [];
        this.files_proposta = [];
        // // if (this.sentPDF) {
        // //   anexo_proposta.push({ name: this.sentPDF, loading: true });
        // //   this.sentPDF = null;
        // // }


        // anexo_proposta.forEach(el => {
        //   const index = this.docs_proposta.findIndex(doc => doc.name === el.name);
        //   if (index > -1) {
        //     this.docs_proposta[index] = { ...el, enviado: true }; // Substituir se houver
        //     this.files_proposta.pop();
        //   }
        //   else this.docs_proposta.push(el);
        // });
        // anexo_habilitacao.forEach(el => this.docs_habilitacao.push(el));
      })
      .catch(err => console.log('err', err))
  };

  @action handleAnexoDocumento = async (type) => {
    const document = this[`document_${type}`];
    this[`files_${type}`].push(document);
    this[`docs_${type}`].push({
      name: document.name,
      enviado: false
    })
    this[`document_${type}`] = null;
  };

  @action enviarDocumentos = async () => {
    await this.files_proposta.forEach(file => {
      this.enviarDocumento(file, 'proposta')
    })
    await this.files_habilitacao.forEach(file => {
      this.enviarDocumento(file, 'habilitacao')
    })
  }

  enviarDocumento = async (file, type) => {
    const queryString = `/portal/proposta_doc_${type}/`;

    this[`docs_${type}`] = this[`docs_${type}`].map(el => el.name === file.name || (file.name.includes('Proposta_') && el.name === this.filename) ? { ...el, loading: true } : el);

    const formData = new FormData();
    formData.append("edital", this.editalSelecionado);
    formData.append("fornecedor", MaquinaDeEstadosStore.getIdEmpresa());
    formData.append(`anexo_${type}`, file);
    try {
      const res = await Api.post(queryString, formData);
      this.checkAnexo(file, type); // Ajustar intervalo para checkar o status do documento

      // this[`document_${type}`] = null;
      // this.changeDocumentsArray([...this[`docs_${type}`], { name: document.name, loading: true }], type);
    }
    catch (err) {
      console.log('err', err);
    }
    finally {
      // this.loadingDocuments = false;
    }
  }

  // Essa função ativará um intervalo para bater requests e checkar se o documento já foi enviado
  @action checkAnexo = (document, type) => {
    const { name } = document;
    const query = `/portal/proposta_verifica_documento/?edital=${this.editalSelecionado}&fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}&nome_arquivo=${name}`;
    let interval = setInterval(async () => {
      let index = this[`docs_${type}`].findIndex(doc => {
        if (name.includes('Proposta_') && doc.name === this.filename) return true;
        return doc.name === name;
      }
      );

      try {
        const res = await Api.get(query);
        const { message, id } = res.data;
        if (!message) return;  // Backend irá retornar null caso a thread ainda esteja sendo executada
        if (message.includes('Anexo enviado com sucesso')) {
          this[`docs_${type}`][index].loading = false;
          this[`docs_${type}`][index].enviado = true;
          this[`docs_${type}`][index].id = id;
          // this.fetchDocuments();
          // this.changeDocumentsArray(this[`docs_${type}`].map(el => el.name === name ? { ...el, loading: false, created_at: new Date().toISOString() } : el), type);
        }
        else this.changeDocumentsArray(this[`docs_${type}`].map(el => el.name === name ? { ...el, error: message, loading: false } : el), type);
        clearInterval(interval);
      }
      catch (err) {
        this[`docs_${type}`][index].loading = false;
        this[`docs_${type}`][index].error = true;
        console.log('err', err);
        clearInterval(interval);
      }
    }, 5000)
  };

  @action removeFromList = (name) => {
    // if (name.includes('Proposta_')) this.files_proposta.shift();
    this.changeDocumentsArray(this.docs_habilitacao.filter(el => el.name !== name), 'habilitacao');
    this.changeDocumentsArray(this.docs_proposta.filter(el => el.name !== name), 'proposta');
    this.files_proposta = this.files_proposta.filter(el => {
      if (name.includes('Proposta_')) return !el.name.includes('Proposta_');
      return el.name !== name
    });
    this.files_habilitacao = this.files_habilitacao.filter(el => el.name !== name);
  }



  @action enviarProposta = async e => {
    this.enviandoDocumentos = true;
    this.showItens = false;
    await this.enviarDocumentos();
    const interval = setInterval(() => {
      if (
        this.docs_proposta.concat(this.docs_habilitacao).some(doc => doc.enviado)
      ) {
        this.sendSpreadsheet();
        clearInterval(interval);
      }
    }, 5000)
  }

  @observable aguardandoResposta = false;
  @observable propostaEnviada = false;
  @observable enviandoDocumentos = false;
  @observable enviandoProposta = false;
  @observable funcaoEnviarPdf = null;

  @action resetarValores = () => {
    this.aguardandoResposta = false;
    this.propostaEnviada = false;
    this.enviandoDocumentos = false;
    this.enviandoProposta = false;
    this.funcaoEnviarPdf = null;
    this.filename = '';
  }

  sendSpreadsheet = async () => {
    this.enviandoProposta = true;
    this.enviandoDocumentos = false;
    try {
      const res = await Api.post('/portal/proposta_envia_item/', { proposta: CadastrarPropostaStore.idProposta });
      ToastInfo('Enviando proposta...');
      // CadastrarPropostaStore.toggleModalDocumentos();
      // ItensPropostaStore.showItens = false;
      // MaquinaDeEstadosStore.redireciona_gerenciar_proposta();
    }
    catch (err) {
      console.log(err, 'err');
    }
    finally {
      this.aguardandoResposta = true;
      this.enviandoProposta = false;
      // this.propostaEnviada = true;
    }
  }





  tratarDados = (dados) => {
    return dados.map(d => ({
      ...d,
      marca: d.marca === true ? '' : d.marca,
      modelo_versao: d.modelo_versao === true ? '' : d.modelo_versao,
      fabricante: d.fabricante === true ? '' : d.fabricante,
      desconto: d.desconto === true ? '' : d.desconto,
      valor_unit: d.valor_unit === true ? '' : d.valor_unit,
      valor_total: d.valor_total === true ? '' : d.valor_total
    }))
  }

}

const ItensPropostaStore = new itensPropostaStore();

export default ItensPropostaStore;


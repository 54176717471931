import React from 'react'
import ReactDOM from 'react-dom'

import { inject, observer } from 'mobx-react'
import { TextAreaInput } from '../../../../UsefulComponents/Inputs'
import { ThumbsDown } from 'react-feather'
import Spinner from '../../../../UsefulComponents/Spinner'

import * as S from './Style'

const ModalReprovar = inject('DetalhesEditalStore')(
  observer(({ DetalhesEditalStore }) => {
    const {
      modalReprovar,
      toggleModalReprovar,
      isFetching,
      mudarStatus,
      onChangeMessage
    } = DetalhesEditalStore
    return (
      <ModalFailEdict
        modalFail={modalReprovar}
        toggleModalFail={toggleModalReprovar}
        changeStatus={mudarStatus}
        isFetching={isFetching}
        onChangeMessage={onChangeMessage}
      />
    )
  })
)

const ModalFailEdict = ({
  modalFail,
  onChangeMessage,
  toggleModalFail,
  changeStatus,
  isFetching
}) => {
  const changeStatusEdict = () => {
    changeStatus('reprovado')
    toggleModalFail()
  }

  return ReactDOM.createPortal(
    <>
      {modalFail && (
        <S.Overlay>
          <S.Container isFetching={isFetching} color={'#ec4242'}>
            <div className="header">
              <div className="icon">
                <ThumbsDown />
              </div>
              <div className="title">REPROVAR</div>
            </div>
            {isFetching ? (
              <Spinner />
            ) : (
              <div className="body">
                <div className="title-message">MENSAGEM</div>
                <TextAreaInput rows={10} onChange={onChangeMessage} />
              </div>
            )}
            <div className="footer">
              <div
                className="confirmar"
                onClick={!isFetching && changeStatusEdict}
              >
                CONFIRMAR
              </div>
              <div className="fechar" onClick={!isFetching && toggleModalFail}>
                FECHAR
              </div>
            </div>
          </S.Container>
        </S.Overlay>
      )}
    </>,
    document.querySelector('body')
  )
}

export default ModalReprovar

import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Star } from 'react-feather'
import DataTable from 'react-data-table-component'
import { Pagination } from '@material-ui/lab'
import Spinner from '../../../UsefulComponents/Spinner'
import SubHeader from '../SubHeader/SubHeader'
import SectionTitle from '../SubHeader/SectionTitle'
import BotaoFiltrar from '../Filter/botaoFiltro'
import { Body, Container, ContainerPagination, ContainerTable } from '../Style'
import { columnsQualificados } from './Column'
import { ExclamationWarning } from '../../../Components/Notifications/ExclamationWarning'
import { sortEditais } from '../Components/sortEditais'
import StatusModal from '../StatusNotice'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'
import FiltroQualificados from '../Filter/filtroQualificados'

const Qualificados = inject(
  'QualificadosStore',
  'MaquinaDeEstadosStore',
  'DetalhesEditalStore'
)(
  observer(
    ({ QualificadosStore, MaquinaDeEstadosStore, DetalhesEditalStore }) => {
      // NECESSÁRIO CHAMAR AS VARIÁVEIS QUE VÃO SER OBSERVADAS AQUI.
      const {
        isFetching,
        fetchEdital,
        editais,
        total_registros,
        total_paginas,
        pagination,
        crescente
      } = QualificadosStore
      const { id_fornecedor, getStatus } = MaquinaDeEstadosStore
      const { toggleModal } = DetalhesEditalStore
      return (
        <QualificadosComStore
          toggleModal={toggleModal}
          data={{ isFetching, fetchEdital }}
          idEmpresa={id_fornecedor}
          editais={editais}
          total_registros={total_registros}
          total_paginas={total_paginas}
          pagination={pagination}
          crescente={crescente}
          getStatus={getStatus}
        />
      )
    }
  )
)

const QualificadosComStore = ({
  data,
  idEmpresa,
  editais,
  total_paginas,
  pagination,
  crescente,
  toggleModal,
  getStatus
}) => {
  const { isFetching, fetchEdital } = data

  useEffect(() => {
    getStatus()
  }, [])

  useEffect(() => {
    if (idEmpresa) fetchEdital(state)
  }, [idEmpresa])

  const { state, filtrarEditaisStatus } = useFiltroEdital()

  const handlePagina = (event, value) => {
    pagination.changePage(value)
    fetchEdital(state)
  }

  if (isFetching) return <Spinner />
  return (
    <Container>
      <SubHeader overflow>
        <SectionTitle title="QUALIFICADOS" icon={<Star />} />
        <BotaoFiltrar />
      </SubHeader>
      <Body>
        <ContainerTable>
          <FiltroQualificados status={'qualificado'} />
          {state.filtro && (
            <>
              <DataTable
                className="tabela-padrao"
                fixedHeader
                allowOverflow={true}
                overflowY={true}
                noHeader
                responsive={true}
                columns={columnsQualificados}
                onRowClicked={edital => toggleModal(edital.id, state)}
                onSort={sortEditais}
                sortServer={true}
                defaultSortAsc={crescente}
                data={editais}
                striped={true}
                highlightOnHover
                noDataComponent={
                  <ExclamationWarning
                    title="NENHUM EDITAL ENCONTRADO"
                    height="70vh"
                  />
                }
              />
              <ContainerPagination>
                <div>
                  <Pagination
                    count={total_paginas}
                    color="primary"
                    shape="rounded"
                    page={pagination.page}
                    onChange={(event, value) => handlePagina(event, value)}
                    showFirstButton
                    showLastButton
                  ></Pagination>
                </div>
              </ContainerPagination>
            </>
          )}
        </ContainerTable>
      </Body>
      <StatusModal />
    </Container>
  )
}

export default Qualificados

import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CardMsg, CustomSwitch, BodySwitch } from '../../../globalStyles'
import { Filter } from 'react-feather'
import { MaterialServicosContainer } from '../styles'
import { ConfiguracaoEditaisContext } from '../stores/store'

const FiltroMateraisServico = observer(() => {
  const { changeMateriaisServicos, materiaisServicos } = useContext(
    ConfiguracaoEditaisContext
  )
  return (
    <CardMsg
      height="auto"
      flexDirection={'row'}
      margin={'10px 0'}
      justifyContent="space-between"
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Filter />
        &nbsp;&nbsp;
        <div className="d-flex flex-column">
          <div style={{ padding: '0px 10px 0px 10px' }}>
            FILTRO MATERIAIS/SERVIÇOS
          </div>
          <small style={{ fontSize: '10px', marginLeft: '10px' }}>
            Filtrar editais por materiais e/ou serviços.
          </small>
        </div>
      </div>
      <MaterialServicosContainer>
        <BodySwitch
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CustomSwitch
            checked={materiaisServicos.includes('M')}
            onChange={changeMateriaisServicos}
            value="M"
            color="primary"
          />
          <b style={{ fontWeight: 500 }}>MATERIAIS</b>
        </BodySwitch>
        <BodySwitch
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CustomSwitch
            checked={materiaisServicos.includes('S')}
            onChange={changeMateriaisServicos}
            value="S"
            color="primary"
          />
          <b style={{ fontWeight: 500 }}>SERVIÇOS</b>
        </BodySwitch>
      </MaterialServicosContainer>
    </CardMsg>
  )
})

export default FiltroMateraisServico

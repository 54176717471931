import { useContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { DocumentosContext } from '../enviarDocumentos/context'
import { PropostaEspecificaContext } from '../../context'
import maquinadeestadosstore from '../../../../../../../Stores/MaquinaDeEstadosStore'
import Api from '../../../../../../../Stores/Conexao/conexao'
import { DadosPdfContext } from '../dadosPdf/context'

const useEnviarProposta = () => {
  const [aleatorio, setAleatorio] = useState(0)
  const {
    filesProposta,
    filesHabilitacao,
    setStatus,
    setDocsProposta
  } = useContext(DocumentosContext)
  const { pdfFile, setPdfStatus, setPdf } = useContext(DadosPdfContext)
  const {
    edital,
    idProposta,
    setEnviandoProposta,
    enviandoProposta,
    setPropostaEnviada
  } = useContext(PropostaEspecificaContext)

  // Tratando o push recebido pelo firebase;
  useEffect(() => {
    const messaging = firebase.messaging()
    messaging.onMessage(({ data }) => {
      if (!window.location.pathname.includes('proposta/cadastrar')) return
      if (data.aleatorio === aleatorio) return
      handleMessage(data)
    })
    const listener = new BroadcastChannel('listener')
    listener.onmessage = function(e) {
      console.log('paylaod bg', e.data.data)
      let { data } = e.data
      handleMessage(data)
    }
  }, [])

  const handleMessage = data => {
    let { tipostring, nome_arquivo, enviado, descricao_tipo } = data
    enviado = enviado === 'true' // correção porque o backend mandou string ao invés de boolean

    if (!tipostring.includes('doc_') && tipostring !== 'spreadsheet') return

    switch (tipostring) {
      case 'doc_proposta':
        if (nome_arquivo.includes('Proposta_')) {
          // TRANTANDO PDF
          setPdfStatus({ loading: false, enviado })
        } else {
          setStatus(nome_arquivo, { loading: false, enviado }, 'proposta')
          // if (enviado) setFilesProposta(filesProposta.filter(file => file.name !== nome_arquivo));
        }
        break
      case 'doc_habilitacao':
        setStatus(nome_arquivo, { loading: false, enviado }, 'habilitacao')
        // if (enviado) setFilesHabilitacao(filesHabilitacao.filter(file => file.name !== nome_arquivo));
        break
      case 'spreadsheet':
        setEnviandoProposta(false)
        if (descricao_tipo === 'Proposta Enviada') setPropostaEnviada(true)
        break
      default:
        return
    }
    setAleatorio(data.aleatorio)
  }

  //////////

  const enviarDocumento = async (type, file) => {
    const data = new FormData()
    data.append('edital', edital.id)
    data.append('fornecedor', maquinadeestadosstore.getIdEmpresa())
    data.append(`anexo_${type}`, file)

    try {
      await Api.post(`/portal/proposta_doc_${type}/`, data)
    } catch (err) {
      console.log(err)
    }
  }

  const enviarProposta = () => {
    let interval = setInterval(() => {
      let enviado

      setPdf(previ => {
        enviado = previ.enviado
        return previ
      })

      setDocsProposta(prev => {
        if (!enviado) enviado = prev.some(doc => doc.enviado)
        return prev
      })

      if (enviado) {
        clearInterval(interval)
        Api.post('/portal/proposta_envia_item/', { proposta: idProposta })
        setEnviandoProposta(true)
      }
    }, 3000)
  }

  const enviar = () => {
    filesProposta.forEach(file => {
      enviarDocumento('proposta', file)
      setStatus(file.name, { loading: true }, 'proposta')
    })
    filesHabilitacao.forEach(file => {
      enviarDocumento('habilitacao', file)
      setStatus(file.name, { loading: true }, 'habilitacao')
    })
    enviarDocumento('proposta', pdfFile)
    setPdfStatus({ loading: true, enviado: false })
    enviarProposta()
  }

  return { enviar }
}

export default useEnviarProposta

import React, { useState, useEffect } from "react";
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from "../../../Components/NewModal";
import {
  IconPlavrasCheveColor,
  IconPlavrasCheve
} from "../../../Icons/iconCustom";
import { ModalSaveButton3, TagMark } from "../../../globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactTooltip } from "../../../globalStyles";
import { Link } from "react-router-dom";
import { ModalCnpj } from "../ModalCnpj";

import { PalavrasChaveContainer, HelperPalavraChave } from "./styles";
import IconCfgGeral from "../../../Icons/Sidebar/IconCfgGeral";

const Exibicao = ({ title, tag, onChange, saveEspecificas, cnpj }) => {
  const [showDescription, toggleDescription] = useState(
    title === "ESPECÍFICAS"
  );
  const [palavras, changePalavras] = useState("");
  const [textinput, changeInput] = useState("");
  // const [houveMudanca, changeMudanca] = useState(false);
  // const [isActive, changeActive] = useState(false);
  const { toggleModalCnpj, modalCnpj } = cnpj;

  // const houveMudanca = palavras !== tag;
  const especificas = title === "ESPECÍFICAS";
  // const antigas = useRef(tag);

  const handleSubmit = e => {
    e.preventDefault();
    if (isCnpj()) return toggleModalCnpj();
    if (textinput.replace(/\s/g, "").length) onChange(textinput);
    changeInput("");
    saveEspecificas();
  };

  const handleChange = ({ target: { value } }) => {
    // if (!value.replace(/\s/g, "").length) return;
    changeInput(value);
  };

  const isCnpj = str => {
    if (str) {
      if (str.match(/[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/))
        return true;
      return false;
    }
    if (
      textinput.match(/[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/)
    ) {
      return true;
    }
    return false;
  };

  const handleTab = e => {
    let key = e.which || e.charCode || e.keyCode;
    if (key === 9 || key === 13) {
      e.preventDefault();
      if (isCnpj()) return toggleModalCnpj();
      if (e.preventDefault) {
        if (textinput.replace(/\s/g, "").length) onChange(textinput);
        changeInput("");
        e.preventDefault();
      }
      return false;
    }
  };

  useEffect(() => {
    let newTag = tag; /* .split(',') */
    if (newTag.length > 0 && !newTag[0]) newTag = newTag.shift();
    if (!Array.isArray(newTag)) newTag = [];
    newTag = newTag.filter(el => !isCnpj(el)); // Remover o CNPJ da lista
    newTag = newTag.map(item => {
      return (
        <>
          <TagMark background="#338bd6" size="15px" mtop="10px">
            {item}{" "}
            {especificas ? (
              <span
                className="icon-scale"
                style={{
                  padding: "2px 2px 0px 10px",
                  color: "#bae0ff",
                  fontSize: "12px"
                }}
                onClick={() => onChange(item)}
              >
                x
              </span>
            ) : (
              ""
            )}
          </TagMark>
          &nbsp;
        </>
      );
    });
    changePalavras(newTag);
  }, [tag]);

  // const removerPalavra = (i) => changePalavras(palavras.filter((pal, index) => index !== i));

  return (
    <PalavrasChaveContainer>
      <ReactTooltip effect="solid" place="left" />
      <div
        onClick={() => toggleDescription(!showDescription)}
        className="title"
      >
        <div style={{ display: "flex", cursor: "pointer", color: "#fff" }}>
          <div style={{ width: "20px" }}>
            <FontAwesomeIcon
              icon={showDescription ? "chevron-down" : "chevron-right"}
            />
          </div>
          <div>
            <span style={{ marginLeft: "0.7rem" }}>{title}</span>
          </div>
        </div>
      </div>
      {showDescription && (
        <div
          className="content custom-scroll-blue"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            whiteSpace: "normal"
          }}
        >
          <div style={{ textAlign: "justify" }}>{palavras}</div>
          {especificas && (
            <div>
              <form
                onSubmit={e => handleSubmit(e)}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-between"
                }}
              >
                <input
                  onKeyDown={e => handleTab(e)}
                  type="text"
                  onChange={handleChange}
                  value={textinput}
                  id="input-palavras-especificas"
                  placeholder={"Adicionar palavra"}
                />
                <ModalSaveButton3 className="icon-scale-xs" marginTop={"0px"}>
                  SALVAR
                </ModalSaveButton3>
              </form>
              {/*<ModalSaveButton2*/}
              {/*    isActive={houveMudanca}*/}
              {/*    onClick={houveMudanca ? handleSave : () => { }}*/}
              {/*>SALVAR</ModalSaveButton2>*/}
            </div>
          )}
        </div>
      )}
      <ModalCnpj
        title="CNPJ DETECTADO"
        message="Não é possível cadastrar um CNPJ como palavra-chave!"
        status={modalCnpj}
        closeModal={toggleModalCnpj}
      />
    </PalavrasChaveContainer>
  );
};

export function ModalPalavra({ tag, state }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animation, setAnimation] = useState(false);
  const {
    changePalavrasEspecificas,
    tags_especifica,
    saveEspecificas,
    modalCnpj,
    toggleModalCnpj
  } = state;
  return (
    <ModalProvider>
      <div
        onClick={() => setIsModalOpen(true)}
        className="icon-scale file"
        data-tip="PALAVRAS-CHAVE"
        data-type="info"
        data-effect="solid"
        data-place="bottom"
      >
        <IconPlavrasCheveColor color="#3A566E" />
      </div>

      {isModalOpen && (
        <ModalLateral
          doobleIcon={
            <div style={{ padding: "0 20px" }}>
              <IconPlavrasCheve />
            </div>
          }
          title={"PALAVRAS-CHAVE"}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <HelperPalavraChave>
            Além das{" "}
            <b>palavras definidas nas configurações de monitoramento</b>, você
            também pode adicionar <b>palavras específicas</b> para serem
            monitoradas em cada <b>licitação</b>.
          </HelperPalavraChave>
          <Exibicao
            title="ESPECÍFICAS"
            tag={tags_especifica}
            onChange={changePalavrasEspecificas}
            saveEspecificas={saveEspecificas}
            cnpj={{ modalCnpj, toggleModalCnpj }}
          />
          <Exibicao
            title="DEFINIDAS"
            tag={tag}
            cnpj={{ modalCnpj, toggleModalCnpj }}
          />

          <Link to="/configuracoesmonitoramento" style={{ marginTop: "auto" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "0rem",
                flexDirection: "column",
                marginTop: "auto",
                height: "auto"
              }}
            >
              <IconCfgGeral color="grey" />
              <h6
                style={{
                  textAlign: "center",
                  color: "grey",
                  marginTop: "0.8rem",
                  fontSize: "0.6rem"
                }}
              >
                CONFIGURAÇÕES DE MONITORAMENTO
              </h6>
            </div>
          </Link>
        </ModalLateral>
      )}
    </ModalProvider>
  );
}

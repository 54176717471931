import styled from 'styled-components';

export const Container = styled.div`
  opacity: 0;
  animation: 700ms fadeIn ease-in-out forwards;
`;

export const Body = styled.div`
  display: flex;
  height: calc(100vh - 126px);
`;
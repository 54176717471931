import styled from 'styled-components'

export const Modal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 0.5s fadeIn ease-in-out forwards;
`

export const Container = styled.div`
  background-color: #fff;
  width: 40%;
  min-height: 40%;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3a566e;

    .close {
      color: #fff;
      cursor: pointer;

      :hover {
        color: #fefefe;
      }
    }

    span {
      color: #fff;
    }

    img {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: 18px;
    }
  }

  .body {
    text-align: center;
    color: #504949;
  }

  footer {
    width: 100%;
    height: 4rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;

    button {
      margin: 0.5rem;
    }

    .cancel {
      border: 1px solid #cecece;
      background-color: #fff;
    }

    .save {
      border: none;
      border: 1px solid #649f22;
      background-color: #649f22;
      color: #fff;
    }
  }
`

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import FormLogin from './FormLogin'
import MaquinaDeEstadosStore from '../../Stores/MaquinaDeEstadosStore'
import ModalStatusConexao from '../../StatusConexao/ModalStatusConexao'
import ListaDeUsuariosSuporte from './ListaDeUsuariosSuporte'
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

@inject('LoginStores')
@observer
class Login extends Component {
  
  componentDidMount() {
    let token = localStorage.getItem('token')
    if (token) this.props.LoginStores.redirectToDashboard(this.props)
    MaquinaDeEstadosStore._props = this.props
  }

  render() {
    const { listaUsuariosAtiva, listaUsuarios } = this.props.LoginStores

    return (
      <>
        <FormLogin {...this.props} />
        <ModalStatusConexao />
        {listaUsuariosAtiva && (
          <ListaDeUsuariosSuporte listaDeUsuarios={listaUsuarios} />
        )}
      </>
    )
  }
}

export default Login

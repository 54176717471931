import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { Portais } from "../../../Pages/Bids/BotoesMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Calendar,
  Play,
  Pocket,
  List,
  XCircle,
  ChevronsRight,
  ChevronsLeft
} from "react-feather";

import PortalSelect from "./PortalSelect";
import { CardLicitacoes } from "./CardLicitacoes";

import {
  ComponentHeader,
  ComponentHeaderOverflow,
  ContentBody,
  ReactTooltip,
  TabButton
} from "../../../globalStyles";
import {
  CardAnim,
  CardAnim1 /* , ContainerFunil */,
  /* Portais, */
  PortalLogo
} from "./StyleFunil";
import { MenuAcoesFunil } from "./MenuAcoesFunil";
import LogoComprasNet from "../../../Images/compras_net.png";
import LogoBb from "../../../Images/bb_logo.jpeg";
import { SelectInput } from "../../../UsefulComponents/Inputs";
import { PortaisSelectContainer } from "../../../Pages/Bids/styleLicitacoes";

@inject("FunilStore")
@observer
class Funil extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      portaisShowing: false,
      width: 0,
      height: 0,
      status: 0,
      card1: true,
      card2: false
    };
  }

  handleLeft = type => {
    this.setState({ status: 1 });
    this.setState({ card1: false, card2: true });
  };

  handleRight = type => {
    this.setState({ status: 3 });
    this.setState({ card1: true, card2: false });
  };

  componentDidMount() {
    this.props.FunilStore.consulta_api();
    document.addEventListener("mousedown", this.handleClickOutside);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ portaisShowing: false });
    }
  }

  updateWindowDimensions = () =>
    this.setState({ width: window.innerWidth, height: window.innerHeight });

  handlePortal = situacao => {
    const { FunilStore } = this.props;
    const { portal, todosPortais } = FunilStore;
    let arr = [];

    portal.forEach(portal => {
      const { name } = todosPortais.find(el => el.id === portal);

      arr = [...arr, ...FunilStore[situacao + name]];
    });

    return arr;
  };

  handleCount = () => {
    const { FunilStore } = this.props;
    const { portal, todosPortais } = FunilStore;

    return portal.reduce((acc, item) => {
      const { name } = todosPortais.find(el => el.id === item);
      return acc + FunilStore["total" + name];
    }, 0);
  };

  render() {
    const { ano, handleChange, filtroAno } = this.props.FunilStore;

    const {
      realizadosComprasNet,
      andamentosComprasNet,
      agendadosComprasNet,
      anuladosComprasNet,
      atasComprasNet,
      totalComprasNet
    } = this.props.FunilStore;
    const {
      realizadosLicitacoesE,
      andamentosLicitacoesE,
      agendadosLicitacoesE,
      anuladosLicitacoesE,
      atasLicitacoesE,
      totalLicitacoesE
    } = this.props.FunilStore;
    const { width, portaisShowing } = this.state;
    return (
      <>
        <ComponentHeader
          height="8vh"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <ReactTooltip />
          <div className="d-flex position-relative">
            <SelectInput
              id="licitacao-select-type"
              onChange={handleChange}
              value={filtroAno}
              width="200px"
              icon={
                <FontAwesomeIcon
                  style={{ marginLeft: "-5px", color: "#363636" }}
                  icon="filter"
                />
              }
            >
              <option value="todos">TODOS OS ANOS</option>
              {ano.map(ano => {
                return <option value={ano}>{ano}</option>;
              })}
            </SelectInput>

            <Portais
              onClick={() => this.setState({ portaisShowing: !portaisShowing })}
            />
            {portaisShowing && (
              <PortaisSelectContainer ref={this.setWrapperRef}>
                <PortalSelect />
              </PortaisSelectContainer>
            )}
          </div>
          <MenuAcoesFunil
            licitacoes_total={this.handleCount()}
            screenSize={width}
          />
        </ComponentHeader>

        <ComponentHeaderOverflow height="8vh" />

        <ContentBody bRadius style={{ height: "81vh", marginBottom: 0 }}>
          {!this.state.card1 ? (
            <div
              onClick={() => this.handleRight(2)}
              className="icon-scale"
              style={{
                position: "absolute",
                display: "flex",
                zIndex: "55555",
                background: "#012c4b",
                textAlign: "center",
                borderRadius: "100px",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                width: "50px",
                height: "50px",
                marginTop: "4vh",
                opacity: "0.7",
                left: "-5px",
                boxShadow: "#3a566ed9 1px 1px 5px 1px"
              }}
              //   data-tip="ANTERIOR"
              //   data-place="bottom"
              //   data-effect="solid"
              //   data-type="info"
            >
              <ChevronsLeft size={"30px"} />
            </div>
          ) : (
            <div
              onClick={() => this.handleLeft(1)}
              className="icon-scale"
              style={{
                position: "absolute",
                display: "flex",
                zIndex: "55555",
                background: "#012c4b",
                textAlign: "center",
                borderRadius: "100px",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                width: "50px",
                height: "50px",
                marginTop: "4vh",
                opacity: "0.7",
                right: "-5px",
                boxShadow: "#3a566ed9 1px 1px 5px 1px"
              }}
              //   data-tip="PRÓXIMA"
              //   data-place="bottom"
              //   data-effect="solid"
              //   data-type="info"
            >
              <ChevronsRight size={"30px"} />
            </div>
          )}

          {this.state.card1 && (
            <CardAnim status={this.state.status}>
              <CardLicitacoes
                title={"AGENDADOS"}
                icon={<Calendar />}
                color={"#159FBF"}
                dados={this.handlePortal("agendados")}
                filtroAno={filtroAno}
              />
              <CardLicitacoes
                title={"ANDAMENTO"}
                icon={<Play />}
                color={"#007bff"}
                dados={this.handlePortal("andamentos")}
                filtroAno={filtroAno}
              />
              <CardLicitacoes
                title={"REALIZADOS"}
                icon={<Pocket />}
                color={"#ffc107"}
                dados={this.handlePortal("realizados")}
                filtroAno={filtroAno}
              />
            </CardAnim>
          )}
          {this.state.card2 && (
            <CardAnim1 status={this.state.status}>
              <CardLicitacoes
                title={"REALIZADOS"}
                icon={<Pocket />}
                color={"#ffc107"}
                dados={this.handlePortal("realizados")}
                filtroAno={filtroAno}
              />
              <CardLicitacoes
                title={"ATA"}
                icon={<List />}
                color={"#28a745"}
                dados={this.handlePortal("atas")}
                filtroAno={filtroAno}
              />
              <CardLicitacoes
                title={"ANULADOS"}
                icon={<XCircle />}
                color={"#dc3545"}
                dados={this.handlePortal("anulados")}
                filtroAno={filtroAno}
              />
            </CardAnim1>
          )}
        </ContentBody>
      </>
    );
  }
}

export default Funil;

import React, { useContext, useEffect, useReducer } from 'react';
import maquinadeestadosstore from '../../../../../../Stores/MaquinaDeEstadosStore';
import { API } from '../../ListaDeLicitacoes/api';
import { PropostaEspecificaContext } from '../context';
import EditarItens from './editarItens/EditarItens';
import DadosGerarProposta from './dadosPdf/DadosGerarProposta';
import EnviarDocumentos from './enviarDocumentos/EnviarDocumentos';
import ConfirmarProposta from './confirmarProposta/ConfirmarProposta';
import EnviarProposta from './enviarProposta/EnviarProposta';
import Api from '../../../../../../Stores/Conexao/conexao';


const initialState = {
  steps: [
    {
      name: 'ITENS_PROPOSTA',
      active: true,
      finished: false,
      component: <EditarItens />
    },
    {
      name: 'DADOS_PROPOSTA',
      active: false,
      finished: false,
      component: <DadosGerarProposta />
    },
    {
      name: 'ANEXAR_DOCUMENTOS',
      active: false,
      finished: false,
      component: <EnviarDocumentos />
    },
    {
      name: 'CONFIRMAR',
      active: false,
      finished: false,
      component: <ConfirmarProposta />
    },
    {
      name: 'ENVIAR_PROPOSTA',
      active: false,
      finished: false,
      component: <EnviarProposta />
    },
  ]
};

const actions = {
  SET_ACTIVE: 'SET_ACTIVE',
  SET_FINISHED: 'SET_FINISHED',
  CLEAN_DATA: 'CLEAN_DATA'
}

function reducer(state, action) {
  switch (action.type) {
    case actions.SET_ACTIVE:
      return { ...state, steps: state.steps.map(step => step.name === action.payload ? { ...step, active: true } : { ...step, active: false }) };
    case actions.SET_FINISHED:
      return { ...state, steps: state.steps.map(step => step.name === action.payload ? { ...step, finished: true } : step) };
    case actions.CLEAN_DATA:
      return initialState;
    default:
      return state;
  }
}

const FluxoDeEnvioContext = React.createContext(null);

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { rows, setIdProposta } = useContext(PropostaEspecificaContext);

  const saveData = async () => {
    const data = {
      fornecedor: maquinadeestadosstore.getIdEmpresa(),
      rows
    }
    const res = await Api.post(API.salvarItensEditados(), data);
    setIdProposta(res.data.proposta)
  }

  const value = {
    ...state,
    saveData,
    cleanData: () => dispatch({ type: actions.CLEAN_DATA }),
    setActive: (name) => dispatch({ type: actions.SET_ACTIVE, payload: name }),
    setFinished: (name) => dispatch({ type: actions.SET_FINISHED, payload: name }),
  }



  return (
    <FluxoDeEnvioContext.Provider value={value}>
      {children}
    </FluxoDeEnvioContext.Provider>
  )
};

const FluxoDeEnvioProvider = Provider;

export { FluxoDeEnvioContext }
export default FluxoDeEnvioProvider;
import React, { createContext, useContext, useReducer } from 'react'
import { format } from 'date-fns'

import BoletimStore from '../../DailyBulletin/Store'
import BuscaManualStore from '../../ManualSearch/Store'
import AprovadoStore from '../../Approved/Store'
import QualificadoStore from '../../Qualified/Store'
import ReprovadoStore from '../../Failed/Store'
import DownloadEditaisStore from '../../DownloadList/store'
import Api from '../../../../Stores/Conexao/conexao'
import modalLoadingStore from '../../../../Stores/ModalLoadingStore'
import MaquinaDeEstadosStore from '../../../../Stores/MaquinaDeEstadosStore'

const FiltroContext = createContext(null)
const siglas = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]

const initialState = {
  status: 'boletim',
  perfil: null,
  perfilId: 0,
  pagina: 1,
  comprador: '',
  pregao: '',
  pregaoUASG: '',
  filtro: false,
  tipoDate: 'abertura',
  meEpp: ['Todos'],
  tipoItem: ['M', 'S'],
  palavra: '',
  palavraExclusao: '',
  modalidade: [],
  estados: siglas,
  portais: '',
  date: {
    dmax: format(new Date(), 'dd/MM/yyyy'),
    dmin: format(new Date(), 'dd/MM/yyyy'),
    obj: new Date()
  },
  perfis: []
}

export const ActionTypes = {
  PERFIL_ID: 'PERFIL_ID',
  GET_TIPO_DATA: 'GET_TIPO_DATA',
  GET_PALAVRA: 'GET_PALAVRA',
  GET_PALAVRA_EXCLUSAO: 'GET_PALAVRA_EXCLUSAO',
  GET_MODALIDADE: 'GET_MODALIDADE',
  GET_ESTADO: 'GET_ESTADO',
  GET_PORTAIS: 'GET_PORTAIS',
  GET_DATE: 'GET_DATE',
  SET_MEEPP: 'SET_MEEPP',
  SET_TIPO_ITEM: 'SET_TIPO_ITEM',
  GET_COMPRADOR: 'GET_COMPRADOR',
  GET_PREGAO: 'GET_PREGAO',
  GET_PREGAO_UASG: 'GET_PREGAO_UASG',
  SHOW_FILTRO: 'SHOW_FILTRO',
  GET_STATUS: 'BOLETIM',
  GET_PERFIL: 'GET_PERFIL',
  SET_PERFIS: 'SET_PERFIS',
  LIMPAR: 'LIMPAR'
}

const Action = {
  comprador: '',
  pregao: '',
  pregaoUASG: '',
  filtro: false,
  tipoDate: '',
  palavra: [],
  modalidade: '',
  estados: '',
  portais: '',
  perfil: '',
  date: '',
  status: '',
  type: ActionTypes
}

function reducer(state = initialState, action = Action) {
  switch (action.type) {
    case ActionTypes.GET_TIPO_DATA:
      return { ...state, tipoDate: action.tipoDate }
    case ActionTypes.GET_PALAVRA:
      return { ...state, palavra: action.palavra }
    case ActionTypes.GET_PALAVRA_EXCLUSAO:
      return { ...state, palavraExclusao: action.palavra }
    case ActionTypes.GET_MODALIDADE:
      return { ...state, modalidade: action.modalidade }
    case ActionTypes.GET_ESTADO:
      return { ...state, estados: action.estados }
    case ActionTypes.GET_PORTAIS:
      return { ...state, portais: action.portais }
    case ActionTypes.GET_DATE:
      return { ...state, date: action.date }
    case ActionTypes.SET_MEEPP:
      return { ...state, meEpp: action.payload }
    case ActionTypes.SET_TIPO_ITEM:
      return { ...state, tipoItem: action.payload }
    case ActionTypes.GET_COMPRADOR:
      return { ...state, comprador: action.comprador }
    case ActionTypes.GET_PREGAO:
      return { ...state, pregao: action.pregao }
    case ActionTypes.GET_PREGAO_UASG:
      return { ...state, pregaoUASG: action.pregaoUASG }
    case ActionTypes.GET_STATUS:
      return { ...state, status: action.status }
    case ActionTypes.SHOW_FILTRO:
      return { ...state, filtro: action.filtro }
    case ActionTypes.GET_PERFIL:
      return { ...state, perfil: action.perfil }
    case ActionTypes.PERFIL_ID:
      return { ...state, perfilId: action.payload }
    case ActionTypes.SET_PERFIS:
      return { ...state, perfis: action.payload }
    case ActionTypes.LIMPAR:
      return { ...initialState, perfis: state.perfis, estados: [] }
    default:
      return state
  }
}

export function useFiltroEdital() {
  const [state, dispatch] = useContext(FiltroContext);

  const getInitialProfileData = async (value) => {
    modalLoadingStore.handleOpenModal();
    try {
      const res = await Api.get(`/portal/configuracao_novo/${value || MaquinaDeEstadosStore.getIdEmpresa()}`);
      dispatch({ type: ActionTypes.SET_PERFIS, payload: res.data.perfis_configuracao })
    }
    catch (err) {
      console.log('err', err)
    }
    finally {
      modalLoadingStore.handleCloseModal()
    }
  }

  const setPerfilData = async (perfilId) => {
    let { modalidade, estados, portal, palavra_classificao, palavra_exclusao, empresa_me, tipo_item, id } = state.perfis.find(perf => perf.id == perfilId);

    palavra_classificao = palavra_classificao ? palavra_classificao.split(',') : [];
    palavra_exclusao = palavra_exclusao ? palavra_exclusao.split(',') : [];
    
    dispatch({ type: ActionTypes.PERFIL_ID, payload: id })
    dispatch({ type: ActionTypes.GET_MODALIDADE, modalidade })
    dispatch({ type: ActionTypes.GET_ESTADO, estados })
    dispatch({ type: ActionTypes.GET_PORTAIS, portais: portal })
    dispatch({ type: ActionTypes.GET_PALAVRA, palavra: palavra_classificao })
    dispatch({ type: ActionTypes.GET_PALAVRA_EXCLUSAO, palavra: palavra_exclusao })
    dispatch({ type: ActionTypes.SET_TIPO_ITEM, payload: tipo_item })
    dispatch({ type: ActionTypes.SET_MEEPP, payload: empresa_me })

  }

  const onChangeMeEpp = selected => {
    dispatch({ type: ActionTypes.SET_MEEPP, payload: selected });
  }

  const onChangeTipoItem = type => {
    dispatch({ type: ActionTypes.SET_TIPO_ITEM, payload: type })
  }

  const onChangeTipoDate = tipoDate => {
    dispatch({ type: ActionTypes.GET_TIPO_DATA, tipoDate })
  }

  const consultaDownload = async (notPagination) => {
    try {
      if (notPagination) DownloadEditaisStore.pagination.page = 1;
      DownloadEditaisStore.fetchEditaisNovo(state)
      dispatch({ type: ActionTypes.SHOW_FILTRO, filtro: false })
    } catch { }
  }
  const consultaDownloadTodos = async () => {
    try {
      DownloadEditaisStore.downloadTodosNovo(state)
      dispatch({ type: ActionTypes.SHOW_FILTRO, filtro: false })
    } catch { }
  }

  const showFiltro = () => {
    // selectTodosEstados(true)
    dispatch({ type: ActionTypes.SHOW_FILTRO, filtro: !state.filtro })
  }

  const onChangePalavra = palavra =>
    dispatch({ type: ActionTypes.GET_PALAVRA, palavra })
  
    const onChangePalavraExclusao = palavra =>
    dispatch({ type: ActionTypes.GET_PALAVRA_EXCLUSAO, palavra })

  const handleAllModalidade = () => {
    const arrayId = []
    MaquinaDeEstadosStore.modalidade.map(({ id }) => arrayId.push(id))
    return arrayId
  }

  const onChangePerfil = perfil => {
    dispatch({ type: ActionTypes.GET_PERFIL, perfil })
  }

  const onChangeStatus = status => {
    dispatch({ type: ActionTypes.GET_STATUS, status })
  }

  const onChangeComprador = comprador => {
    dispatch({ type: ActionTypes.GET_COMPRADOR, comprador })
  }

  const onChangePregao = pregao => {
    dispatch({ type: ActionTypes.GET_PREGAO, pregao })
  }
  
  const onChangePregaoUASG = pregaoUASG => {
    dispatch({ type: ActionTypes.GET_PREGAO_UASG, pregaoUASG })
  }

  const onChangeModalidade = modalidade => {
    dispatch({ type: ActionTypes.GET_MODALIDADE, modalidade })
  }

  const selectTodosPortais = status => {
    if (status) {
      let portais = []
      let array = MaquinaDeEstadosStore.portais
      for (let i = 0, l = array.length; i < l; i++) {
        portais.push(array[i].id)
      }
      dispatch({ type: ActionTypes.GET_PORTAIS, portais })
    } else {
      dispatch({ type: ActionTypes.GET_PORTAIS, portais: [] })
    }
  }

  const filtrar = async () => {
    await showFiltro()
    if (state.modalidade === 'Todas') {
      const nesState = { ...state, modalidade: handleAllModalidade() }
      await BoletimStore.fetchEditaisNovo(nesState)
    } else {
      await BoletimStore.fetchEditaisNovo(state)
    }
  }

  const filtrarManual = async (status, isPagination) => {
    if (!status) {
      BuscaManualStore.pagination.page = 1
      BoletimStore.pagination.page = 1
      DownloadEditaisStore.pagination.page = 1
    }

    ; (await status) !== 'fechar' && showFiltro()

    if(isPagination) return BuscaManualStore.fetchEditaisPagination(state)

    if (state.modalidade === 'Todas') {
      const nesState = { ...state, modalidade: handleAllModalidade() }
      await BuscaManualStore.fetchEditaisNovo(nesState)
    } else {
      await BuscaManualStore.fetchEditaisNovo(state)
    }
  }
  
  const filtrarEditaisStatus = async status => {
  
    if ( status === 'aprovado') {
      AprovadoStore.pagination.changePage(1)
      await AprovadoStore.fetchEdital(state) 
    }
    else if ( status === 'reprovado') {      
      ReprovadoStore.pagination.changePage(1)
      await ReprovadoStore.fetchEdital(state)
    }
    else if ( status === 'qualificado') {
      QualificadoStore.pagination.changePage(1)
      await QualificadoStore.fetchEdital(state)
    }

    !state.filtro && showFiltro();
  }

  const simpleManualFilter = async status => {
    if (!status) {
      BuscaManualStore.pagination.page = 1
      BoletimStore.pagination.page = 1
      DownloadEditaisStore.pagination.page = 1
    }

    const data = {
      comprador: state.comprador,
      pregao: state.pregao
    }
    await BuscaManualStore.fetchEditaisNovoSimple(data)
    showFiltro()
  }

  const selectTodosEstados = status => {
    if (status) {
      dispatch({ type: ActionTypes.GET_ESTADO, estados: siglas })
    }
    else {
      dispatch({ type: ActionTypes.GET_ESTADO, estados: [] })
    }
  }

  const onChangeEstados = options => {
    let estados = []
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        estados.push(options[i].value)
      }
    }
    dispatch({ type: ActionTypes.GET_ESTADO, estados })
  }

  const onChangePortais = portais => {
    dispatch({ type: ActionTypes.GET_PORTAIS, portais })
  }

  const onChangeDate = date => dispatch({ type: ActionTypes.GET_DATE, date })

  return {
    setPerfilData,
    onChangeTipoDate,
    onChangePalavra,
    onChangePalavraExclusao,
    onChangeModalidade,
    onChangeEstados,
    onChangePortais,
    onChangePregao,
    onChangePregaoUASG,
    onChangeComprador,
    onChangeDate,
    onChangeMeEpp,
    onChangeTipoItem,
    onChangePerfil,
    selectTodosEstados,
    selectTodosPortais,
    showFiltro,
    openFiltro: (status) => dispatch({ type: ActionTypes.SHOW_FILTRO, filtro: status || false }),
    filtrarManual,
    simpleManualFilter,
    onChangeStatus,
    consultaDownload,
    state,
    consultaDownloadTodos,
    getInitialProfileData,
    filtrar,
    filtrarEditaisStatus,
    limpar: () => dispatch({ type: ActionTypes.LIMPAR })
  }
}

const FiltroProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <FiltroContext.Provider value={[state, dispatch]}>
      {children}
    </FiltroContext.Provider>
  )
}

export { FiltroContext, FiltroProvider }
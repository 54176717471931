import React from 'react';
import {WrapperBadegHorizontal} from "./style";

const BadegHorizontal = ({texto, background, size}) => {
    return (
        <WrapperBadegHorizontal size={size} color={background} onClick={() => console.log('data')}>
            <div>{texto}</div>
        </WrapperBadegHorizontal>
    );
};

export default BadegHorizontal;

import React, { useState, useEffect } from "react";
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from "../../../Components/NewModal";
import { InputObservacoes, ObservacoesDisplay } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalSaveButton, ReactTooltip } from "../../../globalStyles";
import { AlertCircle, MinusCircle } from "react-feather";

function ModalObservacoes({ observacao, state }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animation, setAnimation] = useState(false);
  return (
    <ModalProvider>
      <div
        onClick={() => setIsModalOpen(true)}
        className="icon-scale"
        data-tip="OBSERVAÇÕES"
        data-type="info"
        data-effect="solid"
        data-place="bottom"
      >
        <FontAwesomeIcon icon="edit" />
      </div>

      {isModalOpen && (
        <ModalLateral
          icon="edit"
          title={"OBSERVAÇÕES"}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <Observacoes
            obs={String(observacao).replace(
              /wavecode3|wavecode2|wavecode|null/g,
              ""
            )}
            state={state}
            changeState={setIsModalOpen}
          />
        </ModalLateral>
      )}
    </ModalProvider>
  );
}

const Observacoes = ({ obs, state, changeState }) => {
  const { changeObservacao, salvarObservacoes, id } = state;
  const [input, changeInput] = useState(obs);

  useEffect(() => {
    changeInput(obs);
  }, [obs]);

  useEffect(() => ReactTooltip.rebuild());

  const handleChange = ({ target: { value } }) => changeInput(value);

  const handleSubmit = async () => {
    changeObservacao(input);
    await salvarObservacoes(id);
    // changeState(false);
  };

  const observ = typeof obs === "string" ? obs.replace("wavecode2", "") : obs;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontSize: "1.1rem",
        height: "100%"
      }}
    >
      <ReactTooltip />

      <div className="d-flex justify-content-between align-items-center">
        <small style={{ color: "grey" }}>Observações:</small>
        {/* <ReactTooltip /> */}
        <AlertCircle
          className="icon-scale"
          size={22}
          color="grey"
          data-tip="Aqui você pode guardar as observações que desejar sobre essa licitação, podendo retornar a qualquer momento para consultar e/ou editá-las!"
          data-place="left"
          data-effect="solid"
          data-type="info"
        />
      </div>

      {observ && (
        <ObservacoesDisplay>
          {observ}
          <MinusCircle
            color="#FFA9A9"
            size={20}
            className="delete-icon icon-scale-xs"
            data-tip="EXCLUIR OBSERVAÇÕES"
            data-place="left"
            data-type="error"
            data-effect="solid"
            onClick={() => salvarObservacoes(id, "deletar")}
          />
        </ObservacoesDisplay>
      )}

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}
      >
        <InputObservacoes
          type="text"
          placeholder="Digite aqui suas observações sobre essa licitação..."
          onChange={handleChange}
          value={input}
          rows="15"
        />
        <ModalSaveButton onClick={handleSubmit}>SALVAR</ModalSaveButton>
      </div>
    </div>
  );
};

export default ModalObservacoes;

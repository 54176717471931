import React from "react";
import { BadgeAzull, BadgeVerde } from "../StyleLicitacao";
import ModalEditarItem from "./modalLateral/ModalEditarItem";

export const rowLicitacao = {
  title: {
    fontSize: "22px",
    fontColor: "rgba(0,0,0,.87)",
    backgroundColor: "transparent",
    height: "0px"
  },

  rows: {
    borderWidth: "0px",
    borderTopStyle: null,
    height: "auto",
    minHeight: "80px",
    backgroundColor: "transparent",
    padding: "0px"
    // whiteSpace: "nowrap"
  },
  cells: {
    borderTopStyle: null,
    // cellPadding: '20px',
    height: "20px",
    padding: "0px",
    whiteSpace: "normal",
    overflow: "auto"
  }
};

export const columnsLicitacao = [
  {
    name: "Nº",
    selector: "codigo",
    sortable: true,
    left: true,
    grow: 0,
    minWidth: "35px"
  },
  {
    name: "GRUPO",
    selector: "grupo",
    sortable: true,
    left: true,
    minWidth: "65px",
    grow: 0
  },

  {
    name: "DESCRIÇÃO",
    selector: "descricao",
    sortable: true,
    whiteSpace: "nowrap",
    left: true,
    grow: 3,
    minWidth: "80px",
    cell: row => <b style={{ padding: "13px" }}>{row.descricao}</b>
  },

  {
    name: "SITUAÇÃO",
    selector: "situacao",
    sortable: true,
    left: true,
    minWidth: "80px",
    grow: 2
  },

  {
    name: "VALOR MÍNIMO",
    selector: "valor_minimo",
    sortable: true,
    left: true,
    grow: 2,
    cell: row => (
      <>
        {row.valor_minimo ? (
          <BadgeVerde>R$ {row.valor_minimo}</BadgeVerde>
        ) : (
          <BadgeAzull>INDEFINIDO</BadgeAzull>
        )}
      </>
    )
  },
  {
    name: "DESCONTO MÍN.",
    selector: "valor_desconto_minimo",
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <>
        {row.valor_desconto_minimo ? (
          <BadgeVerde>R$ {row.valor_desconto_minimo}</BadgeVerde>
        ) : (
          <BadgeAzull>INDEFINIDO</BadgeAzull>
        )}
      </>
    )
  },
  {
    name: "DESCONTO MÁX.",
    selector: "valor_desconto_maximo",
    sortable: true,
    center: true,
    grow: 2,
    cell: row => (
      <>
        {row.valor_desconto_maximo ? (
          <BadgeVerde>R$ {row.valor_desconto_maximo}</BadgeVerde>
        ) : (
          <BadgeAzull>INDEFINIDO</BadgeAzull>
        )}
      </>
    )
  } /* ,

  {
    name: "EDITAR",
    center: true,
    // grow: 2,
    cell: row => (
      <div style={{ whiteSpace: "pre-line", overflow: "auto" }}>
        <ModalEditarItem itens={row} />
      </div>
    )
  } */
];

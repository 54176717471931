import React from 'react'

import BodyDetalhes from './bodyDetalhes'
import FooterDetalhes from './footerDetalhes'
import HeaderDetalhes from './headerDetalhes'

const DetalhesEdital = ({ props }) => {
  const {
    toggleModalReprovar,
    toggleModalQualificar,
    qualificado,
    desqualificado,
    editais,
    id,
    downloadQuebra,
    fetchingCaptchaDownload
  } = props
  const edital = editais.find(edital => edital.id === id)
  const { selecionado, uasg } = edital
  const { status } = selecionado

  const handleQualificar = () => {
    toggleModalQualificar()
  }

  const handleDesqualificar = () => {
    toggleModalReprovar()
  }
  return (
    <>
      <HeaderDetalhes
        edital={edital}
        downloadQuebra={downloadQuebra}
        isFetching={fetchingCaptchaDownload}
      />
      <BodyDetalhes edital={edital} uasg={uasg} />
      <FooterDetalhes
        edital={edital}
        handleQualificar={handleQualificar}
        handleDesqualificar={handleDesqualificar}
        qualificado={qualificado}
        desqualificado={desqualificado}
        status={status}
      />
    </>
  )
}

export default DetalhesEdital

import React from 'react'
import firebase from 'firebase/app'
import 'firebase/messaging'
import $ from 'jquery'
import {
  AlertaMsgVerificada,
  CustomAlertNotification,
  ToastSuccess,
  ToastError
} from './Funcoes/FuncoesGerais'
import MenuStores from './MenuStores'
import ConfiguracoesMonitoramentoStores from './ConfiguracoesMonitoramentoStores'
import OcorrenciasDoDiaStores from './OcorrenciasDoDiaStores'
import MaquinaDeEstadosStore from './MaquinaDeEstadosStore'
import EditaisStores from './EditaisStores'
import SideStores from './SideStores'

import '../Debug'
import {
  getMonitoramento,
  getTokenFirebase,
  setTokenFirebase
} from '../Services/notificacao'
import MonitoramentoStore from './MonitoramentoStore'
import { playSom } from '../som/SomSistema'
import Alert from 'react-s-alert'
import { getPortalName } from '../Services/portais'
import DownloadEditaisStore from '../Pages/Prospection/DownloadList/store'
import painelAvisoStores from './PainelAvisoStores'
import ItensPropostaStore from '../Pages/Proposal/CadastrarProposta/contexto/Itens/store'
import GerenciarPropostaStore from '../Pages/Proposal/GerenciarProposta/store'
import { CustomToast } from '../UsefulComponents/CustomToasts'
import maquinadeestadosstore from './MaquinaDeEstadosStore'

const KEY_FIREBASE =
  'key=AAAAyaeHPZY:APA91bEoVtJEyOef2AUksWh-s9MfBxVytQVwvB_oLiJ9_1Jkc3ni5-zB0lf3kJv5Hqsq1vWiLAHQJJIgcVNeaFjLeVknnMs2jj1F4e0yh332PheGCEQX5t2-nexVVovfU3dT-wa1-kfJ'

const playAudio = (audio, portal, situacao, alerta_sonoro) => {
  if (OcorrenciasDoDiaStores.som)
    playSom(audio, portal, situacao, alerta_sonoro)
}

export const setMessager = () => {
  firebase.messaging().onMessage(payload => {
    const { aleatorio } = payload.data
    if (aleatorio) {
      if (MenuStores.random_tirar_duplicacao !== aleatorio) {
        MenuStores.acrescentar_um()
        MenuStores.random_tirar_duplicacao = aleatorio
        handleMensagem(payload)
        console.log('aleat')
      } else MenuStores.acrescentar_um()
    } else {
      console.log('noaleat')
      handleMensagem(payload)
    }
  })
}

let empresa = []

let status = false

function sleep() {
  return setTimeout(function() {
    empresa = []
    status = false
  }, 150000)
}

const handleClick = (e, url) => {
  e.preventDefault()
  window.open(url, '_blank')
  Alert.close(
    e.target.parentElement.parentElement.parentElement.parentElement
      .parentElement.id
  )
}

const handleMensagem = payload => {
  MonitoramentoStore.verificada = false
  const { configuracao_empresas } = ConfiguracoesMonitoramentoStores
  const {
    tipostring,
    url,
    descricao_tipo,
    fornecedor_nome,
    licitacao_codigo,
    licitacao_id,
    fornecedor_id,
    portal_id,
    portal,
    situacao,
    alerta_sonoro,
    credencial_id,
    monitoramento
  } = payload.data

  console.log('teste tipostring', payload.data)

  if (tipostring === 'download_vazio') {
    clearInterval(EditaisStores.requestInterval)
    EditaisStores.linkDisponivel = false
    EditaisStores.dataPesquisaDownload = undefined
    EditaisStores.downloadsNoZip = undefined
    EditaisStores.horaCriacao = undefined
    EditaisStores.requestGerarLink = false
    DownloadEditaisStore.errorDownload = true
    const message =
      'Os editais ainda estão sendo processados, tente mais tarde.'
    return ToastError('Nenhum edital encontrado.')
    // return AlertaDownloadVazio(message);
  }

  // Corrigir bug que acontece quando usamos mesmo CNPJ em produção e em Dev
  if (
    fornecedor_id &&
    !MaquinaDeEstadosStore.empresas.find(em => em.id == fornecedor_id)
  )
    return

  const newEmpresa = configuracao_empresas.find(item => {
    return Number(item.fornecedor_id) === Number(fornecedor_id)
  })

  const config = {
    empresa: fornecedor_nome,
    licitacao_cod: licitacao_codigo,
    id_licitacao: licitacao_id,
    id_fornecedor: fornecedor_id,
    type: tipostring,
    descricao_tipo,
    portal,
    situacao,
    tipostring
  }

  if (tipostring === 'verificada_monitoramento')
    return AlertaMsgVerificada('Mensagens verificadas')

  if (tipostring === 'alerta_push') {
    return (MaquinaDeEstadosStore.isPushWorking = true)
  }

  if (tipostring === 'spreadsheet') {
    switch (window.location.pathname) {
      case '/proposta/gerenciar':
        GerenciarPropostaStore.fetchPropostas()
        break
      case '/proposta/cadastrar':
        ItensPropostaStore.propostaEnviada = true
        ItensPropostaStore.aguardandoResposta = false
      default:
        return null
    }

    return ToastSuccess(descricao_tipo)
  }

  if (tipostring === 'credencial') {
    MaquinaDeEstadosStore.empresas = MaquinaDeEstadosStore.empresas.map(em => {
      if (em.credenciais.find(cred => cred.id == credencial_id)) {
        return {
          ...em,
          credenciais: em.credenciais.map(cr =>
            cr.id == credencial_id ? { ...cr, expired: true } : cr
          )
        }
      }
      return em
    })
    MaquinaDeEstadosStore.updateCredenciais()
  }

  if (tipostring === 'doc_proposta') {
    const { enviado, nome_arquivo, error } = payload.data
    if (enviado) {
      ItensPropostaStore.docs_proposta = ItensPropostaStore.docs_proposta.map(
        el =>
          el.name === nome_arquivo
            ? { ...el, loading: false, enviado: true }
            : el
      )
      ToastSuccess(`📋 Documento ${nome_arquivo} enviado com sucesso.`)
    } else
      ToastError(`Erro ao enviar o documento ${nome_arquivo}. ${error || ''}`)
  }

  if (tipostring === 'doc_habilitacao') {
    const { enviado, nome_arquivo, error } = payload.data
    if (enviado) {
      ItensPropostaStore.docs_habilitacao = ItensPropostaStore.docs_habilitacao.map(
        el =>
          el.name === nome_arquivo
            ? { ...el, loading: false, enviado: true }
            : el
      )
      ToastSuccess(`📋 Documento ${nome_arquivo} enviado com sucesso.`)
    } else
      ToastError(`Erro ao enviar o documento ${nome_arquivo}. ${error || ''}`)
  }

  if (tipostring === 'verificada') {
    // playChain(["atencao", "encontramos-mensagens-importantes", "comprasnet"]);
    SideStores.consulta_notificacoes()
    if (window.location.pathname === '/monitoramento') return
    AlertaMsgVerificada('Mensagem Verificada')
    MonitoramentoStore.consulta_especifica_firebese(licitacao_id)
    return OcorrenciasDoDiaStores.consultaApi(true)
  }

  if (
    tipostring === 'situacao' &&
    window.location.pathname === '/painel_monitoramento'
  )
    painelAvisoStores.consulta_api(fornecedor_id)

  if (tipostring === 'todas_verificada') {
    AlertaMsgVerificada(
      `Todas as mensagens foram verificadas para ${fornecedor_nome}`
    )
    SideStores.consulta_notificacoes()
    return OcorrenciasDoDiaStores.consultaApi(true)
  }

  if (tipostring === 'download' && !MenuStores.fullScreen) {
    return DownloadEditaisStore.provideUrl(url)
  }

  getMonitoramento() &&
    portal_id &&
    OcorrenciasDoDiaStores.som &&
    playAudio(
      alerta_sonoro,
      tipostring,
      getPortalName(portal_id).toLowerCase(),
      situacao
    )

  if (!MenuStores.fullScreen) {
    if (
      Number(MaquinaDeEstadosStore.getIdEmpresa()) !== Number(fornecedor_id)
    ) {
      if (!status) {
        sleep()
        status = true
      }

      if (newEmpresa && newEmpresa.monitoramento) {
        if (empresa.indexOf(fornecedor_id) === -1) {
          ToastSuccess(`Alerta de notificação para ${fornecedor_nome}`)
          empresa.push(fornecedor_id)
        }
      }
    } else {
      if (getMonitoramento() === 'true') {
        const {
          mensagem: { monitoramento: mensagemMonitoramento },
          especifica: { monitoramento: especificaMonitoramento },
          empresa: { monitoramento: empresaMonitoramento },
          palavra_importante: { monitoramento: palavraimportanteMonitoramento },
          situacao: { monitoramento: situacaoMonitoramento }
        } = ConfiguracoesMonitoramentoStores
        
        switch (tipostring) {
          case 'mensagem': {
            if (mensagemMonitoramento) MaquinaDeEstadosStore.reloadSearchNewNotifications();
              
              CustomToast(config, { className: 'toast-mensagem' }, () =>
                maquinadeestadosstore.redirect(licitacao_id)
              )
            break
          }
          case 'importante': {
            if (palavraimportanteMonitoramento) MaquinaDeEstadosStore.reloadSearchNewNotifications();
            
            CustomToast(config, { className: 'toast-importante' }, () =>
              maquinadeestadosstore.redirect(licitacao_id)
            )
            break
          }
          case 'especifica': {
            if (especificaMonitoramento) MaquinaDeEstadosStore.reloadSearchNewNotifications();
            
            CustomToast(config, { className: 'toast-importante' }, () =>
              maquinadeestadosstore.redirect(licitacao_id)
            )
            break
          }
          case 'empresa': {
            if (empresaMonitoramento) MaquinaDeEstadosStore.reloadSearchNewNotifications();
            
            CustomToast(config, { className: 'toast-importante' }, () =>
              maquinadeestadosstore.redirect(licitacao_id)
            )
            break
          }
          case 'situacao': {
            if(situacaoMonitoramento) MaquinaDeEstadosStore.reloadSearchNewNotifications();
            CustomToast(config, { className: 'toast-situacao' })
            break
          }
          default: {
            CustomAlertNotification(config, descricao_tipo)
          }
        }
      }
      SideStores.consulta_notificacoes()
    }
  }
  if (MaquinaDeEstadosStore.getIdEmpresa() === Number(fornecedor_id))
    MonitoramentoStore.consulta_api_fire()
  OcorrenciasDoDiaStores.consultaApi(true)
}

export const pedirPermissaoParaReceberNotificacoes = async () => {
  try {
    const messaging = firebase.messaging()
    await messaging.requestPermission()
    const token = await messaging.getToken()
    setTokenFirebase(token)
    MenuStores.cadastrar_topico_firebase()
    // messaging.

    cadastrar_topico()
    return token
  } catch (error) {}
}

export const inicializarFirebase = () => {
  firebase.initializeApp({
    apiKey: 'AIzaSyCdigvlzHuAH4Ph-HnpdoCOq1bGCMXLR6A',
    authDomain: 'reactrafaeljanionot.firebaseapp.com',
    databaseURL: 'https://reactrafaeljanionot.firebaseio.com',
    projectId: 'reactrafaeljanionot',
    storageBucket: 'reactrafaeljanionot.appspot.com',
    messagingSenderId: '866099084694'
  })
  removeServiceWorkers()
  startServiceWorkers()
}

// const checkifSafari = () => {
//   var ua = navigator.userAgent.toLowerCase();
//   if (ua.indexOf("safari") !== -1) {
//     if (ua.indexOf("chrome") > -1) {
//       return false; // Chrome
//     } else {
//       return true; // Safari
//     }
//   }
// };

export const removeServiceWorkers = () => {
  // console.log(firebase.apps)
  // if (firebase.apps.length) {
  //   const messaging = firebase.messaging();
  //   firebase.apps = [];
  //   // console.log('teste ins', messaging.getInstanceId())
  //   // messaging.deleteInstanceId();
  // }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then(function(registrations) {
        for (let registration of registrations) {
          registration.unregister()
        }
      })
      .catch(function(err) {
        console.log('Service Worker registration failed: ', err)
      })
  }
}

export const startServiceWorkers = () => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then(registration => {
        firebase.messaging().useServiceWorker(registration)
      })

    window.addEventListener(
      'beforeunload',
      function(e) {
        removeServiceWorkers()
      },
      false
    )

    // FOREGROUND
    setMessager()
    // BACKGROUND
    const listener = new BroadcastChannel('listener')
    listener.onmessage = function(e) {
      console.log('s')
      const payload = e.data
      const { aleatorio } = payload.data
      if (MenuStores.random_tirar_duplicacao !== aleatorio) {
        MenuStores.acrescentar_um()
        MenuStores.random_tirar_duplicacao = aleatorio
        handleMensagem(payload)
      } else {
        MenuStores.acrescentar_um()
      }
    }
  }
}

export const cadastrar_topico = () => {
  if (MaquinaDeEstadosStore.getCnpjEmpresa())
    $.post({
      url: `https://iid.googleapis.com/iid/v1/${getTokenFirebase()}/rel/topics/${MaquinaDeEstadosStore.getCnpjEmpresa()}`,
      headers: { Authorization: KEY_FIREBASE },
      success: function(data, textStatus, request) {},
      error: function(request, textStatus, errorThrown) {}
    })
}

export const cadastrar_topico_especifico = cnpj => {
  let cnpjespecial = cnpj.replace(/\./g, '')
  cnpjespecial = cnpjespecial.replace(/-/g, '')
  cnpjespecial = cnpjespecial.replace(/\//g, '')
  $.post({
    url: `https://iid.googleapis.com/iid/v1/${getTokenFirebase()}/rel/topics/${cnpjespecial}`,
    headers: { Authorization: KEY_FIREBASE },
    success: function(data, textStatus, request) {},
    error: function(request, textStatus, errorThrown) {}
  })
}

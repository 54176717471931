import { observable, action } from "mobx";
import $ from "jquery";
import ConfiguracoesGeraisStores from "./ConfiguracoesGeraisStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import Api from "./Conexao/conexao";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import MenuStores from "./MenuStores";
import { statusConexao } from "../StatusConexao/StatusConexao";
import StatusConexaoStores from "./StatusConexaoStores";

class ConfiguracoesGeraisModalStores {
  @observable
  empresas = [];
  @observable
  empresas_id = [];
  @observable
  key = 0;
  @observable
  first_name = "";
  @observable
  last_name = "";
  @observable
  email_cobranca = "";
  @observable
  phone = "";
  @observable
  password = "";
  @observable
  repeat_password = "";
  @observable
  fornecedores = [];
  @observable
  adminValidation = false;
  @observable
  isActive = false;
  @observable
  email = "";
  @observable
  senha = "";

  @action
  busca_empresa = id => {
    this.empresas_id = [];
    Api.get("get_usuario/" + id + "/")
      .then(response => {
        this.empresas = response.data.empresas;

        this.empresas.forEach(item => {
          if ((this.empresas_id.length === 0) | (this.empresas_id.length > 0))
            this.empresas_id.push(item.id);
        });
        this.key = 1;
        this.key = 2;
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        if (global.debug) {
          console.log("Busca Empresa Erro", error);
        }
      });
  };

  @action
  limpa_campos = () => {
    this.email = this.email_cobranca;
    this.senha = this.password;
    this.first_name = "";
    this.last_name = "";
    this.email_cobranca = "";
    this.phone = "";
    this.password = "";
    this.repeat_password = "";
    this.fornecedores = 0;
  };

  @action
  excluir_usuario = id => {
    if (Number(localStorage.getItem("user_id")) === Number(id)) {
      AlertaError(
        "Nao é possível Excluir o Usuário que você está utilizando ! "
      );
    } else {
      Api.post("cad_usuario/" + id + "/delete/")
        .then(response => {
          AlertaSuccess("Usuario excluido com sucesso!");
          ConfiguracoesGeraisStores.consulta_api();
          this.key = this.key + 1;
        })
        .catch(error => {
          if (!statusConexao()) StatusConexaoStores.handleOpenModal();
          if (global.debug) console.log("falhou Exluir Usuario", error);
        });
    }
  };

  @action
  busca_dados = (first_name, last_name, email_cobranca, phone) => {
    this.first_name = first_name;
    this.last_name = last_name;
    this.email_cobranca = email_cobranca;
    this.phone = phone;
  };

  @action
  onChange = evento => {
    const campo = evento.target.id;
    this[campo] = evento.target.value;
    if (campo === "email") this.email_cobranca = evento.target.value;
    this.empresas_id = $("#empresas_cliente").val();
    return evento;
  };

  @action
  onChangeSenha = evento => {
    const campo = evento.target.id;
    this[campo] = evento.target.value;
  };

  @action
  validandoInputsEditar = () => {
    let status_editar = false;
    while (status_editar === false) {
      if (this.fornecedores.length === 0) {
        AlertaError("Selecione um empresa!");
        return false;
      } else if (this.first_name === "") {
        AlertaError("Campos de nome em branco!");
        return false;
      } else if (this.last_name === "") {
        AlertaError("Campos de sobrenome em branco!");
        return false;
      }
      if (
        this.fornecedores.length > 0 &&
        this.first_name !== "" &&
        this.last_name !== ""
      ) {
        return true;
        // status_editar = true;
      }
    }
    this.limpa_campos();
  };

  @action
  validandoInputs = () => {
    this.adminValidation = false;
    this.fornecedores = $("#empresas_cliente").val();
    if (this.adminValidation === false) {
      if (this.fornecedores.length === 0) {
        AlertaError("Selecione ao menos uma empresa!");
        return false;
      } else if (this.first_name === "") {
        AlertaError("Campo nome em branco!");
        return false;
      } else if (this.last_name === "") {
        AlertaError("Campo sobrenome em branco!");
        return false;
      } else if (this.email_cobranca === "") {
        AlertaError("Campo e-mail em branco!");
        return false;
      } else if (
        !this.email_cobranca.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        AlertaError("Campo de e-mail contém um e-mail inválido!");
        return false;
      }
      if (
        this.fornecedores.length !== 0 &&
        this.first_name !== "" &&
        this.last_name !== "" &&
        this.email_cobranca !== ""
      ) {
        return true;
      }
    }
    this.limpa_campos();
  };

  @action
  salva_usuario = (id, funcaoFecharModal) => {
    const data = new FormData();
    this.fornecedores.forEach(item => {
      data.append("fornecedores[]", item);
    });
    data.append("username", this.email_cobranca);
    data.set("email_cobranca", this.email_cobranca);
    data.set("last_name", this.last_name);
    data.set("first_name", this.first_name);
    data.set("phone", this.phone);
    data.set("permissao", "analista");

    if (this.validandoInputsEditar()) {
      Api.post("cad_usuario/" + id + "/", data, {})
        .then(response => {
          ConfiguracoesGeraisStores.consulta_api();
          this.key = 1;
          this.key = 2;
          MenuStores.consulta_api();
          if (response.data.ok === "sim") {
            AlertaSuccess("Usuário editado com sucesso!");
          } else {
            AlertaError("Erro ao editar Usúario ! E-mail já cadastrado!");
          }
          funcaoFecharModal(false);
        })
        .catch(error => {
          if (!statusConexao()) {
            StatusConexaoStores.handleOpenModal();
          }
          if (global.debug) {
            console.log("falhou SALVAR API", error);
          }
          AlertaError("Erro ao Editar Usúario!");
        });
    }
  };

  @action
  fecharModal = () => {
    this.adminValidation = false;
  };
  @action
  handleOpenModal = () => {
    this.isActive = true;
  };

  @action
  handleCloseModal = () => {
    this.isActive = false;
  };

  @action
  add_usuario_validacao_admin = funcaoFecharModal => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);

    Api.post("/login/", login, {})
      .then(response => {
        this.adicionar_usuario(funcaoFecharModal);
        this.adminValidation = false;
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaError("Senha invalida!");
      });
    this.senha = "";
  };

  @action
  adicionar_usuario = funcaoFecharModal => {
    const data = new FormData();
    this.fornecedores.forEach(item => {
      data.append("fornecedores[]", item);
    });
    data.append("username", this.email_cobranca);
    data.set("email_cobranca", this.email_cobranca);
    data.set("last_name", this.last_name);
    data.set("first_name", this.first_name);
    data.set("phone", this.phone);
    data.set("password", this.password);
    data.set("repeat_password", this.repeat_password);
    data.set("permissao", "analista");

    Api.post("cad_usuario/", data, {})
      .then(response => {
        ConfiguracoesGeraisStores.consulta_api();
        this.key = this.key + 1;
        if (response.data.ok === "sim") {
          const userId =
            response.data.usuarios[response.data.usuarios.length - 1].id;
          ConfiguracoesGeraisStores.busca_empresa(userId);
          AlertaSuccess("Usuário criado com sucesso!");
          this.isActive = false;
          funcaoFecharModal(false);
        } else {
          AlertaError("E-mail já cadastrado!");
        }
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaError("Erro ao criar Usúario!");
        if (global.debug) {
          console.log("falhou SALVAR API", error);
        }
      });
  };

  @action
  editar_usuario_validacao_admin = (id, funcaoFecharModal) => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);

    Api.post("/login/", login, {})
      .then(response => {
        this.salva_usuario(id, funcaoFecharModal);
        this.adminValidation = false;
        this.limpa_campos();
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaError("Senha invalida!");
      });
    this.senha = "";
  };

  @action
  modal_validacao_senha_exlcuir = id => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);

    Api.post("/login/", login, {})
      .then(response => {
        this.excluir_usuario(id);
        this.adminValidation = false;
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaError("Senha invalida!");
      });
    this.senha = "";
  };

  @action
  modal_validacao_senha_excluir = () => {
    this.adminValidation = true;
  };

  @action
  modal_validacao_senha = fechar => {
    if (fechar) return (this.adminValidation = false);
    if (this.validandoInputs()) {
      this.fornecedores = $("#empresas_cliente").val();
      this.adminValidation = true;
    }
  };

  @action limparVariaveis = () => {
    this.empresas = [];
    this.empresas_id = [];
    this.key = 0;
    this.first_name = "";
    this.last_name = "";
    this.email_cobranca = "";
    this.phone = "";
    this.password = "";
    this.repeat_password = "";
    this.fornecedores = [];
    this.adminValidation = false;
    this.isActive = false;
    this.email = "";
    this.senha = "";
  };
}

const configuracoesGeraisModalStores = new ConfiguracoesGeraisModalStores();
export default configuracoesGeraisModalStores;

import React, { useState } from "react";
// import { Card, CardBody, CardHeader, CustomSwitch } from "../../../../globalStyles";
// import { BodySwitch } from "../StyleEditaisSelecionados";
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from "../../../Components/NewModal";
import { IconFiltro } from "../../../Icons/iconCustom";
import FiltroCompradores from "../Itens/FiltroCompradores";

export function ModalFiltroCompradores() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animation, setAnimation] = useState(false);

  return (
    <ModalProvider>
      <div
        onClick={() => setIsModalOpen(true)}
        className="icon-scale file"
        data-tip="CONFIGURAR FILTRO"
        data-type="info"
        data-effect="solid"
        data-place="left"
      >
        <IconFiltro color="#3A566E" />
      </div>

      {isModalOpen && (
        <ModalLateral
          doobleIcon={
            <div style={{ padding: "0 20px" }}>
              <IconFiltro />
            </div>
          }
          title={"CONFIGURAR FILTRO"}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          {/* <ContextModalFiltros onClose={() => setIsModalOpen(false)} /> */}
          <FiltroCompradores toggleModal={setIsModalOpen} />
        </ModalLateral>
      )}
    </ModalProvider>
  );
}

export default ModalFiltroCompradores;

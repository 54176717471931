import { observable, action } from 'mobx'

import Api from '../../../Stores/Conexao/conexao'
import BoletimStore from '../../../Pages/Prospection/DailyBulletin/Store'
import BuscaManualStore from '../../../Pages/Prospection/ManualSearch/Store'
import DownloadEditaisStore from '../../../Pages/Prospection/DownloadList/store'
import QualificadosStore from '../../../Pages/Prospection/Qualified/Store'
import ReprovadosStore from '../../../Pages/Prospection/Failed/Store'
import AprovadosStore from '../../../Pages/Prospection/Approved/Store'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import { tratarDadosEditais } from '../../../Pages/Prospection/Components/tratarDadosEditais'
import {
  FormatDateData,
  AlertaError
} from '../../../Stores/Funcoes/FuncoesGerais'

class DetalhesEditalStore {
  @observable isFetching = false
  @observable fetchingMore = false
  @observable itens = []
  @observable id = null

  @action consultarItens = async () => {
    this.isFetching = true
    try {
      let res = await Api.post('/portal/itens_edital/', { id: this.id })
      this.itens = res.data.itens_edital
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  @action setId = id => {
    const index = this.editais.map(ed => ed.id).indexOf(id)
    if (this.page * 10 < index) this.page++
    if (this.page * 10 > index + 10) this.page--
    this.id = id
  }

  @action toggleModal = (id = null, state) => {
    this.id = id
    this.getDataFromStore()
    this.contextState = state
  }

  @observable contextState = {}

  @observable editais = []
  @observable total_registros = 0
  @observable page = 1
  @action setPage = page => (this.page = page)

  @observable next = false
  @observable previous = false
  @observable atual = 0

  @observable user = {
    id: null,
    changeUser: ({ target: { value } }) => (this.user.id = value)
  }

  @observable prioridade = 'media'
  @action mudarPrioridade = ({ target: { value } }) => (this.prioridade = value)

  @observable modalQualificar = false
  @observable modalAprovar = false
  @observable modalReprovar = false

  @action toggleModalQualificar = () => {
    const { prioridade, usuarios } = this.editais.find(ed => ed.id === this.id)
    if (usuarios.length) this.user.id = usuarios[0].id
    this.prioridade = prioridade || 'media'
    this.modalQualificar = !this.modalQualificar
  }

  @action toggleModalAprovar = () => {
    const { prioridade, usuarios } = this.editais.find(ed => ed.id === this.id)
    if (usuarios.length) this.user.id = usuarios[0].id
    this.prioridade = prioridade || 'media'
    this.modalAprovar = !this.modalAprovar
  }

  @action toggleModalReprovar = () => (this.modalReprovar = !this.modalReprovar)

  @action mudarStatus = async status => {
    this.isFetching = true
    const boletim = this.editais.find(ed => ed.id === this.id).selecionado.id
    const url = `/portal/${boletim ? 'boletim' : 'edital'}_status/`
    const config = {
      edital: this.id,
      status,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      usuarios: [this.user.id || MaquinaDeEstadosStore.usuarioLogado.id],
      prioridade: this.prioridade
    }

    if (this.msg_reprovar) config.msg_status = this.msg_reprovar

    try {
      let res = await Api.post(url, config)
      this.editais = this.editais.map(ed => {
        if (ed.id === this.id)
          return { ...ed, status: res.data.status, selecionado: res.data }
        return ed
      })
      // this.getStore().changeEditais(this.id, res.data.status);
      if (
        window.location.pathname.includes('aprovados') ||
        window.location.pathname.includes('reprovados') ||
        window.location.pathname.includes('qualificados')
      )
        this.getStore().fetchEditais()
      else this.getStore().fetchEditaisNovo(this.contextState)
      // this.closeConfirmationModal(status)
    } catch (err) {
      console.log('err', err)
      AlertaError('Não foi possível executar essa ação, tente mais tarde.')
    } finally {
      this.isFetching = false
      this.msg_reprovar = ''
    }
  }

  @action closeConfirmationModal = status => {
    switch (status) {
      case 'reprovado':
        this.toggleModalReprovar()
        break
      case 'qualificado':
        this.toggleModalQualificar()
        break
      case 'aprovado':
        this.toggleModalAprovar()
        break
      default: {
        this.modalAprovar = false
        this.modalReprovar = false
        this.modalQualificar = false
      }
    }
    this.prioridade = 'media'
  }

  @observable msg_reprovar = ''
  @action onChangeMessage = e => {
    this.msg_reprovar = e.target.value
  }

  @action fetchMore = async type => {
    this.fetchingMore = true
    const novosEditais = await this.fetchEditais(
      this.page + type,
      this.contextState
    )
    this.fetchingMore = false
    if (type > 0) {
      this.tratarEditaisAdicionados(
        tratarDadosEditais(novosEditais),
        this.page + type
      )
      this.id = novosEditais[0].id
    } else {
      this.tratarEditaisAdicionados(
        tratarDadosEditais(novosEditais),
        this.page + type
      )
      this.id = novosEditais[9].id
    }
    this.page += type
  }

  tratarEditaisAdicionados(editais, page) {
    let editaisAtuais = [...this.editais]
    editais.forEach((ed, index) => {
      if (!editaisAtuais.find(edet => ed.id === edet.id)) {
        editaisAtuais[(page - 1) * 10 + index] = ed
      }
    })
    this.editais = [...editaisAtuais]
  }

  getDataFromStore = () => {
    const {
      editais,
      total_registros,
      pagination: { page },
      fetchMore
    } = this.getStore()
    let array = []
    for (let i = 0; i < total_registros; i++) {
      const nec = i >= (page - 1) * 10 && i < (page - 1) * 10 + 10
      if (nec) {
        array[i] = editais[i - (page - 1) * 10]
      } else {
        array[i] = { id: null }
      }
    }
    this.editais = [...array]
    this.total_registros = total_registros
    this.page = page
    this.fetchEditais = fetchMore
  }

  @action getStore = () => {
    let store
    switch (window.location.pathname) {
      case '/prospeccao/qualificados':
        store = QualificadosStore
        break
      case '/prospeccao/boletim':
        store = BoletimStore
        break
      case '/prospeccao/aprovados':
        store = AprovadosStore
        break
      case '/prospeccao/reprovados':
        store = ReprovadosStore
        break
      case '/prospeccao/qualificados':
        store = QualificadosStore
        break
      case '/prospeccao/qualificados':
        store = QualificadosStore
        break
      case '/prospeccao/download':
        store = DownloadEditaisStore
        break
      default:
        if (window.location.pathname.includes('/prospeccao/pesquisa'))
          store = BuscaManualStore
    }
    return store
  }

  @observable fetchingCaptchaDownload = false
  @action downloadQuebra = async edital => {
    const { id, licitacao } = edital
    const { empresas, id_fornecedor } = MaquinaDeEstadosStore

    const date = FormatDateData(new Date())
    const empresaName = empresas.find(
      empresa => Number(empresa.id) === Number(id_fornecedor)
    ).nome_fantasia
    const fileName = `Edital_${licitacao}_${empresaName}_${date}.zip`
    const dados = [id]

    this.fetchingCaptchaDownload = true
    try {
      const res = await Api.post(
        'portal/download_zip/',
        { dados, fornecedor_pk: id_fornecedor },
        { responseType: 'blob' }
      )
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (err) {
      console.log('err', err)
    } finally {
      this.fetchingCaptchaDownload = false
    }
  }

  trocouDeEmpresa = () => {
    this.limparVariaves()
  }

  limparVariaveis = () => {
    this.isFetching = false
    this.fetchingMore = false
    this.id = null

    this.editais = []
    this.total_registros = 0
    this.page = 1

    this.next = false
    this.previous = false
    this.atual = 0

    this.modalQualificar = false
    this.qualificado = false

    this.modalReprovar = false
    this.desqualificado = false
  }
}

export default new DetalhesEditalStore()

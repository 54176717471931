import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  background:#1D7C98;
  height: 75px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 98%;
  border-radius: 5px;
  background: white;
  height: 55px;
`;

export const WrapperInputs = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-right: 15px;
  border-right: 1px solid #c3c3c3;

  form {
    display: flex;
    width: 100%;

    div {
      margin: 0px 20px;

      div { margin: 0 };
    }
  }
`;

export const WrapperCarregarItens = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  width: 170px;
  cursor: pointer;
  transition: 200ms;
  svg{
  width: 20px;
  height: 20px;
  }
  :hover{
  transform: scale(1.1);
  }
`;



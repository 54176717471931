import React, { useState } from 'react'

import maquinadeestadosstore from '../Stores/MaquinaDeEstadosStore'

import * as S from './Style'

const logo = '/static/Imagens/logo_wavecode.png'

const SplashScreen = ({ props }) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    maquinadeestadosstore._props = props
    if (localStorage.getItem('token') === null)
      return maquinadeestadosstore.redireciona_login()
    setLoading(true)
    await maquinadeestadosstore.consultaEmpresas()
    setLoading(false)
  }

  return (
    <S.Container disabled={loading}>
      <img src={logo} alt={'logo wavecode'} />
      <button onClick={handleSubmit} disabled={loading}>
        COMEÇAR
      </button>
    </S.Container>
  )
}

export default SplashScreen

import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
// import ModalLoadingStore from "./ModalLoadingStore";

class SideStores {
  @observable
  qtd_editais = 0;
  @observable
  qtd_monitoramento_verde = 0;
  @observable
  qtd_monitoramento_vermelho = 0;
  @observable
  qtd_ocorrencias = 0;
  @observable
  qtd_licitacoes_dia = 0;
  @observable
  situacao_licitacao_id = [];

  @action
  consulta_notificacoes = async () => {
    // ModalLoadingStore.handleOpenModal();
    try {
      let response = await Api.get(
        `atualiza_sidebar/${MaquinaDeEstadosStore.getIdEmpresa()}/`
      );
      const {
        editaisselecionados,
        monitoramento_verde,
        monitoramento_vermelho,
        ocorrencias_nao_verificadas,
        licitacoes_dia,
        licitacoes_id
      } = response.data;
      this.qtd_editais = editaisselecionados;
      this.qtd_monitoramento_verde = monitoramento_verde;
      this.qtd_monitoramento_vermelho = monitoramento_vermelho;
      this.qtd_ocorrencias = ocorrencias_nao_verificadas;
      this.qtd_licitacoes_dia = licitacoes_dia;
      this.situacao_licitacao_id = licitacoes_id.length;
    } catch (e) {
      console.log("err", e);
    } finally {
      // ModalLoadingStore.handleCloseModal();
    }
  };

  @action limparVariaveis = () => {
    this.qtd_editais = 0;
    this.qtd_monitoramento_verde = 0;
    this.qtd_monitoramento_vermelho = 0;
    this.qtd_ocorrencias = 0;
    this.qtd_licitacoes_dia = 0;
    this.situacao_licitacao_id = [];
  };
}

const sideStores = new SideStores();
export default sideStores;

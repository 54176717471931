import React from "react";

export const rowEdital = {
  title: {
    fontSize: "22px",
    fontColor: "rgba(0,0,0,.87)",
    backgroundColor: "transparent",
    height: "0px"
  },

  rows: {
    borderWidth: "0px",
    borderTopStyle: null,
    height: "auto",
    minHeight: "80px",
    backgroundColor: "transparent",
    padding: "0px",
    whiteSpace: "nowrap"
  },
  cells: {
    borderTopStyle: null,
    // cellPadding: '20px',
    height: "20px",
    padding: "0px",
    whiteSpace: "nowrap"
  }
};

export const columnsEdital = [
  {
    name: "Nº",
    selector: "codigo",
    sortable: true,
    left: true,
    grow: 0,
    minWidth: "50px"
  },

  {
    name: "DESCRIÇÃO",
    selector: "descricao",
    sortable: true,
    whiteSpace: "nowrap",
    left: true,
    grow: 10,
    minWidth: "80px",
    cell: row => (
      <span
        style={{ padding: "13px", textAlign: "justify", whiteSpace: "normal" }}
      >
        {row.descricao_completa ? row.descricao_completa : row.descricao}
      </span>
    )
  },

  {
    name: "QTD",
    selector: "quantidade",
    sortable: true,
    left: true,
    minWidth: "80px",
    grow: 1
  },
  {
    name: "TIPO",
    selector: "tipo",
    sortable: true,
    center: true,
    grow: 1
  },
  {
    name: "SITUAÇÃO",
    selector: "situacao",
    sortable: true,
    left: true,
    grow: 1,
    cell: row => (
      <span
        style={{
          padding: "1px 7px",
          borderRadius: "9px",
          backgroundColor: "#436077",
          color: "#fff",
          marginLeft: "8px"
        }}
      >
        {row.situacao}
      </span>
    )
  }
];

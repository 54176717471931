import React from "react";
import { CheckCircle } from "react-feather";

import {
  DateStringToObject,
  DiffBetweenTwoDates
} from "../../Stores/Funcoes/FuncoesGerais";

import * as S from "./style";

const SinglePlano = ({ empresa, last }) => {
  if (!empresa) return null;
  const { nome_fantasia, contratos, razao_social, combo_id, combo_nome } = empresa;
  // console.log(empresa)
  const { data_validade } = contratos.find(el => el.status) || contratos[0];

  const diasRestantes = data_validade ? DiffBetweenTwoDates(DateStringToObject(data_validade)) : -1
  const vencido = diasRestantes < 0;
  const status =
    diasRestantes < 0
      ? "overDue"
      : diasRestantes > 10
      ? "longDue"
      : "almostDue";

  return (
    <S.PlanoContainer last={last}>
      <S.EmpresaInfo>
        <span>{razao_social}</span>
        <small>{nome_fantasia}</small>
        <div className="teste-gratis">
          {combo_nome}
        </div>
      </S.EmpresaInfo>
      <S.ModulosContainer>
        {contratos.map((con, i) => (
          <S.SingleModulo status={con.status} key={i}>
            <span>{con.status && <CheckCircle size="20px" />}</span>
            {con.name_modulo}
          </S.SingleModulo>
        ))}
      </S.ModulosContainer>
      <S.DaysRemainingContainer status={status}>
        <div className="d-flex flex-column justify-content-around h-100">
          {vencido ? (
            combo_id === 34 ? (
              "BLOQUEADO"
            ) : (
              "EXPIRADO"
            )
          ) : (
            <>
              <span>{`EXPIRA: ${data_validade}`}</span>{" "}
              {`${vencido ? "0" : diasRestantes} DIAS RESTANTES`}
            </>
          )}
        </div>
      </S.DaysRemainingContainer>
    </S.PlanoContainer>
  );
};

export default SinglePlano;

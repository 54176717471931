import maquinadeestadosstore from "../Stores/MaquinaDeEstadosStore";

export const getPortalName = id => {
  if (!id) return;
  return maquinadeestadosstore.portais.find(portal => portal.id == id).nome;
}

export const getPortalId = name => {
  switch (name.toLowerCase()) {
    case "comprasnet":
      return 5;
    case "licitações-e":
      return 6;
    default:
      return null;
  }
};
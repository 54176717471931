import React from "react";
import $ from "jquery";
import { observable, action } from "mobx";
import { Alert } from "react-bootstrap";
import { defaultsHeadersAxios } from "./Conexao/conexao";
import axios from "axios"

import "./FirebaseStore";
import "../Debug";
import Api from "./Conexao/conexao"
import StatusConexaoStores from "./StatusConexaoStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import { pedirPermissaoParaReceberNotificacoes } from "./FirebaseStore";
import { statusConexao } from "../StatusConexao/StatusConexao";
import { setToken } from "../Services/auth";
// import { ToastError } from "./Funcoes/FuncoesGerais";


class LoginStores {
  // Acesso das contas de suporte à qualquer usuário;
  @observable listaUsuarios = [];
  @observable listaUsuariosAtiva = false;
  @observable listaSuporte = [
    'admin@wavecode.com.br'
  ];
  //////////////////////////////////////////////////


  @observable
  user_id = "";
  @observable
  fornecedor_id = "";

  @observable
  status = false;
  @observable
  senha = "";
  @observable
  email = "";
  @observable
  CNPJ = "";
  @observable
  isActive = true;
  @observable
  msg_email = "";
  @observable
  color = "";
  @observable
  msg_senha = "";
  @observable
  status_modal = false;
  @observable
  flagColor = 0;
  @observable
  credenciais = true;
  @observable
  statusEmail = true;
  @observable
  statusSenha = false;

  @observable
  rSenha = "";
  @observable
  novaSenha = "";
  @observable
  token = "";
  @observable
  statusRecuperarSenha = false;
  @observable isFetching = false;
  @observable
  isSuccess = false;
  @observable
  errorMessage = "";
  @observable
  user_id = false;

  redireciona_editais = props => {
    props.history.push("/dashboard");
  };

  user = () => {
    let user = new FormData();
    user.set("tipo", "login");
    user.set("username", this.email);
    user.set("password", this.senha);
    return user;
  };

  compassInit = (empresa_nova) => {
    if (window.Conpass) {
      console.log('empresa nova', empresa_nova)
      window.Conpass.init({
        name: MaquinaDeEstadosStore.usuarioLogado.first_name,
        email: MaquinaDeEstadosStore.usuarioLogado.email,
        custom_fields: {
          lang: "pt_BR",
          empresa_nova
        }
      });
    };
  }

  @action logar = async (e, props) => {
    this.isSuccess = false;
    this.isFetching = true;
    this.errorMessage = "";
    try {
      const res = await axios.post(`${global.url}login/`, this.user());
      const { usuarios } = res.data;
      this.isSuccess = true;
      if (usuarios) this.handleListaSuporte(usuarios);
      else this.inicializar(res.data);

    } catch (error) {
      const status = error.response.status;

      this.errorMessage =
        status === 401
          ? "Login e/ou password inválidos"
          : "Servidor temporáriamente indisponível, tente mais tarde...";
    } finally {
      this.isFetching = false;
      setTimeout(() => this.isSuccess = false, 2000);
    }
  };

  @action handleListaSuporte = async (users) => {
    this.listaUsuarios = users;
    this.listaUsuariosAtiva = true;
  }

  @action inicializar = async (data) => {
    const { token, fornecedor_id, cnpj, user_id, email } = data;
    const { consultaEmpresas, setDadosUser } = MaquinaDeEstadosStore;

    defaultsHeadersAxios(token);
    setToken(token);
    await pedirPermissaoParaReceberNotificacoes();
    setDadosUser(user_id, fornecedor_id, cnpj, email, "login");
    await consultaEmpresas();
    this.user_id = user_id;
    this.redireciona_editais(MaquinaDeEstadosStore._props);
    this.errorMessage = "";
  }

  @action
  msgAviso = () => {
    if (this.flagColor === 0) {
      this.flagColor = 1;
      return (this.msgObs = (
        <Alert bsStyle={"danger"}>
          <strong>! Credenciais incorretas </strong>
        </Alert>
      ));
    } else {
      this.flagColor = 0;
      return (this.msgObs = (
        <Alert bsStyle={"warning"}>
          <strong>! Tente novamente credenciais incorretas </strong>
        </Alert>
      ));
    }
  };

  @action
  toggleModal = props => {
    this.status_modal = !this.status_modal;
    if (!this.status_modal) {
      props.history.push("/");
      window.location.reload();
    }
  };

  @action
  recuperar_senha = e => {
    this.msg_email = "";
    e.preventDefault();
    var email = $("#email").val();
    $.post({
      url: global.url + "recuperar_senha/",
      contentType: "application/x-www-form-urlencoded",
      data: { username: email },
      error: function (error) {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        this.color = "red";
        this.msg_email = "Email inválido!";
      }.bind(this),
      success: function (response) {
        this.color = "green";
        this.msg_email = "Email enviado com sucesso!";
      }.bind(this)
    });
    this.msg_email = "";
  };

  @action
  voltar_tela_login = (e, props) => {
    e.preventDefault();
    this.status_modal = false;
    props.history.push("/");
    window.location.reload();
  };
  
  @action redirectToDashboard = (props) => {
    props.history.push("/dashboard");
  }

  @action
  redefinir = e => {
    $.post({
      url: global.url + "alterar_senha_recuperada/" + this.token + "/",
      contentType: "application/x-www-form-urlencoded",
      data: { password: this.novaSenha, rpassword: this.rSenha },
      error: function (error) {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
      },
      success: function (response) {
        this.status_modal = true;
      }.bind(this)
    });
    this.msg_senha = "";
  };

  @action
  validaEmial = () => {
    if (this.novaSenha === this.rSenha) {
      this.statusRecuperarSenha = false;
    } else {
      return (this.statusRecuperarSenha = true);
    }
  };

  @action
  onChange = evento => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
    this.validaEmial();

    if (this.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.statusEmail = true;
      // return (false);
    } else {
      this.statusEmail = false;
    }
  };

  @action
  handleOpenModal = () => {
    this.isActive = true;
  };

  @action
  handleCloseModal = () => {
    this.isActive = false;
  };

  @action limparVariaveis = () => {
    this.user_id = "";
    this.fornecedor_id = "";

    this.status = false;
    this.senha = "";
    this.email = "";
    this.CNPJ = "";
    this.isActive = true;
    this.msg_email = "";
    this.color = "";
    this.msg_senha = "";
    this.status_modal = false;
    this.flagColor = 0;
    this.credenciais = true;
    this.statusEmail = true;
    this.statusSenha = false;

    this.rSenha = "";
    this.novaSenha = "";
    this.token = "";
    this.statusRecuperarSenha = false;
    this.isFetching = false;
    this.isSuccess = false;
    this.errorMessage = "";
    this.user_id = false;
  };
}

const loginStores = new LoginStores();
export default loginStores;

import styled from 'styled-components'
import OriginalReactTooltip from 'react-tooltip'
import { withStyles } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'

export const ReactTooltip = styled(OriginalReactTooltip).attrs({
  className: 'custom-tooltip'
})`
  &.custom-tooltip {
    padding: 0.32rem 0.6rem;
  }
`

export const colors = {
  firstColor: '#3A566E',
  secondColor: '#fff',
  thirdColor: '#E5E5E5'
}

export const MainHeader = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  background-color: #fbfbfb;
  padding: 0 30px;

  .help-button {
    color: #4f8ba5;
    margin-right: 15px;
    font-size: 20px;
  }

  @media only screen and (max-height: 780px) {
    height: 50px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    height: auto;
  }
`

export const MainBody = styled.main`
  background: #f9f9f9;
  position: relative;
  height: calc(100vh - 70px);

  @media only screen and (max-height: 780px) {
    height: calc(100vh - 50px);
  }
`

export const PageTitle = styled.h4`
  font-size: 18px;
  margin: 0;

  /* @media (max-width: 1450px) {
    font-size: 19px;
  } */
`

export const ComponentHeader = styled.div`
  position: relative;
  display: flex;
  background-color: ${colors.firstColor};
  width: 100%;
  height: ${props => props.height || '13vh'};
  padding: ${props => (props.lessPadding ? '1.5rem' : '2rem')};

  @media (max-width: 1400px) {
    padding: ${props => (props.lessPadding ? '1rem 2rem' : '2rem')};
  }
`
export const ComponentHeaderOverflow = styled(ComponentHeader)`
  position: absolute;
  width: 100%;
  height: ${props => props.height || '3vh'};
  z-index: 0;
`

export const ContentHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #e5e5e5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0.77rem;
  margin: 0 1.5rem;
  margin-bottom: 0;
  border-bottom: 1px solid #bebebe;
  box-shadow: 0px -1px 5px 1px #6b6b6bcc;
`

export const ContentBody = styled.div`
  background: ${props => (props.background ? props.background : '#ebebeb')};
  margin: 1.5rem;
  margin-top: 0;
  padding: ${props => props.padding || '1rem'};
  box-shadow: 0px 2px 4px 0.1px #00000073;
  position: relative;
  border-radius: ${props => (props.bRadius ? '7px' : 'none')};
  opacity: 1;
  height: ${props => props.height || 'auto'};
  min-height: ${props => props.minHeight || 'auto'};

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scroll-black {
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  /* animation: fadeIn 1s; */
`

export const ContentBodyLicitacoes = styled.div`
  margin: 1.5rem;
  margin-top: 0;
  box-shadow: 0px 2px 4px 0.1px #00000073;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 1;
  height: ${props => props.height || 'auto'};
  margin-bottom: 0;
  /* animation: fadeIn 1s; */
  .rdt_TableBody {
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(128, 187, 224)),
        color-stop(0.72, rgb(80, 132, 166)),
        color-stop(0.86, rgb(95, 163, 204))
      );
    }
  }
`

export const BackToTop = styled.button`
  z-index: 38126482163;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  background-color: #3a566e;
  color: #fff;
  box-shadow: 1px 1px 7px 0px #0000004a;
  border: none;
  position: fixed;
  bottom: 10px;
  right: calc(50% - 37.5px);
  display: flex;
  align-items: center;

  :focus {
    outline: none;
  }
`

export const SingleEdital = styled.div`
  display: flex;
  background-color: ${colors.secondColor};
  height: auto;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  box-shadow: 1px 1px 7px 0px #0000004a;
  font-size: 13px;
`

export const EditalActionButton = styled.button`
  width: 90%;
  border-radius: 5px;
  background-color: ${props => (props.enviada ? '#3b8652' : 'transparent')};
  margin-bottom: 0.2rem;
  color: ${props => (props.enviada ? '#fff' : '#4682b4')};
  border-color: #4682b4;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: 100ms;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;

  :hover {
    background-color: ${props => (props.green ? '#3b8652' : '#4682b4')};
    border-color: ${props => (props.green ? '#3b8652' : '#4682b4')};
    color: #fff;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  .spinner {
    position: relative;
  }
  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    margin-top: -7.5px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #3a566e;
    animation: spinner 0.6s linear infinite;
  }
`

export const EditalNotFound = styled.h4`
  text-align: center;
  margin: 1rem;
  color: ${colors.firstColor};
  font-size: 1.5rem;

  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }
`

export const CredentialNotFound = styled.span`
  text-align: center;
  margin: 1rem;
  color: ${colors.firstColor};
  font-size: 1.2rem;

  @media (max-width: 1450px) {
    font-size: 1rem;
  }

  @media (max-width: 1200px) {
    font-size: 0.9rem;
  }
`

export const VerticalLine = styled.div`
  height: auto;
  border-left: ${props => props.shape || '1px solid #000'};
  margin: ${props => props.marginTotal || '0 30px'};
`

export const TabButton = styled.b`
  font-weight: ${props => (props.ul ? '500' : '400')};
  cursor: pointer;
  width: fit-content;
  color: ${props => (props.ul ? '#fff' : '#cecece')};
  font-size: ${props => (props.smallFont ? '14px' : '16px')};
  margin: ${props => props.margin};
  text-transform: ${props => props.textTransform};
  min-height: 20px;

  :after {
    content: '';
    display: block;
    border-bottom: 2px solid #5b9ae4;
    width: ${props => (props.ul ? '100%' : '0')};
    transition: 0.6s ease;
    -webkit-transition: 0.6s ease;
  }

  @media (max-width: 1400px) {
    font-size: 12.5px;
  }

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`

export const ModalSaveButton = styled.button`
  position: absolute;
  bottom: 35px;
  right: calc(50% - 60px);
  padding: 3px 30px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  background: #287e26b5;
  box-shadow: 1px 1px 4px 1px #00000073;
  border-radius: 8px;
  border: none;
`

export const ModalCloseButton = styled.button`
  border-radius: 5px;
  margin-top: 25px;
  width: 130px;
  border: 1px solid #b3b3b3;
  color: #000;
  background: #fff;
  cursor: pointer;

  :hover {
    color: #fff;
    background: #a74b4b;
  }
`

export const ModalSaveButton2 = styled.button`
  width: 100%;
  height: 30px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: ${props => (props.isActive ? '#3a566e' : '#8c8e90')};
  border: 0;
  margin-bottom: 4px;
  color: ${props => (props.isActive ? '#e8e8e8' : '#cec9c9')};
  border-radius: 3px;
  box-shadow: 1px 1px 7px 0px #00000080;
  cursor: ${props => (props.isActive ? 'pointer' : 'not-allowed')};
`

export const ModalSaveButton3 = styled.button`
  border-radius: 7px;
  border: none;
  padding: 3px 15px;
  background-color: #29732e;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  margin-top: ${props => (props.marginTop ? props.marginTop : '3rem')};
  box-shadow: 1px 1px 7px 0px #00000080;
`

// ****** TAGS *******
export const TagMark = styled.span`
  display: inline-flex;
  padding: 5px 6px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  border-radius: 7px;
  font-size: ${props => (props.size ? props.size : '13px')};
  color: #f3f3f3;
  background-color: ${props =>
    props.background ? props.background : '#007bff'};
  margin-top: ${props => props.mtop || '0'};
`

// ***************CARS***************

export const Card = styled.div`
  margin: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : '100%')};
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: ${props => `1px solid ${props.cor || 'rgba(0, 0, 0, 0.125)'}`};
  border-radius: 0.4rem;
  box-shadow: ${props => `1px 1px 7px 0px ${props.cor}5e`};
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin-bottom: 0;
  background-color: ${props =>
    props.background ? props.background : 'rgba(0,0,0,.03)'};
  font-size: 15px;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
  min-height: ${props => (props.minHeight ? props.minHeight : null)};
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;

  svg.tag-info {
    position: absolute;
    cursor: pointer;
    right: 6px;
    bottom: 3px;
    color: #386796;
  }

  .company-words-used {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    background: #fff;
    padding: 15px;
    border-radius: 7px;
    box-shadow: 1px 1px 9px #000000c7;
    z-index: 5;
    transform: translate(100%);
    bottom: 11px;
    border-bottom-left-radius: 0;
    animation: 500ms fadeIn ease-in-out forwards;
  }

  @media (max-width: 1500px) {
    font-size: 12.5px;
    padding: 0.25rem;
  }
`

export const CardBody = styled.div`
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  display: flex;
  height: ${props => (props.height ? props.height : 'auto')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'center'};
  flex: 1 1 auto;
  padding: 0.7rem;
  border-bottom: ${props => props.linha && ' 1px solid rgba(0,0,0,.125);'};

  @media (max-width: 1500px) {
    padding: 0.2rem;
  }
`

export const CardWarpMsg = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${props => (props.height ? props.height : '100%')};
  width: 100%;
  font-size: 12px;
  flex-wrap: wrap;
`

export const CardMsg = styled.div`
  overflow: ${props => props.overflow || 'hidden'};
  margin: ${props => props.margin || '0'};
  position: relative;
  min-width: 35vh;
  height: ${props => (props.height ? props.height : '100%')};
  display: flex;
  width: ${props => props.width || '100%'};
  min-height: ${props => (props.minHeight ? props.minHeight : '50px')};
  max-height: ${props => props.maxHeight || 'unset'};
  padding: ${props => (props.padding ? props.padding : '10px')};
  //align-items: center;
  border-radius: 8px;
  background: white;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'start'};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  box-shadow: ${props => `1px 1px 7px 0px ${props.cor || '#00000073'}`};
  margin-bottom: ${props =>
    props.noMarginBottom && window.innerWidth > 1200 ? '0' : '10px'};
  flex-basis: ${props => props.fBasis || '49.8%'};

  @media (max-width: 1500px) and (min-width: 1200px) {
    margin-left: ${props => (props.mLeft ? '5px !important' : 'unset')};
  }

  @media (max-width: 1290px) {
    margin-top: ${props => (props.mTop ? '10px' : '0')};
  }

  #show-list-filter-buyers {
    display: flex;
    /* width: 50%; */
    justify-content: space-around;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1500px) and (min-width: 1200px) {
      align-items: center;
      flex-direction: column-reverse;
      justify-content: center;
    }
  }
`

export const CardMsgWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${props =>
    props.height && window.innerWidth > 1290 ? props.height : 'unset'};

  @media (max-width: 1290px) {
    display: block;
  }
`

// ***************Switch***************

export const CustomSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#3A566E'
    },

    '&$checked + $track': {
      backgroundColor: '#3A566E',
      opacity: 0.38
    },
    '& + $track': {
      opacity: 0.38,
      backgroundColor: '#000'
    },
    '&$disabled': {
      color: '#f00'
    }
  },
  checked: {},
  track: {}
})(Switch)

export const SingleSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#50a476'
    },

    '&$checked + $track': {
      backgroundColor: '#50a476',
      opacity: 0.38
    },
    '& + $track': {
      opacity: 0.38,
      backgroundColor: '#000'
    }
  },
  checked: {},
  track: {}
})(Switch)

export const DoubleSwitch = withStyles({
  switchBase: {
    color: 'rgb(71,153,16)',
    '&$checked': {
      color: '#3A566E'
    },
    '&$checked + $track': {
      backgroundColor: '#3A566E',
      opacity: 0.38
    },
    '& + $track': {
      opacity: 0.38,
      backgroundColor: 'rgb(71,153,16)'
    }
  },
  checked: {},
  track: {}
})(Switch)

// ***************Licitacao Situacão***************

export const SituacaoLicita = styled.div`
  border-radius: 10px;
  padding: 2px 10px 2px 10px;
  width: 100%;
  min-width: 250px;
  background: ${props => props.background};
  color: white;
  text-align: center;
  font-size: ${props => props.fontSize};
  margin: auto;

  @media (max-width: 1500px) {
    min-width: unset;
  }
`

// ***************Button mobile***************

export const ButtonMobile = styled.div`
  position: absolute;
  right: -15px;
  bottom: -15px;
  z-index: 1;
  border-radius: 20px;
  background: ${props => (props.background ? '#3ebe39' : '#C1C1C1')};
  width: 40px;
  height: 40px;
  padding: 5px;
  color: white;
  box-shadow: 0px 2px 4px 0.1px #00000073;
  text-align: center;
  font-size: 20px;
`

export const BodySwitch = styled.div`
  width: ${props => (props.width ? props.width : 'auto')};
  text-align: center;
  padding: 0px 15px 0px 15px;
  font-size: ${props => (props.size ? props.size : '12px')};

  .portal-name {
    font-size: 13px;

    @media (max-width: 1500px) {
      font-size: 10px;
    }
  }
`
// KEY-FRAMES
// const fadeIn = keyframes({
//   from: {
//     opacity: 0
//   },
//   to: {
//     opacity: 1
//   }
// });

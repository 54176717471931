import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { FileText, Search } from 'react-feather'
import Api from '../../../../../../../Stores/Conexao/conexao'
import maquinadeestadosstore from '../../../../../../../Stores/MaquinaDeEstadosStore'
// import useOnClickOutside from '../../../../../../../UsefulComponents/useClickOutside'
import { PropostaEspecificaContext } from '../../../PropostaEspecifica/context'
import { FileInput } from '../../../PropostaEspecifica/fluxoDeEnvio/dadosPdf/styles'
import {
  DocumentSelectText,
  FileInputLabel
} from '../../../PropostaEspecifica/fluxoDeEnvio/enviarDocumentos/styles'
import { API } from '../../api'

import * as S from './styles'

const ModalCarregar = () => {
  const {
    modalCarregarItens,
    closeModal,
    buscarManualmente,
    exportExcel
  } = useContext(PropostaEspecificaContext)
  const [exportarPlanilha, setExportar] = useState(false)
  const [file, setFile] = useState(null)
  // useOnClickOutside(modalRef, closeModal)

  if (!modalCarregarItens) return null

  const { numero, uasg } = modalCarregarItens

  const handleDownload = async () => {
    const endpoint = `portal/proposta_itens/?fornecedor=${maquinadeestadosstore.getIdEmpresa()}&uasg=${uasg}&numero=${numero}&tipo=planilha`
    try {
      const res = await Api.get(endpoint, { responseType: 'blob' })
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `pregao_${numero}_uasg_${uasg}.xlsx`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (err) {
      console.log(err)
    }
  }

  const handleDocument = async e => {
    let document = e.target.files[0]
    setFile(document)
  }

  const handleSubmit = async () => {
    if (exportarPlanilha) await exportExcel(numero, uasg, file)
    else buscarManualmente({ url: API.buscaManual(numero, uasg) })
    closeModal()
  }

  return ReactDOM.createPortal(
    <S.ModalOverlay>
      <S.Container>
        <S.ModalHeader>
          <FileText />
          carregar itens
          <button className="close-button icon-scale-xs" onClick={closeModal}>
            X
          </button>
        </S.ModalHeader>
        <S.ModalBody>
          <S.Title>
            <h4 style={{ fontSize: '110%', fontWeight: 700 }}>
              Edital: {numero}
            </h4>
            <h4 style={{ fontSize: '80%' }}>UASG: {uasg}</h4>
          </S.Title>
          <S.SelecionarOpcao
            selected={!exportarPlanilha}
            onClick={() => setExportar(false)}
          >
            <S.OptionIcon bgColor="#589ebf" titleBgColor="#336075">
              <div className="option-title">SISTEMA</div>
            </S.OptionIcon>
            <S.ContainerTitle>
              <b>EDITAR ITENS A PARTIR DO SISTEMA</b>
              <span>
                Os itens serão gerados automaticamente através do sistema
              </span>
            </S.ContainerTitle>
            <S.ContainerSelection selected={!exportarPlanilha}>
              <div className="selection" />
            </S.ContainerSelection>
          </S.SelecionarOpcao>
          <S.SelecionarOpcao
            selected={exportarPlanilha}
            onClick={() => setExportar(true)}
          >
            <S.OptionIcon bgColor="#de9944" titleBgColor="#ab2828">
              <div className="option-title">PLANILHA</div>
            </S.OptionIcon>
            <S.ContainerTitle>
              <b>EXPORTAR PLANILHA DE ITENS</b>
              <div className="passo-download">
                <span>
                  <b>Passo 1:</b> Faça o download da planilha de itens.
                </span>
                <button
                  id="download_planilha"
                  className="icon-scale-xs"
                  onClick={handleDownload}
                >
                  DOWNLOAD
                </button>
              </div>
              <div className="passo-download">
                <span>
                  <b>Passo 2:</b> Após preencher, exporte-a abaixo.
                </span>
              </div>

              {/* <span>
                <a onClick={handleDownload}>
                  Faça aqui <Save />
                </a> o download da planilha vazia e após terminar de preencher, exporte-a abaixo.
                
              </span> */}
              <div className="selecionar-arquivo" style={{ marginTop: '5px' }}>
                <FileInputLabel
                  htmlFor="enviar-documento"
                  className="position-relative"
                  data-tip="Tamanho máximo 50mb."
                  data-type="warning"
                  data-effect="solid"
                  data-place="bottom"
                >
                  <div
                    className="position-absolute"
                    style={{ pointerEvents: 'none' }}
                  >
                    <Search />
                  </div>
                  <DocumentSelectText htmlFor="select-document-proposta">
                    {(file && file.name) || 'SELECIONE A PLANILHA'}
                  </DocumentSelectText>
                  <FileInput
                    type="file"
                    id="select-document-proposta"
                    style={{ position: 'absolute', width: '100%', left: 0 }}
                    accept=".xlsx"
                    // accept="image/*"
                    onChange={handleDocument}
                  />
                </FileInputLabel>
                {/* <button className="icon-scale-xs" disabled={!documentFile} onClick={() => handleAnexoDocumento('proposta')}>ANEXAR ARQUIVO</button> */}
              </div>
            </S.ContainerTitle>
            <S.ContainerSelection selected={exportarPlanilha}>
              <div className="selection" />
            </S.ContainerSelection>
          </S.SelecionarOpcao>
        </S.ModalBody>
        <S.ActionButtons>
          <button
            id="action-submit"
            disabled={exportarPlanilha && !file}
            onClick={handleSubmit}
          >
            CARREGAR ITENS
          </button>
          <button id="action-close" onClick={closeModal}>
            FECHAR
          </button>
        </S.ActionButtons>
      </S.Container>
    </S.ModalOverlay>,
    document.querySelector('body')
  )
}

export default ModalCarregar

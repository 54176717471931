import axios from 'axios'
import '../../Debug'

export const Api = axios.create({
  baseURL: global.url,
  contentType: 'application/x-www-form-urlencoded',
})

Api.interceptors.response.use(
  function (res) {
    return res
  },
  function (err) {
    if (err.response.status === 401) {
      // alert('Por favor refaça o login.');
      window.location.pathname = '/'
    }
    return Promise.reject(err)
  }
)

export default Api

export const defaultsHeadersAxios = token => {
  Api.defaults.headers.common['Authorization'] = token
}

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Switch from '@material-ui/core/Switch'
import { CheckCircle, Info } from 'react-feather'

import PainelMensagens from './PainelMensagens'
import { ModalPalavra } from './modalLateral/ModalPalavrasChaves'
import ModalObservacoes from './modalLateral/ModalObservacoes'
import { ModalItensLicitacao } from './ModalIntesLicitacao'
import { ExclamationWarning } from '../../Components/Notifications/ExclamationWarning'
import ModalFiltros from './modalLateral/ModalFiltros'
import ChatPregoeiro from './ChatPregoeiro'
import ModalConfirmacao from '../../Components/NewModal/modalConfirmacao'
import { setLicitacaoId } from '../../Services/licitacao'
import LicitacaoStores from '../../Stores/LicitacaoStores'

import {
  FlagColor,
  TabelaContainer,
  PortalLogo,
  LicitacaoActions,
  GavelContainer
} from './styleMonitoramento'
import {
  ComponentHeader,
  ComponentHeaderOverflow,
  TabButton,
  ContentBody,
  ReactTooltip
} from '../../globalStyles'
import {
  ListaLicitacoes,
  TabContainer,
  MessageList,
  AlertNovasMensagens,
  MessageListWrapper
} from './styles'
import { DoubleSwitch } from '../../Themes/StyleSistema'
import LogoComprasNet from '../../Images/compras_net.png'
import LogoBb from '../../Images/bb_logo.jpeg'

@inject('MonitoramentoStore', 'MenuStores', 'MaquinaDeEstadosStore')
@observer
class Monitoramento extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      selectLicitacao: 0,
      status: false,
      portals: []
    }
  }

  componentDidMount() {
    const { consulta_api, flagConsulta } = this.props.MonitoramentoStore
    const { setPortaisContratados } = this.props.MaquinaDeEstadosStore
    this.portals = setPortaisContratados()
    flagConsulta && consulta_api()
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  handleStatus = () => {
    this.setState({ status: !this.state.status })
  }

  statusCor = item => {
    if (item.numero_msg_nao_verificadas > 0 && item.contem_palavra_chave === 0)
      return '#41d641'
    if (
      item.numero_msg_nao_verificadas > 0 &&
      item.contem_palavra_chave === 999
    )
      return '#de3636'
    if (
      item.numero_msg_nao_verificadas === 0 &&
      item.contem_palavra_chave === 0
    )
      return '#bfbfbf'
  }

  handleSort = () => {
    const { radio_licita, licitacoes } = this.props.MonitoramentoStore
    switch (radio_licita) {
      case 'ocorrencias':
        return licitacoes
      case 'novas':
        return licitacoes.filter(
          lic => lic.numero_msg_nao_verificadas && !lic.contem_palavra_chave
        )
      default:
        return licitacoes
    }
  }

  handleSelectLicitacao = id => {
    this.props.MonitoramentoStore.defaultChat()
    if (this.props.MonitoramentoStore.status) {
      this.props.MonitoramentoStore.selectLicitacao = id
      this.props.MonitoramentoStore.consulta_especifica(id)
    }
  }

  handleListaEditais = () => {
    const {
      selectLicitacao,
      /* licitacoes, */
      fetchingLicitacoes
      /*       radio_licita */
    } = this.props.MonitoramentoStore

    const handleLogo = portal => {
      switch (portal) {
        case 5:
          return LogoComprasNet
        case 6:
          return LogoBb
        default:
          return
      }
    }

    if (fetchingLicitacoes)
      return (
        <div
          style={{
            display: 'flex',
            height: '80vh',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )

    return this.handleSort().map((item, i) => {
      if (this.portals && !this.portals.monitoramento.includes(item.portal))
        return null
      return (
        <TabelaContainer
          id={`monitoramento_licitacao_${i}`}
          key={item.id}
          color={this.statusCor(item)}
          background={selectLicitacao === item.id && 'rgba(224,224,224,0.31)'}
          onClick={() => this.handleSelectLicitacao(item.id)}
        >
          {item.citou_empresa ? (
            <FlagColor
              background={this.statusCor(item)}
              blink={item.citou_empresa}
              data-tip="EMPRESA MENCIONADA"
              data-type="warning"
              data-effect="solid"
              data-place="right"
            />
          ) : (
            <FlagColor background={this.statusCor(item)} />
          )}

          <div style={{ width: '75px' }}>{item.codigo}</div>
          <div style={{ width: '20%' }}>{item.uasg}</div>
          <div style={{ width: '50%' }}> {item.data_ultima_msg} </div>
          <div style={{ width: '10%' }}>
            <PortalLogo
              src={handleLogo(item.portal)}
              alt="portal-logo"
              data-place="right"
              data-type="success"
              data-tip={item.portal === 5 ? 'ComprasNet' : 'Licitações-e'}
              data-effect="solid"
            />
          </div>
        </TabelaContainer>
      )
    })
  }

  handleOrdenacao = param => {
    const {
      sortBy,
      sortByParam,
      reverseLicitacoes
    } = this.props.MonitoramentoStore
    if (sortBy === param) return reverseLicitacoes()
    return sortByParam(param)
  }

  handleRedirect = id => {
    setLicitacaoId(id)
    LicitacaoStores.licitacao_vista(id)
    LicitacaoStores.pagina_atual = '1'
    this.props.history.push('/licitacao')
  }

  render() {
    const {
      onChangeFiltro,
      msgComOcorrencia,
      todasMsg,
      selectLicitacao,
      changeObservacao,
      observacaoOnchange,
      salvarObservacoes,
      tags_especifica,
      changePalavrasEspecificas,
      toggleModalCnpj,
      modalCnpj,
      ordemDecrescente,
      marcarTodasMensagens,
      sortBy
    } = this.props.MonitoramentoStore

    const {
      licitacoes,
      qtdNovasMensagens,
      atualizaAtual,
      /* marcarTodasMensagens, */
      muda_estado,
      radio_licita,
      marcar_msgs_como_verificadas,
      mensagens: messages
    } = this.props.MonitoramentoStore
    const {
      licitacao,
      palavras
      /* onChangeMsgChat,
      msgChat */
    } = this.props.MonitoramentoStore
    // const monitoramento = this.props.MonitoramentoStore;

    // Verificr se todas as mensagens foram marcadas como lida
    const licitacaoSelecionada = licitacoes.find(
      li => li.id === selectLicitacao
    )
    const todasLicitacoesVerificadas = licitacoes.every(
      li => li.numero_msg_nao_verificadas === 0
    )
    const todasVerificadas = licitacaoSelecionada
      ? licitacaoSelecionada.numero_msg_nao_verificadas === 0
      : false
    // const ultimaMsg = messages.length && messages[0];
    // const {nomeEmpresa, nomeEmpresaNovo} = this.props.MenuStores;

    const {
      licitacao_codigo,
      observacao,
      itens,
      comprador_nome,
      modalidade,
      estado,
      portal_nome,
      licitacao: id
    } = licitacao

    return (
      <>
        <ReactTooltip />
        <ComponentHeader
          height="8vh"
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 0
          }}
        >
          <TabContainer>
            <TabButton
              onClick={muda_estado}
              ul={radio_licita === 'todas'}
              value="todas"
              smallFont
            >
              TODAS
            </TabButton>
            <TabButton
              onClick={muda_estado}
              value="novas"
              ul={radio_licita === 'novas'}
              smallFont
            >
              NOVAS
            </TabButton>
            <TabButton
              onClick={muda_estado}
              value="ocorrencias"
              ul={radio_licita === 'ocorrencias'}
              smallFont
            >
              COM OCORRÊNCIA
            </TabButton>
            <ModalConfirmacao
              title="VERIFICAR TODAS AS LICITAÇÕES"
              message="Tem certeza que deseja verificar todas as mensagens de TODAS AS LICITAÇÕES?"
              confirmAction={marcarTodasMensagens}
              disabled={todasLicitacoesVerificadas}
              button={
                <CheckCircle
                  color={todasLicitacoesVerificadas ? '#e0e0e0' : '#71bd68'}
                  className="icon-scale"
                  data-tip={
                    todasLicitacoesVerificadas
                      ? 'TODAS MENSAGENS JÁ VERIFICADAS'
                      : 'MARCAR TODAS AS MENSAGENS COMO LIDAS'
                  }
                  data-place="right"
                  data-effect="solid"
                  data-type={todasLicitacoesVerificadas ? 'light' : 'success'}
                />
              }
            />
          </TabContainer>
          <GavelContainer>
            <FontAwesomeIcon icon="gavel" id="gavel-monitoramento" />

            <b
              data-tip
              data-for="registerTip"
              id="gavel-title"
              onClick={() => this.handleRedirect(id)}
              className="icon-scale-xs"
            >
              {`LICITAÇÃO - ${licitacao_codigo || ''}`}
            </b>
            <ReactTooltip
              type="warning"
              id="registerTip"
              place="bottom"
              effect="solid"
            >
              IR PARA LICITAÇÃO
            </ReactTooltip>
            <Info
              style={{ width: '18px', marginLeft: '12px', cursor: 'pointer' }}
              className="info-licitacao icon-scale-xs"
              data-tip
              data-for="info-licitacoes"
            />
            <ReactTooltip
              id="info-licitacoes"
              type="success"
              place="right"
              effect="solid"
            >
              <div>{`PORTAL: ${portal_nome || ''}`}</div>
              <div>{`COMPRADOR: ${comprador_nome || ''}`}</div>
              <div>{`MODALIDADE: ${modalidade || ''} - UF: ${estado ||
                ''}`}</div>
            </ReactTooltip>
          </GavelContainer>
        </ComponentHeader>
        <ComponentHeaderOverflow height="8vh" />
        <ContentBody
          bRadius
          style={{ height: '83.2vh', marginBottom: 0 }}
          padding="0.5rem 1rem 0.5rem"
        >
          <ListaLicitacoes>
            <div className="lista-header">
              <div style={{ width: '75px' }}>
                <b>
                  NÚMERO
                  <FontAwesomeIcon
                    icon={
                      ordemDecrescente && sortBy === 'edital'
                        ? 'chevron-up'
                        : 'chevron-down'
                    }
                    onClick={() => this.handleOrdenacao('edital')}
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                  />
                </b>
              </div>
              <div style={{ width: '20%' }}>
                <b>
                  UASG
                  <FontAwesomeIcon
                    icon={
                      ordemDecrescente && sortBy === 'uasg'
                        ? 'chevron-up'
                        : 'chevron-down'
                    }
                    onClick={() => this.handleOrdenacao('uasg')}
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                  />
                </b>
              </div>
              <div style={{ width: '35%' }}>
                <b style={{ display: 'flex', alignItems: 'center' }}>
                  DATA{' '}
                  <FontAwesomeIcon
                    icon={
                      ordemDecrescente && sortBy === 'data_ultima_msg'
                        ? 'chevron-up'
                        : 'chevron-down'
                    }
                    onClick={() => this.handleOrdenacao('data_ultima_msg')}
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                  />
                </b>
              </div>
              <ModalFiltros />
            </div>
            <div
              className="custom-scroll-blue"
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '0.8rem',
                overflow: 'auto'
              }}
            >
              {this.handleListaEditais()}
            </div>
          </ListaLicitacoes>

          {/* PAINEL LATERAL  */}
          <MessageList>
            {licitacoes.length ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexDirection: 'column',
                  position: 'relative'
                }}
              >
                <LicitacaoActions>
                  {' '}
                  {/* HEADER  */}
                  <div
                    style={{
                      display: 'flex',
                      fontSize: '1.5rem',
                      color: '#3A566E',
                      justifyContent: 'space-between',
                      height: '100%',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <div id="icon-action">
                      <FontAwesomeIcon
                        className={`icon-scale ${
                          todasVerificadas ? '' : 'blink-envelope'
                        }`}
                        icon={
                          todasVerificadas ? 'envelope-open-text' : 'envelope'
                        }
                        style={{
                          color: todasVerificadas ? '#a5ada5' : '#0dd00d',
                          marginTop: '5px'
                        }}
                        onClick={
                          todasVerificadas
                            ? () => {}
                            : () => marcar_msgs_como_verificadas(id)
                        }
                        data-tip={
                          todasVerificadas
                            ? 'JÁ VERIFICADAS'
                            : 'MARCAR MENSAGENS COMO VERIFICADAS'
                        }
                        data-type={todasVerificadas ? 'light' : 'success'}
                        data-place="left"
                        data-effect="solid"
                      />
                      <ModalPalavra
                        tag={palavras}
                        state={{
                          tags_especifica,
                          changePalavrasEspecificas,
                          saveEspecificas: () => salvarObservacoes(id),
                          modalCnpj,
                          toggleModalCnpj
                        }}
                      />
                      <ModalObservacoes
                        observacao={observacao}
                        state={{
                          changeObservacao,
                          observacaoOnchange,
                          id,
                          salvarObservacoes
                        }}
                      />
                      <ModalItensLicitacao itens={itens} />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      <DoubleSwitch
                        checked={todasMsg}
                        onChange={e => onChangeFiltro(e)}
                        value="todas"
                      />
                      <span
                        className="text-header"
                        style={{ color: '#808080ba', marginTop: '-6px' }}
                      >
                        {`${todasMsg ? 'NOVAS' : 'TODAS'} MENSAGENS`}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      <Switch
                        checked={msgComOcorrencia}
                        onChange={e => onChangeFiltro(e)}
                        value="com_ocorrencia"
                        color="primary"
                      />
                      <span
                        className="text-header"
                        style={{
                          color: '#808080ba',
                          marginTop: '-6px'
                        }}
                      >
                        COM OCORRÊNCIAS
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#3A566E',
                        fontSize: '1.1rem',
                        textAlign: 'center'
                      }}
                    >
                      <span
                        style={{
                          fontWeight: '700',
                          fontSize: '1.3rem'
                        }}
                      >
                        {licitacao.msg_total}
                      </span>
                      <span className="text-header" style={{ opacity: '0.7' }}>
                        MENSAGENS
                      </span>
                    </div>
                  </div>
                </LicitacaoActions>

                {/* CONTENT BODY */}
                <MessageListWrapper>
                  {qtdNovasMensagens > 0 && (
                    <AlertNovasMensagens
                      onClick={atualizaAtual}
                      className="icon-scale-xs"
                    >{`${qtdNovasMensagens} novas mensagens.`}</AlertNovasMensagens>
                  )}
                  <PainelMensagens history={this.props.history} />
                </MessageListWrapper>
              </div>
            ) : (
              <ExclamationWarning
                title="Nenhuma licitação encontrada"
                width="100%"
              />
            )}
          </MessageList>

          <ChatPregoeiro />
        </ContentBody>
      </>
    )
  }
}

export default Monitoramento

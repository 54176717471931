import React from 'react'
import { inject, observer } from 'mobx-react'
import { Container, Body } from '../../../Components/Modules/styles'
import { Search } from 'react-feather'

import Editais from './Notice'
import SectionTitle from '../SubHeader/SectionTitle'
import SubHeader from '../SubHeader/SubHeader'
import Spinner from '../../../UsefulComponents/Spinner'
import FiltroBuscaManual from '../Filter/filtroBuscaManual'
import BotaoFiltrar from '../Filter/botaoFiltro'
import ModalQualificarManual from '../StatusNotice/modals/modalQualificarManual'
import ModalConfirmation from '../StatusNotice/modals/modalConfirmation'

import * as S from './Style'

const ManualSearch = inject(
  'BuscaManualStore',
  'MaquinaDeEstadosStore'
)(
  observer(({ BuscaManualStore, MaquinaDeEstadosStore }) => {
    const { isFetching } = BuscaManualStore

    return (
      <Container>
        <SubHeader overflow>
          <SectionTitle title="BUSCA MANUAL" icon={<Search />} />
          <BotaoFiltrar />
        </SubHeader>
        <Body>
          <S.BodyContainer>
            <FiltroBuscaManual />
            {isFetching ? <Spinner /> : <Editais />}
          </S.BodyContainer>
        </Body>
        <ModalQualificarManual />
        <ModalConfirmation />
      </Container>
    )
  })
)

export default ManualSearch
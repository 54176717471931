import { action, observable } from 'mobx'
import Api from './Conexao/conexao'
// import MenuStores from "./MenuStores";
import { AlertaError, AlertaSuccess } from './Funcoes/FuncoesGerais'
import ModalLoadingStore from './ModalLoadingStore'
import { setMonitoramento } from '../Services/notificacao'
import MaquinaDeEstadosStore from './MaquinaDeEstadosStore'
import { playSom } from '../som/SomSistema'
import { playChain } from '../som/AlertasSistema'

class ConfiguracoesMonitoramentoStores {
  @observable houveAlteracao = false
  @observable
  configuracao_empresas = []
  @observable
  monitoramento = []
  @observable
  emails = []
  @observable
  empresa = []
  @observable
  situacao = []
  @observable
  especifica = []
  @observable
  mensagem = []
  @observable
  palavra_importante = []
  @observable
  aviso = []
  @observable
  statusAviso = false

  @observable montagemPortal = {}
  @observable portal = []

  @observable
  modalCnpj = false

  @observable
  tags_notificao_email = []
  @observable
  tag_notificao_email = ''
  @observable
  tags_importante = []
  @observable
  tag_importante = ''
  @observable
  tags_comuns = []
  @observable
  tag_comuns = ''

  @observable
  clearVariaveis = false

  @observable infoShowing = false

  @action toggleInfoShowing = () => (this.infoShowing = !this.infoShowing)

  @action changePortal = portal => {
    this.portal = this.portal.includes(portal)
      ? this.portal.filter(el => el !== portal)
      : [...this.portal, portal]
  }

  @action
  toggleModalCnpj = () => (this.modalCnpj = !this.modalCnpj)

  @action
  handleMonitorar = (nameVariavel, monitoramento) => {
    this[nameVariavel] = {
      ...this[nameVariavel],
      monitoramento: monitoramento
    }
    this.houveAlteracao = true
  }

  @action
  handleNotificacao = (nameVariavel, notificacao) => {
    this[nameVariavel] = { ...this[nameVariavel], notificacao: notificacao }
    this.houveAlteracao = true
  }

  @action
  handleSom = (nameVariavel, som) => {
    if (som === 'VOCAL MASCULINO' || som === 'VOCAL FEMININO')
      this.handlePlayChain(nameVariavel[0], som)
    else playSom(som)

    this[nameVariavel] = { ...this[nameVariavel], som: som }
    this.houveAlteracao = true
  }

  handlePlayChain = (nameVariavel, som) => {
    switch (nameVariavel) {
      case 'empresa':
        playChain(
          ['urgente', 'sua-empresa-foi-citada'],
          som === 'VOCAL FEMININO'
        )
        break
      case 'situacao':
        playChain(
          ['atencao', 'licitacao-com-situacao-atualizada'],
          som === 'VOCAL FEMININO'
        )
        break
      case 'especifica':
        playChain(
          ['urgente', 'encontramos-mensagens-importantes'],
          som === 'VOCAL FEMININO'
        )
        break
      case 'mensagem':
        playChain(
          ['atencao', 'encontramos-novas-mensagens'],
          som === 'VOCAL FEMININO'
        )
        break
      case 'p':
        playChain(
          ['urgente', 'encontramos-mensagens-importantes'],
          som === 'VOCAL FEMININO'
        )
        break
      default:
        return
    }
  }

  @action
  handleMonitoramentoAviso = () => {
    this.statusAviso = !this.statusAviso
    this.aviso = { ...this.aviso, monitoramento: this.statusAviso }
    this.houveAlteracao = true
  }

  readyData() {
    setMonitoramento(this.monitoramento.aviso.monitoramento)
    return (this.monitoramento = {
      ...this.monitoramento,
      empresa: this.empresa,
      situacao: this.situacao,
      especifica: this.especifica,
      mensagem: this.mensagem,
      palavra_importante: this.palavra_importante,
      emails: this.emails,
      aviso: this.aviso,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      portal: { ...this.montagemPortal, portal: this.portal }
    })
  }

  @action
  handleChangeInput_notificao_email = tag => {
    this.tag_notificao_email = tag
  }

  @action
  handleChange_notificao_email = tags => {
    if (tags.length === 0) {
      this.emails = { ...this.emails, emails: '' }
      this.tags_notificao_email = tags
    } else {
      let email = tags[tags.length - 1]
      if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        this.tags_notificao_email = tags
        this.emails = { ...this.emails, emails: tags.toString() }
      } else {
        AlertaError('E-mail não é valido, tente novamente')
      }
    }
    this.houveAlteracao = true
  }

  @action
  handleChange_importante = tags => {
    if (tags.length) {
      if (
        tags[tags.length - 1].match(
          /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/
        )
      )
        return (this.modalCnpj = true)
      this.tags_importante = tags
      this.palavra_importante = {
        ...this.palavra_importante,
        palavra_importante: tags.toString()
      }
    } else {
      this.tags_importante = tags
      this.palavra_importante = {
        ...this.palavra_importante,
        palavra_importante: tags.toString()
      }
    }
    this.houveAlteracao = true
  }

  @action
  handleChangeInput_importante = tag => {
    this.tag_importante = tag
  }

  @action
  salva_api = () => {
    ModalLoadingStore.handleOpenModal()
    Api.post('monitoramento/configuracao_novo/', this.readyData())
      .then(response => {
        AlertaSuccess('Configurações salvas')
        this.houveAlteracao = false
      })
      .catch(error => {
        console.log('err', error)
      })
      .finally(() => ModalLoadingStore.handleCloseModal())
  }

  @action
  handleClear = () => {
    this.clearVariaveis = false
  }

  @action
  limpar = () => {
    const dados = { monitoramento: false, notificacao: false, som: null }
    this.empresa = { ...this.empresa, ...dados }
    this.situacao = { ...this.situacao, ...dados }
    this.especifica = { ...this.especifica, ...dados }
    this.mensagem = { ...this.mensagem, ...dados }
    this.palavra_importante = {
      ...this.palavra_importante,
      palavra_importante: '',
      ...dados
    }
    this.palavra_importante = {
      ...this.palavra_importante,
      palavra_importante: '',
      ...dados
    }
    this.emails = { ...this.emails, emails: [] }
    this.tags_importante = []
    this.tags_comuns = []
    this.tags_notificao_email = []
    this.clearVariaveis = true
  }

  @action
  tratamento_palavras_importante(tags) {
    this.palavra_importante_palavra_importante = tags.toString()
  }

  @action
  tratamento_palavras_comuns(tags) {
    this.palavra_comum_palavra_comum = tags.toString()
  }

  @action
  tratamento_email(tags) {
    this.emails_emails = tags.toString()
  }

  @action
  consulta_api = async () => {
    ModalLoadingStore.handleOpenModal()
    try {
      let response = await Api.get(
        `monitoramento/configuracao_novo/${MaquinaDeEstadosStore.getIdEmpresa()}`,
        {}
      )
      const {
        empresa,
        situacao,
        especifica,
        mensagem,
        palavra_importante,
        emails,
        aviso,
        portal
      } = response.data

      this.monitoramento = response.data
      this.situacao = situacao
      this.especifica = especifica
      this.mensagem = mensagem
      this.palavra_importante = palavra_importante
      this.empresa = empresa
      this.emails = emails
      this.aviso = aviso
      this.statusAviso = aviso.monitoramento
      setMonitoramento(aviso.monitoramento)
      this.portal = portal.portal
      this.montagemPortal = portal

      this.configuracao_empresas = response.data.configuracao_empresas
      this.mensagem_monitoramento = response.data.mensagem
      this.especifica_monitoramento = response.data.especifica
      this.empresa_monitoramento = response.data.empresa
      this.palavra_importante_palavra_importante =
        response.data.palavra_importante
      this.palavra_comum_palavra_importante = response.data.palavra_comum

      const { palavra_importante: palavras } = palavra_importante
      this.tags_importante = palavras ? palavras.split(',') : []
      this.tags_comuns = response.data.palavra_comum.palavra_comum
        ? response.data.palavra_comum.palavra_comum.split(',')
        : []
      this.tags_notificao_email = response.data.emails.emails
        ? response.data.emails.emails.split(',')
        : []
      this.houveAlteracao = false
    } catch (e) {
      console.log('err', e)
    } finally {
      ModalLoadingStore.handleCloseModal()
    }
    this.handleChangeInput_importante('')
    this.handleChangeInput_comuns('')
    this.handleChangeInput_notificao_email('')
  }

  @action
  handleChange_comuns = tags => {
    this.tags_comuns = tags
    this.houveAlteracao = true
  }

  @action
  handleChangeInput_comuns = tag => {
    this.tag_comuns = tag
  }

  @action limparVariaveis = () => {
    this.configuracao_empresas = []
    this.monitoramento = []
    this.emails = []
    this.portal = []
    this.montagemPortal = {}
    this.empresa = []
    this.situacao = []
    this.especifica = []
    this.mensagem = []
    this.palavra_importante = []
    this.aviso = []
    this.statusAviso = false
    this.modalCnpj = false
    this.tags_notificao_email = []
    this.tag_notificao_email = ''
    this.tags_importante = []
    this.tag_importante = ''
    this.tags_comuns = []
    this.tag_comuns = ''
    this.clearVariaveis = false
    this.houveAlteracao = false
  }

  @action trocouDeEmpresa() {
    this.limparVariaveis()
    this.consulta_api()
  }
}

const configuracoesMonitoramentoStores = new ConfiguracoesMonitoramentoStores()
export default configuracoesMonitoramentoStores

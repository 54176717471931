import React, { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { ConfiguracaoEditaisContext } from "../stores/store";

import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from "../../../Components/NewModal";
import { IconFiltro } from "../../../Icons/iconCustom";
import {
  Card,
  CardBody,
  CardHeader,
  ModalSaveButton3
} from "../../../globalStyles";
import { FormControl } from "react-bootstrap";

function ModalSelecaoPortais() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animation, setAnimation] = useState(false);

  return (
    <ModalProvider>
      <div
        onClick={() => setIsModalOpen(true)}
        className="icon-scale file"
        data-tip="CONFIGURAR PORTAIS"
        data-type="info"
        data-effect="solid"
        data-place="left"
      >
        <IconFiltro color="#3A566E" />
      </div>

      {isModalOpen && (
        <ModalLateral
          doobleIcon={
            <div style={{ padding: "0 20px" }}>
              <IconFiltro />
            </div>
          }
          title={"CONFIGURAR PORTAIS"}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          {/* <ContextModalFiltros onClose={() => setIsModalOpen(false)} /> */}
          <Portais
            toggleModal={() => closeModal(setAnimation, setIsModalOpen)}
          />
        </ModalLateral>
      )}
    </ModalProvider>
  );
}

const Portais = observer(props => {
  const { toggleModal } = props;
  const { portal, changePortal, todosPortais } = useContext(
    ConfiguracaoEditaisContext
  );

  const handleSubmit = () => {
    toggleModal();
  };

  return (
    <div style={containerStyle}>
      <Card>
        <CardHeader style={{ flexDirection: "column" }}>
          <div>PORTAIS</div>
          <div
            style={{
              fontSize: "12px",
              color: "#696969",
              fontWeight: "normal"
            }}
          ></div>
        </CardHeader>
        <div
          className="d-flex flex-column"
          style={{
            alignItems: "flex-start",
            margin: "0.5rem 0.5rem 0",
            fontSize: "13px"
          }}
        >
          Selecione os portais em que deseja automatizar a busca de editais
          <small>
            Utilize <i>ALT</i>, <i>CTRL</i> e <i>SHIFT</i> para facilitar a seleção.
          </small>
        </div>
        <CardBody linha={true} height="250px">
          <FormControl
            defaultValue={portal}
            onChange={changePortal}
            name="portais"
            id="portais-select"
            componentClass="select"
            placeholder="select"
            multiple
          >
            {todosPortais.map(port => (
              <option value={port.id} selected={portal.includes(port.id)}>
                {port.nome}
              </option>
            ))}
          </FormControl>
        </CardBody>
      </Card>
      <ModalSaveButton3
        marginTop="auto"
        className="icon-scale-xs"
        onClick={handleSubmit}
      >
        APLICAR
      </ModalSaveButton3>
    </div>
  );
});

export default ModalSelecaoPortais;

const containerStyle = {
  height: "100%",
  display: "flex",
  flexDirection: "column"
};

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { XCircle } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { ReactTooltip } from '../../../../../globalStyles'
import {
  ToastError,
  ToastSuccess
} from '../../../../../Stores/Funcoes/FuncoesGerais'
import { PropostaEspecificaContext } from './context'
import { FluxoDeEnvioContext } from './fluxoDeEnvio/context'
import { DadosPdfContext } from './fluxoDeEnvio/dadosPdf/context'
import { DocumentosContext } from './fluxoDeEnvio/enviarDocumentos/context'
import BotaoEnviar from './fluxoDeEnvio/enviarProposta/BotaoEnviar'
import { BotoesContainer } from './styles'

const BotoesNavegacao = () => {
  const [loading, setLoading] = useState(false)
  const { propostaEnviada, rows, setError } = useContext(
    PropostaEspecificaContext
  )
  const { saveData, steps, setActive, setFinished } = useContext(
    FluxoDeEnvioContext
  )
  const { saveData: saveDataPdf, pdfFile } = useContext(DadosPdfContext)
  const { docsProposta, docsHabilitacao, cleanData } = useContext(
    DocumentosContext
  )

  const history = useHistory()

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const index = steps.findIndex(stp => stp.active)
  const envioCompleto =
    propostaEnviada &&
    docsProposta.concat(docsHabilitacao).every(doc => doc.enviado)

  const handleRedirect = useCallback(path => history.push(path))

  const handleNextStep = async () => {
    setLoading(true)
    switch (index) {
      case 0:
        if (verificarItens(rows, setError)) {
          setLoading(false)
          return
        }
        // if (!rows.some(r => r.checked)) {

        //   return ToastError('Selecione ao menos um item para continuar.')
        // }
        await saveData()
        break
      case 1:
        await saveDataPdf()
        break
      case 2:
        if (!docsProposta.length && !pdfFile) {
          setLoading(false)
          return ToastError('É necessário ao menos um documento de proposta.')
        }
        break
      // case 3:
      //   enviar();
      //   break;
    }
    setLoading(false)

    setFinished(steps[index].name)
    setActive(steps[index + 1].name)
  }

  const handlePreviousStep = () => {
    setActive(steps[index - 1].name)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await saveData()
      ToastSuccess('Dados salvos')
    } catch (err) {
      ToastError('Erro ao salvar')
    } finally {
      setLoading(false)
    }
  }

  if (envioCompleto)
    return (
      <BotoesContainer>
        <button
          id="action-save"
          onClick={() => {
            handleRedirect('/proposta/gerenciar')
            cleanData()
          }}
        >
          GERENCIAR PROPOSTAS
        </button>
        <button id="action-submit" onClick={cleanData}>
          CADASTRAR NOVA PROPOSTA
        </button>
      </BotoesContainer>
    )

  return (
    <BotoesContainer>
      <ReactTooltip effect="solid" />
      {index ? (
        index < 4 ? (
          <button id="action-previous" onClick={handlePreviousStep}>
            ANTERIOR
          </button>
        ) : null
      ) : (
        <button id="action-save" onClick={handleSave} disabled={loading}>
          SALVAR
        </button>
      )}
      {index < 3 && (
        <button id="action-submit" onClick={handleNextStep} disabled={loading}>
          PRÓXIMO
        </button>
      )}
      {index === 3 && <BotaoEnviar handleNextStep={handleNextStep} />}
      <XCircle
        className="close-s icon-scale-xs"
        onClick={() => {
          cleanData()
          ReactTooltip.hide()
        }}
        data-tip="FECHAR"
        data-place="left"
        data-type="error"
        data-effect="solid"
      />
    </BotoesContainer>
  )
}

export default BotoesNavegacao

const verificarItens = (rows, setError) => {
  const itens = rows.filter(row => row.checked)

  const grupos = itens.filter(item => item.grupo).map(it => it.grupo)
  const gruposSet = [...new Set(grupos)]

  for (let i = 0; i < gruposSet.length; i++) {
    if (
      grupos.filter(g => g === gruposSet[i]).length !==
      rows.filter(row => row.grupo === gruposSet[i]).length
    )
      return ToastError('Preencha todos os itens do ' + gruposSet[i])
  }

  if (!itens.length) return ToastError('Selecione ao menos um item')
  let it_item
  if (
    itens.some(it => {
      if (!it.qtd_ofertada) return false
      const result = Number(it.qtd_ofertada) > Number(it.qtd_estimada)
      if (result) it_item = it.item
      setError(it_item)
      return result
    })
  )
    return ToastError(
      `Erro item ${it_item}: Quantidade ofertada não pode ser maior que estimada`
    )
  if (
    itens.some(it => {
      // console.log(it.desconto.replace(',', '.').slice(it.desconto.includes('%') ? -1 : 0));
      const result = it.desconto
        ? Number(
            it.desconto
              .replace(',', '.')
              .slice(0, it.desconto.includes('%') ? -1 : it.desconto.length)
          ) === 0
        : false
      if (result) it_item = it.item
      setError(it_item)
      return result
    })
  )
    return ToastError(`Erro item ${it_item}: Desconto não pode ser 0`)

  return false
}

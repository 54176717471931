import React, {useContext} from 'react'
import { ThumbsUp } from 'react-feather'
import BadegRedondo from '../../../Components/ReusableComponents/Badeg/badegRedondo'
import { ModalContext } from './context/modal'
import { useStatusEdital } from './context'
import BuscaManualStore from '../ManualSearch/Store';
import { set } from 'mobx'


const BotaoQualificarManual = ({ edital }) => {
  const { setEdital } = useStatusEdital()
  const { openModalQualificar } = useContext(ModalContext)

  const status = () =>  {
    return {
      id: edital.id,
      msg_status: "'",
      palavras_usadas: "",
      prioridade: "media",
      status: "qualificado",
    }
  }
  
  
  const showModalQualificar = async () => {
    const newEdital = {...edital, selecionado: status(),  status: "qualificado"}
    await setEdital(newEdital)
    openModalQualificar()
  }
  
  
  return (
    <>
      <BadegRedondo
        icon={<ThumbsUp />}
        background={edital.status === 'qualificado' ? '#58c340' : '#b9dbb6'}
        onClick={showModalQualificar}
      />
    </>
  )
}

export default BotaoQualificarManual 

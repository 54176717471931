import React from 'react'

import * as S from './Style'

const Select = props => {
  return (
    <S.Select
      value={props.value}
      onChange={event => props.handleChange(event.target.value)}
    >
      {props.data.map(profile => (
        <S.Option value={profile.nome_perfil} key={profile.id}>
          {`${profile.nome_perfil} (${profile.qtd_edital} ${
            profile.qtd_edital !== 0 ? 'Editais' : 'Edital'
          })`}
        </S.Option>
      ))}
    </S.Select>
  )
}

export default Select

import React, { Component } from 'react'
import DataTable from 'react-data-table-component'
import { inject, observer } from 'mobx-react'

import LicitacaoStores from '../../Stores/LicitacaoStores'
import { columnsLicitacao, rowTheme } from './EstruturaTabela'
import { MenuLicitacoes } from './MenuLicitacoes'
import { setLicitacaoId } from '../../Services/licitacao'
import { ExclamationWarning } from '../../Components/Notifications/ExclamationWarning'
import { ContainerDataTable } from './styleLicitacoes'

// Import styles
import {
  ComponentHeader,
  ComponentHeaderOverflow,
  ContentBodyLicitacoes
} from '../../globalStyles'
import { getPortalId } from '../../Services/portais'

@inject('LicitacoesPaginadaStore', 'MaquinaDeEstadosStore')
@observer
class LicitacoesPaginada extends Component {
  componentDidMount() {
    // this.props.LicitacoesPaginadaStore.consulta_api()
    this.props.LicitacoesPaginadaStore.fecthBids(1)
  }

  redireciona(id) {
    setLicitacaoId(id)
    LicitacaoStores.licitacao_vista(id)
    LicitacaoStores.pagina_atual = '1'
    this.props.history.push('/licitacao')
  }

  handleChange = ({ target: { value } }) => {
    this.props.LicitacoesPaginadaStore.FilterSituacao(value)
  }

  sortByDate = array =>
    array
      .sort((a, b) => new Date(b.data_disputa) - new Date(a.data_disputa))
      .reverse()

  licitacoes = () => {
    const { portal, licitacoesFilter } = this.props.LicitacoesPaginadaStore
    return licitacoesFilter.filter(lic =>
      portal.includes(getPortalId(lic.portal))
    )
  }
  
  handlePageChange = (page) => {
    this.props.LicitacoesPaginadaStore.fecthBids(page)
  }

  render() {
    const {
      Search,
      tipo_licitacao,
      fim_progresso_carregano_lic,
      licitacaoMonitorada
    } = this.props.LicitacoesPaginadaStore
    return (
      <>
        <ComponentHeader
          style={{ justifyContent: 'space-between' }}
          id="pagina-licitacoes"
        >
          <MenuLicitacoes
            licitacaoMonitorada={licitacaoMonitorada}
            tipo_licitacao={tipo_licitacao}
            handleChange={this.handleChange}
            licitacoesFilter={this.licitacoes()}
            Search={Search}
            fim_progresso_carregano_lic={fim_progresso_carregano_lic}
          />
        </ComponentHeader>
        <ComponentHeaderOverflow />
        <ContentBodyLicitacoes height="78vh">
          <div style={{ display: 'flex', overflowY: 'hidden' }}>
            <ContainerDataTable>
              <DataTable
                fixedHeader
                className="tabelalo"
                fixedHeaderScrollHeight="92vh"
                subHeader
                allowOverflow={true}
                overflowY={true}
                striped
                id="tabela-principal-licitacoes"
                pointerOnHover
                onRowClicked={e => this.redireciona(e.id)}
                columns={columnsLicitacao}
                highlightOnHover
                customTheme={rowTheme}
                data={this.licitacoes()}
                pagination
                paginationServer
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50]}
                paginationComponentOptions={{
                  rowsPerPageText: 'Licitações por pagina:',
                  rangeSeparatorText: 'até'
                }}
                onChangePage={this.handlePageChange}
                paginationTotalRows={this.props.LicitacoesPaginadaStore.licitacoes_total}
                noDataComponent={
                  <ExclamationWarning
                    title="Nenhuma licitação encontrada"
                    height="70vh"
                  />
                }
              />
            </ContainerDataTable>
          </div>
        </ContentBodyLicitacoes>
      </>
    )
  }
}

export default LicitacoesPaginada

import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import MenuStores from "./MenuStores";

class PainelAvisoStores {
  @observable
  isActive = false;
  @observable
  licitacoes = [];
  @observable
  idEmpresa = undefined;
  @observable
  isLoading = true;
  @observable
  som = false;

  @action
  handleSom = () => {
    this.som = !this.som;
  };

  @action
  consulta_api_toda_licitacoes = () => {
    this.idEmpresa = "todas";
    this.isLoading = false;
    ModalLoadingStore.handleOpenModal();

    Api.get("licitacoes/licitacoes_dia_novo/", {})
      .then(response => {
        this.licitacoes = response.data.licitacoes;
      })
      .catch(error => {
        this.licitacoes = [];
        console.log("err", error);
      })
      .finally(() => {
        ModalLoadingStore.handleCloseModal();
        this.isLoading = true;
      });
  };

  @action
  somSistema = () => {
    if (this.som && MenuStores.fullScreen) {
      document.getElementById("avisos").play();
    }
  };

  @action
  consulta_api = id => {
    // this.somSistema();
    
    console.log(this.idEmpresa)
    
    if(!id)
    id = this.idEmpresa ? this.idEmpresa : MaquinaDeEstadosStore.getIdEmpresa()
    else
    this.idEmpresa = id;

    if (id === "todas") return this.consulta_api_toda_licitacoes();

    this.isLoading = false;
    ModalLoadingStore.handleOpenModal();
    Api.post("licitacoes/licitacoes_dia_empresa/", { fornecedor_id: id })
      .then(response => {
        this.licitacoes = response.data.licitacoes;
      })
      .catch(error => {
        this.licitacoes = [];
        console.log("err", error);
      })
      .finally(() => {
        ModalLoadingStore.handleCloseModal();
        this.isLoading = true;
      });
  };

  @action limparVariaveis = () => {
    this.isActive = false;
    this.licitacoes = [];
    // this.idEmpresa = undefined;
    this.isLoading = true;
    this.som = false;
  };

  @action trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.consulta_api();
    // this.consulta_api(MaquinaDeEstadosStore.getIdEmpresa());
  };
}

const painelAvisoStores = new PainelAvisoStores();
export default painelAvisoStores;

import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Key } from 'react-feather'

import Api from '../../Stores/Conexao/conexao'
import maquinadeestadosstore from '../../Stores/MaquinaDeEstadosStore'
import storeConfiguracaoCredenciais from '../../Stores/StoreConfiguracaoCredenciais'
import SpinnerMenor from '../SpinnerMenor'
import Checkmark from '../Checkmark'
import { getPortalName } from '../../Services/portais'
import { CustomInput } from '../../Pages/Proposal/ImprimirProposta/contexto/stylesInputs'
import { ToastError } from '../../Stores/Funcoes/FuncoesGerais'

import * as S from './styles'

const ModalEditarCredencial = ({ closeModal, credencial }) => {
  const { portal_id } = credencial

  const [credencialAlterada, setCredencialAlterada] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [login, setLogin] = useState(credencial.login)
  const [password, setPassword] = useState('')
  const [repeat, setRepeat] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    const data = {
      portal: portal_id,
      fornecedor: maquinadeestadosstore.getIdEmpresa(),
      login,
      password,
      sync: true
    }
    setIsSubmitting(true)
    try {
      const res = await Api.post('/credencial/', data)
      setCredencialAlterada(true)
      setTimeout(() => {
        storeConfiguracaoCredenciais.editarNoStore({
          ...res.data,
          expired: false
        })
      }, 7000)
    } catch (err) {
      console.log('err', err)
      if (err.response.data.error) ToastError(err.response.data.error)
      else ToastError('Erro ao editar credencial, tente mais tarde')
    } finally {
      setIsSubmitting(false)
    }
  }

  return ReactDOM.createPortal(
    <S.Overflow>
      <S.Container>
        <div className="modal-header">
          <div className="title">
            <Key />
            <span>CREDENCIAL</span>
          </div>
          <button className="close-button icon-scale-xs" onClick={closeModal}>
            X
          </button>
        </div>
        <div className="modal-body">
          <div className="section-header">
            <div className="title">
              <div className="main-title">EDITAR CREDENCIAL</div>
              <div className="sub-title">
                Insira abaixo os dados cadastrais utilizados no portal{' '}
                <b>{getPortalName(portal_id)}</b>
              </div>
            </div>
          </div>
          <div className="section-inputs">
            <form onSubmit={handleSubmit}>
              <CustomInput mRight width="100%" validationValues>
                <span>Login</span>
                {portal_id === 6 && (
                  <div
                    style={{
                      position: 'absolute',
                      left: '-10px',
                      top: '12%',
                      fontWeight: '600'
                    }}
                  >
                    J
                  </div>
                )}
                <input
                  id="cred-login"
                  name="cred-login"
                  type="text"
                  placeholder=""
                  value={login}
                  // disabled={isSubmitting}
                  onChange={e => setLogin(e.target.value)}
                />
              </CustomInput>
              <CustomInput mRight width="100%" validationValues>
                <span>Senha</span>
                <input
                  id="cred-password"
                  name="cred-password"
                  type="password"
                  disabled={isSubmitting}
                  placeholder=""
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </CustomInput>
              <CustomInput
                mRight
                width="100%"
                validationValues={password === repeat}
              >
                <span>Repetir senha</span>
                <input
                  id="cred-repeat"
                  name="cred-repeat"
                  type="password"
                  disabled={isSubmitting}
                  placeholder=""
                  value={repeat}
                  onChange={e => setRepeat(e.target.value)}
                />
              </CustomInput>
            </form>
          </div>
          {isSubmitting ? (
            <SpinnerMenor />
          ) : credencialAlterada ? (
            <Sucesso closeModal={closeModal} />
          ) : (
            <div className="botoes-container">
              <button
                id="submit-editar-cred"
                disabled={
                  isSubmitting || password !== repeat || password.length < 3
                }
                onClick={handleSubmit}
              >
                SALVAR
              </button>
              <button id="close-editar-cred" onClick={closeModal}>
                FECHAR
              </button>
            </div>
          )}
        </div>
      </S.Container>
    </S.Overflow>,
    document.querySelector('body')
  )
}

const Sucesso = ({ closeModal }) => (
  <div className="cred-alterada">
    <Checkmark />
    <span className="title">Credencial alterada com sucesso</span>
    <span className="fechar icon-scale-xs" onClick={closeModal}>
      FECHAR
    </span>
  </div>
)

export default ModalEditarCredencial

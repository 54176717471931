import React from 'react'
import { Container } from '../Style'
import CardModalidade from './filtroModalidade'
import CardEstados from './filtroEstados'
import CardPortais from './filtroPortais'
import FiltroTipoData from './filtroTipoData'
import CardData from './filtroData'
import { useFiltroEdital } from './context/FiltroEditaisContext'
import FiltroComprador from './filtroComprador'
import FiltroCodigo from './filtroCodigoPregao'
import CardPalavrasBuscaManual from './filtroPalavraBuscaManual'
import ImportarDePerfil from './ImportarDePerfil'
import FiltroMeEpp from './filtroMeEpp'
import FiltroMaterialServico from './filtroMaterialServico'
import CustomizedTabs from './components/Tab'
import { useEffect } from 'react'
import { Trash2 } from 'react-feather'

const TABS = ['Avançado', 'Básico']

const FiltroBuscaManual = () => {
  const {
    state,
    filtrarManual,
    simpleManualFilter,
    showFiltro,
    openFiltro,
    getInitialProfileData,
    limpar
  } = useFiltroEdital()
  const [tab, setTab] = React.useState(0)
  const refContainer = React.useRef()

  useEffect(() => {
    openFiltro()
    getInitialProfileData()
  }, [])

  const handleSelectedTab = value => setTab(value)
  
  const handleClean = () => limpar()
  
  const props = { handleClean: handleClean }
 
  return (
    <>
      {!state.filtro && (
        <Container ref={refContainer}>
          <div
            style={{
              display: 'flex',
              marginTop: '50px',
              flexWrap: 'wrap',
              justifyContent: 'center',
              paddingBottom: '50px'
            }}
          >
            <div className='width-pattern' style={{margin: '10px' }}>
              <CustomizedTabs
                handleSelectedTab={handleSelectedTab}
                tabOptions={TABS}
                value={tab}
              />
            </div>

            {tab === 0 ? <Licitacoes {...props} /> : <Licitacao {...props} />}

            <div className="action-buttons">
              <button
                id="filtrar-editais"
                className="icon-scale-xs"
                onClick={tab === 0 ? filtrarManual : simpleManualFilter}
              >
                FILTRAR
              </button>
              <button
                id="fechar-modal-filtro-editais"
                className="icon-scale-xs"
                onClick={() => showFiltro()}
              >
                FECHAR
              </button>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

const Licitacoes = ({ handleClean }) => (
  <React.Fragment>
    <div
      className='width-pattern'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px'
      }}
    >
      <ImportarDePerfil />
      <button
        id="limpar-busca-manual"
        className="icon-scale-xs"
        onClick={handleClean}
      >
        <Trash2 /> LIMPAR
      </button>
    </div>
    <CardData />
    <CardEstados />
    <CardPortais />
    <CardModalidade />
    <CardPalavrasBuscaManual />    
    <FiltroTipoData />

    <h3>FILTROS ESPECÍFICOS PARA O PORTAL COMPRASNET: </h3>
    <FiltroMeEpp />
    <FiltroMaterialServico />
  </React.Fragment>
)
const Licitacao = ({ handleClean }) => (
  <React.Fragment>
    <div
      className='width-pattern'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: '10px'
      }}
    >
      <button
        id="limpar-busca-manual"
        className="icon-scale-xs"
        onClick={handleClean}
      >
        <Trash2 /> LIMPAR
      </button>
    </div>
    <FiltroCodigo />
    <FiltroComprador />
  </React.Fragment>
)

export default FiltroBuscaManual

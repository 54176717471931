import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import ReactDOM from 'react-dom';
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from "../../../Components/NewModal";
import { AddAction } from "../Style";
import { UserForm, SaveButton, ModalContainer, RedefinirSenha } from "./styles";
import { FormGroup } from "react-bootstrap";
import MaquinaDeEstadosStore from "../../../Stores/MaquinaDeEstadosStore";
import EmpresaInputConstumizado from "../InputsCustomizados/EmpresaInputConstumizado";
import UsuariosInputConstumizado from "../InputsCustomizados/UsuariosInputConstumizado";
import SelectUsersCustomizado from "../InputsCustomizados/SelectUsersCustomizado";
import TelefoneInputCustomizado from "../InputsCustomizados/TelefoneInputCustomizado";
import CnpjInputCustomizado from "../InputsCustomizados/CnpjInputCustomizado";

function ModalAddCompany({ empresas, state }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animation, setAnimation] = useState(false);
  const div = document.getElementById('container-configuracao-geral');
  return (
    <ModalProvider height="auto">
      {div && ReactDOM.createPortal(
        <AddAction
          className="icon-scale"
          data-tip="ADICIONAR EMPRESA"
          data-place="left"
          data-type="success"
          data-effect="solid"
          onClick={() => setIsModalOpen(true)}
        >
          +
      </AddAction>
        , div)
      }


      {isModalOpen && (
        <ModalLateral
          icon="building"
          title={"ADICIONAR EMPRESA"}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <ModalContainer>
            <CompanyInfo toggleModal={setIsModalOpen} />
          </ModalContainer>
        </ModalLateral>
      )}
    </ModalProvider>
  );
}

const CompanyInfo = inject(
  "StoreConfiguracoesGerais",
  "StoreConfiguracaoEmpresas"
)(
  observer(props => {
    const {
      onChange,
      adminValidation,
      verificaAdmin,
      validarAdmin
    } = props.StoreConfiguracaoEmpresas;

    const { usuarios } = props.StoreConfiguracoesGerais;

    const { toggleModal } = props;

    const handleSubmit = (e, validate) => {
      e.preventDefault();
      if (!validate) return validarAdmin("adicionarEmpresa");
      return verificaAdmin("adicionarEmpresa", toggleModal);
    };

    if (adminValidation)
      return (
        <UserForm onSubmit={e => handleSubmit(e, true)}>
          <h5 style={{ textAlign: "center" }}>
            {MaquinaDeEstadosStore.getEmailUser()}
          </h5>
          <UsuariosInputConstumizado
            titulo={"Digite a senha do administrador"}
            id={"inputAdminPw"}
            placeholder={"senha..."}
            type={"password"}
            onChange={onChange}
          />
          <RedefinirSenha onClick={() => validarAdmin("fecharModal")}>
            Voltar
          </RedefinirSenha>
          <SaveButton type="submit" className="icon-scale-xs">
            SALVAR
          </SaveButton>
        </UserForm>
      );

    return (
      <UserForm onSubmit={handleSubmit}>
        <FormGroup>
          <SelectUsersCustomizado
            id="usuarios_id"
            onChange={onChange}
            defaultValue={[0]}
            users={usuarios}
            titulo="Selecione os usuários autorizados"
          />
          <EmpresaInputConstumizado
            titulo={"Razão social"}
            id={"razao_social"}
            placeholder={"Insira a razão social da empresa.."}
            type={"text"}
            onChange={onChange}
          />

          <EmpresaInputConstumizado
            titulo={"Nome fantasia"}
            id={"nome_fantasia"}
            placeholder="Insira o nome fantasia da empresa.."
            type={"text"}
            onChange={onChange}
          />

          <CnpjInputCustomizado
            titulo="CNPJ"
            id="cnpj"
            placeholder="Insira seu CNPJ"
            onChange={onChange}
          />

          <TelefoneInputCustomizado
            titulo="Telefone"
            id="phone"
            onChange={onChange}
            // defaultValue={phone}
            placeholder="Insira seu telefone"
          />
        </FormGroup>
        <SaveButton type="submit" className="icon-scale-xs">
          SALVAR
        </SaveButton>
      </UserForm>
    );
  })
);

export default ModalAddCompany;

import React, { Component } from 'react'
import DataTable from 'react-data-table-component'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PortalSelect from './PortalSelect'
import { rowTheme, columnsLicitacao } from './EstruturaTabela'
import { Portais } from '../BotoesMenu'
import { checkPathname } from '../../../Stores/LicitacoesExclusao'
import { ExclamationWarning } from '../../../Components/Notifications/ExclamationWarning'

import {
  PortaisSelectContainer,
  NavegacaoContainer,
  SingleNavigationItem,
  ContainerDataTable,
  SizeLicitacoes
} from '../styleLicitacoes'
import {
  ComponentHeader,
  ComponentHeaderOverflow,
  TabButton,
  ReactTooltip,
  ContentBodyLicitacoes
} from '../../../globalStyles'

@inject('LicitacoesExclusao')
@observer
class ExcluirLicitacoes extends Component {
  constructor(props) {
    super(props)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.state = {
      portaisShowing: false
    }
  }

  componentDidMount() {
    this.props.LicitacoesExclusao.consultaExcluidas()
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ portaisShowing: false })
    }
  }

  handlePortals = () => {
    const {
      todosPortais,
      portal,
      listaExcluidas
    } = this.props.LicitacoesExclusao

    return listaExcluidas.filter(lic => {
      const portalId = todosPortais.find(portal => portal.name === lic.portal)
        .id
      return portal.includes(portalId)
    })
  }

  render() {
    // const { listaExcluidas, totalExcluidas } = this.props.LicitacoesExclusao
    const { portaisShowing } = this.state
    return (
      <>
        <ComponentHeader
          height="8vh"
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 0
          }}
        >
          <ReactTooltip />
          <div className="d-flex position-relative align-items-center">
            <TabButton ul={true}>LICITAÇÕES EXCLUÍDAS</TabButton>
            <Portais
              onClick={() => this.setState({ portaisShowing: !portaisShowing })}
            />
            {portaisShowing && (
              <PortaisSelectContainer ref={this.setWrapperRef}>
                <PortalSelect />
              </PortaisSelectContainer>
            )}
          </div>
          <NavegacaoContainer top="10px">
            <SingleNavigationItem active={checkPathname('/agenda')}>
              <Link to="/agenda">
                <FontAwesomeIcon
                  className="icon-scale icon-header"
                  icon="calendar-check"
                  data-tip="AGENDA"
                  data-effect="solid"
                  data-type="warning"
                  data-place="bottom"
                />
              </Link>
            </SingleNavigationItem>
            <SingleNavigationItem active={checkPathname('/funil')}>
              <Link to="/funil">
                <FontAwesomeIcon
                  className="icon-scale icon-header"
                  icon="filter"
                  data-tip="FUNIL"
                  data-effect="solid"
                  data-type="warning"
                  data-place="bottom"
                />
              </Link>
            </SingleNavigationItem>
            <SingleNavigationItem active={checkPathname('/licitacoes')}>
              <Link to="/licitacoes">
                <FontAwesomeIcon
                  className="icon-scale icon-header"
                  icon="list"
                  data-tip="LISTA"
                  data-effect="solid"
                  data-type="warning"
                  data-place="bottom"
                />
              </Link>
            </SingleNavigationItem>
            <SingleNavigationItem active={checkPathname('/licitacoes/excluir')}>
              <Link to="/licitacoes/excluir">
                <FontAwesomeIcon
                  className="icon-scale icon-header"
                  icon="trash"
                  data-tip="LIXEIRA"
                  data-effect="solid"
                  data-type="warning"
                  data-place="bottom"
                />
              </Link>
            </SingleNavigationItem>
          </NavegacaoContainer>

          <SizeLicitacoes>
            <span className="contador-numero">
              {this.handlePortals().length}
            </span>
            <span className="contador-mensagem">Excluídas</span>
          </SizeLicitacoes>
        </ComponentHeader>
        <ComponentHeaderOverflow />
        <ContentBodyLicitacoes height="80vh">
          <div style={{ display: 'flex', overflowY: 'hidden' }}>
            <ContainerDataTable>
              <DataTable
                fixedHeader
                className="tabelalo"
                fixedHeaderScrollHeight="88vh"
                subHeader
                allowOverflow={true}
                overflowY={true}
                striped
                id="tabela-principal-licitacoes"
                pointerOnHover
                // onRowClicked={e => this.redireciona(e.id)}
                columns={columnsLicitacao}
                highlightOnHover
                customTheme={rowTheme}
                data={this.handlePortals()}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 1000]}
                paginationComponentOptions={{
                  rowsPerPageText: 'Licitações por pagina:',
                  rangeSeparatorText: 'até'
                }}
                noDataComponent={
                  <ExclamationWarning
                    title="Nenhuma licitação excluída"
                    height="75vh"
                  />
                }
              />
            </ContainerDataTable>
          </div>
        </ContentBodyLicitacoes>
      </>
    )
  }
}

export default ExcluirLicitacoes

// export const ExcluirLicitacoes = inject("LicitacoesExclusao")(
//   observer(props => {
//     const { listaExcluidas } = props.LicitacoesExclusao;

//     return <div>a</div>;
//   })
// );

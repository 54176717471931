import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom';
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import * as S from './styles';
import help from './helper';
import ImprimirPropostaStore from '../../Pages/Proposal/ImprimirProposta/store';
import { ArrowRightCircle, XCircle } from 'react-feather';

const ImageCropper = ({ image, closeModal }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [cropp, setCropp] = useState({});
  // console.log(image)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
    setCropp(croppedAreaPixels)
  }, [])

  const handleSave = async () => {
    const res = await help(URL.createObjectURL(image), image, cropp)
    console.log(res)
    await ImprimirPropostaStore.handleAssinatura(res);
    closeModal();
  }

  return ReactDOM.createPortal(
    <S.Overflow>
      <S.Container>
        <div className="crop-container">
          <Cropper
            image={URL.createObjectURL(image)}
            crop={crop}
            zoom={zoom}
            aspect={9 / 3}
            restrictPosition={false}
            style={{ mediaStyle: { background: 'red' } }}
            cropSize={{ width: 1000, height: 333 }}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="controls">
          {/* <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              classes={{ container: 'slider' }}
            /> */}
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          // classes={{ container: 'slider' }}
          />
        </div>
        <XCircle
          className="close-cropping icon-scale-xs"
          onClick={closeModal}
        />
        <ArrowRightCircle
          className="done-cropping icon-scale-xs"
          onClick={handleSave}
        />
      </S.Container>
    </S.Overflow>, document.querySelector('body')
  )
}


export default ImageCropper

import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import MonitoramentoStore from "./MonitoramentoStore";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import { AlertaDownload } from "./Funcoes/FuncoesGerais";
import SideStores from "./SideStores";

class OcorrenciasDoDiaStores {
  @observable mensagens = [];
  @observable items = 10;
  @observable loadingState = false;
  @observable status = false;
  @observable total_mensagens_nao_verificadas = null;
  @observable total_mensagens = 0;
  @observable pagina_atual = 1;
  @observable isLoading = true;
  @observable palavras = [];
  @observable flagConsultaApi = false;
  @observable nome_empresa = "";
  @observable idEmpresa = 'todas';
  @observable som = true;
  @observable isFetching = false;
  @observable fetchingMessages = false;
  @observable newMsg = 0;
  @observable intervaloSonoro = undefined;
  @observable saindoDeFullScreen = false;

  @action handleSom = () => {
    this.som = !this.som;
    if (this.som) {
      this.somSistema();
    }
  };

  @action consultaApi = push => {
    const data = new FormData();
    this.isFetching = true;

    if (push && window.location.pathname === '/ocorrenciasdodia') {

      document
        .getElementById("ocorrencias-dia-list")
        .scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.pagina_atual = 1;
    }

    data.set("numpage", this.pagina_atual);

    if (this.idEmpresa !== "todas") {
      if (this.idEmpresa) {
        data.set("fornecedor", this.idEmpresa);
      } else {
        data.set("fornecedor", MaquinaDeEstadosStore.id_fornecedor);
      }

      Api.post("monitoramento/ocorrencias_empresa_alterado/", data, {})
        .then(({ data: res }) => {
          this.mergeArrays(res.mensagens);
          this.newMsg = 0;
          this.newMsg = res.total_mensagens - this.total_mensagens;
          this.total_mensagens = res.total_mensagens;
          this.total_mensagens_nao_verificadas =
            res.total_mensagens_nao_verificadas;
          this.nome_empresa = res.nome_empresa;
          this.status = true;
          this.palavras = res.palavras;
          this.idEmpresa = res.id_empresa;
          // if (push) this.pushNewMessage();
        })
        .catch(err => {
          console.log("err", err);
        })
        .finally(() => {
          this.isFetching = false;
          clearInterval(this.intervaloSonoro);
          this.somSistema();
        });
    } else {
      Api.post("monitoramento/ocorrencias_do_dia/", data, {})
        .then(({ data: res }) => {
          this.mensagens = res.mensagens;
          this.nome_empresa = "";
          this.newMsg = 0;
          this.newMsg = res.total_mensagens - this.total_mensagens;
          this.total_mensagens = res.total_mensagens;
          this.palavras = res.palavras;
          this.total_mensagens_nao_verificadas =
            res.total_mensagens_nao_verificadas;
          this.idEmpresa = "todas";
          // if (push) this.pushNewMessage();
        })
        .catch(err => console.log("err", err))
        .finally(() => {
          this.isFetching = false;
          clearInterval(this.intervaloSonoro);
          this.somSistema();
        });
    }
  };

  pushNewMessage = () => {
    const { newMsg, total_mensagens } = this;
    if (total_mensagens !== 0 && newMsg > 0)
      AlertaDownload(newMsg + " novas mensagens");
  };

  @action handleInfinityScroll = () => {
    if (this.total_mensagens <= 10) return;
    if (this.total_mensagens <= this.mensagens.length) return;
    const { idEmpresa } = this;
    this.isFetching = true;
    this.pagina_atual++;
    const data = new FormData();
    if (idEmpresa !== "todas") {
      data.set("numpage", this.pagina_atual);
      data.set("fornecedor", idEmpresa);
      Api.post("monitoramento/ocorrencias_empresa_alterado/", data, {})
        .then(res => {
          this.mensagens = [...this.mensagens, ...res.data.mensagens];
        })
        .catch(err => console.log("err", err))
        .finally(() => (this.isFetching = false));
    } else {
      data.set("numpage", this.pagina_atual);
      Api.post("monitoramento/ocorrencias_do_dia/", data, {})
        .then(res => {
          this.mensagens = [...this.mensagens, ...res.data.mensagens];
        })
        .catch(err => console.log("err", err))
        .finally(() => (this.isFetching = false));
    }
  };

  mergeArrays = res => {
    // if (!this.mensagens.length) return (this.mensagens = res);
    // res.forEach(msg => {
    //   const arrayCompare = this.mensagens.map(el => el.id);
    //   const ind = arrayCompare.indexOf(msg.id);
    //   if (ind !== -1 && msg.verificada !== this.mensagens[ind].verificada) {
    //     this.mensagens[ind] = msg;
    //   } else if (ind === -1) {
    //     this.mensagens.unshift(msg);
    //   }
    // });
    this.mensagens = res;
  };

  @action marcaMsgVerificada = (e, id) => {
    const parentContainer = document.getElementById(`ocorrencia_msg_${id}`);
    parentContainer.className = `${parentContainer.className} slide-out-bottom`;
    setTimeout(() => {
      this.total_mensagens_nao_verificadas--;
      // this.mensagens = this.mensagens.map(msg =>
      //   msg.id === id ? { ...msg, verificada: true } : msg
      // );

      Api.post(
        "monitoramento/mensagem_verificada/",
        {
          mensagem_id: id
        },
        {}
      )
        .then(response => { })
        .catch(err => console.log("err", err));
    }, 100);
  };

  @action redireciona(props, id) {
    MonitoramentoStore.licitacao = id;
    MonitoramentoStore.primeira_vez = "1";
    localStorage.setItem("licitacao_monitorada", id);
    props.history.push("/monitoramento");
  }

  @action mudaEmpresa = (e) => {
    this.mensagens = [];
    document.getElementById("ocorrencias-dia-list").scrollTo(0, 0);
    const id = e.target.value;
    this.pagina_atual = 1;
    this.idEmpresa = id;
    this.consultaApi();
  }

  @action verificarTodas = async () => {
    const { idEmpresa } = this;
    const queryString =
      idEmpresa === "todas" ? "?todas=true" : `?fornecedor_pk=${idEmpresa}`;
    try {
      await Api.get(`/monitoramento/mensagem_verificada_dia/${queryString}`);
      this.mensagens = this.mensagens.map(msg => ({
        ...msg,
        verificada: true
      }));
      this.total_mensagens_nao_verificadas = 0;
    } catch (err) {
      console.log("err", err);
    } finally {
      SideStores.consulta_notificacoes();
    }
  };

  @action onPageChanged = data => {
    const { currentPage } = data;
    this.pagina_atual = currentPage;
    if (this.isLoading) {
      if (!this.flagConsultaApi) {
        this.consulta_api(this.idEmpresa);
      } else {
        this.consulta_api_todas_empresas();
      }
    }
  };

  @action somSistema = () => {
    this.intervaloSonoro = setInterval(() => {
      const { pathname } = window.location;
      const { som, total_mensagens_nao_verificadas } = this;
      if (
        !som ||
        !total_mensagens_nao_verificadas ||
        pathname !== "/ocorrenciasdodia"
      )
        return clearInterval(this.intervaloSonoro);
      document.getElementById("avisos").play();
    }, 60000);
  };

  @action loadMoreItems = () => {
    this.loadingState = true;
    setTimeout(() => {
      this.items = this.items + 10;
      this.loadingState = false;
    }, 500);
  };

  @action limparVariaveis = () => {
    this.mensagens = [];
    this.items = 10;
    this.loadingState = false;
    this.status = false;
    this.total_mensagens_nao_verificadas = null;
    this.total_mensagens = 0;
    this.pagina_atual = 1;
    this.isLoading = true;
    this.palavras = [];
    this.flagConsultaApi = false;
    this.nome_empresa = "";
    // this.idEmpresa = this.idEmpresa !== 'todas';
    this.som = true;
    this.isFetching = false;
    this.fetchingMessages = false;
    this.newMsg = 0;
    clearInterval(this.intervaloSonoro);
    this.intervaloSonoro = undefined;
  };

  @action trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.consultaApi();
  };
}

const ocorrenciasDoDiaStores = new OcorrenciasDoDiaStores();
export default ocorrenciasDoDiaStores;

import { observable, action } from "mobx";

class StatusConexaoStores {
  @observable isActive = false;

  @action handleOpenModal = () => {
    this.isActive = true;
  };
  @action handleCloseModal = () => {
    this.isActive = false;
  };

  @action limparVariaveis = () => {
    this.isActive = false;
  };
}

const statusConexaoStores = new StatusConexaoStores();
export default statusConexaoStores;

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Prompt } from 'react-router-dom'
import { CheckCircle } from 'react-feather'
import {
  MessageCircle,
  // MinusCircle,
  Trash,
  Package,
  Share2,
  Layers
} from 'react-feather'

import { PortalSelectContainer, PortalContainer } from './styles'
import { SomSitema } from '../../som/SomSistema'
import LogoComprasNet from '../../Images/compras_net.png'
import LogoBb from '../../Images/bb_logo.jpeg'
import {
  ComponentHeader,
  ComponentHeaderOverflow,
  ContentBody,
  CardWarpMsg,
  TabButton,
  ReactTooltip
} from '../../globalStyles'

import NotificacoesEmail from './NotificacoesEmail'

import MonitorarPalavraChave from './MonitorarPalavraChave'
import CardMonitoramento from './CardMonitoramento'
import { IconPlavrasCheveColor } from '../../Icons/iconCustom'
import { ModalConfirmacao } from '../../Components/NewModal/modalConfirmacao'
import { ModalCnpj } from '../Monitoring/ModalCnpj'
import {
  ActionButtonsContainer,
  LimparButton,
  SaveButton
} from '../ConfigurationSearchForNotices/styles'

@inject(
  'ConfiguracoesMonitoramentoStores',
  'MenuStores',
  'MaquinaDeEstadosStore'
)
@observer
class ConfiguracaoMonitoramento extends Component {
  componentDidMount() {
    const {
      ConfiguracoesMonitoramentoStores: { consulta_api },
      MaquinaDeEstadosStore: { id_fornecedor }
    } = this.props
    if (id_fornecedor) consulta_api()
  }

  render() {
    const {
      configuracao_empresas,
      limpar,
      portal,
      changePortal,
      salva_api,
      modalCnpj,
      toggleModalCnpj,
      houveAlteracao
    } = this.props.ConfiguracoesMonitoramentoStores

    const { contratos } = this.props.MaquinaDeEstadosStore
    const { portal: portalMonitoramento } = contratos[2] || { portal: [] }

    return (
      <>
        <ReactTooltip />
        <SomSitema />
        {configuracao_empresas.length > 0 && (
          <>
            <ComponentHeader
              height="8vh"
              style={{ justifyContent: 'space-between' }}
            >
              <TabButton ul>MONITORAR</TabButton>
            </ComponentHeader>
            <ComponentHeaderOverflow height="8vh" />

            <ContentBody
              bRadius
              height="calc(100vh - 8vh - 78px)"
              style={{ overflow: 'auto' }}
            >
              <NotificacoesEmail />

              <PortalSelectContainer>
                <Layers />
                <span>SELECIONE OS PORTAIS QUE DESEJA MONITORAR: </span>
                <div>
                  {portalMonitoramento.includes(5) && (
                    <PortalContainer
                      className="icon-scale-xs"
                      onClick={() => changePortal(5)}
                      selected={portal.includes(5)}
                    >
                      <img src={LogoComprasNet} alt="Logo Comprasnet" />
                      <span>ComprasNet</span>
                    </PortalContainer>
                  )}

                  {portalMonitoramento.includes(6) && (
                    <PortalContainer
                      className="icon-scale-xs"
                      onClick={() => changePortal(6)}
                      selected={portal.includes(6)}
                    >
                      <img src={LogoBb} alt="logo bb" />
                      <span>Licitações-e</span>
                    </PortalContainer>
                  )}
                </div>
              </PortalSelectContainer>

              <CardWarpMsg height="initial">
                <CardMonitoramento
                  nameVariavel={'empresa'}
                  title={'MONITORAR EMPRESAS'}
                  subTitle={
                    'Monitorar CNPJ e Razão Social citados no CHAT do portal.'
                  }
                  Icon={<Package />}
                  cor="#d41a1a"
                />

                <CardMonitoramento
                  nameVariavel={'situacao'}
                  title={'SITUAÇÃO DA LICITAÇÃO'}
                  subTitle={'Monitorar mudanças na situação.'}
                  Icon={<Share2 />}
                  cor="#e6ab26"
                />

                <CardMonitoramento
                  nameVariavel={'especifica'}
                  title={'PALAVRAS-CHAVE ESPECÍFICAS DA LICITAÇÃO'}
                  subTitle={
                    'Monitora palavras específicas da licitação citadas no CHAT.'
                  }
                  Icon={<IconPlavrasCheveColor />}
                  cor="#d41a1a"
                />

                <CardMonitoramento
                  nameVariavel={'mensagem'}
                  title={'MENSAGENS DA LICITAÇÃO'}
                  subTitle={'Monitorar LICITAÇÕES contendo NOVAS mensagens.'}
                  Icon={<MessageCircle />}
                  cor="#82ad4a"
                />
              </CardWarpMsg>

              <MonitorarPalavraChave />
              <ModalCnpj
                title="CNPJ DETECTADO"
                message="Não é possível cadastrar um CNPJ como palavra-chave!"
                status={modalCnpj}
                closeModal={toggleModalCnpj}
              />

              <ActionButtonsContainer style={{ marginTop: '30px' }}>
                <ModalConfirmacao
                  button={
                    <LimparButton className="limpar icon-scale-xs">
                      <Trash size="27px" />
                      LIMPAR
                    </LimparButton>
                  }
                  width="700px"
                  title="LIMPAR DADOS"
                  message="Tem certeza que deseja limpar os dados?"
                  confirmAction={limpar}
                />
                <SaveButton className="icon-scale-xs" onClick={salva_api}>
                  <CheckCircle size="27px" />
                  SALVAR
                </SaveButton>
              </ActionButtonsContainer>
            </ContentBody>
          </>
        )}
        <Prompt
          when={houveAlteracao}
          message={location =>
            `Suas alterações ainda não foram salvas, deseja realmente sair sem salvar?`
          }
        />
      </>
    )
  }
}

export default ConfiguracaoMonitoramento

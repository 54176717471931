import React, { useEffect, useState } from 'react'
import {
  CardBody,
  CardFiltroData,
  CardHeard,
  CardTitulo,
  InputData
} from './styles'
import 'react-infinite-calendar/styles.css' // only needs to be imported once
import { Calendar } from 'react-feather'
import { useFiltroEdital } from './context/FiltroEditaisContext'
import { format } from 'date-fns'

import { DatePicker } from '../SubHeader/Filtros/DatePicker'

const CardData = () => {
  const { onChangeDate } = useFiltroEdital()
  const [state, setState] = useState()

  useEffect(() => {
    const dataAtual = format(new Date(), 'dd/MM/yyyy')
    const dados = `${dataAtual} até ${dataAtual}`
    setState(dados)
  }, [])

  const handleData = e => {
    const data = {
      dmin: format(e.startDate._d, 'dd/MM/yyyy'),
      dmax: format(e.endDate._d, 'dd/MM/yyyy')
    }
    onChangeDate(data)
    const dados = `${data.dmin} até ${data.dmax}`
    setState(dados)
  }

  return (
    <CardFiltroData>
      <CardHeard>
        <Calendar />
        <CardTitulo>
          <div>FILTRO POR INTERVALO DE DATA</div>
          <p>Selecione o dia ou período que deseja pesquisar</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <InputData>{state}</InputData>
        <DatePicker handleSelect={e => handleData(e)} showing={true} />
      </CardBody>
    </CardFiltroData>
  )
}

export default CardData

import useAxios from 'axios-hooks'
import React, { useEffect, useReducer, useState } from 'react'
import Api from '../../../../../Stores/Conexao/conexao'
import { ToastError } from '../../../../../Stores/Funcoes/FuncoesGerais'
import maquinadeestadosstore from '../../../../../Stores/MaquinaDeEstadosStore'

const initialState = {
  edital: null,
  idProposta: null,
  loading: false,
  error: false,
  // rows: [],
  modalCarregarItens: false,
  enviandoProposta: false,
  propostaEnviada: false,
  progress: 0,
  aleatorio: 0,
  selectedItems: [],
}

const actions = {
  SET_EDITAL: 'SET_EDITAL',
  SET_ID_PROPOSTA: 'SET_ID_PROPOSTA',
  // SET_ROWS: 'SET_ROWS',
  SET_MODAL: 'SET_MODAL',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SETERROR',
  SET_ENVIANDO_PROPOSTA: 'SET_ENVIANDO_PROPOSTA',
  SET_PROPOSTA_ENVIADA: 'SET_PROPOSTA_ENVIADA',
  SET_PROGRESS: 'SET_PROGRESS',
  SET_ALEATORIO: 'SET_ALEATORIO',
  CLEAN_DATA: 'CLEAN_DATA',
  SELECTED_ITEMS: 'SELECTED_ITEMS',
}

function reducer(state, action) {
  switch (action.type) {
    case actions.SET_EDITAL:
      return { ...state, edital: action.payload }
    case actions.SET_ID_PROPOSTA:
      return { ...state, idProposta: action.payload }
    // case actions.SET_ROWS:
    //   return { ...state, rows: action.payload };
    case actions.SET_MODAL:
      return { ...state, modalCarregarItens: action.payload }
    case actions.SET_LOADING:
      return { ...state, loading: action.payload }
    case actions.SET_ERROR:
      return { ...state, error: action.payload }
    case actions.SET_ENVIANDO_PROPOSTA:
      return { ...state, enviandoProposta: action.payload }
    case actions.SET_PROPOSTA_ENVIADA:
      return { ...state, propostaEnviada: action.payload }
    case actions.SET_PROGRESS:
      return { ...state, progress: action.payload }
    case actions.SET_ALEATORIO:
      return { ...state, aleatorio: action.payload }
    case actions.SELECTED_ITEMS:
      return { ...state, selectedItems: action.payload }
    case actions.CLEAN_DATA:
      return initialState
    default:
      return state
  }
}

const PropostaEspecificaContext = React.createContext(null)

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [rows, setRows] = useState([])
  const [manualmente, setManualmente] = useState(false)

  const [{ data, loading, error }, buscarManualmente] = useAxios(
    {
      method: 'GET'
    },
    {
      manual: true,
      useCache: false
    }
  )

  const setEdital = edital =>
    dispatch({ type: actions.SET_EDITAL, payload: edital })

  const exportExcel = async (numero, uasg, arquivo) => {
    const data = new FormData()
    data.append('numero', numero)
    data.append('uasg', uasg)
    data.append('fornecedor', maquinadeestadosstore.getIdEmpresa())
    data.append('arquivo', arquivo)

    try {
      const res = await Api.post('/portal/proposta_exportar_planilha/', data)
      let { rows, edital } = res.data
      rows = tratamentoBackend(rows)
      setRows(
        rows.map(ed =>
          ed.descricao_detalhada
            ? ed
            : { ...ed, descricao_detalhada: ed.descricao_completa }
        )
      )
      setEdital(edital)
    } catch (err) {
      if (err.response && err.response.data) ToastError(err.response.data.erro)
      console.log(err.response)
      console.log('err', err)
    }
  }

  const value = {
    ...state,
    buscarManualmente: (options, manual) => {
      if (manual) setManualmente(true)
      buscarManualmente(options)
    },
    exportExcel,
    setLoading: status =>
      dispatch({ type: actions.SET_LOADING, payload: status }),
    setEdital,
    setRows,
    rows,
    setError: error => dispatch({ type: actions.SET_ERROR, payload: error }),
    setIdProposta: id =>
      dispatch({ type: actions.SET_ID_PROPOSTA, payload: id }),
    openModal: options =>
      dispatch({ type: actions.SET_MODAL, payload: options }),
    setEnviandoProposta: status =>
      dispatch({ type: actions.SET_ENVIANDO_PROPOSTA, payload: status }),
    setPropostaEnviada: status =>
      dispatch({ type: actions.SET_PROPOSTA_ENVIADA, payload: status }),
    setProgress: progress =>
      dispatch({
        type: actions.SET_PROGRESS,
        payload: progress >= 100 ? 99 : progress
      }),
    setAleatorio: number =>
      dispatch({ type: actions.SET_ALEATORIO, payload: number }),
    closeModal: () => dispatch({ type: actions.SET_MODAL, payload: false }),
    cleanData: () => dispatch({ type: actions.CLEAN_DATA }),
    setSelectedItems: (data) => dispatch({ type: actions.SELECTED_ITEMS, payload: data }),
  }

  useEffect(() => {
    const tratarEdital = () => {
      if (error) {
        setEdital(null)
        setRows([])
        return ToastError('Ocorreu um problema.')
      }

      if (!data) return

      const { edital, rows } = data
      
      value.setSelectedItems(rows.filter(e => e.checked).map(e => e.item))
      
      if (!edital) return ToastError('Edital não encontrado')

      if (edital.proposta.enviada) return ToastError('Proposta já enviada.')

      if (manualmente) {
        const path = 'portal/proposta_verifica_edital/?edital=' + edital.id   
        value.setLoading(true)
        Api.get(path)
          .then(res => {
            value.openModal({
              numero: edital.numero,
              uasg: edital.comprador.codigo,
            })
          })
          .catch(err => {                      
            if(err.response.status === 404)
            ToastError('Pregão suspenso')
          })
          .finally(() => {
            setManualmente(false)
            value.setLoading(false)
          })
      } else {
        setEdital(edital)
        setRows(
          rows.map(ed => {
            return {
              ...ed,
              descricao_detalhada:
                ed.descricao_detalhada || ed.descricao_completa,
              trat_diferenciado: ed.trat_diferenciado || '-'
            }
          })
        )
      }
    }
    tratarEdital()
  }, [data, error])

  useEffect(() => {
    const { setLoading } = value
    if(manualmente && data && data.edital)
    return setLoading(true)
    setLoading(loading)
  }, [loading])

  return (
    <PropostaEspecificaContext.Provider value={value}>
      {children}
    </PropostaEspecificaContext.Provider>
  )
}

const PropostaEspecificaProvider = Provider

export { PropostaEspecificaContext }
export default PropostaEspecificaProvider

const checkNullish = val => val !== undefined && val !== null

const tratamentoBackend = rows => {
  return rows.map(item => {
    let newItem = { ...item }
    if (item.valor_unit !== undefined)
      newItem.valor_unit = tratarValorUnitario(item.valor_unit)
    newItem.valor_total = tratarValorTotal(newItem)
    // if (newItem.desconto) newItem.desconto = String(newItem.desconto).replace('.', ',').concat('%');
    if (newItem.desconto) newItem.desconto = String(newItem.desconto)
    if (newItem.aliquota)
      newItem.valor_total_equal = tratarTotalEqualizado(newItem)
    if (newItem.grupo === '') newItem.grupo = null
    if (newItem.trat_diferenciado === '') newItem.trat_diferenciado = '-'
    return newItem
  })
}

const tratarValorUnitario = valor => {
  let newValor = valor.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 4
  })
  if (newValor.includes('R$')) newValor = newValor.slice(3)
  return newValor
}

const tratarValorTotal = item => {
  let qtd = checkNullish(item.qtd_ofertada)
    ? item.qtd_ofertada
    : item.qtd_estimada
  // let result = (parseFloat(item.valor_unit.replace(',', '.')) * Number(qtd));
  let result = Number(item.valor_unit.replace(/[.]/g, '').replace(',', '.'))
  result *= Number(qtd)
  if (isNaN(result)) result = 0

  if (result && item.desconto) result = result - (result * item.desconto) / 100

  return result
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 4
    })
    .slice(3)

  // Esse return faz:
  // 1 - Trata a string substituindo , por . (necessário para fazer qualquer matemática)
  // 2 - Transfroma a string em float
  // 3 - Multiplica pela quantidade
  // 4 - Aplica função para transformar em moeda brasileira com 4 digitos fatoriais
  // 5 - Remove o R$
}

const tratarTotalEqualizado = item => {
  const valorTotal = parseFloat(
    item.valor_total.replace(/[.]/g, '').replace(',', '.')
  )
  const eql = valorTotal * parseFloat(item.aliquota.replace(',', '.'))

  // const valorTotalEqualizado = valorTotal + aliquota;
  // debugger;

  return eql
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 4
    })
    .slice(3)
}

const tratarDesconto = num => {
  let numero = num.toString().replace(/\./g, '')
  const removePercentage = () => {
    const num = numero.replace('%', '').replace(',', '')
    return num.slice(0, num.length - 2) + '.' + num.slice(-2)
  }

  const tratamento = numero.slice(-1) === '%' ? removePercentage() : numero
  const result = Number(tratamento.replace(',', '.')).toFixed(2)
  return result
}
import React from 'react'
import { XCircle } from 'react-feather'

import LogoWave from '../../../Images/logo_wave.png'

import * as S from './Style'

const ModalConfirmation = ({ handleCancel, handleOk, message, textButtonOk }) => {
  return (
    <S.Modal>
      <S.Container>
        <header>
          <div>
            <img src={LogoWave} alt="Logo da plataforma Wavecode" />
            <span>Wavecode diz:</span>
          </div>
          <div className="close">
            <XCircle onClick={handleCancel} />
          </div>
        </header>

        <div className="body">
          <h3>{message}</h3>
        </div>

        <footer>
          <button className="cancel" onClick={handleCancel}>
            Cancelar
          </button>
          <button className="save" onClick={handleOk}>
            {textButtonOk ? textButtonOk : 'Salvar'}
          </button>
        </footer>
      </S.Container>
    </S.Modal>
  )
}

export default ModalConfirmation

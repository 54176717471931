import React, { useContext, useMemo } from 'react';
import ReactPDF, { PDFDownloadLink, PDFViewer, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Download } from 'react-feather';

import Roboto from './fonts/Roboto-Bold.ttf';
import { useEffect } from 'react';
import { FormatDateData } from '../../../../../../../../Stores/Funcoes/FuncoesGerais';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactTooltip } from '../../../../../../../../globalStyles';
import { DadosPdfContext } from '../context';
import { PropostaEspecificaContext } from '../../../context';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: Roboto, fontWeight: 900 },
  ]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '25px',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '2px solid grey',
    paddingBottom: '10px',
    logo: {
      width: '25%',
      justifyContent: 'center',
      borderRight: '1px solid #b3b0b0',
      textAlign: 'center',
      paddingLeft: '10px',
      image: {
        width: '90px',
      }
    },
    companyInfo: {
      flexDirection: 'column',
      width: '75%',
      padding: '10px',
      companyName: {
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: '700'
      },
      companyAddress: {
        fontSize: '10px',
        color: 'black',
        margin: '1px 0px'
      },
      companyCNPJ: {
        fontSize: '10px',
        color: 'black',
        margin: '5px 0px 2px 0px'
      }
    }
  },
  dadosPregao: {
    margin: '8px 0px',
    border: '1px solid black',
    textAlign: 'center',
    padding: '9px',
    fontWeight: '900',
    dados: {
      fontFamily: 'Roboto',
      fontWeight: '900',
      margin: '2px',
      fontSize: '12px'

    },
    headline: {
      fontFamily: 'Roboto',
      fontWeight: '900',
      fontSize: '9px',
      color: '#1f1f1f'
    },
    subtitle: {
      fontWeight: 'bold',
      fontSize: '11px',
      color: 'grey',
      marginTop: '3px'
    }
  },
  itensPregao: {
    minHeight: '30px',
    border: '1px solid grey',
    margin: '5px 0px',
    padding: '5px 8px',
    flexDirection: 'column',
    fontSize: '9px',
    firstRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '3px'
    },
    propriedade: {
      fontFamily: 'Roboto',
      fontWeight: '600',
    },
    valor: {
      marginTop: '5px',
      marginBottom: '5px',
      fontWeight: '300',
      justifyContent: 'center',
      color: 'black'
    },

  },
  totalGeral: {
    fontFamily: 'Roboto',
    fontWeight: '900',
    margin: '2px',
    fontSize: '15px'

  },
  footer: {
    marginTop: '30px',
    bottom: '0',
    fontSize: '9px',
    dadosRepresentante: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '15px',
      title: {
        fontFamily: 'Roboto',
        marginRight: '5px'
        // fontWeight: '600'
      },
      description: {
        // marginTop: '3px',
        // marginBottom: '5px',
        justifyContent: 'center',
        color: 'black'
      },
    },
    assinatura: {
      // width: '100%',
      // width: 'auto',
      width: 'auto',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      image: {
        // flexGrow: 0,
        height: 'auto',
        width: 'auto',
        maxHeight: '200px',
        maxWidth: '100%',
        // margin: 'auto'
      }
    }
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});


// Create Document Component
const MyDocument = (props) => {

  const {
    setPdfFile, logo, numero, nome, rua, complemento, bairro, cidade, uf, telefone, celular, email, site,
    cep, dadosBancarios, observacoes, validade, assinatura, cpf, rg, razaoSocial, cnpj, inscEstadual, inscMunicipal,
    edital, rows, filename
  } = props;

  const { numero: numeroEdital, comprador, data_abertura, data_disputa, modalidade } = edital;

  const itensSelecionados = rows.filter(item => item.checked);

  const generateItens = (arr) => arr.map((item, i) => (
    <View style={[styles.itensPregao, { backgroundColor: i % 2 === 0 ? '#e0e0e0' : '#fff' }]} wrap={false} key={i}>
      <View style={styles.itensPregao.firstRow}>
        <View>
          <Text style={styles.itensPregao.propriedade}>ITEM</Text>
          <Text style={styles.itensPregao.valor}>{item.item}</Text>
        </View>
        {item.grupo &&
          <View>
            <Text style={styles.itensPregao.propriedade}>GRUPO/LOTE</Text>
            <Text style={styles.itensPregao.valor}>{item.grupo}</Text>
          </View>
        }
        <View>
          <Text style={styles.itensPregao.propriedade}>QTD ESTIMADA</Text>
          <Text style={styles.itensPregao.valor}>{item.qtd_estimada}</Text>
        </View>
        <View>
          <Text style={styles.itensPregao.propriedade}>QTD OFERTADA</Text>
          <Text style={styles.itensPregao.valor}>{item.qtd_ofertada || item.qtd_estimada}</Text>
        </View>
        {item.desconto &&
          <View>
            <Text style={styles.itensPregao.propriedade}>DESC.</Text>
            <Text style={styles.itensPregao.valor}>{item.desconto}</Text>
          </View>
        }
        <View>
          <Text style={styles.itensPregao.propriedade}>R$ UNITÁRIO</Text>
          <Text style={styles.itensPregao.valor}>{item.valor_unit}</Text>
        </View>
        <View>
          <Text style={styles.itensPregao.propriedade}>R$ TOTAL</Text>
          <Text style={styles.itensPregao.valor}>{item.valor_total}</Text>
        </View>
        {item.valor_total_equal &&
          <View>
            <Text style={styles.itensPregao.propriedade}>R$ TOT. EQ.</Text>
            <Text style={styles.itensPregao.valor}>{item.valor_total_equal}</Text>
          </View>
        }
      </View>
      <View style={styles.itensPregao.firstRow}>
        {item.tp &&
          <View>
            <Text style={styles.itensPregao.propriedade}>7174/TP</Text>
            <Text style={styles.itensPregao.valor}>SIM</Text>
          </View>
        }
        {item.ppb &&
          <View>
            <Text style={styles.itensPregao.propriedade}>7174/PPB</Text>
            <Text style={styles.itensPregao.valor}>SIM</Text>
          </View>
        }
        <View>
          <Text style={styles.itensPregao.propriedade}>FABRICANTE</Text>
          <Text style={styles.itensPregao.valor}>{item.fabricante}</Text>
        </View>
        <View>
          <Text style={styles.itensPregao.propriedade}>MODELO</Text>
          <Text style={styles.itensPregao.valor}>{item.modelo_versao}</Text>
        </View>
        <View>
          <Text style={styles.itensPregao.propriedade}>MARCA</Text>
          <Text style={styles.itensPregao.valor}>{item.marca}</Text>
        </View>
      </View>
      <View style={styles.itensPregao.firstRow}>
        <View>
          <Text style={styles.itensPregao.propriedade}>DESCRIÇÃO</Text>
          <Text style={styles.itensPregao.valor}>
            {item.descricao_detalhada}
          </Text>
        </View>
      </View>
    </View>
  ));

  const generateFooter = () => (
    <View>
      {/* VALOR TOTAL DA PROPOSTA */}
      < View style={{ backgroundColor: '#c1efcd', padding: '10px', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '10px', fontSize: '14px' }} >
        <Text style={styles.totalGeral}>VALOR TOTAL DA PROPOSTA:&nbsp;&nbsp;&nbsp;</Text>
        <Text style={styles.totalGeral}>R$ {valorTotal}</Text>
      </View >
      {valorTotal !== totalEqualizado &&
        <View style={{ backgroundColor: '#a5c3d8', padding: '10px', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '10px', fontSize: '14px' }} >
          <Text style={styles.totalGeral}>VALOR TOTAL EQUALIZADO:&nbsp;&nbsp;&nbsp;</Text>
          <Text style={styles.totalGeral}>R$ {totalEqualizado}</Text>
        </View>
      }
      {/* FOOTER */}
      <View style={styles.footer}>
        <View style={{ ...styles.footer.dadosRepresentante, flexDirection: 'row', justifyContent: 'space-between' }}>
          {nome ?
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.footer.dadosRepresentante.title}>NOME DO REPRESENTANTE:</Text>
              <Text style={styles.footer.dadosRepresentante.description}>{nome}</Text>
            </View> : null
          }
          {cpf ?
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.footer.dadosRepresentante.title}>CPF:</Text>
              <Text style={styles.footer.dadosRepresentante.description}>{cpf}</Text>
            </View> : null
          }
          {rg ?
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.footer.dadosRepresentante.title}>RG:</Text>
              <Text style={styles.footer.dadosRepresentante.description}>{rg}</Text>
            </View> : null
          }
        </View>
        {dadosBancarios ?
          <View style={styles.footer.dadosRepresentante}>
            <Text style={styles.footer.dadosRepresentante.title}>DADOS BANCÁRIOS:</Text>
            <Text style={styles.footer.dadosRepresentante.description}>{dadosBancarios}</Text>
          </View> : null
        }
        {observacoes ?
          <View style={styles.footer.dadosRepresentante}>
            <Text style={styles.footer.dadosRepresentante.title}>OBSERVAÇÕES:</Text>
            <Text style={styles.footer.dadosRepresentante.description}>{observacoes}</Text>
          </View> : null
        }
        {validade ?
          <View style={styles.footer.dadosRepresentante}>
            <Text style={styles.footer.dadosRepresentante.title}>PROPOSTA VÁLIDA ATÉ:</Text>
            <Text style={styles.footer.dadosRepresentante.description}>{validade}</Text>
          </View> : null
        }


        {assinatura ?
          <View style={styles.footer.assinatura}>
            <Image cache={false} src={assinaturaUrl} style={styles.footer.assinatura.image} crossorigin="anonymous" />
          </View> : null
        }
      </View>
    </View>
  );


  const valorTotal = itensSelecionados.reduce((acc, current) => acc + Number(current.valor_total.replace(/\./g, '').replace(',', '.'))
    , 0).toLocaleString('pt-br', { minimumFractionDigits: 4 });

  const tratamentoTotalEqualizado = itensSelecionados.map((item) => {
    return item.valor_total_equal == null ? item.valor_total : item.valor_total_equal
  })
  const totalEqualizado = tratamentoTotalEqualizado.reduce((acc, current) => acc + Number(current.replace(/\./g, '').replace(',', '.'))
    , 0).toLocaleString('pt-br', { minimumFractionDigits: 4 });


  const sendPDF = async (blob) => {
    const file = new File([blob.blob], filename, { type: 'application/pdf' });
    setPdfFile(file);
  }

  let url;
  if (logo) {
    url = new URL(logo);
    url.uri = logo;
    url.method = 'GET';
    url.headers = {
      "Cache-Control": "no-cache",
      // "content-type": 'image/*',
    };
    url.body = "";
  }

  let assinaturaUrl;
  if (assinatura) {
    assinaturaUrl = new URL(assinatura);
    assinaturaUrl.uri = assinatura;
    assinaturaUrl.method = 'GET';
    assinaturaUrl.headers = {
      "Cache-Control": "no-cache",
    };
    assinaturaUrl.body = "";
  }

  const cabecalhoDesabilitado = JSON.parse(localStorage.getItem('proposta-cabecalho-desabilitado'));

  // Quebrar itens selecionados
  let oldArr = [...itensSelecionados];
  let arrayItens = [];
  while (oldArr.length) {
    arrayItens.push(oldArr.splice(0, 24))
  }

  return (
    <Document onRender={sendPDF}>
      <Page size="A4" style={styles.page}>
        {!cabecalhoDesabilitado ?
          <View style={styles.header}>
            {logo ?
              <View style={styles.header.logo}>
                <Image src={url} style={styles.header.logo.image} crossorigin="anonymous" />
              </View> : null
            }
            {/* CABEÇALHO */}
            <View style={styles.header.companyInfo}>
              {/* NOME */}
              {razaoSocial ?
                <Text style={styles.header.companyInfo.companyName}>{razaoSocial}</Text> : null
              }

              {/* LOGRADUORO */}
              {rua ?
                <Text style={styles.header.companyInfo.companyAddress}>
                  {`${rua}, ${numero}${complemento ? `, ${complemento} ` : ' '}- ${bairro}, ${cidade} - ${uf}, ${cep}`}
                </Text> : null
              }
              {telefone ?
                <Text style={styles.header.companyInfo.companyAddress}>{`${telefone} | ${celular}`}</Text> : null
              }
              {email ?
                <Text style={styles.header.companyInfo.companyAddress}>{`Email: ${email} | site: ${site}`}</Text> : null
              }
              {cnpj ?
                <Text style={styles.header.companyInfo.companyCNPJ}>{`CNPJ: ${cnpj} | IE: ${inscEstadual || ''} | IM: ${inscMunicipal || ''}`}</Text> : null
              }
            </View>
          </View> : null
        }

        {/* DADOS DO PREGÃO */}
        <View style={styles.dadosPregao}>
          <Text style={styles.dadosPregao.dados}>{comprador.nome} - UASG: {comprador.codigo} | Pregão N. {numeroEdital}</Text>
          <View style={{ flexDirection: 'row', textAlign: 'left', justifyContent: 'space-around', margin: '5px 0' }}>
            {modalidade && <Text style={styles.dadosPregao.headline}>{`MODALIDADE: ${modalidade.descricao}`}</Text>}
            <Text style={styles.dadosPregao.headline}>{`ABERTURA: ${FormatDateData(data_abertura)}`}</Text>
            <Text style={styles.dadosPregao.headline}>{`DISPUTA: ${FormatDateData(data_disputa)}`}</Text>
          </View>
          <Text style={styles.dadosPregao.subtitle}>Apresentamos nossa proposta de preços</Text>
        </View>
        {/* ITENS DA PROPOSTA */}
        {generateItens(arrayItens[0])}
        {arrayItens.length === 1 ? generateFooter() : null}
      </Page>
      {arrayItens.length > 1 ? arrayItens.slice(1).map((it, index) => {
        return (
          <Page size="A4" style={styles.page}>
            {generateItens(it)}
            {index === (arrayItens.length - 2) ? generateFooter() : null}
          </Page>
        )
      }) : null}
    </Document>
  )
};


const PDFMaker = ({ filename }) => {
  const dadosPdf = useContext(DadosPdfContext);
  const dadosEdital = useContext(PropostaEspecificaContext);

  const obj = { ...dadosPdf, ...dadosEdital };

  const stringified = JSON.stringify(obj);

  const props = useMemo(() => {
    return obj;
  }, [stringified])

  const date = (new Date()).toLocaleString('pt-BR').replace(/ /g, '_').replace(/\//g, '-');


  const PDFLink = () => {
    useEffect(ReactTooltip.rebuild);

    return (
      <Download
        className="pdf-download"
        data-tip="DOWNLOAD PDF"
        data-type="success"
        data-place="right"
      />
    )
  }

  const comp = useMemo(() => {
    return (
      <PDFDownloadLink
        document={<MyDocument filename={filename} {...props} />}
        fileName={`${filename}__${date}.pdf`}
      >
        {({ blob, url, loading, error }) => (loading ? <FontAwesomeIcon icon="spinner" spin className="pdf-download" /> : <PDFLink />)}
      </PDFDownloadLink >
    )
  }, [stringified])

  return comp;
};

export default PDFMaker;


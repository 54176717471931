import { situacao } from "../../BiddingPanel/styles";
import React from "react";
import LicitacoesExclusao from "../../../Stores/LicitacoesExclusao";
import { IconLixeira } from '../../../Themes/Modal/IconsSVG';

export const rowTheme = {
  title: {
    height: "0px"
  },

  rows: {
    borderWidth: "0px",
    borderTopStyle: null,
    height: "70px",
    backgroundColor: "transparent",
    padding: "0px",
    minHeight: "70px"
  },
  cells: {
    borderTopStyle: null,
    // cellPadding: '20px',
    height: "20px",
    padding: "0px"
  }
};

export const columnsLicitacao = [
  {
    name: "PORTAL",
    selector: "portal",
    sortable: true,
    left: true,
    grow: 1,
    maxWidth: "120px"
  },

  {
    name: "Nº PREGAO",
    selector: "codigo",
    sortable: true,
    left: true,
    grow: 0,
    maxWidth: "80px"
  },

  {
    name: "COMPRADOR",
    selector: "comprador.nome",
    sortable: true,
    left: true,
    minWidth: "80px",
    maxWidth: window.innerWidth < 1200 ? "297.5px" : "auto",
    grow: 1
  },

  {
    name: "DATA DISPUTA",
    selector: "data_disputa",
    sortable: true,
    left: true,
    grow: 0.5
  },
  {
    name: "SITUAÇÃO",
    selector: "situacao.descricao",
    sortable: true,
    center: true,
    grow: 0.8,
    cell: row => (
      <span
        style={{
          borderRadius: "10px",
          padding: "2px 10px 2px 10px",
          background: situacao(row.situacao.cor),
          marginLeft: "-10px",
          color: "white",
          width: "70%",
          textAlign: "center"
        }}
      >
        {row.situacao.descricao}
      </span>
    )
  },
  {
    name: "RESTAURAR",
    selector: "id",
    sortable: false,
    center: true,
    grow: 0.1,
    cell: row => (
      <button
        className="icon-scale-xs"
        data-tip="EXCLUIR LICITAÇÃO"
        data-place="left"
        data-type="error"
        data-effect="solid"
        style={{
          padding: 0,
          background: "transparent",
          border: "none"
        }}
        onClick={() => LicitacoesExclusao.restaurarLicitacao(row.id)}
      >
        <i
          className="icon-scale"
          data-tip="RESTAURAR LICITAÇÃO"
          data-type="success"
          data-effect="solid"
          data-place="bottom"
        >
          <svg width="25" height="25">
            <path d={IconLixeira} fill={"#1d9716"} />
          </svg>
        </i>
      </button>
    )
  }
];

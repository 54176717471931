import axios from "axios";
import AlertaVerbalStore from "../Stores/AlertaVerbalStore";
import AlertaVerbalFemininoStore from "../Stores/AlertaVerbalFemininoStore";

let chainArr = [];

export const playChain = (arr, feminino) => {
  const chainLength = chainArr.length;
  chainArr = [...chainArr, ...arr];
  if (!chainLength) handleAudio(feminino);
};

export const handleAudio = async feminino => {
  if (!chainArr.length) return;

  let audioContext = new (window.AudioContext || window.webkitAudioContext)();

  const source = audioContext.createBufferSource();

  const audioName = chainArr[0].replace(/-/g, "_").replace(/\s/g, "_");
  const audioFile = !feminino ? AlertaVerbalStore[audioName] : AlertaVerbalFemininoStore[audioName];


  const som = audioFile ? audioFile.slice() : null;
  if (som) {
    audioContext.decodeAudioData(
      som,
      function (buffer) {
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start(0);

        source.onended = function () {
          chainArr.shift();
          if (chainArr.length) handleAudio(feminino);
        };
      },
      e => console.log("audio err", e)
    );
  }
};

// export const playChain = async arr => {
//   if (!arr.length) return;
//   // push to the array
//   // get from the array;
//   const newArr = [...arr];
//   const audioContext = new (window.AudioContext || window.webkitAudioContext)();

//   const source = audioContext.createBufferSource();

//   // const som = str2ab(localStorage.getItem(arr[0]));
//   const som = AlertaVerbalStore[
//     arr[0].replace(/-/g, "_").replace(/\s/g, "_")
//   ].slice();
//   audioContext.decodeAudioData(
//     som,
//     function(buffer) {
//       source.buffer = buffer;
//       source.connect(audioContext.destination);
//       source.start(0);
//       // remove from the array
//       newArr.shift();
//       // console.log(newArr);

//       source.onended = function() {
//         playChain(newArr);
//       };
//     },
//     e => console.log("audio err", e)
//   );
// };

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint16Array(buf));
}
function str2ab(str) {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

// function ab3str(buf) {
//   return String.fromCharCode.apply(null, new Uint8Array(buf));
// }

// function str3ab(str) {
//   var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
//   var bufView = new Uint8Array(buf);
//   for (var i = 0, strLen = str.length; i < strLen; i++) {
//     bufView[i] = str.charCodeAt(i);
//   }
//   return buf;
// }

export const loadAlertaAudio = () => {
  const somType = [
    "audio1",
    "audio2",
    "audio3",
    "audio4",
    "audio5",
    "atencao",
    "urgente",
    "licitacao-atualizada-nova-situacao",
    "sua-empresa-foi-citada",
    "encontramos-mensagens-importantes",
    "encontramos-novas-mensagens",
    "comprasnet",
    "licitações-e",
    "banrisul",
    "licitacao-com-situacao-atualizada",
    "Aberto",
    "Em disputa",
    "Homologado",
    "Encerrado",
    "Revogado, Anulado ou Abandonado",
    "Em andamento - Aguardando convocação ME/EPP/7174",
    "Pregão temporariamente suspenso",
    "Em andamento - Análise de Proposta",
    "Em andamento - Lance",
    "Em andamento - Suspensão Administrativa",
    "Em andamento - Avisos",
    "Em andamento - Admissibilidade",
    "Em andamento - Aceitação/Habilitação",
    "Em andamento",
    "Agendado",
    "Agendado - Informações do Pregão",
    "Agendado - Aberto para Propostas",
    "Agendado - Encerrado para Propostas",
    "Agendado - Fechado para Propostas",
    "Realizado - Recurso/Contra-Razão",
    "Realizado - Aguardando Homologação",
    "ATA - Homologado",
    "Disputa encerrada",
    "Suspensa",
    "Fracassada",
    "Acolhimento de propostas",
    "Homologada",
    "Disputa suspensa",
    "Cancelada",
    "Abertura de propostas",
    "Propostas abertas",
    "Deserta",
    "Revogada",
    "Anulada",
    "Com recurso",
    "Em andamento - Julgamento/Habilitação",
    "Realizado - Recurso/Contrarrazão",
    "Realizado - Aguardando Adjudicação",
    "Encerrada"
  ];
  somType.forEach(som => {
    // if (!localStorage.getItem(som)) getSom(som);
    if (
      !AlertaVerbalStore[
      som
        .replace(/-/g, "_")
        .replace(" ", "_")
        .replace(/\//g, "_")
      ]
    )
      getSom(som.replace(/\//g, "-"));
    if (
      !AlertaVerbalFemininoStore[
      som
        .replace(/-/g, "_")
        .replace(" ", "_")
        .replace(/\//g, "_")
      ]
    )
      getSomFeminino(som.replace(/\//g, "-"));
  });
};

const getSom = async som => {
  try {
    const res = await axios.get(`/static/alerta/${som}.mp3`, {
      responseType: "arraybuffer"
    });
    AlertaVerbalStore[som.replace(/-/g, "_").replace(/\s/g, "_")] = res.data;
    // localStorage.setItem(som, ab2str(res.data));
  } catch (err) {
    console.log("err", err);
  }
};
const getSomFeminino = async som => {
  try {
    const res = await axios.get(`/static/alerta-feminino/${som}.mp3`, {
      responseType: "arraybuffer"
    });
    AlertaVerbalFemininoStore[som.replace(/-/g, "_").replace(/\s/g, "_")] =
      res.data;
    // localStorage.setItem(som, ab2str(res.data));
  } catch (err) {
    console.log("err", err);
  }
};

// export default AlertasSistema;

import React from 'react'
import { Container } from '../Style'
import CardEstados from './filtroEstados'
import CardPortais from './filtroPortais'
import FiltroTipoData from './filtroTipoData'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import CardPalavrasBuscaManual from "./filtroPalavraBuscaManual";
import FiltroStatus from "./filtroStatus";
import { FiltroDataSimples } from "./filtroDataSimples";


const FiltroDownload = () => {
  const { state, consultaDownload, showFiltro } = useFiltroEdital()


  return (
    <>
      {
        state.filtro &&
        <Container>
          <div style={{
            display: 'flex',
            marginTop: '50px',
            flexWrap: 'wrap',
            justifyContent: 'center',
            overflow: 'auto',
            height: '100%',
            paddingBottom: '50px'
          }}>

            <FiltroDataSimples />
            <CardEstados />
            <CardPortais status={true} />
            <FiltroStatus />
            <CardPalavrasBuscaManual />
            <FiltroTipoData />


            <div className="action-buttons">
              <button id="filtrar-editais" className="icon-scale-xs" onClick={() => consultaDownload(true)}>FILTRAR</button>
              <button id="fechar-modal-filtro-editais" className="icon-scale-xs" onClick={showFiltro}>FECHAR</button>
            </div>
          </div>

        </Container>
      }
    </>
  )
}

export default FiltroDownload

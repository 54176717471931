import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import StoreConfiguracoesGerais from "./StoreConfiguracoesGerais";
import { ToastSuccess, ToastError } from "./Funcoes/FuncoesGerais";

class StoreConfiguracaoCredenciais {
  // INFO VALIDAÇÃO
  @observable inputAdminPw = "";
  @observable adminValidation = false;
  @observable validarExcluir = false;

  // INFO CREDENCIAL
  @observable login = "";
  @observable password = "";
  @observable repeat_password = "";
  @observable fornecedor = "";
  @observable portal = 5;

  // ACTIONS
  @action popularCredencial = credencial => {
    if (credencial === "closeModal") return this.limparDadosCredencial();
    const { login, password, empresaId, portal } = credencial;
    this.login = login;
    this.password = password;
    this.fornecedor = empresaId;
    this.portal = portal;
  };

  @action validarAdmin = tipo => {
    if (tipo === "fecharModal") return (this.adminValidation = false);
    if (tipo === "excluirCredencial") {
      this.adminValidation = true;
      return (this.validarExcluir = true);
    }
    // this.usuarios_id = $("#usuarios_id").val();
    if (this.validarInputs(tipo)) {
      this.adminValidation = true;
    }
  };

  @action onChange = e => {
    const field = e.target.name;
    if (Number(this.portal) === 6 && field === "login")
      return (this.login =
        e.target.value[0] === "J" ? e.target.value : "J" + e.target.value);
    this[field] = e.target.value;
  };

  @action verificaAdmin = async (action, toggleModal, id) => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.inputAdminPw);

    try {
      await Api.post("/login/", login, {});
      this[action](toggleModal, id);
      this.adminValidation = false;
    } catch (err) {
      ToastError("Senha inválida!");
      console.log("err", err);
    } finally {
      this.inputAdminPw = "";
    }
  };

  @action adicionarCredencial = async (toggleModal, id) => {
    this.fornecedor = id;
    // const possuiModulo = MaquinaDeEstadosStore.getContrato(
    //   "editais",
    //   this.fornecedor
    // );

    let { portal, fornecedor, login, password } = this;
    login = portal === "6" ? login.slice(1) : login;
    const data = { portal, fornecedor, login, password, sync: true };
    try {
      const res = await Api.post("/credencial/", data);
      this.adicionarAoStore(res.data, id);
      ToastSuccess("Credencial cadastrada com sucesso!");
      this.limparDadosCredencial();
      toggleModal(false);
    } catch (err) {
      console.log("err", err);
      if (err.response.data.error) ToastError(err.response.data.error);
      else ToastError("Erro ao cadastrar nova credencial, tente mais tarde");
    }
  };

  adicionarAoStore = (credencial, empresaId) => {
    const { empresas } = MaquinaDeEstadosStore;
    MaquinaDeEstadosStore.empresas = empresas.map(empr =>
      empr.id === empresaId
        ? { ...empr, credenciais: [...empr.credenciais, { ...credencial, fornecedor_id: credencial.fornecedor }] }
        : empr
    );
    MaquinaDeEstadosStore.updateCredenciais();
    StoreConfiguracoesGerais.popularStore();
  };

  @action editarCredencial = async (toggleModal, empresaId) => {
    let { fornecedor, login, password, portal } = this;
    login = Number(portal) === 6 && login.startsWith('J') ? login.slice(1) : login;
    const data = {
      portal,
      fornecedor: fornecedor || MaquinaDeEstadosStore.getIdEmpresa(),
      login,
      password,
      sync: true
    };

    try {
      const res = await Api.post("/credencial/", data);
      ToastSuccess("Credencial editada com sucesso!");
      this.editarNoStore(res.data);
      MaquinaDeEstadosStore.updateCredenciais();
      this.limparDadosCredencial();
      toggleModal(false);
    } catch (err) {
      console.log("err", err);
      if (err.response.data.error) ToastError(err.response.data.error);
      else ToastError("Erro ao editar credencial, tente mais tarde");
    }
  };

  editarNoStore = credencial => {
    const { empresas } = MaquinaDeEstadosStore;
    MaquinaDeEstadosStore.empresas = empresas.map(empr => ({
      ...empr,
      credenciais: empr.credenciais.map(cr =>
        cr.id === credencial.id ? { ...cr, ...credencial } : cr
      )
    }));
    MaquinaDeEstadosStore.updateCredenciais();
    StoreConfiguracoesGerais.popularStore();
  };

  @action excluirCredencial = (toggleModal, id) => {
    Api.delete(`credencial/${id}/`).then(res => {
      ToastSuccess('Credencial excluída com sucesso!');
      this.excluirDoStore(id);
      this.limparDadosCredencial();
      toggleModal(false);
    }).catch(err => {
      console.log('err', err)
      ToastError('Não foi possível excluir a credencial')
    }).finally(() => {
      this.validarExcluir = false;
    })
  }

  excluirDoStore = id => {
    const { empresas } = MaquinaDeEstadosStore;
    MaquinaDeEstadosStore.empresas = empresas.map(empr => ({
      ...empr,
      credenciais: empr.credenciais.filter(cred => cred.id !== id)
    }));
    MaquinaDeEstadosStore.updateCredenciais();
    StoreConfiguracoesGerais.popularStore();
  };

  limparDadosCredencial = () => {
    this.login = "";
    this.password = "";
    this.repeat_password = "";
    this.fornecedor = "";
    this.portal = 5;
  };

  @action validarInputs = () => {
    if (!this.login) {
      ToastError("Preencha o campo de login");
      return false;
    }
    if (this.password !== this.repeat_password || this.password === "") {
      ToastError("Verifique os campos de senha");
      return false;
    }
    return true;
  };

  @action limparVariaveis = () => {
    this.inputAdminPw = "";
    this.adminValidation = false;
    this.validarExcluir = false;

    // INFO CREDENCIAL
    this.login = "";
    this.password = "";
    this.repeat_password = "";
    this.fornecedor = "";
    this.portal = 5;

    // Relacionado ao modal de sincronizar
    this.ModalSincronizar = false;
  };
}

const storeConfiguracaoCredenciais = new StoreConfiguracaoCredenciais();
export default storeConfiguracaoCredenciais;

import React from 'react';
import { format, } from 'date-fns';
import BadegRedondo from '../../../../Components/ReusableComponents/Badeg/badegRedondo';
import { handleBadge } from '../../../Prospection/Components/status';
import { Edit, Printer, DownloadCloud } from 'react-feather';
import BadgeStatus from '../../Components/BadgeStatus';
import ImprimirProposta from './ImprimirProposta';

const formattedDate = (data) => {
  return (format(new Date(data), 'dd/MM/yyyy'))
};


export const columns = [
  {
    name: 'PROPOSTA',
    selector: 'proposta',
    grow: 0,
    wrap: true,
  },
  {
    name: 'LICITAÇÃO',
    selector: 'licitacao',
    center: true,
    grow: 0,
    // style: {
    //   fontWeight: 'bold',
    //   marginRight: '9px',

    // },
  },
  {
    name: 'ORGÃO',
    selector: 'orgao',
    // sortable: true,
    grow: 2,
    wrap: true,
  },
  {
    name: 'DATA DISPUTA',
    selector: 'disputa',
    sortable: true,
    center: true,
    grow: 1,
    style: {
      fontWeight: 'bold',
    },
    cell: ({ disputa }) => {
      return (formattedDate(disputa))
    }
    // cell: formattedDate
  },
  {
    name: 'STATUS',
    selector: 'status',
    wrap: true,
    center: true,
    grow: 1,
    cell: ({ status }) => {
      return (<BadgeStatus status={status} />)
    }
  },
  {
    name: 'DATA ENVIO',
    selector: 'data_envio',
    // sortable: true,
    center: true,
    grow: 1,
    // style: {
    //   fontWeight: 'bold',
    //   marginRight: '9px',

    // },
    // cell: formattedDate
    cell: ({ data_envio }) => {
      return (formattedDate(data_envio))
    }
  },

  {
    name: 'IMPRIMIR',
    selector: 'id',
    wrap: true,
    center: true,
    grow: 0,
    cell: ({ id }) => {
      return (<ImprimirProposta id={id} />)
    }
  },
  {
    name: 'EXPORTAR',
    selector: 'id',
    wrap: true,
    center: true,
    grow: 0,
    cell: ({ id }) => {
      return (<BadegRedondo autoMargin icon={<DownloadCloud style={{ width: '20px' }} />} background={'#3db3b9'} onClick={() => console.log(id)} />)
    }
  },
];
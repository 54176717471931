import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import DetalhesEdital from './contexto/detalhes/DetalhesEdital'
import ItensEdital from './contexto/itens/itensEdital'
import { useKeyPress } from '../../../UsefulComponents/useKeyPress'

import * as S from './styles'

const Detalhes = inject(
  'DetalhesEditalStore',
  'MaquinaDeEstadosStore'
)(
  observer(({ DetalhesEditalStore, MaquinaDeEstadosStore }) => {
    const { getStatus } = MaquinaDeEstadosStore
    return (
      <DetalhesComStore
        getStatus={getStatus}
        props={DetalhesEditalStore}
        {...DetalhesEditalStore}
      />
    )
  })
)

const DetalhesComStore = ({ props, getStatus }) => {
  const { id, consultarItens } = props
  const [active, setActtve] = useState(true)

  const TabKey = useKeyPress('Tab')

  useEffect(() => {
    if (TabKey) setActtve(!active)
  }, [TabKey])

  const consultarApiItens = () => {
    consultarItens(id)
    setActtve(!active)
  }
  return (
    <S.DetalhesContainer>
      <div className="abas">
        <S.Tab active={active} onClick={() => setActtve(!active)}>
          LICITAÇÃO
        </S.Tab>
        <S.Tab active={!active} onClick={() => consultarApiItens()}>
          ITENS
        </S.Tab>
      </div>

      {active ? <DetalhesEdital props={props} /> : <ItensEdital {...props} />}
    </S.DetalhesContainer>
  )
}

export default Detalhes

import React, { useState } from 'react'
import { CardBody, CardFiltroSmall, CardHeard, CardTitulo } from './styles'
import { Bookmark, Code, User } from 'react-feather'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const FiltroCodigo = () => {
  const { onChangePregao, state } = useFiltroEdital()
  return (
    <CardFiltroSmall>
      <CardHeard>
        <Code />
        <CardTitulo>
          <div>PREGÃO</div>
          <p>Filtrar por nome ou código do pregão</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <TextField
          id="standard-multiline-flexible"
          label="Código do pregão"
          variant="outlined"
          required
          margin="normal"
          // placeholder="Código do pregão"
          value={state.pregao}
          fullWidth
          onChange={(e) => onChangePregao(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Code />
              </InputAdornment>
            ),
          }}
        >
        </TextField>

      </CardBody>
    </CardFiltroSmall>
  )
}

export default FiltroCodigo

import { observable, action } from 'mobx'

import Api from '../../../../Stores/Conexao/conexao'
import { tratarDadosEditais } from '../../Components/tratarDadosEditais'
import MaquinaDeEstadosStore from '../../../../Stores/MaquinaDeEstadosStore'

class BoletimStore {
  @observable isFetching = false
  @observable perfis = []
  @observable selectedPerfil = 0
  @observable editais = []
  @observable total_paginas = 0
  @observable total_registros = 0
  @observable ordenacao = 'data_disputa'
  @observable crescente = true

  @action changeEditais = (id, status) =>
    (this.editais = this.editais.map(ed =>
      ed.id === id ? { ...ed, status } : ed
    ))

  @observable portal = {
    portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
    changePortais: portais => (this.portal.portais = portais)
  }

  @observable filtros = {
    dmin: new Date().toLocaleDateString('pt-br'),
    dmax: new Date().toLocaleDateString('pt-br'),
    qualData: 'inicial',
    resetDate: () => {
      this.filtros.dmin = new Date().toLocaleDateString('pt-br')
      this.filtros.dmax = new Date().toLocaleDateString('pt-br')
    },
    changeDate: range => {
      const { qualData } = this.filtros

      this.filtros.dmin = new Date(range.startDate).toLocaleDateString('pt-br')
      this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

      return (this.filtros.qualData =
        qualData === 'inicial' ? 'final' : 'inicial')
    },
    onInputChange: e => {
      this.filtros[e.target.name] = e.target.value
    }
  }

  @observable pagination = {
    page: 1,
    changePage: page => (this.pagination.page = page)
  }

  getData = (id, page) => {
    const { dmin, dmax } = this.filtros
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmax=${dmax}&dmin=${dmin}`
    query += `&pagina=${page || this.pagination.page}`
    query += `&status=todos`
    query += `&perfil=${id}`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    return query
  }

  getDataFiltro = data => {
    const {
      date: { dmin, dmax },
      estados,
      palavra,
      modalidade,
      tipoDate,
      portais
    } = data
    this.filtros.dmin = dmin
    this.filtros.dmax = dmax
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmax=${dmax}&dmin=${dmin}`
    query += `&pagina=${this.pagination.page}`
    query += `&status=todos`
    query += `&tipo_data=${tipoDate}`
    query += `&portal=${portais}`
    query += `&estados=${estados}`
    query += `&palavras=${palavra}`
    query += `&modalidade=${modalidade}`
    query += `&perfil=${
      this.selectedPerfil !== undefined
        ? this.selectedPerfil.id
        : this.perfis[0].id
    }`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    return query
  }

  @action fetchEditaisNovo = async data => {
    const param = this.getDataFiltro(data)
    this.isFetching = true
    try {
      const res = await Api.get(param)
      const {
        config_perfis,
        editais,
        // palavras_config,
        // palavras_usadas,
        // total_paginas,
        total_registros
      } = res.data
      this.perfis = config_perfis
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  @action fetchEditais = async (perfil_id = null, perfil) => {
    this.selectedPerfil = perfil
    this.isFetching = true
    if (perfil_id) this.pagination.changePage(1)
    try {
      const res = await Api.get(this.getData(perfil_id))
      const {
        config_perfis,
        editais,
        // palavras_config,
        // palavras_usadas,
        // total_paginas,
        total_registros
      } = res.data
      this.perfis = config_perfis
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  fetchMore = async page => {
    try {
      const res = await Api.get(this.getData(null, page))
      return res.data.editais
    } catch (err) {
      console.log('err', err)
    }
  }

  trocouDeEmpresa = () => {
    this.limparVariaveis()
    this.fetchEditais()
  }

  limparVariaveis = () => {
    this.isFetching = false

    this.perfis = []
    this.editais = []
    this.total_paginas = 0
    this.total_registros = 0

    this.portal = {
      portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
      changePortais: portais => (this.portal.portais = portais)
    }

    this.filtros = {
      dmin: new Date().toLocaleDateString('pt-br'),
      dmax: new Date().toLocaleDateString('pt-br'),
      qualData: 'inicial',
      resetDate: () => {
        this.filtros.dmin = new Date().toLocaleDateString('pt-br')
        this.filtros.dmax = new Date().toLocaleDateString('pt-br')
      },
      changeDate: range => {
        const { qualData } = this.filtros

        this.filtros.dmin = new Date(range.startDate).toLocaleDateString(
          'pt-br'
        )
        this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

        return (this.filtros.qualData =
          qualData === 'inicial' ? 'final' : 'inicial')
      },
      onInputChange: e => {
        this.filtros[e.target.name] = e.target.value
      }
    }

    this.pagination = {
      page: 1,
      changePage: page => (this.pagination.page = page)
    }
  }
}

export default new BoletimStore()
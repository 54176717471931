import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import axios from "axios";
import { AlertaVerde, AlertaError } from "./Funcoes/FuncoesGerais";
import { ReactTooltip } from "../globalStyles";

class StoreProposta {
  @observable statusMessageSpreadsheet = "";
  @observable itensCadastrados = [];
  @observable nenhumItemEnviado = false;
  @observable envioHabilitado = true;

  // Ashamed of this.
  @observable proposalDeleted = false;
  @observable deletingItem = false;
  @observable interval = null;

  // Modais
  @observable modalLastItem = false;
  @observable modalDeleteProposal = false;

  @action fetchNenhum = id => {
    const url = "/portal/itens_edital/";
    const data = { id };
    Api.post(url, data).then(res => {
      this.itensCadastrados = res.data.itens_edital.map(it => {
        return { ...it, codigo: Number(it.codigo) };
      });
    });
    this.nenhumItemEnviado = false;
  };

  @action changeItensCadastrados = itens => (this.itensCadastrados = itens);

  @action changeSpreadsheetStatus = status =>
    (this.statusMessageSpreadsheet = status);

  @action changeEnvioHabilitado = () => {
    this.envioHabilitado = !this.envioHabilitado;
    ReactTooltip.hide();
  };

  @action habilitarEnvio = status => (this.envioHabilitado = status);

  @action fetchItens = id => {
    Api.get(`/portal/itens_cadastrados/?edital_selecionado=${id}`)
      .then(res => {
        if (typeof res.data.message === "string")
          this.statusMessageSpreadsheet = res.data.message;
        else {
          if (res.data.message.length) {
            this.envioHabilitado = false;
          } else this.nenhumItemEnviado = true;
          this.itensCadastrados = res.data.message;
        }
      })
      .catch(err => {
        console.log("err", err);
      });
  };

  @action deleteItem = (id, lastItem) => {
    this.deletingItem = id;
    axios
      .delete(`${global.url}portal/delete_item_proposal/${id}`)
      .then(res => {
        AlertaVerde(res.data.message, 1000);
        if (lastItem) {
          this.proposalDeleted = true;
          this.envioHabilitado = true;
          this.nenhumItemEnviado = true;
        }
        this.itensCadastrados = this.itensCadastrados.filter(
          it => it.id !== id
        );
      })
      .catch(err => {
        console.log("err", err);
        AlertaError("Erro ao excluir item da proposta!", 1000);
      })
      .finally(() => {
        ReactTooltip.hide();
        this.deletingItem = false;
      });
  };

  @action deleteProposal = id => {
    axios
      .delete(`${global.url}portal/delete_proposal/${id}`)
      .then(res => {
        AlertaVerde(res.data.message, 1000);
        this.itensCadastrados = [];
        this.proposalDeleted = true;
        this.envioHabilitado = true;
        this.nenhumItemEnviado = true;
        this.statusMessageSpreadsheet = "";
      })
      .catch(err => {
        console.log("err", err);
        AlertaError("Erro ao excluir proposta!", 1000);
      })
      .finally(() => ReactTooltip.hide());
  };

  @action toggleModal = modal => {
    this[modal] = !this[modal];
  };

  @action handleInterval = id => {
    this.interval = setInterval(() => {
      Api.get(`/portal/itens_cadastrados/?edital_selecionado=${id}`)
        .then(res => {
          if (Array.isArray(res.data.message) && res.data.message.length === 0)
            return;
          else {
            clearInterval(this.interval);
            if (typeof res.data.message === "string")
              this.statusMessageSpreadsheet = res.data.message;
            else {
              if (res.data.message.length) this.envioHabilitado = false;
              this.itensCadastrados = res.data.message;
            }
          }
        })
        .catch(err => {
          console.log("err", err);
        });
    }, 15 * 1000);
  };

  @action changeProposalDeleted = () =>
    (this.proposalDeleted = !this.proposalDeleted);

  @action limparVariaveis = () => {
    this.isActive = false;
    this.licitacoes = [];
  };
}

export default new StoreProposta();


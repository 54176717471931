import styled from 'styled-components'

export const OccupationAreas = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90%;
`

export const TableItem = styled.div`
  width: 48%;
  height: 98%;
  background: #fff;
  box-shadow: 1px 1px 5px #00000075;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: initial;
  position: relative;
  margin: 0 1%;

  .header {
    display: flex;
    padding: 10px;
    border-bottom: 2px solid gray;
    background: #3a566e;
    color: #fff !important;
    border-radius: 8px 8px 0 0;
    height: 40px !important;
  }

  .body-table {
    display: block;
    max-height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;

    div:nth-child(odd) {
      background: #f4f6f7;
    }

    div:nth-child(even) {
      background: #ffffff;
    }

    .list-item {
      width: 100%;
      height: auto;
      display: flex;
      margin: 0;
      flex-wrap: wrap;
      cursor: pointer;
      align-items: baseline;
      justify-content: space-between;
      padding: 0 2%;

      &:hover {
        background: #dee1e2;
      }

      p {
        margin-bottom: 0;
      }

      .name {
        max-width: 90%;
      }
    }

    .list-area-item {
      display: flex;
      margin: 0;
      flex-wrap: nowrap;
      cursor: pointer;
      align-items: baseline;
      padding: 0 2%;
      width: 100%;
      height: auto;

      p {
        margin-bottom: 0;
      }

      .area-name {
        max-width: 90%;
        margin-left: 2%;
      }
    }
  }

  /* @media (max-height: 768px) {
    height: 80%;
  }

  @media (man-width: 800px) {
    height: 68%;
    margin: -21px 1%;
    font-size: 12px;
  } */
`

export const GroupItem = styled.div`
  width: 100%;
  cursor: pointer !important;
  position: relative;
  color: #3A566E;
  background: #fff;
  padding: 2px 5px;
  display: flex;
  justify-content: space-between;

  .count {
    position: absolute;
    right: 6%;
    top: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8bba3f !important;
    color: #fff !important;
    box-shadow: 1px 1px 3px #000000ba;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
  }
`

export const AreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 98%;
  width: 48%;
  animation: 500ms fadeIn ease-in-out forwards;
  box-shadow: 1px 1px 5px #00000057;
  border-radius: 8px;
  margin: 0 1%;

  .header {
    display: flex;
    height: 40px !important;
    padding: 10px;
    border-bottom: 2px solid gray;
    background: #3a566e;
    color: #fff !important;
    border-radius: 8px 8px 0 0;
  }

  .body-item {
    display: block;
    max-height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
  }

  /* @media (max-width: 1366px) {
    height: 80%;
  }

  @media (max-width: 800px) {
    height: 68%;
    margin: -21px 1%;
    font-size: 12px;
  } */
`

export const AreaItem = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer !important;
  align-items: baseline;
  transition: 200ms ease-in-out;
  color: ${({ selected }) => (selected ? '#fff' : '#3a566e')};
  background: ${({ selected }) => (selected ? '#8BBA3F' : '#fff')};
  padding: 2px 5px;
  box-shadow: ${({ selected }) =>
    selected ? 'inset 1px 1px 5px #4e4e4e54' : 'none'};

  :nth-child(odd) {
    background: ${({ selected }) => (selected ? '#8BBA3F' : '#f4f6f7')};
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 10px;
    color: #fff;
    width: 165px;
    height: 24px;
    border: none;
    box-shadow: 1px 1px 4px #000000a3;

    :disabled {
      opacity: 0.5;
    }
  }

  button.limpar {
    background: #da1c1ad6;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  button.salvar {
    background: #14b123;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  @media (max-width: 1024px) {
    height: 15%;
  }

  @media (max-width: 800px) {
    font-size: 9px;
    height: 25%;
    button {
      width: 150px;
      height: 21px;
    }
  }
`
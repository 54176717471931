import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { SelectCompany } from "../../UsefulComponents/Inputs";
import { useFiltroEdital } from "../../Pages/Prospection/Filter/context/FiltroEditaisContext";
import { useHistory } from "react-router-dom";

@inject("MenuStores", "ModalSincronizarStores", "MaquinaDeEstadosStore")
@observer
class MenuEmpresas extends Component {
  atualizaEmpresa = (value) => {
    this.props.MenuStores.id_fornecedor = value;
    this.props.MenuStores.mudou_empresa(value);
  };

  render() {
    const { empresas } = this.props.MenuStores;
    const { id_fornecedor: fornecedor } = this.props.MaquinaDeEstadosStore;
    return (
      <MenuEmpresasComStore
        fornecedor={fornecedor}
        atualizaEmpresa={this.atualizaEmpresa}
        empresas={empresas}
      />
    )
  }
}


const MenuEmpresasComStore = ({
  fornecedor,
  atualizaEmpresa,
  empresas
}) => {
  const { getInitialProfileData, openFiltro } = useFiltroEdital();
  const { push } = useHistory();

  const handleChange = async (e) => {
    if (window.location.pathname.includes('/prospeccao/pesquisa')) {
      push('/prospeccao/pesquisa/0');
    }
    openFiltro(!window.location.pathname.includes('/prospeccao/pesquisa'));

    atualizaEmpresa(e.target.value);
    getInitialProfileData(e.target.value);
  }

  return (
    <SelectCompany
      icon={<FontAwesomeIcon icon="retweet" />}
      id="mudar-empresa"
      width="400px"
      value={fornecedor}
      onChange={handleChange}
    >
      {empresas.map(item => {
        return (
          <option key={item.id} value={item.id}>
            {item.razao_social}
          </option>
        );
      })}
    </SelectCompany>
  )
}

export default MenuEmpresas;

import React from 'react'
import { inject, observer } from 'mobx-react'
import ReactDOM from 'react-dom'

import Navegacao from './Navegacao'
import Detalhes from './Detalhes'
import Spinner from '../../../UsefulComponents/Spinner'
import ModalQualificar from './modalConfirmacao/ModalQualificar'
import ModalReprovar from './modalConfirmacao/ModalReprovar'
import ModalAprovar from './modalConfirmacao/ModalAprovar'

import * as S from './styles'

const ModalDetalhes = inject('DetalhesEditalStore')(
  observer(({ DetalhesEditalStore }) => {
    const { id, toggleModal, fetchingMore } = DetalhesEditalStore
    if (!id) return null
    return (
      <ModalDetalhesComStore
        id={id}
        toggleModal={toggleModal}
        fetching={fetchingMore}
      />
    )
  })
)

const ModalDetalhesComStore = ({ id, toggleModal, fetching }) => {
  return ReactDOM.createPortal(
    <S.Container>
      <Navegacao />
      {fetching ? <Spinner /> : <Detalhes />}
      <ModalQualificar />
      <ModalReprovar />
      <ModalAprovar />
    </S.Container>,
    document.querySelector('body')
  )
}

export default ModalDetalhes

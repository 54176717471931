export const NOTIFICACOA = "notificacao";
export const MON_EDITAIS = "editaisselecionados";
export const MON_VERDE = "monitoramento_verde";
export const MON_VERMELHO = "monitoramento_vermelho";
export const MON_OCORRENCIA = "ocorrenciasdodia";
export const MON_LICT_DIA = "licitacoes_dia";
export const MONITORAMENTO = "monitoramento";

export const TOKEN_FIREBASE = "tokenfirebase";

export const setNotificacoes = ({
  editaisselecionados,
  monitoramento_verde,
  monitoramento_vermelho,
  ocorrenciasdodia,
  licitacoes_dia
}) => {
  let defaultNotificacoes = {
    notificacoes: {
      editaisselecionados: editaisselecionados,
      monitoramento_verde: monitoramento_verde,
      monitoramento_vermelho: monitoramento_vermelho,
      ocorrenciasdodia: ocorrenciasdodia,
      licitacoes_dia: licitacoes_dia
    }
  };
  localStorage.setItem(NOTIFICACOA, JSON.stringify(defaultNotificacoes));
};

export const setTokenFirebase = token => {
  localStorage.setItem(TOKEN_FIREBASE, token);
};
export const getTokenFirebase = () => localStorage.getItem(TOKEN_FIREBASE);

export const setMonitoramento = type => {
  localStorage.setItem(MONITORAMENTO, type);
};
export const getMonitoramento = () => localStorage.getItem(MONITORAMENTO);

export const getNotificacoes = type => {
  const data = JSON.parse(localStorage.getItem(NOTIFICACOA));
  if (data) {
    return data["notificacoes"][type];
  }
};

import DetalhesEditalStore from '../../../Components/Modules/ModalDetalhes/store';

export const sortEditais = (column, sortDirection, data) => {
  const { selector } = column;
  const { getStore } = DetalhesEditalStore;

  const crescente = sortDirection === 'asc' ? false : true;
  let ordenacao;
  if (selector === 'disputa') ordenacao = 'data_disputa';
  else ordenacao = selector;

  getStore().ordenacao = ordenacao;
  getStore().crescente = crescente;
  getStore().pagination.changePage(1);

  switch (window.location.pathname) {
    case "/prospeccao/qualificados":
      getStore().fetchEditais(data);
      break;
    case "/prospeccao/aprovados":
      getStore().fetchEditais(data);
      break;
    case "/prospeccao/reprovados":
      getStore().fetchEditais(data);
      break;
    default:
      getStore().fetchEditaisNovo(data);
  }
}
import React from 'react'

import { approvals, qualifications, reprovals } from './utils'
import { useSetting } from '../../Context/index.js'
import { SelectInput } from '../../../../../UsefulComponents/Inputs'
import { AlertaVermelho } from '../../../../../Stores/Funcoes/FuncoesGerais'

import * as S from './style'

const CustomFormat = () => {
  const {
    togglesQualifiedFormat,
    toggleApprovedFormat,
    toggleReprovedFormat,
    qualifiedFormat,
    approvedFormat,
    reprovedFormat,initialReproveFormat,
    changeThereWasChange
  } = useSetting()

  const changeQualified = () => {
    let { value: priorityValue } = document.getElementById('select-priority')
    let { value: userValue } = document.getElementById('select-user')

    priorityValue = priorityValue === 'true'
    userValue = userValue === 'true'

    togglesQualifiedFormat([true, priorityValue, userValue])
    changeThereWasChange(true)
  }

  const changeApproved = () => {
    let { value: qualifiedApprovedValue } = document.getElementById(
      'approved-qualified'
    )
    let { value: priorityApprovedValue } = document.getElementById(
      'approved-priority'
    )
    let { value: userApprovedValue } = document.getElementById('approved-user')

    qualifiedApprovedValue = qualifiedApprovedValue === 'true'
    priorityApprovedValue = priorityApprovedValue === 'true'
    userApprovedValue = userApprovedValue === 'true'

    !qualifiedApprovedValue && (priorityApprovedValue || userApprovedValue)
      ? AlertaVermelho(
          'Desabilitando aprovação o restante não poderá ser habilitado'
        ) && toggleApprovedFormat([false, false, false])
      : toggleApprovedFormat([
          qualifiedApprovedValue,
          priorityApprovedValue,
          userApprovedValue
        ])
    changeThereWasChange(true)
  }

  const changeReproved = () => {
    let { value: reasonReportCard } = document.getElementById(
      'reason-report-card'
    )
    let { value: reasonQualified } = document.getElementById('reason-qualified')
    let { value: reasonApproved } = document.getElementById('reason-approved')
    reasonReportCard = reasonReportCard === 'true'
    reasonQualified = reasonQualified === 'true'
    reasonApproved = reasonApproved === 'true'

    toggleReprovedFormat([reasonReportCard, reasonQualified, reasonApproved])

    changeThereWasChange(true)
  }

  return (
    <S.Format>
      <S.Table>
        <div className="header">
          <div className="title">Qualificação</div>
        </div>
        <div className="body">
          <div className="item">
            <p>QUALIFICADOS</p>
            <SelectInput
              id="select-qualified"
              width="100%"
              icon=" "
              bgColor="#fff"
            >
              <option key={0} name="materiais" value={true}>
                Habilitado
              </option>
            </SelectInput>
          </div>
          {qualifications.map(item => (
            <div className="item" key={item.id}>
              <p>{item.name}</p>
              <SelectInput
                id={item.byId}
                width="100%"
                icon={item.icon}
                bgColor="#fff"
                value={qualifiedFormat[item.id]}
                onChange={changeQualified}
              >
                <option key={0} name="priority" value={true}>
                  Habilitado
                </option>
                <option key={1} name="priority" value={false}>
                  Desabilitado
                </option>
              </SelectInput>
            </div>
          ))}
        </div>
      </S.Table>
      <S.Table>
        <div className="header">
          <div className="title">Aprovação</div>
        </div>
        <div className="body">
          {approvals.map(item => (
            <div className="item" key={item.id}>
              <p>{item.name}</p>
              <SelectInput
                id={item.byId}
                width="100%"
                icon={item.icon}
                value={approvedFormat[item.id]}
                bgColor="#fff"
                onChange={changeApproved}
              >
                <option key={0} name="approvals" value={true}>
                  Habilitado
                </option>
                <option key={1} name="approvals" value={false}>
                  Desabilitado
                </option>
              </SelectInput>
            </div>
          ))}
        </div>
      </S.Table>
      <S.Table>
        <div className="header">
          <div className="title">Reprovação</div>
        </div>
        <div className="body">
          {reprovals.map(item => (
            <div className="item" key={item.id}>
              <p>{item.name}</p>
              <SelectInput
                id={item.byId}
                width="100%"
                icon={item.icon}
                value={reprovedFormat[item.id]}
                bgColor="#fff"
                onChange={changeReproved}
              >
                <option key={0} name="reprovals" value={true}>
                  Habilitado
                </option>
                <option key={1} name="reprovals" value={false}>
                  Desabilitado
                </option>
              </SelectInput>
            </div>
          ))}
        </div>
      </S.Table>
    </S.Format>
  )
}

export default CustomFormat

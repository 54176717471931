import { observable, action } from "mobx";
import $ from "jquery";
import ModalLoadingStore from "./ModalLoadingStore";
import Api from "./Conexao/conexao";
import { statusConexao } from "../StatusConexao/StatusConexao";
import StatusConexaoStores from "./StatusConexaoStores";
import {
  AlertaInfo,
  AlertaSuccess,
  AlertaVerde,
  AlertaVermelho
} from "./Funcoes/FuncoesGerais";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import LoginStores from "./LoginStores";

class BuscaDeEditaisLicitacaoStores {
  @observable
  dataInicial = new Date().toLocaleDateString("pt-br");

  @observable
  dataFinal = new Date().toLocaleDateString("pt-br");

  @observable
  qualData = "inicial";

  @observable
  editais = null;

  @observable
  currentEditais = [];

  @observable
  itens_edital = [];

  @observable
  estado = "TODOS";

  @observable
  palavra = "";

  @observable
  showing = false;

  @observable pagina_atual = 0;

  // MATERIAIS/SERVIÇOS
  @observable tipo_item = ["M", "S"];

  @observable
  flagConsultar = true;

  @observable
  pesquisaDataType = "abertura";

  @observable
  statusDataType = true;

  @observable
  editais_total = 0;

  @observable
  styleData = "abertura";

  @observable
  licitacao = "";

  @observable
  comprador = "";

  @observable
  ordenar = "disputa";

  @observable
  filtroMe = false;

  // PORTAIS
  @observable
  todosPortais = [
    { nome: "ComprasNet", id: 5 },
    { nome: "Licitações-e", id: 6 },
    { nome: "Compras RS", id: 12 },
    { nome: "Compras Banrisul", id: 13 },
    { nome: "Portal de Compras Vitória", id: 15 },
    { nome: "Portal de Compras Natal", id: 17 },
    { nome: "Portal FIESC SESI ", id: 18 },
    { nome: "Portal de Compras Amazonas ", id: 129 },
    { nome: "Portal de Compras Paraíba", id: 130 },
    { nome: "Portal ComprasNet Goiânia", id: 131 },
    { nome: "Portal de Compras Empro", id: 137 }
  ];

  @observable
  portal = this.todosPortais.map(p => p.id);

  @action changeMaterialServico = ({ target: { value } }) => {
    this.tipo_item = this.tipo_item.includes(value)
      ? this.tipo_item.filter(el => el !== value)
      : [...this.tipo_item, value];
  };

  @action
  sincronizar_Licit_E = id => {
    this.editais = this.editais.map(item =>
      id === item.id ? { ...item, sincronizado: true } : item
    );

    Api.post("licitacoes/cadastrar_licitacao_manual/", {
      id_edital: id,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      user_pk: LoginStores.user_id
        ? LoginStores.user_id
        : MaquinaDeEstadosStore.getIdUser()
    })
      .then(response => {
        AlertaSuccess("Edital adicinado as licitações com sucesso!");
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
      });
  };

  @action
  changePortal = () => (this.portal = $("#portais-select").val());

  loadDataLicitacao = () => {
    if (!this.tipo_item.length) this.tipo_item = ["M", "S"];

    return {
      tipo: this.pesquisaDataType,
      pagina: this.pagina_atual,
      dt_inicio: this.dataInicial,
      dt_fim: this.dataFinal,
      licitacao: this.licitacao,
      comprador: this.comprador,
      ordenar: this.ordenar,
      empresa_me: this.filtroMe,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      portal: this.portal,
      tipo_item: this.tipo_item
    };
  };

  @action
  handleStatusMe = ({ target: { value } }) => {
    this.filtroMe = !this.filtroMe;
  };

  @action
  handleOrdenacao = () => {
    this.ordenar = this.ordenar === "disputa" ? "abertura" : "disputa";
    this.pesquisa_pregao();
  };

  @action
  pesquisa_pregao = () => {
    if (this.licitacao !== "" || this.comprador !== "") {
      this.styleData = this.pesquisaDataType;
      this.showing = false;
      this.flagConsultar = true;
      this.ChecksCuurentPage();
      ModalLoadingStore.handleOpenModal();

      Api.post("portal/pesquisa_licitacao_alterado/", this.loadDataLicitacao())
        .then(res => {
          this.editais = res.data.editais;
          this.editais_total = res.data.total_registros;
          this.editais = res.data.editais;
        })
        .catch(error => {
          console.log("err", error);
          AlertaVermelho("Erro Ao Carregar os Editais");
        })
        .finally(() => {
          ModalLoadingStore.handleCloseModal();
        });
    } else {
      AlertaInfo("Os campos de pesquisa não podem estar em branco");
    }
  };

  @action
  limparFiltros = () => {
    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");
    this.editais = null;
    this.currentEditais = [];
    this.itens_edital = [];
    this.estado = "TODOS";
    this.palavra = "";
    this.showing = false;
    this.pagina_atual = 0;
    this.flagConsultar = true;
    this.pesquisaDataType = "abertura";
    this.statusDataType = true;
    this.editais_total = 0;
    this.styleData = "abertura";
    this.licitacao = "";
    this.comprador = "";
    this.ordenar = "disputa";
    this.filtroMe = false;
    this.tipo_item = ["M", "S"];
  };

  @action
  handleStatusPesquisa = ({ target: { value } }) => {
    this.pesquisaDataType = value;
    this.statusDataType = value === "abertura";
    this.pagina_atual = 1;
    this.pesquisa_pregao(1);
  };

  @action
  handleSelect = range => {
    this.dataInicial = new Date(range.startDate).toLocaleDateString("pt-br");
    this.dataFinal = new Date(range.endDate).toLocaleDateString("pt-br");
    if (this.qualData === "final") setTimeout(this.fecharDate, 250);
    else this.qualData = "final";
  };

  @action
  abreDate = () => {
    this.showing = true;
  };

  @action
  fecharDate = () => {
    this.showing = false;
    if (this.qualData === "final") this.qualData = "inicial";
  };

  @action
  onPageChanged = data => {
    const { currentPage } = data;
    if (this.pagina_atual !== currentPage) {
      this.pagina_atual = currentPage;
      this.pesquisa_pregao();
    }
  };

  @action
  ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action
  onChange = evento => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
  };

  @action
  handleConsulta = () => {
    this.pesquisa_pregao();
  };

  @action
  favoritar = id => {
    const data = new FormData();
    data.set("codigo_edital", id);
    data.set("id_fornecedor", MaquinaDeEstadosStore.getIdEmpresa());
    Api.post("portal/inserir_busca_edital_favorito/", data, {})
      .then(response => {
        if (response.data.resposta === "SIM") {
          AlertaVerde("Adicionado aos favoritos com sucesso!");
        } else {
          AlertaVermelho("Não foi possível adicionar o edital aos favoritos.");
        }
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho("Erro Ao Adicionar Edital Para os Favoritos!");
      });
  };

  @action
  desfavoritar(id) {
    Api.post("portal/tirar_favorito/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        AlertaInfo("Removido dos favoritos");
        this.handleConsulta();
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho("Não foi possível desfavoritar o edital");
      });
  }

  @action limparVariaveis = () => {
    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");
    this.qualData = "inicial";
    this.editais = null;
    this.currentEditais = [];
    this.itens_edital = [];
    this.estado = "TODOS";
    this.palavra = "";
    this.showing = false;
    this.pagina_atual = 0;
    this.flagConsultar = true;
    this.pesquisaDataType = "abertura";
    this.statusDataType = true;
    this.editais_total = 0;
    this.styleData = "abertura";
    this.licitacao = "";
    this.comprador = "";
    this.ordenar = "disputa";
    this.filtroMe = false;
    // MATERIAIS/SERVIÇOS
    this.tipo_item = ["M", "S"];
    // PORTAIS
    this.todosPortais = [
      { nome: "ComprasNet", id: 5 },
      { nome: "Licitações-e", id: 6 },
      { nome: "Compras RS", id: 12 },
      { nome: "Compras Banrisul", id: 13 },
      { nome: "Portal de Compras Vitória", id: 15 },
      { nome: "Portal de Compras Natal", id: 17 },
      { nome: "Portal FIESC SESI ", id: 18 },
      { nome: "Portal de Compras Amazonas ", id: 129 },
      { nome: "Portal de Compras Paraíba", id: 130 },
      { nome: "Portal ComprasNet Goiânia", id: 131 },
      { nome: "Portal de Compras Empro", id: 137 }
    ];
  };
}

const buscaDeEditaisLicitacaoStores = new BuscaDeEditaisLicitacaoStores();
export default buscaDeEditaisLicitacaoStores;

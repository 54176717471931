import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import ModalLoadingStore from "./ModalLoadingStore";
import { AlertaVermelho, AlertaVerde } from "./Funcoes/FuncoesGerais";

class ConfiguracoesGeraisStores {
  @observable
  credenciais = null;
  @observable
  credenciaisPropposta = [];
  @observable
  empresas = [];
  @observable
  empresas_id = [];
  @observable
  portais = [];
  @observable
  usuarios = [];
  @observable
  users_id = [];
  @observable
  username = "";
  @observable
  email_cobranca = "";
  @observable
  first_name = "";
  @observable
  last_name = "";
  @observable
  phone = "";
  @observable
  permissao = "analista";
  @observable
  empresas_cliente = [];
  @observable
  usuario = [];
  @observable
  senha = false;
  @observable
  senha_visivel = "";
  @observable
  email_usuario_admin = "";
  @observable
  icon = "eye-slash";
  @observable
  cor = "#5F9EA0";
  @observable
  changePassword = false;
  @observable
  oldPassword = "";
  @observable
  newPassword = "";
  @observable
  repeatPassword = "";

  @action
  newEmpresas = id => {
    const nomeFantasia = this.empresas.filter(empresa => {
      return empresa.id === id;
    })[0].nome_fantasia;

    const newCredenciais = this.credenciais.filter(credenciais => {
      return credenciais.fornecedor.nome_fantasia === nomeFantasia;
    });

    const newUsuario = this.usuarios.filter(user => {
      return Number(user.id) === Number(MaquinaDeEstadosStore.getIdUser());
    })[0].email_cobranca;

    this.credenciaisPropposta = newCredenciais;
    this.email_usuario_admin = newUsuario;
  };

  @action
  consulta_api = async () => {
    ModalLoadingStore.handleOpenModal();
    try {
      // const res = await Api.get("geral/");
      const res = await Api.get("geral_novo/");
      const { empresas, credenciais, usuarios, portais } = res.data;
      this.usuarios = usuarios;
      this.credenciais = credenciais;
      this.empresas = empresas;
      this.portais = portais;
    } catch (err) {
      console.log("err", err.response.data);
      AlertaVermelho("Não foi possível carregar suas configurações");
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action
  busca_usuarios = async id => {
    // ModalLoadingStore.handleOpenModal();
    const empresa_id = id;
    if (this.users_id.find(el => el.fornecedor === empresa_id)) return;
    Api.get("get_empresa/" + empresa_id + "/")
      .then(res => {
        const {
          fornecedor: { id },
          usuarios
        } = res.data;
        this.users_id = [
          ...this.users_id,
          { fornecedor: id, users: usuarios.map(us => us.id) }
        ];
      })
      .catch(err => console.log("err", err));
  };

  @action
  busca_empresa = async id => {
    if (this.empresas_id.find(el => el.uid === id)) return;
    try {
      const res = await Api.get("get_usuario/" + id + "/");
      const {
        usuarios: { id: uid },
        empresas
      } = res.data;
      this.empresas_id = [
        ...this.empresas_id,
        { uid, empresas: empresas.map(emp => emp.id) }
      ];
    } catch (err) {
      console.log("err", err);
    }
  };

  @action
  redefinirSenha = async toggleModal => {
    const data = new FormData();

    data.set("old_password", this.oldPassword);
    data.set("new_password1", this.newPassword);
    data.set("new_password2", this.repeatPassword);

    let res = await Api.post("/password_change/", data, {});
    res = res.data;
    if (res.sucess) {
      AlertaVerde(res.sucess);
      this.changePassword = false;
      toggleModal(false);
      this.newPassword = "";
      this.repeatPassword = "";
    } else if (res.error && res.error.old_password)
      AlertaVermelho(res.error.old_password[0]);
    else AlertaVermelho("Erro no catch");
  };

  @action
  toggleRedefinir = () => (this.changePassword = !this.changePassword);

  @action
  resetPasswordFileds = () => {
    this.oldPassword = "";
    this.newPassword = "";
    this.repeatPassword = "";
  };

  @action
  onChangeRedefinir = e => {
    const { name, id, value } = e.target;
    const campo = name || id;
    this[campo] = value;
  };

  @action limparVariaveis = () => {
    this.credenciais = null;
    this.credenciaisPropposta = [];
    this.empresas = [];
    this.empresas_id = [];
    this.portais = [];
    this.usuarios = [];
    this.users_id = [];
    this.username = "";
    this.email_cobranca = "";
    this.first_name = "";
    this.last_name = "";
    this.phone = "";
    this.permissao = "analista";
    this.empresas_cliente = [];
    this.usuario = [];
    this.senha = false;
    this.senha_visivel = "";
    this.email_usuario_admin = "";
    this.icon = "eye-slash";
    this.cor = "#5F9EA0";
    this.changePassword = false;
    this.oldPassword = "";
    this.newPassword = "";
    this.repeatPassword = "";
  };

  @action trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.consulta_api();
  };
}

const configuracoesGeraisStores = new ConfiguracoesGeraisStores();
export default configuracoesGeraisStores;

import React, { useState } from 'react'
import {
  ModalContainer,
  ModalHead,
  ModalTitle,
  BodyMessage,
  ActionButtons
} from './modalValidacaoAdmin/styles'

import { AlertCircle } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalOverlay } from '../../../Components/NewModal/sytleModal'
import { ReactTooltip } from '../../../globalStyles'

export const ModalValidacaoAdmin = ({
  status,
  confirmAction,
  closeModal,
  width,
  onChange
}) => {
  const [password, changePassword] = useState('')

  const handleConfirm = () => {
    confirmAction()
    // closeModal();
  }

  const handleChange = e => {
    changePassword(e.target.value)
    onChange(e)
  }

  return (
    <>
      <ReactTooltip />
      {status && (
        <ModalOverlay>
          <ModalContainer
            width={width || '700px'}
            left="unset"
            right="unset"
            top="unset"
            bottom="unset"
          >
            <ModalHead>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AlertCircle color="#fff" />
                <ModalTitle>SENHA DO ADMINISTRADOR</ModalTitle>
              </div>
              <FontAwesomeIcon
                className="icon-scale"
                icon="times"
                color="#fff"
                style={{ cursor: 'pointer', opacity: '0.9', fontSize: '15px' }}
                onClick={closeModal}
              />
            </ModalHead>
            <div
              style={{ width: '100%', textAlign: 'center', margin: '2rem 0' }}
            >
              <AlertCircle color={'#3A566E'} size={'5rem'} />
            </div>

            <BodyMessage>
              Para confirmar essa ação é necessário a senha do administrador
            </BodyMessage>
            <div className="w-100 d-flex justify-content-center my-2">
              <input
                type="password"
                name="senha"
                id="inputAdminPw"
                value={password}
                onChange={handleChange}
              />
            </div>

            <ActionButtons>
              <button className="icon-scale-xs modal-back" onClick={closeModal}>
                VOLTAR
              </button>
              <button
                disabled={password.length < 3}
                className="icon-scale-xs modal-confirm"
                onClick={() => handleConfirm(password)}
              >
                CONFIRMAR
              </button>
            </ActionButtons>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}

import React from 'react'
import { Container } from '../Style'
import FiltroTipoData from './filtroTipoData'
import { FiltroDataSimples } from './filtroDataSimples'
import { useFiltroEdital } from './context/FiltroEditaisContext'
import FiltroPregao from './filtroPregao'
import { useEffect } from 'react'

const FiltroQualificados = ({status}) => {
  const { state, showFiltro, getInitialProfileData, filtrarEditaisStatus } = useFiltroEdital();

  useEffect(() => {
    getInitialProfileData();
  }, []);

  return (
    <>
      {!state.filtro && (
        <Container>
          <div
            style={{
              display: 'flex',
              marginTop: '50px',
              flexWrap: 'wrap',
              justifyContent: 'center',
              overflow: 'auto',
              height: '100%',
              paddingBottom: '50px'
            }}
          >
            <FiltroDataSimples />

            <div style={{flexDirection: 'column', display: 'flex', justifyContent: 'space-between'}}>
            <FiltroPregao />
            <FiltroTipoData />
            </div>

            <div className="action-buttons">
              <button
                id="filtrar-editais"
                className="icon-scale-xs"
                onClick={ () => filtrarEditaisStatus(status)}
              >
                FILTRAR
              </button>
              <button
                id="fechar-modal-filtro-editais"
                className="icon-scale-xs"
                onClick={() => showFiltro(state)}
              >
                FECHAR
              </button>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

export default FiltroQualificados;
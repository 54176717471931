import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'react-bootstrap'

import LoginStores from '../../Stores/LoginStores'
import { Wrapper, Box, Logo, WrapperImag } from './styleSide'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import FormHelperText from '@material-ui/core/FormHelperText'
import RecuperarSenhaUsuario from './RecuperarSenhaUsuario'
import MaquinaDeEstadosStore from '../../Stores/MaquinaDeEstadosStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { startServiceWorkers } from '../../Stores/FirebaseStore'

const logo = '/static/Imagens/logo_wavecode.png'

@inject('LoginStores', 'MaquinaDeEstadosStore')
@observer
class FormLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 'paused',
      recuperar: false,
      animacao: false,
      submitEnabled: false
    }
  }

  componentDidMount() {
    this.setState({
      status: 'running',
      animacao: true
    })
  }

  handleRecuperarSenha = e => {
    MaquinaDeEstadosStore._props = this.props
    this.setState({
      status: 'running',
      animacao: false
    })

    setTimeout(() => {
      this.setState({ recuperar: true })
    }, 300)
  }

  handleRedirecionaLogin = () => {
    MaquinaDeEstadosStore._props = this.props
    this.setState({ status: 'paused' })
    this.setState({ recuperar: false })
    this.setState({
      status: 'running',
      animacao: true
    })
  }

  onSubmit = e => {
    e.preventDefault()
    if (navigator.serviceWorker)
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        if (!registrations.length) {
          startServiceWorkers()
        }
      })
    this.props.LoginStores.logar(e, this.props)
  }

  checkifSafari = () => {
    var ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        return false // Chrome
      } else {
        return true // Safari
      }
    }
  }

  render() {
    const { isFetching, isSuccess, errorMessage } = this.props.LoginStores
    return (
      <Wrapper>
        <WrapperImag />
        {!this.state.recuperar ? (
          <Box
            animationPlayState={this.state.status}
            animation={this.state.animacao}
            height="auto"
          >
            <Logo>
              <img src={logo} alt={'logo'} />
              <h4>Faça seu login</h4>
            </Logo>
            {this.checkifSafari() && (
              <h6 style={{ color: '#f00' }}>
                Detectamos que você está utilizando o navegador <b>Safari</b>.
                Sugerimos você utilizar o navegador{' '}
                <b>Chrome ou Mozilla Firefox</b>&nbsp; para conseguir desfrutar
                de todas as funcionalidades do sistema.
              </h6>
            )}
            <form onSubmit={e => this.onSubmit(e)}>
              <TextField
                error={!LoginStores.statusEmail}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                variant="outlined"
                type="email"
                autoFocus
                required
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
                onChange={this.props.LoginStores.onChange}
              />

              <TextField
                variant="outlined"
                type="password"
                InputLabelProps={{ shrink: true }}
                margin="normal"
                required
                fullWidth
                id="password"
                label="password"
                name="senha"
                autoComplete="password"
                onChange={this.props.LoginStores.onChange}
              />
              <FormHelperText
                style={{ marginBottom: '10px', color: 'green' }}
                id="component-helper-text"
              >
                {isSuccess && 'Login realizado com sucesso'}
              </FormHelperText>
              <FormHelperText
                style={{ marginBottom: '10px', color: 'red' }}
                id="component-helper-text"
              >
                {!isFetching && !isSuccess && errorMessage}
              </FormHelperText>

              <Button
                style={{ width: '100%' }}
                size={'sm'}
                bsClass="btn"
                bsStyle="primary"
                disabled={isFetching}
                type={'submit'}
                active={true}
              >
                ACESSAR
              </Button>
            </form>

            <Grid container style={{ marginTop: '15px' }}>
              <Grid item xs>
                <FontAwesomeIcon icon="lock" />
                <Link to={'/'}>
                  <span
                    style={{ fontSize: '14px' }}
                    onClick={() => this.handleRecuperarSenha()}
                  >
                    &nbsp;&nbsp;Esqueceu a senha?{' '}
                  </span>
                </Link>
              </Grid>

              <Grid item>
                <span style={{ fontSize: '14px' }}>Não tem uma conta? </span>
                <Link to={'/cadastro'}>
                  <span style={{ fontSize: '14px' }}>Cadastre-se</span>
                </Link>
              </Grid>
            </Grid>
          </Box>
        ) : (
            <RecuperarSenhaUsuario
              handleRedirecionaLogin={this.handleRedirecionaLogin}
            />
          )}
      </Wrapper>
    )
  }
}

export default FormLogin

import React from 'react'
import { ContainerBody } from './styles'
import DateDisplay from './dateDisplay'
import {
  getHour,
  FormatDateData,
  FormatDateHora
} from '../../../../../Stores/Funcoes/FuncoesGerais'

const BodyDetalhes = ({
  edital: {
    uf,
    objeto,
    orgao,
    portal,
    uasg,
    disputa,
    abertura,
    createdAt,
    modalidade,
    tipo_item,
    selecionado: { palavras_usadas, msg_status }
  }
}) => {
  const tratamentoPalavras = () => {
    if (!palavras_usadas) return false
    const palavras = palavras_usadas.split(',')
    return palavras
  }

  const dataAbertura = {
    day: abertura ? FormatDateData(abertura) : '',
    hour: abertura ? FormatDateHora(abertura) : ''
  }

  const dataDisputa = {
    day: disputa ? FormatDateData(disputa) : '',
    hour: disputa ? FormatDateHora(disputa) : ''
  }

  const dataPublicacao = {
    day: createdAt ? FormatDateData(createdAt) : '',
    hour: createdAt ? FormatDateHora(createdAt) : ''
  }

  return (
    <>
      <ContainerBody>
        <div className="dados">
          <div className="info">
            <div className="item">
              <b>ORGÃO: </b>
              {orgao}
            </div>
            <div className="item">
              <b>PORTAL: </b>
              {portal}
            </div>
            <div className="item mb-0">
              <div className="item mr-3">
                <b>UASG: </b>
                {uasg}
              </div>
              <div className="item">
                <b>UF: </b>
                {uf}
              </div>
            </div>
            <div className="item">
              <b>MODALIDADE: </b>
              {modalidade}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '135px',
              padding: '20px',
              marginLeft: 'auto'
            }}
          >
            <DateDisplay title="PUBLICAÇÃO" date={dataPublicacao} />
          </div>
          <div className="data">
            <DateDisplay title="ABERTURA" date={dataAbertura} />
            {getHour}
            <DateDisplay title="DISPUTA" date={dataDisputa} />
          </div>
        </div>
        <div className="descricao">
          <b>OBJETO:</b>
          <div>{objeto}</div>
        </div>
        <div className="palavras">
          <b>PALAVRAS UTILIZADAS PARA CAPTURAR EDITAL</b>
          {tratamentoPalavras() &&
            tratamentoPalavras().map((palavras, index) => (
              <span key={index}>{palavras}</span>
            ))}
        </div>
        <div className="mensagem">
          <b>MENSAGEM:</b>
          <span>{msg_status}</span>
        </div>
        <div className="tipo-item d-flex mt-3">
          <b>TIPO DE EDITAL:</b>
          <span className="ml-2">{tipo_item}</span>
        </div>
      </ContainerBody>
    </>
  )
}

export default BodyDetalhes

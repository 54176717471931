import React, { useState } from 'react';
import HeaderItens from "./headerItens";
import DataTable from "react-data-table-component";
import { columnsItens } from "./tabella/tabela";
import { ContainerTabela } from "./styles";
import FooterDetalhes from '../detalhes/footerDetalhes';

const markKeyword = (words, listKeyword) => {  
  if(typeof words !== "string") return words
  
  return (
    words
    .split(' ')
    .map(word => 
      (listKeyword.includes(word.replace(',', "").toUpperCase()) ?
      <span>
        <mark style={{backgroundColor: '#0190FF', color: 'white', padding: '1px 0', borderRadius: '3px'}}>
          {word}
        </mark> </span> : 
      word + ' ')
    ))
}

const ItensEdital = (props) => {
  const { id, itens, editais, } = props;
  const [palavra, setPalavra] = useState('');
  const edital = editais.find(edital => edital.id === id);
  const { licitacao } = edital;

  const filteredItems = itens.filter(item => {
    return (
      (item.codigo && item.codigo.toLowerCase().includes(palavra.toLowerCase()))
      || (item.descricao_completa && item.descricao_completa.toLowerCase().includes(palavra.toLowerCase()))
      || (item.grupo && item.grupo.toLowerCase().includes(palavra.toLowerCase()))
      || (item.unidade_fornecimento && item.unidade_fornecimento.toLowerCase().includes(palavra.toLowerCase()))
    )
  });

    const formatKeyword = edital.selecionado.palavras_usadas && edital.selecionado.palavras_usadas.length ?
      edital.selecionado.palavras_usadas.split(',').map(key => key.toUpperCase()) :
      null
  
    const data = formatKeyword ?
     filteredItems.map(item => ({item, descricao_completa : markKeyword(item.descricao_completa, formatKeyword)})) :
     filteredItems

  return (
    <>
      <HeaderItens licitacao={licitacao} setPalavra={setPalavra} palavra={palavra} />
      <ContainerTabela>
        <DataTable
          className="tabela-itens-detalhes"
          noHeader
          responsive={true}
          columns={columnsItens}
          data={data}
          striped={true}
          highlightOnHover
        />
      </ContainerTabela>
      <FooterDetalhes />
    </>
  );
};

export default ItensEdital;
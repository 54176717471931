import React from 'react'
import { inject, observer } from 'mobx-react'

import LogoBb from '../../../Images/bb_logo.jpeg'
import LogoComprasNet from '../../../Images/compras_net.png'

import * as S from './styles'

const PortalSelect = inject('LicitacoesPaginadaStore')(
  observer(props => {
    const { portal, changePortal } = props.LicitacoesPaginadaStore
    return (
      <S.PortaisContainer>
        <S.PortalContainer
          active={portal.includes(5)}
          onClick={() => changePortal(5)}
        >
          <img src={LogoComprasNet} alt="Logo ComprasNet" />
          <span>ComprasNet</span>
        </S.PortalContainer>
        <S.PortalContainer
          active={portal.includes(6)}
          onClick={() => changePortal(6)}
        >
          <img src={LogoBb} alt="Logo Banco do Brasil" />
          <span>Licitações-e</span>
        </S.PortalContainer>
      </S.PortaisContainer>
    )
  })
)

export default PortalSelect

import React from "react";
import {
  UserCard,
  UserAvatar,
  UserName,
  UserEmail,
  ActionContainer
} from "../Style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactTooltip } from "../../../globalStyles";
import { Trash, Smartphone, Mail } from "react-feather";
import ModalEditUser from "../modalLateral/ModalEditUser";
import { ToastError, ToastDark } from "../../../Stores/Funcoes/FuncoesGerais";
import { inject, observer } from "mobx-react";

export const Usuario = ({ user }) => {
  const { first_name, last_name, phone, username } = user;
  return (
    <>
      <ReactTooltip effect="solid" />
      <UserCard>
        <div style={{ width: "9%" }}>
          <UserAvatar>
            <FontAwesomeIcon icon="user" size="2x" color="#3a566eb5" />
          </UserAvatar>
        </div>
        <div style={{ display: "flex", width: "80%", alignItems: "center" }}>
          <UserName>{`${first_name} ${last_name}`}</UserName>
          <UserEmail>
            <Mail style={{ width: "22px" }} className="mr-1" /> {username}
          </UserEmail>
          <UserEmail>
            <Smartphone style={{ width: "22px" }} className="mr-1" />
            {phone}
          </UserEmail>
        </div>
        <ActionContainer>
          <ModalEditUser user={user} />
          <DeletarUsuarioButton
           user={user}
          />
        </ActionContainer>
      </UserCard>
    </>
  );
};

const DeletarUsuarioButton = 
inject("StoreConfiguracoesGerais")
(observer(({StoreConfiguracoesGerais : {excluir_usuario}, user}) => {

  if(user.is_superuser)
  return (
    <Trash
        className="icon-scale"
        data-effect="solid"
        color="#981212"
        data-tip="USUÁRIO ADM NÂO PODE SER EXCLUIDO "
        data-place="bottom"
        data-type="error"        
    />
  )

  return (
    <Trash
        className="icon-scale"
        data-effect="solid"
        color="#981212"
        data-tip="EXCLUIR USUÁRIO"
        data-place="bottom"
        data-type="error"
        onClick={() => excluir_usuario(user.id)}
    />
  )
  
}))

export default Usuario;

import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from 'styled-components';
import { useFiltroEdital } from "./context/FiltroEditaisContext";

export const Container = styled.div`
  height: 100%;
  padding: 0 15px;
  color: ${props => props.active ? '#3b576f' : '#fff'};
  background: ${props => props.active ? '#ffffffeb' : 'transparent'};
  font-weight: 500;
  font-size: 13px;
  border-left: 1px solid ${props => props.active ? '#ab8a4e' : '#fff'};
  border-right: 1px solid ${props => props.active ? '#ab8a4e' : '#fff'};
  box-shadow: ${props => props.active ? '1px 1px 3px #0000004f' : 'none'};
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    transition: 250ms;
    :hover {
      color: #ffe095;
    }
  }

  svg {
    margin-right: 5px;
  }
`;

const BotaoFiltrar = ({ onClick, noActive }) => {
  const { showFiltro, state: { filtro } } = useFiltroEdital()

  const active = noActive ? false : !filtro;

  return (
    <Container onClick={onClick || showFiltro} active={active}>
      <FontAwesomeIcon icon="filter" color="#67baec" />
      <span>FILTROS</span>
    </Container>
  );
};

export default BotaoFiltrar;

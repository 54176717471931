import React from 'react';
import { inject, observer } from 'mobx-react';
import BadegRedondo from '../../../../Components/ReusableComponents/Badeg/badegRedondo'
import { Printer } from 'react-feather';

const ImprimirProposta = inject("ImprimirPropostaStore", "ExportarPropostaStore")(observer(({ ImprimirPropostaStore, ExportarPropostaStore, id }) => {
  // TODO
  // Capturar os editais e enviar para Imprimir store, para pegar os dados.
  const { toggleModalImprimir } = ImprimirPropostaStore;
  return (
    <BadegRedondo
      autoMargin
      icon={<Printer style={{ width: '20px' }} />}
      background={'#d28009'}
      onClick={toggleModalImprimir}
    />
  )
}));

export default ImprimirProposta

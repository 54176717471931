import React, { useMemo, useState, useEffect } from 'react'
import { ChevronRight, CheckCircle } from 'react-feather'

import { useSetting } from '../../Context/index.js'

import * as S from './style'

const OccupationArea = () => {
  const {
    selectedAreas,
    occupationArea,
    tootleSelectedAreas,
    changeThereWasChange,
    thereWasChange
  } = useSetting()

  const [selected, setSelected] = useState(null)
  const [selectedAreasState, setSelectedAreasState] = useState([])

  useEffect(() => {
    let areas = [...listOfAreas].map(area => area.id)
    let areaSel = [...selectedAreas]
    let difference = areaSel.filter(area => areas.includes(area))
    setSelectedAreasState(difference)
  }, [selectedAreas, selected])

  const listOfAreas = useMemo(() => {
    return selected
      ? occupationArea.find(area => area.name === selected).area
      : []
  }, [selected])

  const handleSelect = e => setSelected(e.target.getAttribute('name'))

  const handleSelectedArea = e => {
    changeThereWasChange(true)
    const id = e.target.getAttribute('id')
    tootleSelectedAreas(
      selectedAreas.includes(id)
        ? selectedAreas.filter(a => a !== id)
        : [...selectedAreas, id]
    )
  }

  const addAllArea = () => {
    changeThereWasChange(true)
    let area = listOfAreas.map(area => area.id)
    let arr = [...selectedAreas].concat(area)
    let novaArr = arr.filter((este, i) => arr.indexOf(este) === i)
    tootleSelectedAreas(novaArr)
  }

  const excludeAllAreas = () => {
    changeThereWasChange(true)
    let areas = [...listOfAreas].map(area => area.id)
    let areaSel = [...selectedAreas]
    let difference = areaSel.filter(area => !areas.includes(area))
    tootleSelectedAreas(difference)
  }

  return (
    <>
      <S.OccupationAreas>
        <S.TableItem>
          <div className="header">
            <div className="title">Grupo</div>
          </div>
          <div className="body-table">
            {occupationArea.map((itemArea, index) => {
              const { name, area } = itemArea
              const count = selectedAreas.reduce((acc, el) => {
                return area.map(el => el.id).includes(el) ? acc + 1 : acc
              }, 0)

              return (
                <S.GroupItem
                  key={index}
                  name={name}
                  onClick={handleSelect}
                  active={selected === name}
                >
                  {name}
                  {!!count && <div className="count">{count}</div>}
                  <ChevronRight />
                </S.GroupItem>
              )
            })}
          </div>
        </S.TableItem>
        {listOfAreas.length ? (
          <>
            <S.AreaContainer>
              <div className="header">
                <div className="icon"></div>
                <div className="title">{selected.toLowerCase()}</div>
                <div className="sub-title"></div>
              </div>
              <div className="body-item">
                {listOfAreas.map(area => (
                  <S.AreaItem
                    key={area.id}
                    id={area.id}
                    onClick={handleSelectedArea}
                    selected={selectedAreas.includes(String(area.id))}
                  >
                    <CheckCircle size="17" /> {area.name}
                  </S.AreaItem>
                ))}
              </div>
            </S.AreaContainer>
          </>
        ) : null}
      </S.OccupationAreas>
      <S.Container>
        <button
          className="salvar"
          onClick={addAllArea}
          disabled={selectedAreasState.length === listOfAreas.length}
        >
          Adicionar todas modalidades
        </button>
        <button
          className="limpar"
          onClick={excludeAllAreas}
          disabled={!selectedAreasState.length}
        >
          Remover todas modalidades
        </button>
      </S.Container>
    </>
  )
}

export default OccupationArea

import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Highlighter from 'react-highlight-words'
import { getEmpresaId } from '../../Services/auth'
// import { ReactTooltip } from "../../../globalStyles";
import { SingleMessageContainer, VerticalLine } from './styleOcorrencias'
import { observer, inject } from 'mobx-react'
import { FormatDate } from '../../Stores/Funcoes/FuncoesGerais'

@inject(
  'MonitoramentoStore',
  'OcorrenciasDoDiaStores',
  'MenuStores',
  'MaquinaDeEstadosStore'
)
@observer
class MensagemOcorrenciaDia extends Component {
  verificaEmpresaAtual = id => {
    if (getEmpresaId() !== id) {
      this.props.MenuStores.id_fornecedor = id
      this.props.MenuStores.mudou_empresa(id)
      this.props.MenuStores.flag_mudar_empresa = Math.random()
    }
  }

  redirenciona = async (e, msg) => {
    let tag = e.target.tagName
    let tagId = e.target.id

    if (tag === 'sgv' || tag === 'path' || tagId === 'msg') {
    } else {
      const id = msg.licitacao.id
      const id_empresa = msg.id_empresa
        ? msg.id_empresa
        : this.props.OcorrenciasDoDiaStores.idEmpresa
      this.verificaEmpresaAtual(id_empresa)

      await this.props.MaquinaDeEstadosStore.redireciona_monitoramento()

      setTimeout(() => {
        this.props.MonitoramentoStore.statusVerificada()
        this.props.MonitoramentoStore.consulta_especifica(id)
        this.props.MonitoramentoStore.changeSelectedLicita(id)
      }, 3000)
    }
  }

  render() {
    const { marcaMsgVerificada, palavras } = this.props.OcorrenciasDoDiaStores
    const { msg, titleSize, bodySize } = this.props
    const {
      id,
      verificada,
      licitacao,
      remetente,
      data_hora,
      texto,
      nome_empresa
    } = msg
    const { comprador, codigo, portal, uasg } = licitacao

    return (
      <SingleMessageContainer
        verificada={verificada}
        id={`ocorrencia_msg_${id}`}
        key={`msg_${id}`}
        bodySize={bodySize}
        titleSize={titleSize}
        onClick={e => this.redirenciona(e, msg)}
      >
        <div className="icon-container">
          <FontAwesomeIcon icon="comments" className="icon-mess" />
        </div>
        <div className="message-container">
          <BtnMarcarMsgLida
            id={id}
            marcaMsgVerificada={marcaMsgVerificada}
            verificada={verificada}
          />
          <div className="container-title">
            <div
              style={{ width: '70%' }}
              data-tip={comprador}
              data-type="info"
              data-place="bottom"
              data-effect="solid"
            >
              Órgão:&nbsp;{' '}
              <b>
                {comprador.length > 120
                  ? comprador.substr(0, 120).concat('...')
                  : comprador}
              </b>
            </div>
            <div style={{ width: '23%' }}>
              Pregão:&nbsp; <b>{codigo}</b>
            </div>
            <div style={{ width: '23%' }}>
              UASG:&nbsp; <b>{uasg}</b>
            </div>
            <div className="w-25">
              Portal:&nbsp; <b>{portal}</b>
            </div>
          </div>

          <div className="message-body">
            <Highlighter
              highlightClassName={
                !verificada ? 'TagMarkRed' : 'TagMarkSucesses'
              }
              searchWords={palavras}
              autoEscape={false}
              textToHighlight={texto}
            />
            <VerticalLine />
          </div>
        </div>

        <div className="company-container">
          <div className="container-title">Empresa:</div>
          <div className="message-body">
            {nome_empresa
              ? nome_empresa
              : this.props.OcorrenciasDoDiaStores.nome_empresa}
          </div>
          <VerticalLine />
        </div>
        <div className="data-container">
          <div className="container-title">{`Origem: ${remetente}`}</div>
          <div className="message-body">
            <div>{FormatDate(data_hora).split(' ')[0]}</div>
            <div style={{ fontWeight: '300' }}>
              {FormatDate(data_hora).split(' ')[1]}
            </div>
          </div>
          <VerticalLine />
        </div>
      </SingleMessageContainer>
    )
  }
}

export default MensagemOcorrenciaDia

export const BtnMarcarMsgLida = ({ verificada, id, marcaMsgVerificada }) => {
  return (
    <div
      onClick={e => !verificada && marcaMsgVerificada(e, id)}
      id="msg"
      className={'icon-scale msg'}
      style={{
        position: 'absolute',
        width: '30px',
        height: '30px',
        background: verificada ? '#d7dee1' : '#00af3c',
        color: verificada ? 'rgba(215,222,225,0.38)' : '#00af3c',
        right: '-0.8%',
        borderRadius: '100%',
        top: '35%',
        fontSize: '14px',
        textAlign: 'center'
      }}
    >
      {verificada ? (
        <div
          id="msg"
          style={{ marginTop: '4px' }}
          data-tip={'JÁ VERIFICADA'}
          data-type="warning"
          data-place="left"
          data-effect="solid"
        >
          <FontAwesomeIcon
            id="msg"
            style={{ color: 'white' }}
            icon="envelope-open-text"
          />
        </div>
      ) : (
        <div
          data-tip={'VERIFICAR'}
          data-type="success"
          data-place="left"
          data-effect="solid"
          id="msg"
          style={{ marginTop: '4px' }}
        >
          <FontAwesomeIcon
            id="msg"
            style={{ color: 'white' }}
            icon="envelope"
          />
        </div>
      )}
    </div>
  )
}

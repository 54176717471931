import React, { Component } from "react";
import { CardMsg } from "../../globalStyles";
import { Filter } from "react-feather";
import ModalFiltroCompradores from "./modalLateral/ModalFiltroCompradores";
import { inject, observer } from "mobx-react";
import { CompradoresContainer, Portal, SelecionarPortal } from "./styles";
import { sliceString } from "../../Services/funcoes";
import { getPortalName } from "../../Services/portais";

@inject("CompradoresStore")
@observer
class FiltroCompradores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtrados: true
    };
  }

  render() {
    const { filtrados } = this.state;
    const {
      responseFiltrados,
      responseExcluidos
    } = this.props.CompradoresStore;
    const compradores = filtrados ? responseFiltrados : responseExcluidos;

    return (
      <CardMsg flexDirection="column" height="275px" minHeight="275px" maxHeigth="275px" mTop={true}>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Filter
              style={{
                width: "26px",
                height: "26px",
                margin: "4px 15px 4px 0px"
              }}
            />
            <div className="d-flex flex-column">
              <span>FILTRO DE COMPRADORES</span>
              <span style={{ fontSize: "10px" }}>
                Definir quais compradores deseja incluir ou excluir.
              </span>
            </div>
          </div>
          <div id="show-list-filter-buyers">
            <ModalFiltroCompradores />
          </div>
        </div>
        <SelecionarPortal style={{maxHeigth: "140px"}}>
          <Portal
            selected={filtrados}
            onClick={() => this.setState({ filtrados: true })}
            className="icon-scale-xs"
          >
            FILTRADOS
          </Portal>
          <Portal
            selected={!filtrados}
            onClick={() => this.setState({ filtrados: false })}
            className="icon-scale-xs"
          >
            EXCLUÍDOS
          </Portal>
        </SelecionarPortal>
        <div style={{ width: "100%", height: "100%", padding: "15px" }}>
          <CompradoresContainer>
            <div className="title">{filtrados ? "FILTRADOS" : "EXCLUÍDOS"}</div>
            <div className="body">
              {compradores.map(buyer => (
                <div>
                  <span>{sliceString(buyer.nome, 60)}</span>
                  <span className="portal-name">
                    {sliceString(getPortalName(buyer.portal), 35)}
                  </span>
                </div>
              ))}
            </div>
          </CompradoresContainer>
        </div>
      </CardMsg>
    );
  }
}

export default FiltroCompradores;

import { observable, action } from 'mobx'
import MaquinaDeEstadosStore from '../../../../Stores/MaquinaDeEstadosStore'
import Api from '../../../../Stores/Conexao/conexao'
import { tratarDadosEditais } from '../../Components/tratarDadosEditais'

class ReprovadosStore {
  @observable isFetching = false

  @observable editais = []
  @action changeEditais = (id, status) =>
    (this.editais = this.editais.map(ed =>
      ed.id === id ? { ...ed, status } : ed
    ))
  @observable total_paginas = 0
  @observable total_registros = 0

  @observable ordenacao = 'data_disputa'
  @observable crescente = true

  @observable portal = {
    portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
    changePortais: portais => (this.portal.portais = portais)
  }

  @observable filtros = {
    dmin: null,
    dmax: null,
    qualData: 'inicial',
    resetDate: () => {
      this.filtros.dmin = new Date().toLocaleDateString('pt-br')
      this.filtros.dmax = new Date().toLocaleDateString('pt-br')
    },
    changeDate: range => {
      const { qualData } = this.filtros

      this.filtros.dmin = new Date(range.startDate).toLocaleDateString('pt-br')
      this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

      return (this.filtros.qualData =
        qualData === 'inicial' ? 'final' : 'inicial')
    },
    onInputChange: e => {
      this.filtros[e.target.name] = e.target.value
    }
  }

  @observable pagination = {
    page: 1,
    changePage: page => (this.pagination.page = page)
  }

  getData = page => {
    const { dmin, dmax } = this.filtros
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmax=${dmax}&dmin=${dmin}`
    query += `&pagina=${page || this.pagination.page}`
    query += `&status=reprovado`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    // query += `&perfil=${id}`;
    return query
  }

  @action fetchEditais = async (perfil_id = null) => {
    this.isFetching = true
    try {
      const res = await Api.get(this.getData(perfil_id))
      const {
        editais,
        palavras_usadas,
        total_paginas,
        total_registros
      } = res.data
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  @action fetchEditaisComFiltro = async (data) => {
    this.isFetching = true
    try {
      const res = await Api.get(this.getDataComFiltro(data))
      const { config_perfis, editais, total_registros } = res.data
      this.perfis = config_perfis
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  @action fetchEdital = async (data) => {
    this.isFetching = true;
    const {
      date: { dmin },
      pregao,
      pregaoUASG : uasg,
      tipoDate,
    } = data;
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`;
    query += `&date=${dmin}`;
    query += `&date_type=${tipoDate}`;
    query += `&uasg=${uasg}`;
    query += `&edital=${pregao}`;
    query += `&pagina=${this.pagination.page}`;
    const queryPattern = '&dmax=null&dmin=null&status=reprovado&ordenacao=data_disputa&crescente=true&usuario=null';  
    query += queryPattern;
    try {
      const res = await Api.get(query)
      const { config_perfis, editais, total_registros } = res.data
      this.perfis = config_perfis
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
      console.log('err', err)
    } finally {
      this.isFetching = false
    }
  }

  getDataComFiltro = data => {
    const {
      date: { dmin, dmax },
      pregao,
      pregaoUASG : uasg,
      tipoDate,
   } = data;

    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmax=${dmax}&dmin=${dmin}`
    query += `&pagina=${this.pagination.page}`
    query += `&status=reprovado`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    query += `&usuario=${this.usuario}`
    query += `&tipo_data=${tipoDate}`
    query += `&uasg=${uasg}`
    query += `&edital=${pregao}`
    
    return query
  }

  fetchMore = async page => {
    try {
      const res = await Api.get(this.getData(page))
      return res.data.editais
    } catch (err) {
      console.log('err', err)
    }
  }

  trocouDeEmpresa = () => {
    this.limparVariaveis()
    this.fetchEditais()
  }

  limparVariaveis = () => {
    this.isFetching = false

    this.editais = []
    this.total_paginas = 0
    this.total_registros = 0

    this.portal = {
      portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
      changePortais: portais => (this.portal.portais = portais)
    }

    this.filtros = {
      dmin: null,
      dmax: null,
      qualData: 'inicial',
      resetDate: () => {
        this.filtros.dmin = new Date().toLocaleDateString('pt-br')
        this.filtros.dmax = new Date().toLocaleDateString('pt-br')
      },
      changeDate: range => {
        const { qualData } = this.filtros

        this.filtros.dmin = new Date(range.startDate).toLocaleDateString(
          'pt-br'
        )
        this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

        return (this.filtros.qualData =
          qualData === 'inicial' ? 'final' : 'inicial')
      },
      onInputChange: e => {
        this.filtros[e.target.name] = e.target.value
      }
    }

    this.pagination = {
      page: 1,
      changePage: page => (this.pagination.page = page)
    }
  }
}

export default new ReprovadosStore()
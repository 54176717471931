import React, { useState, useEffect } from 'react'
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from '../../../Components/NewModal'
import { UserForm, SaveButton, ModalContainer, RedefinirSenha } from './styles'
import { FormGroup } from 'react-bootstrap'
import UsuariosInputConstumizado from '../InputsCustomizados/UsuariosInputConstumizado'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import { Key } from 'react-feather'
import { inject, observer } from 'mobx-react'
import CredencialInputConstumizado from '../InputsCustomizados/CredencialInputConstumizado'
import SelectPortalInputCustomizado from '../InputsCustomizados/SelectPortalInputCustomizado'
import { ToastError } from '../../../Stores/Funcoes/FuncoesGerais'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
// import { ModalSaveButton } from '../../../../globalStyles';

function ModalAddCredential({ id }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [animation, setAnimation] = useState(false)
  return (
    <ModalProvider>
      <button
        className="add-credencial icon-scale-xs"
        onClick={() => setIsModalOpen(true)}
        data-tip="ADICIONAR CREDENCIAL"
        data-place="bottom"
        data-type="success"
        data-effect="solid"
      >
        + CREDENCIAL
      </button>

      {isModalOpen && (
        <ModalLateral
          doobleIcon={<Key />}
          title={'ADICIONAR CREDENCIAL'}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <ModalContainer>
            <CredentialHooks
              toggleModal={setIsModalOpen}
              empresaId={id}
              closeModal={() => closeModal(setAnimation, setIsModalOpen)}
            />
          </ModalContainer>

          {/* <UserInfo empresas={empresas} state={state} toggleModal={setIsModalOpen} /> */}
        </ModalLateral>
      )}
    </ModalProvider>
  )
}

const CredentialHooks = inject(
  'StoreConfiguracaoEmpresas',
  'StoreConfiguracoesGerais',
  'StoreConfiguracaoCredenciais',
  'ConfiguracoesMonitoramentoStores',
  'MaquinaDeEstadosStore'
)(
  observer(props => {
    const {
      onChange,
      login,
      password,
      adminValidation,
      verificaAdmin,
      validarAdmin,
      validarExcluir,
      fornecedor,
      portal,
      popularCredencial
    } = props.StoreConfiguracaoCredenciais

    const {
      portal: portalConfigMonitoramento
    } = props.ConfiguracoesMonitoramentoStores

    const { contratos, id_fornecedor } = props.MaquinaDeEstadosStore

    const { empresas, usuarios } = props.StoreConfiguracoesGerais
    const { empresaId, toggleModal, closeModal } = props

    return (
      <CredentialInfo
        empresas={empresas}
        usuarios={usuarios}
        empresaId={empresaId}
        toggleModal={toggleModal}
        onChange={onChange}
        login={login}
        password={password}
        portal={portal}
        portalConfigMonitoramento={portalConfigMonitoramento}
        fornecedor={fornecedor}
        adminValidation={adminValidation}
        verificaAdmin={verificaAdmin}
        validarAdmin={validarAdmin}
        validarExcluir={validarExcluir}
        popularCredencial={popularCredencial}
        contratos={contratos}
        id_fornecedor={id_fornecedor}
        closeModal={closeModal}
      />
    )
  })
)

const CredentialInfo = props => {
  const {
    empresas,
    empresaId,
    toggleModal,
    onChange,
    login,
    password,
    adminValidation,
    verificaAdmin,
    validarAdmin,
    popularCredencial,
    portalConfigMonitoramento,
    contratos,
    closeModal,
    id_fornecedor
  } = props

  const [error, setError] = useState('')

  useEffect(() => {
    popularCredencial('closeModal')

    return () => {
      setError('')
    }
  }, [])

  const mesmoPortal = () => {
    const { credenciais } = empresas.find(em => em.id === empresaId)
    const { value } = document.getElementById('select-portal-add-credencial')

    return credenciais.some(cr => cr.portal_id === Number(value))
  }

  const company = empresas.find(emp => emp.id === empresaId)
  const fornecedorName = company.nome_fantasia

  const handleSubmit = (e, validate) => {
    e.preventDefault()
    if (!validate && mesmoPortal())
      return ToastError('Você já possui uma credencial para esse portal.')
    if (!validate) return validarAdmin('adicionarCredencial')
    return verificaAdmin('adicionarCredencial', toggleModal, empresaId)
  }

  if (adminValidation)
    return (
      <UserForm onSubmit={e => handleSubmit(e, true)}>
        <h5 style={{ textAlign: 'center' }}>
          {MaquinaDeEstadosStore.getEmailUser()}
        </h5>
        <UsuariosInputConstumizado
          titulo={'Digite a senha do administrador'}
          name={'inputAdminPw'}
          id={'senha'}
          placeholder={'senha...'}
          type={'password'}
          onChange={onChange}
        />
        <RedefinirSenha onClick={() => validarAdmin('fecharModal')}>
          Voltar
        </RedefinirSenha>
        <SaveButton type="submit" className="icon-scale-xs">
          SALVAR
        </SaveButton>
      </UserForm>
    )

  const comprasNetId = 5
  const portalsOptions =
    company.id == id_fornecedor
      ? portalConfigMonitoramento
      : company.contratos.find(elem => elem.name_modulo === 'monitoramento')
          .portal
  if (
    !contratos
      .find(elem => elem.name_modulo === 'monitoramento')
      .portal.includes(comprasNetId) &&
    !portalsOptions.includes(comprasNetId) &&
    contratos.find(elem => elem.name_modulo === 'proposta').status
  )
    portalsOptions.unshift(comprasNetId)

  return (
    <UserForm onSubmit={handleSubmit} autocomplete="off">
      <input
        type="text"
        name="login"
        autocomplete="off"
        style={{ opacity: 0, position: 'absolute' }}
      />
      <input
        type="password"
        autocomplete="off"
        style={{ opacity: 0, position: 'absolute' }}
      />
      {company.contratos.find(elem => elem.name_modulo === 'monitoramento')
        .status ||
      company.contratos.find(elem => elem.name_modulo === 'proposta').status ? (
        <>
          <FormGroup>
            <h3 style={{ marginBottom: '3rem', textAlign: 'center' }}>
              {fornecedorName}
            </h3>
            <SelectPortalInputCustomizado
              id="select-portal-add-credencial"
              titulo="Selecione o portal"
              name="portal"
              onChange={onChange}
              portalConfigMonitoramento={portalsOptions}
            />
            <CredencialInputConstumizado
              titulo={'Login'}
              name="login"
              id={'add-credencial-login'}
              placeholder={'Insira seu login de acesso...'}
              type={'text'}
              value={login}
              onChange={onChange}
            />
            <CredencialInputConstumizado
              titulo={'Senha de Acesso ao Portal'}
              name="password"
              id={'add-credencial-password'}
              placeholder={'Insira sua senha de acesso...'}
              type={'password'}
              value={password}
              onChange={onChange}
            />

            <CredencialInputConstumizado
              titulo={'Senha de Acesso ao Portal'}
              name="repeat_password"
              id="add-credencial-repeat"
              placeholder={'Confirmar senha novamente...'}
              type={'password'}
              onChange={onChange}
            />
          </FormGroup>

          <SaveButton type="submit" className="icon-scale-xs">
            SALVAR
          </SaveButton>
        </>
      ) : (
        <>
          <FormGroup>
            <h3 style={{ marginBottom: '3rem', textAlign: 'center' }}>
              {fornecedorName}
            </h3>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <div style={{ marginBottom: '20px' }}>
                <Typography style={{color:"black"}} variant="h6" gutterBottom>
                  O Plano não possui monitoramento liberado
                </Typography>
              </div>
              <Button
              style={{backgroundColor:"#45994b", color:'white'}}
                variant="contained"                
                onClick={closeModal}
              >
                Voltar
              </Button>
            </div>
          </FormGroup>
        </>
      )}
    </UserForm>
  )
}

export default ModalAddCredential

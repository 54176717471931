import React, { useRef, useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import Select from '../../../Components/Select'

import * as S from './Style'

const PerfilSelection = inject('BoletimStore')(
  observer(({ BoletimStore }) => {
    const { perfis, fetchEditais } = BoletimStore
    return (
      <PerfilSelectionComStore perfis={perfis} fetchEditais={fetchEditais} />
    )
  })
)

const PerfilSelectionComStore = ({ perfis, fetchEditais }) => {
  const [selectedProfile, setSelectedProfile] = useState([])
  const [width, setWidth] = useState(0)
  const [valueSelect, setValueSelect] = useState('')

  useEffect(() => {
    let findProfile = perfis.find(profile => profile.activated === true)
    setValueSelect(findProfile ? findProfile.nome_perfil : '')
  }, [])

  const ref = useRef()

  const handleChange = value => {
    setValueSelect(value)
    let currentProfile = perfis.find(profile => profile.nome_perfil === value)
    fetchEditais(currentProfile.id, currentProfile)
  }

  useEffect(() => {
    const wid = ref.current.offsetWidth / 2
    if (wid !== width) setWidth(wid)
    setSelectedProfile(perfis)
  }, [perfis])

  return (
    <>
      {perfis.length >= 6 || window.innerWidth < 1000 ? (
        <S.Container
          ref={ref}
          style={{ margin: '8px' }}
          left={`calc(50% - ${width}px)`}
        >
          <Select
            data={selectedProfile}
            handleChange={handleChange}
            value={valueSelect}
          />
        </S.Container>
      ) : (
        <S.Container ref={ref} left={`calc(50% - ${width}px)`}>
          {perfis.map(perfil => {
            return (
              <S.SinglePerfil
                key={perfil.id}
                active={perfil.activated}
                onClick={() => fetchEditais(perfil.id, perfil)}
              >
                {perfil.nome_perfil}
                <div className="counter">{perfil.qtd_edital}</div>
              </S.SinglePerfil>
            )
          })}
        </S.Container>
      )}
    </>
  )
}

export default PerfilSelection

import React from 'react';
import { Container } from './styles';
import SubHeader from '../SubMenu/SubMenu';
import SectionTitle from '../SubMenu/SectionTitle';
import { FileText } from 'react-feather';
import { useContext } from 'react';
import PropostaEspecifica from './PropostaEspecifica/PropostaEspecifica';
import ListaDeLicitacoes from './ListaDeLicitacoes/ListaDeLicitacoes';
import { PropostaEspecificaContext } from './PropostaEspecifica/context';
import Spinner from '../../../../UsefulComponents/Spinner';
import ModalCarregar from './ListaDeLicitacoes/detalhes/modalCarregar/ModalCarregar';

const CadastrarProposta = () => {

  const { edital, loading, selectedItems } = useContext(PropostaEspecificaContext);

  return (
    <Container>
      <SubHeader status={true} overflow>
        <SectionTitle title="CADASTRAR PROPOSTA" icon={<FileText />} />
        {edital && 
        <div className="edital-info-prop">
          {`ITENS SELECIONADOS: ${selectedItems.length}`}
          &nbsp; &nbsp;&nbsp; &nbsp;
          {`EDITAL: ${edital.numero}`} &nbsp; &nbsp;&nbsp; &nbsp;
          {`UASG: ${edital.comprador.codigo}`}
        </div>
        }
      </SubHeader>
      {loading ? <Spinner /> : edital ? <PropostaEspecifica /> : <ListaDeLicitacoes />}
      <ModalCarregar />
    </Container>
  )
}

export default CadastrarProposta;

import React, { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import DataTable from 'react-data-table-component'
import { Pagination } from '@material-ui/lab'

import Spinner from '../../../../../../UsefulComponents/Spinner'
import { API } from '../api'
import { ListaDeLicitacoesContext } from '../context'
import { columnsCadastar } from './estrutura'
import { ExclamationWarning } from '../../../../../Notifications/ExclamationWarning'

import { Body, ContainerTabela, ContainerPagination } from '../../styles'

const Tabela = () => {
  const {
    status,
    pagina,
    quantidade: { total_registros },
    mudarPagina,
    mudarQuantidade
  } = useContext(ListaDeLicitacoesContext)

  const [{ data, loading, error }, refetch] = useAxios(
    API.filtrar(status, pagina),
    { useCache: false }
  )

  const total_paginas = Math.ceil(total_registros / 10)

  useEffect(() => {
    if (data) {
      const { aprovado, qualificado, todos, boletim, total_registros } = data
      const quantidade = {
        aprovado,
        qualificado,
        todos,
        boletim,
        total_registros
      }
      mudarQuantidade(quantidade)
    }
  }, [data])

  return (
    <Body height="calc(100vh - 240px)">
      <ContainerTabela>
        {!loading ? (
          <>
            <DataTable
              className="tabela-proposta-carregar"
              fixedHeader
              allowOverflow={true}
              overflowY={true}
              noHeader
              responsive={true}
              columns={columnsCadastar}
              data={data.editais || []}
              striped={true}
              highlightOnHover
              noDataComponent={
                <ExclamationWarning
                  title="NENHUM EDITAL ENCONTRADO"
                  height="auto"
                />
              }
            />
            <ContainerPagination>
              <div>
                <Pagination
                  count={total_paginas}
                  color="primary"
                  shape="rounded"
                  page={pagina}
                  onChange={mudarPagina}
                  showFirstButton
                  showLastButton
                ></Pagination>
              </div>
            </ContainerPagination>
          </>
        ) : (
          <Spinner />
        )}
      </ContainerTabela>
    </Body>
  )
}

export default Tabela

import React, { Component } from 'react'
import $ from 'jquery'
import Modal from 'react-modal'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'

import MenuEmpresas from './MenuEmpresas'
import MenuUsuario from './MenuUsuario'

import { MainHeader, PageTitle } from '../../globalStyles'
import { ReactTooltip } from '../../globalStyles'
import { BolaVerdePiscando } from './styles'

@inject(
  'ModalNotificacoesStores',
  'MenuStores',
  'ModalSincronizarStores',
  'MaquinaDeEstadosStore'
)
@observer
class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false }
  }

  notificacoes_dropdown() {
    let estado = $('#notify-app').hasClass('h-notify')
    if (estado === false) {
      $('#notify-app').addClass('h-notify')
    } else {
      $('#notify-app').removeClass('h-notify')
      this.props.ModalNotificacoesStores.consulta_api()
    }
  }

  componentWillMount() {
    Modal.setAppElement('body')
    // this.props.ModalNotificacoesStores.consulta_api();
    this.props.MenuStores.consultaTitle()
  }

  render() {
    const { simbolo_pagina, titulo_pagina } = this.props.MenuStores

    const {
      usuarioLogado: { email }
    } = this.props.MaquinaDeEstadosStore

    return (
      <MainHeader id="main-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            style={{
              fontSize: '26px',
              marginRight: '0.8rem',
              color: '#4682B4'
            }}
            icon={simbolo_pagina}
          />
          <PageTitle>{titulo_pagina}</PageTitle>
          {(titulo_pagina === 'MONITORAMENTO' ||
            titulo_pagina === 'OCORRÊNCIAS DO DIA') && <BolaVerdePiscando />}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
          <MenuEmpresas />
        </div>
        <ReactTooltip />
        <FontAwesomeIcon
          icon="headset"
          data-tip="SUPORTE"
          data-effect="solid"
          data-type="info"
          data-place="left"
          onClick={() =>
            window.open(
              `https://wavecode.atlassian.net/servicedesk/customer/portal/2/create/4?email=${email}`,
              '_blank'
            )
          }
          className="help-button icon-scale-xs"
        />
        <MenuUsuario />
      </MainHeader>
    )
  }
}
export default Menu

import React, { useState, createContext, useContext, useEffect } from 'react'
import { List, Hexagon } from 'react-feather'

import Api from '../../../../Stores/Conexao/conexao'
import { ToastSuccess } from '../../../../Stores/Funcoes/FuncoesGerais'
import maquinadeestadosstore from '../../../../Stores/MaquinaDeEstadosStore'
import modalLoadingStore from '../../../../Stores/ModalLoadingStore'
import OccupationArea from '../Components/occupationArea/OccupationArea'
import CustomFormat from '../Components/customFormat/CustomFormat'

const setting = [
  {
    title: 'ÁREAS DE ATUAÇÃO',
    subTitle: 'Selecionar as áreas de atuação da sua empresa',
    icon: <List size="30" />,
    component: <OccupationArea />,
    active: true
  },
  {
    title: 'FORMATO PERSONALIZADO',
    subTitle: 'Configurar prioridade, designação e qualificação',
    icon: <Hexagon size="30" />,
    component: <CustomFormat />,
    active: false
  }
]

export const SettingsContext = createContext(null)

export function SettingsContextProvider({ children }) {
  const [initialLoad, setInitialLoad] = useState(true)
  const [thereWasChange, setThereWasChange] = useState(false)
  // Request Variables
  const [selectedAreas, setSelectedAreas] = useState([])
  const [qualifiedFormat, setQualifiedFormat] = useState([])
  const [approvedFormat, setApprovedFormat] = useState([])
  const [reprovedFormat, setReprovedFormat] = useState([])
  const [portals, setPortals] = useState([])
  const [modality, setModality] = useState([])
  const [states, setStates] = useState([])
  const [treatment, setTreatment] = useState([])
  const [notificationEmail, setNotificationEmail] = useState('')
  const [days, setDays] = useState([])
  const [schedule, setSchedule] = useState([])
  const [wordsClassification, setWordsClassification] = useState('')
  const [exclusionWords, setExclusionWords] = useState('')
  const [monitorPortals, setMonitorPortals] = useState(false)
  const [buyer, setBuyer] = useState([])
  const [exclusionBuyer, setExclusionBuyer] = useState([])
  const [appointmentDownload, setAppointmentDownload] = useState('')
  const [typeItem, setTypeItem] = useState([])
  const [nameProfile, setNameProfile] = useState('')
  // Variables that keep the initial state of the request data
  const [initialSelectedAreas, setInitialSelectedAreas] = useState([])
  const [initialQualifiedFormat, setInitialQualifiedFormat] = useState([])
  const [initialApprovedFormat, setInitialApprovedFormat] = useState([])
  const [initialReproveFormat, setInitialReproveFormat] = useState([])
  // Other variables
  const [occupationArea, setOccupationArea] = useState([])
  const [settings, setSettings] = useState(setting)
  const [profiles, setProfiles] = useState([])
  const [allPortals, setAllPortals] = useState([])
  const [index, setIndex] = useState(0)
  const [idProfile, setIdProfile] = useState(null)

  useEffect(() => {
    if (profiles.length) setVariables(profiles[index])
  }, [index])

  const initialRequest = async () => {
    modalLoadingStore.handleOpenModal()

    const responseSettings = await Api.get(
      `/portal/configuracao_novo/${maquinadeestadosstore.getIdEmpresa()}`
    )
    const responseArea = await Api.get('/areas_atuacao/')
    const responseStatus = await Api.get(
      `/portal/config_status/${maquinadeestadosstore.getIdEmpresa()}`
    )

    setOccupationArea(responseArea.data)

    setProfiles(responseSettings.data.perfis_configuracao)

    setQualifiedFormat(responseStatus.data.config_status.qualificado)
    setInitialQualifiedFormat(responseStatus.data.config_status.qualificado)
    setApprovedFormat(responseStatus.data.config_status.aprovado)
    setInitialApprovedFormat(responseStatus.data.config_status.aprovado)
    setReprovedFormat(responseStatus.data.config_status.reprovado)
    setInitialReproveFormat(responseStatus.data.config_status.reprovado)

    modalLoadingStore.handleCloseModal()
    setVariables(responseSettings.data.perfis_configuracao[index])
    setInitialLoad(false)
  }

  // Functions that change the state

  const toggleIndex = value => {
    setIndex(value)
  }

  const toggleApprovedFormat = value => {
    setApprovedFormat(value)
  }

  const togglesQualifiedFormat = value => {
    setQualifiedFormat(value)
  }

  const toggleReprovedFormat = value => {
    setReprovedFormat(value)
  }

  const toggleSettings = value => {
    setSettings(value)
  }

  const tootleSelectedAreas = value => {
    setSelectedAreas(value)
  }

  const changeThereWasChange = value => {
    setThereWasChange(value)
  }

  // Other Functions

  const setVariables = profile => {
    setIdProfile(profile.id)
    setSelectedAreas(profile.area.map(area => area.id))
    setInitialSelectedAreas(profile.area.map(area => area.id))
    setPortals(profile.portal)
    setStates(profile.estados)
    setTreatment(profile.empresa_me)
    setNotificationEmail(profile.notificao_email)
    setDays(profile.notificao_dia)
    setSchedule(profile.notificao_hora)
    setWordsClassification(
      profile.palavra_classificao ? profile.palavra_classificao.split(',') : []
    )
    setExclusionWords(
      profile.palavra_exclusao ? profile.palavra_exclusao.split(',') : []
    )
    setMonitorPortals(profile.monitorar_portais)
    setBuyer(profile.comprador.map(buyer => buyer.id))
    setExclusionBuyer(profile.comprador_exclusao.map(buyer => buyer.id))
    setAppointmentDownload(profile.nomeacao_download)
    setTypeItem(profile.tipo_item)
    setNameProfile(profile.nome_perfil)
    setModality(profile.modalidade)
  }

  const saveData = async () => {
    const { value } = document.getElementById('select-perfil-config')
    console.log(value)

    const arrayTreatmentWords = array => {
      return array
        .join(',')
        .replace('[|]', '')
        .toLowerCase()
    }

    const data = {
      id: idProfile,
      notificao_email: notificationEmail,
      notificao_dia: days,
      notificao_hora: schedule,
      estados: states,
      palavra_classificao: arrayTreatmentWords(wordsClassification),
      palavra_exclusao: arrayTreatmentWords(exclusionWords),
      portal: portals,
      modalidade: modality,
      empresa_me: treatment,
      fornecedor_pk: maquinadeestadosstore.getIdEmpresa(),
      comprador: buyer,
      comprador_exclusao: exclusionBuyer,
      nomeacao_download: appointmentDownload,
      tipo_item: typeItem,
      nome_perfil: nameProfile,
      area: selectedAreas.map(area => Number(area))
    }

    try {
      const response = await Api.post('portal/configuracao_novo/', data)
      setInitialSelectedAreas(selectedAreas)
      setThereWasChange(false)

      const { perfis_configuracao } = response.data

      setProfiles(
        profiles.map(profile =>
          profile.id === perfis_configuracao.id ? perfis_configuracao : profile
        )
      )

      ToastSuccess('Dados salvos')
    } catch (err) {
      console.log('err', err)
    }
  }

  const saveOtherData = async () => {
    const dataOther = {
      fornecedor_id: maquinadeestadosstore.getIdEmpresa(),
      config_status: {
        aprovado: approvedFormat,
        qualificado: qualifiedFormat,
        reprovado: reprovedFormat
      }
    }

    try {
      await Api.post('portal/config_status/', dataOther)

      setInitialQualifiedFormat(qualifiedFormat)
      setInitialApprovedFormat(approvedFormat)
      setInitialReproveFormat(reprovedFormat)
      setThereWasChange(false)

      ToastSuccess('Dados salvos')
    } catch (err) {
      console.log('err', err)
    }
  }

  const clean = () => {
    setSelectedAreas(initialSelectedAreas)
    setQualifiedFormat(initialQualifiedFormat)
    setApprovedFormat(initialApprovedFormat)
    setReprovedFormat(initialReproveFormat)

    setThereWasChange(false)
  }

  return (
    <SettingsContext.Provider
      value={{
        // Variables
        profiles,
        initialLoad,
        thereWasChange,
        occupationArea,
        selectedAreas,
        initialSelectedAreas,
        qualifiedFormat,
        initialQualifiedFormat,
        approvedFormat,
        initialApprovedFormat,
        reprovedFormat,
        initialReproveFormat,
        settings,
        index,
        idProfile,
        // Functions
        initialRequest,
        clean,
        saveData,
        saveOtherData,
        changeThereWasChange,
        tootleSelectedAreas,
        toggleSettings,
        togglesQualifiedFormat,
        toggleApprovedFormat,
        toggleReprovedFormat,
        toggleIndex
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export const useSetting = () => {
  return useContext(SettingsContext)
}

// const Provider = ({ children }) => {
//   const [state, dispatch] = useReducer(reducer, initialState)
//   const [idx, setIdx] = useState(0)

//   useEffect(() => {
//     const { perfis } = state
//     if (perfis.length) setVariables(state.perfis[idx])
//   }, [idx])

//   const setCarregamentoInicial = valor =>
//     dispatch({ type: actions.SET_CARREGAMENTO_INICIAL, payload: valor })

//   const setPalavrasClassificacao = palavras =>
//     dispatch({ type: actions.SET_PALAVRAS_CLASSIFICACAO, payload: palavras })

//   const setPalavrasExclusao = palavras =>
//     dispatch({ type: actions.SET_PALAVRAS_EXCLUSAO, payload: palavras })

//   const setAcrescentarHora = hora =>
//     dispatch({ type: actions.SET_HORARIO, payload: hora })

//   const setAcrescentarDia = dia =>
//     dispatch({ type: actions.SET_DIAS, payload: dia })

//   const setAcrescentarEstados = estado =>
//     dispatch({ type: actions.SET_ESTADOS, payload: estado })

//   const setAcrescentarPortal = portal =>
//     dispatch({ type: actions.SET_PORTAIS, payload: portal })

//   const setAcrescentarModalidade = modalidade =>
//     dispatch({ type: actions.SET_MODALIDADE, payload: modalidade })

//   const setModificarMateriais = value =>
//     dispatch({ type: actions.SET_MATERIAIS_E_SERVICOS, payload: value })

//   const setCompradores = compradores => {
//     dispatch({
//       type: actions.SET_COMPRADORES,
//       payload: compradores
//     })
//   }

//   const setFormartoPersoQualificados = value =>
//     dispatch({
//       type: actions.SET_FORMATO_PERSO_QUALIFICADOS,
//       payload: Object.assign({}, value)
//     })

//   const setFormartoPersoAprovados = value =>
//     dispatch({
//       type: actions.SET_FORMATO_PERSO_APROVADOS,
//       payload: Object.assign({}, value)
//     })

//   const setCompradoresExclusao = compradores => {
//     dispatch({
//       type: actions.SET_COMPRADORES_EXCLUSAO,
//       payload: compradores
//     })
//   }

//   const setModificarNomeacaoArquivos = name => {
//     let valorSemAnderline = name.slice(1)
//     dispatch({
//       type: actions.SET_NOMEACAO_DOWNLOAD,
//       payload: valorSemAnderline
//     })
//   }

//   const setMonitorarTodosPortais = value => {
//     dispatch({ type: actions.SET_MONITORAR_PORTAIS, payload: Boolean(value) })
//   }

//   const init = async () => {
//     modalLoadingStore.handleOpenModal()
//     try {
//       const atuacao = await Api.get('/areas_atuacao/')
//       dispatch({ type: actions.SET_AREAS_ATUACAO, payload: atuacao.data })
//       const selecionadas = await Api.get(
//         `/portal/configuracao_novo/${maquinadeestadosstore.getIdEmpresa()}`
//       )
//       const status = await Api.get(
//         `/portal/config_status/${maquinadeestadosstore.getIdEmpresa()}`
//       )
//       const portais = await Api.get('/api/portal')
//       const modalidades = await Api.get('/api/modalidade/')
//       dispatch({ type: actions.SET_CARREGAMENTO_INICIAL, payload: true })
//       dispatch({
//         type: actions.SET_PERFIS,
//         payload: selecionadas.data.perfis_configuracao
//       })
//       dispatch({
//         type: actions.SET_MODALIDADES,
//         payload: modalidades.data
//       })
//       dispatch({ type: actions.SET_TODOS_PORTAIS, payload: portais.data })
//       setFormartoPersoQualificados(status.data.config_status.qualificado[0])
//       dispatch({
//         type: actions.SET_FORMATO_PERSO_QUALIFICADOS_INICIAL,
//         payload: Object.assign({}, status.data.config_status.qualificado[0])
//       })
//       setFormartoPersoAprovados(status.data.config_status.aprovado[0])
//       dispatch({
//         type: actions.SET_FORMATO_PERSO_APROVADOS_INICIAL,
//         payload: Object.assign({}, status.data.config_status.aprovado[0])
//       })
//       setVariables(selecionadas.data.perfis_configuracao[idx])
//     } catch (err) {
//       console.log('err', err)
//     } finally {
//       modalLoadingStore.handleCloseModal()
//       setCarregamentoInicial(false)
//     }
//   }

//   const setVariables = perfil => {
//     setMonitorarTodosPortais(perfil.monitorar_portais)
//     dispatch({
//       type: actions.SET_MONITORAR_PORTAIS_INICIAL,
//       payload: perfil.monitorar_portais
//     })
//     dispatch({
//       type: actions.SET_AREAS_SELECIONADAS,
//       payload: perfil.area
//     })
//     dispatch({
//       type: actions.SET_AREAS_ATUACAO_INICIAL,
//       payload: perfil.area
//     })
//     dispatch({
//       type: actions.SET_PALAVRAS_CLASSIFICACAO_INICIAL,
//       payload: perfil.palavra_classificao
//         ? perfil.palavra_classificao.split(',')
//         : []
//     })
//     dispatch({
//       type: actions.SET_PALAVRAS_EXCLUSAO_INICIAL,
//       payload: perfil.palavra_exclusao ? perfil.palavra_exclusao.split(',') : []
//     })
//     setPalavrasClassificacao(
//       perfil.palavra_classificao ? perfil.palavra_classificao.split(',') : []
//     )
//     setPalavrasExclusao(
//       perfil.palavra_exclusao ? perfil.palavra_exclusao.split(',') : []
//     )
//     dispatch({
//       type: actions.SET_DIAS_INICIAIS,
//       payload: perfil.notificao_dia
//     })
//     dispatch({
//       type: actions.SET_HORARIO_INICIAL,
//       payload: perfil.notificao_hora
//     })
//     dispatch({
//       type: actions.SET_HORARIO,
//       payload: perfil.notificao_hora
//     })
//     dispatch({
//       type: actions.SET_ESTADOS_INICIAIS,
//       payload: perfil.estados
//     })
//     dispatch({
//       type: actions.SET_ESTADOS,
//       payload: perfil.estados
//     })
//     dispatch({
//       type: actions.SET_MODALIDADE_INICIAL,
//       payload: perfil.modalidade
//     })
//     dispatch({
//       type: actions.SET_MODALIDADE,
//       payload: perfil.modalidade
//     })
//     dispatch({
//       type: actions.SET_PORTAIS_INICIAIS,
//       payload: perfil.portal
//     })
//     dispatch({
//       type: actions.SET_PORTAIS,
//       payload: perfil.portal
//     })
//     setCompradores(perfil.comprador.map(c => c.id))
//     dispatch({
//       type: actions.SET_COMPRADORES_INICIAL,
//       payload: perfil.comprador.map(c => c.id)
//     })
//     setCompradoresExclusao(perfil.comprador_exclusao.map(c => c.id))
//     dispatch({
//       type: actions.SET_COMPRADORES_EXCLUSAO_INICIAL,
//       payload: perfil.comprador_exclusao.map(c => c.id)
//     })
//     dispatch({
//       type: actions.SET_NOMEACAO_DOWNLOAD,
//       payload: perfil.nomeacao_download
//     })
//     dispatch({
//       type: actions.SET_NOMEACAO_DOWNLOAD_INICIAL,
//       payload: perfil.nomeacao_download
//     })
//     setAcrescentarDia(perfil.notificao_dia)
//     setModificarMateriais(perfil.tipo_item)
//     dispatch({
//       type: actions.SET_MATERIAIS_E_SERVICOS_INICIAL,
//       payload: perfil.tipo_item
//     })
//     dispatch({
//       type: actions.SET_TRATAMENTO,
//       payload: perfil.empresa_me
//     })
//     dispatch({
//       type: actions.SET_TRATAMENTO_INICIAL,
//       payload: perfil.empresa_me
//     })
//     dispatch({
//       type: actions.SET_NOVO_PERFIL,
//       payload: perfil.novo_perfil || false
//     })
//   }

//   const validation = () => {
//     const { estados, dias, horario, modalidade, portais } = state

//     if (!dias.length) {
//       AlertaVermelho(
//         'É necessário selecionar ao menos um dia para receber notificações por email'
//       )
//       return false
//     }
//     if (!horario.length) {
//       AlertaVermelho(
//         'É necessário selecionar ao menos um horário para receber notificações por email'
//       )
//       return false
//     }
//     if (!estados.length) {
//       AlertaVermelho(
//         'Você precisa adicionar pelo menos um estado para monitorar'
//       )
//       return false
//     }
//     if (!portais.length) {
//       AlertaVermelho('É necessário adicionar ao menos um portal')
//       return false
//     }
//     if (!modalidade.length) {
//       AlertaVermelho('É necessário adicionar ao menos uma modalidade')
//       return false
//     }
//     return true
//   }

//   const saveData = async () => {
//     const { value } = document.getElementById('select-perfil-config')
//     const perfil = state.perfis[value]

//     const tratamentoDoArray = array => {
//       return array
//         .join(',')
//         .replace('[|]', '')
//         .toLowerCase()
//     }

//     const data = {
//       id: perfil.id,
//       notificao_email: perfil.notificao_email,
//       notificao_dia: state.dias,
//       notificao_hora: state.horario,
//       estados: state.estados,
//       palavra_classificao: tratamentoDoArray(state.palavrasClassificacao),
//       palavra_exclusao: tratamentoDoArray(state.palavrasExclusao),
//       portal: state.portais,
//       monitorar_portais: Boolean(state.monitorarPortais),
//       modalidade: state.modalidade,
//       empresa_me: state.tratamento,
//       fornecedor_pk: maquinadeestadosstore.getIdEmpresa(),
//       comprador: state.compradores,
//       comprador_exclusao: state.compradores_exclusao,
//       nomeacao_download: state.nomeacaoDownload,
//       tipo_item: state.materiaisEServicos,
//       nome_perfil: perfil.nome_perfil,
//       area: state.areasSelecionadas.map(a => Number(a))
//     }

//     const { novo_perfil } = perfil
//     if (!validation()) return
//     try {
//       const res = await Api.post(
//         novo_perfil ? 'portal/configuracao/' : 'portal/configuracao_novo/',
//         data
//       )
//       dispatch({
//         type: actions.SET_AREAS_ATUACAO_INICIAL,
//         payload: state.areasSelecionadas
//       })
//       dispatch({
//         type: actions.SET_PALAVRAS_CLASSIFICACAO_INICIAL,
//         payload: state.palavrasClassificacao
//       })
//       dispatch({
//         type: actions.SET_PALAVRAS_EXCLUSAO_INICIAL,
//         payload: state.palavrasExclusao
//       })
//       dispatch({ type: actions.SET_DIAS_INICIAIS, payload: state.dias })
//       dispatch({ type: actions.SET_HORARIO_INICIAL, payload: state.horario })
//       dispatch({ type: actions.SET_ESTADOS_INICIAIS, payload: state.estados })
//       dispatch({
//         type: actions.SET_MODALIDADE_INICIAL,
//         payload: state.modalidade
//       })
//       dispatch({ type: actions.SET_PORTAIS_INICIAIS, payload: state.portais })
//       dispatch({
//         type: actions.SET_NOMEACAO_DOWNLOAD_INICIAL,
//         payload: state.nomeacaoDownload
//       })
//       dispatch({
//         type: actions.SET_COMPRADORES_INICIAL,
//         payload: state.compradores
//       })
//       dispatch({
//         type: actions.SET_COMPRADORES_EXCLUSAO_INICIAL,
//         payload: state.compradores_exclusao
//       })
//       dispatch({
//         type: actions.SET_HOUVE_ALTERACAO,
//         payload: Boolean(false)
//       })
//       dispatch({
//         type: actions.SET_MONITORAR_PORTAIS_INICIAL,
//         payload: state.monitorarPortais
//       })
//       dispatch({
//         type: actions.SET_MATERIAIS_E_SERVICOS_INICIAL,
//         payload: state.materiaisEServicos
//       })
//       dispatch({
//         type: actions.SET_TRATAMENTO_INICIAL,
//         payload: state.tratamento
//       })

//       const { perfis_configuracao } = res.data

//       const newProfile = [...state.perfis]
//       newProfile[newProfile.length - 1] = perfis_configuracao

//       dispatch({
//         type: actions.SET_PERFIS,
//         payload: novo_perfil
//           ? newProfile
//           : state.perfis.map(p =>
//               p.id === perfis_configuracao.id ? perfis_configuracao : p
//             )
//       })
//       if (novo_perfil) {
//         setTimeout(() => {
//           document.getElementById('select-perfil-config').value =
//             state.perfis.length - 1
//         }, 100)
//       }
//       ToastSuccess('Dados salvos')
//     } catch (err) {
//       console.log('err', err)
//     }
//   }

//   const saveDataOuther = async () => {
//     const dataOuther = {
//       fornecedor_id: maquinadeestadosstore.getIdEmpresa(),
//       config_status: {
//         aprovado: Object.values(state.formatoPersoAprovados),
//         qualificado: Object.values(state.formatoPersoQualificados)
//       }
//     }

//     try {
//       await Api.post('portal/config_status/', dataOuther)

//       dispatch({
//         type: actions.SET_FORMATO_PERSO_APROVADOS_INICIAL,
//         payload: state.formatoPersoAprovados
//       })
//       dispatch({
//         type: actions.SET_FORMATO_PERSO_QUALIFICADOS_INICIAL,
//         payload: state.formatoPersoQualificados
//       })
//       dispatch({
//         type: actions.SET_HOUVE_ALTERACAO,
//         payload: Boolean(false)
//       })

//       ToastSuccess('Dados salvos')
//     } catch (err) {
//       console.log('err', err)
//     }
//   }

//   const limpar = () => {
//     dispatch({ type: actions.SET_AREAS_SELECIONADAS, payload: [] })
//     dispatch({
//       type: actions.SET_AREAS_SELECIONADAS,
//       payload: state.areasDeAtuacaoInicial
//     })
//     dispatch({
//       type: actions.SET_PALAVRAS_CLASSIFICACAO,
//       payload: state.palavrasClassificacaoInicial
//     })
//     dispatch({
//       type: actions.SET_PALAVRAS_EXCLUSAO,
//       payload: state.palavrasExclusaoInicial
//     })
//     dispatch({ type: actions.SET_DIAS, payload: state.diasIniciais })
//     dispatch({ type: actions.SET_HORARIO, payload: state.horarioInicial })
//     dispatch({ type: actions.SET_ESTADOS, payload: state.estadosIniciais })
//     dispatch({
//       type: actions.SET_MODALIDADE,
//       payload: state.modalidadeInicial
//     })
//     dispatch({
//       type: actions.SET_MONITORAR_PORTAIS,
//       payload: state.monitorarPortaisInicial
//     })
//     dispatch({ type: actions.SET_PORTAIS, payload: state.portaisIniciais })
//     dispatch({
//       type: actions.SET_NOMEACAO_DOWNLOAD,
//       payload: state.nomeacaoDownloadInicial
//     })
//     dispatch({
//       type: actions.SET_HOUVE_ALTERACAO,
//       payload: Boolean(false)
//     })
//     dispatch({
//       type: actions.SET_COMPRADORES,
//       payload: state.compradoresInicial
//     })
//     dispatch({
//       type: actions.SET_COMPRADORES_EXCLUSAO,
//       payload: state.compradores_exclusaoInicial
//     })
//     dispatch({
//       type: actions.SET_MATERIAIS_E_SERVICOS,
//       payload: state.materiaisEServicosInicial
//     })
//     dispatch({
//       type: actions.SET_TRATAMENTO,
//       payload: state.tratamentoInicial
//     })
//     dispatch({
//       type: actions.SET_FORMATO_PERSO_QUALIFICADOS,
//       payload: state.formatoPersoQualificadosInicial
//     })
//     dispatch({
//       type: actions.SET_FORMATO_PERSO_APROVADOS,
//       payload: state.formatoPersoAprovadosInicial
//     })
//   }

//   const setConfiguracoes = configs => {
//     dispatch({ type: actions.SET_CONFIGURACOES, payload: configs })
//   }

//   const removerPerfil = async id => {
//     try {
//       await Api.post('/portal/delete_configuracao/', { id })
//       const newPerfis = state.perfis.filter(p => p.id !== id)
//       dispatch({
//         type: actions.SET_PERFIS,
//         payload: newPerfis
//       })
//       ToastSuccess('Perfil removido')
//     } catch (err) {
//       AlertaVermelho('Erro ao remover perfil')
//     }
//   }

//   const adicionarPerfil = config => {
//     const { nome, descricao, email } = config

//     dispatch({ type: actions.CLEAN_DATA })
//     dispatch({ type: actions.SET_NOME, payload: nome })
//     dispatch({ type: actions.SET_DESCRICAO, payload: descricao })
//     dispatch({
//       type: actions.SET_PERFIS,
//       payload: [
//         ...state.perfis,
//         {
//           area: [],
//           comprador: [],
//           comprador_exclusao: [],
//           empresa_me: ['Todos'],
//           estados: [],
//           fornecedor_pk: maquinadeestadosstore.getIdEmpresa(),
//           modalidade: [],
//           nome_perfil: nome,
//           nomeacao_download: '',
//           notificao_dia: [],
//           notificao_email: email,
//           notificao_hora: [],
//           palavra_classificao: '',
//           palavra_exclusao: '',
//           portal: [],
//           tipo_item: ['M', 'S'],
//           novo_perfil: true
//         }
//       ]
//     })
//     setIdx(state.perfis.length)
//     setConfiguracoes(
//       state.configuracoes.map((c, i) => {
//         return { ...c, active: i === 0 }
//       })
//     )
//     setTimeout(() => {
//       document.getElementById('select-perfil-config').value =
//         state.perfis.length
//     }, 100)
//   }

//   const value = {
//     ...state,
//     setConfiguracoes,
//     setAreasSelecionadas: array =>
//       dispatch({ type: actions.SET_AREAS_SELECIONADAS, payload: array }),
//     setHouveAlteracao: valor =>
//       dispatch({ type: actions.SET_HOUVE_ALTERACAO, payload: Boolean(valor) }),
//     setAcrescentarHora,
//     setMonitorarTodosPortais,
//     setAcrescentarDia,
//     setPalavrasClassificacao,
//     setPalavrasExclusao,
//     setAcrescentarEstados,
//     setAcrescentarModalidade,
//     setAcrescentarPortal,
//     setModificarMateriais,
//     setModificarNomeacaoArquivos,
//     setCompradores,
//     setIdx,
//     setCompradoresExclusao,
//     setTratamento: arr =>
//       dispatch({ type: actions.SET_TRATAMENTO, payload: arr }),
//     setFormartoPersoQualificados,
//     setFormartoPersoAprovados,
//     saveData,
//     saveDataOuther,
//     removerPerfil,
//     adicionarPerfil,
//     limpar,
//     init
//   }

//   return (
//     <ConfiguracaoContext.Provider value={value}>
//       {children}
//     </ConfiguracaoContext.Provider>
//   )
// }

// const ConfiguracaoProvider = Provider

// export { ConfiguracaoContext }
// export default ConfiguracaoProvider

import React, { useContext } from 'react';
import { Container, TabLista } from "./styles";
import { ListaDeLicitacoesContext } from '../context';


const Filtro = () => {
  const { mudarStatus, status, quantidade, mudarPagina } = useContext(ListaDeLicitacoesContext);

  const { todos, boletim, aprovado, qualificado } = quantidade;

  const handleChange = (status) => {
    mudarPagina(null, 1);
    mudarStatus(status);
  }

  return (
    <Container>
      <TabLista active={status === "todos"} onClick={() => handleChange('todos')}>
        TODOS
        <div className="counter">{todos}</div>
      </TabLista>
      <TabLista active={status === "boletim"} onClick={() => handleChange('boletim')}>
        BOLETIM DIÁRIO
        <div className="counter">{boletim}</div>
      </TabLista>
      <TabLista active={status === "qualificado"} onClick={() => handleChange('qualificado')}>
        QUALIFICADOS
        <div className="counter">{qualificado}</div>
      </TabLista>
      <TabLista active={status === "aprovado"} onClick={() => handleChange('aprovado')}>
        APROVADOS
        <div className="counter" >{aprovado}</div>
      </TabLista>
    </Container>
  );
};

export default Filtro;
import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import MUToolTip from '@material-ui/core/Tooltip'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'

import { MODULO_EDITAIS, MODULO_BOLETIM, MODULO_PROPOSTA } from '../../../Services/contratos'
import { handleMsg } from '../SidebarIcon'
import useOnClickOutside from '../../../UsefulComponents/useClickOutside'
import { ToastError } from '../../../Stores/Funcoes/FuncoesGerais'

import { IconContainer, SecondaryMenuContainer } from './style'

const SideItem = inject('MaquinaDeEstadosStore')(
  observer(
    ({
      MaquinaDeEstadosStore,
      title,
      url,
      icon,
      items,
      acesso,
      dataExpiracao
    }) => {
      const {
        credenciais,
        comboId,
        checkIdCombo,
        getContrato
      } = MaquinaDeEstadosStore
      let disabled
      if (
        !credenciais.find(cred => cred.portal_id === 5) &&
        title === 'PROPOSTA'
      )
        disabled = true
      return (
        <SideItemComStore
          disabled={disabled}
          title={title}
          url={url}
          icon={icon}
          items={items}
          acesso={acesso}
          getContrato={getContrato}
          dataExpiracao={dataExpiracao}
          comboId={comboId}
          checkIdCombo={checkIdCombo}
        />
      )
    }
  )
)

const SideItemComStore = ({
  disabled,
  title,
  url,
  icon,
  items,
  acesso,
  getContrato,
  checkIdCombo
}) => {
  const ref = useRef()

  const [expanded, setExpanded] = useState(false)
  const { pathname } = window.location

  useEffect(() => {
    checkIdCombo()
  }, [])

  useOnClickOutside(ref, () => setExpanded(false))

  if (!acesso)
    return (
      <IconContainer active={expanded || pathname.includes(url)} blocked>
        {icon}
        {handleMsg('Módulo bloqueado, contatar setor comercial.')}
      </IconContainer>
    )

  if (disabled)
    return (
      <MUToolTip title={title} placement="right">
        <IconContainer
          active={false}
          onClick={() =>
            ToastError('Nenhuma credencial cadastrada para o portal ComprasNet')
          }
        >
          {icon}
        </IconContainer>
      </MUToolTip>
    )

  return (
    <div ref={ref}>
      <MUToolTip title={title} placement="right">
        <IconContainer active={expanded} onClick={setExpanded}>
          {icon}
        </IconContainer>
      </MUToolTip>
      {expanded && (
        <SecondaryMenuContainer>
          <div className="content">
            <div className="header">
              {icon}
              <div className="title">{title}</div>
            </div>
            <div className="body">
              {items.map((item, index) =>
                (getContrato(MODULO_EDITAIS) && index === 5) ||
                getContrato(MODULO_BOLETIM) || getContrato(MODULO_PROPOSTA) ? (
                  <Link
                    to={url + item.url}
                    onClick={() => setExpanded(false)}
                    key={item.url}
                  >
                    {item.icon}
                    <div className="title">{item.name}</div>
                  </Link>
                ) : (
                  <>
                    <div className="item-disable">
                      <div
                        className="item-blocked"
                        data-tip="Módulo bloqueado, contatar setor comercial."
                        data-effect="solid"
                        data-place="right"
                        data-type="warning"
                      >
                        <FontAwesomeIcon icon="exclamation" />
                      </div>
                      <div className="icone">{item.icon}</div>
                      <ReactTooltip />
                      <div className="title">{item.name}</div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        </SecondaryMenuContainer>
      )}
    </div>
  )
}

export default SideItem

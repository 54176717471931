import React from 'react'
import styled from 'styled-components'

export const InputStyle = styled.div`
  position: relative;
  display: flex;
  background-color: ${props => props.bgColor || '#e2e2e2'};
  height: ${props => props.height || '30px'};
  width: ${props => props.width};
  /* box-shadow: 1px 1px 8px 1px #231c1c47; */
  box-shadow: 1px 1px 4px 0px #231c1ca6;
  border: 1px solid #fff;
  border-radius: 8px;
  transition: background-color 500ms;
  color: ${props => props.color || '#000'};

  :focus-within {
    outline: none;
    background: #fff;
  }

  span.icon-container {
    position: absolute;
    left: 0;
    background: #fff;
    display: flex;
    height: 100%;
    width: 35px;
    align-items: center;
    justify-content: center;
    border: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #3a566e;
    user-select: none;
  }

  .input {
    margin-left: 35px;
    width: 100%;
    max-height: 200px;
    padding-left: 5px;
    background: ${props => props.bgColor || '#e2e2e2'};
    border: none;
    font-weight: 500;
    font-size: 15px;
    border-radius: 10px;
    transition: background-color 500ms;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    :focus {
      outline: none;
      background: #fff;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #3a566e;
      opacity: 52%;
      font-weight: 300;
    }
  }
`

export const MultipleSelectStyle = styled(InputStyle)`
  margin: ${props => props.margin || 0};

  span {
    cursor: pointer;
    transition: background-color 300ms;
    background: ${props =>
      props.allSelected ? '#d2e9ff !important' : 'initial'};
  }

  select {
    user-select: none;
    /* ::-webkit-scrollbar {
      width: 10px;
      border-radius: 7px;
    }
    ::-webkit-scrollbar-track {
      background: #adadad;
      border-radius: 7px;
    }

    ::-webkit-scrollbar-thumb {

      background: #fafafa;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    } */
  }
  select[multiple]:focus option:checked {
    background: #007eff linear-gradient(0deg, #007eff 0%, #91badca1 100%);
    box-shadow: 1px 1px 7px #00000069;
  }
  option:checked {
    background: #94c9ff linear-gradient(0deg, #94c9ff 0%, #c3e4ffa1 100%);
  }
`

export const InputTextAreaStyle = styled(InputStyle)`
  margin-left: 0;
  height: auto;

  textarea {
    margin-left: 0 !important;
  }
`

export const TagsInputContainerStyle = styled(InputStyle)`
  max-height: 155px;

  .react-tagsinput2 {
    overflow-y: auto;
  }
`

export const SelectCompanyStyle = styled(InputStyle)`
  height: 30px;
  width: 300px;
  background: transparent;
  box-shadow: 1px 1px 9px 0px #231c1c24;
  border: 1px solid #fffcfc;

  .input {
    background: #efefef;
  }
`

export const SelectCompany = props => {
  const { icon, id, width, children, value, onChange } = props
  return (
    <SelectCompanyStyle width={width}>
      <span className="icon-container">{icon || '-'}</span>
      <select className="input" id={id} onChange={onChange} value={value}>
        {children}
      </select>
    </SelectCompanyStyle>
  )
}

export const SelectInput = props => {
  const {
    bgColor,
    icon,
    id,
    width,
    children,
    value,
    onChange,
    defaultValue,
    color
  } = props
  return (
    <InputStyle color={color} bgColor={bgColor} width={width}>
      <span className="icon-container">{icon || '-'}</span>
      <select
        className="input"
        id={id}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      >
        {children}
      </select>
    </InputStyle>
  )
}

export const TextInput = props => {
  const { icon, width, value, onChange, placeholder, id, name, bgColor } = props
  return (
    <InputStyle width={width} bgColor={bgColor}>
      <span className="icon-container">{icon || '-'}</span>
      <input
        className="input"
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </InputStyle>
  )
}

export const TagContainerInput = props => {
  const { icon, width, children } = props
  return (
    <TagsInputContainerStyle
      width={width}
      style={{ height: 'auto', marginBottom: '5px' }}
    >
      <span className="icon-container">{icon || '-'}</span>
      {children}
    </TagsInputContainerStyle>
  )
}

export const TextAreaInput = props => {
  const { width, value, onChange, placeholder, id, name, rows } = props
  return (
    <InputTextAreaStyle width={width}>
      <textarea
        className="input"
        rows={rows}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </InputTextAreaStyle>
  )
}

export const MultipleSelectInput = props => {
  const {
    icon,
    id,
    width,
    height,
    children,
    value,
    onChange,
    margin,
    clickAction,
    allSelected
  } = props
  return (
    <MultipleSelectStyle
      width={width}
      height={height}
      margin={margin}
      allSelected={allSelected}
    >
      <span className="icon-container" onClick={clickAction}>
        {icon || '-'}
      </span>
      <select
        className="input"
        id={id}
        onChange={onChange}
        value={value}
        multiple
      >
        {children}
      </select>
    </MultipleSelectStyle>
  )
}

// export const DateInput = props => {
//   const { icon, bgColor, inputProps, width, id } = props;
//   const { onChange, value, name, placeholder } = inputProps;

//   return (
//     <InputStyle width={width} bgColor={bgColor}>
//       <span className="icon-container">{icon || "-"}</span>
//       <input
//         className="input"
//         id={id}

//         placeholder={placeholder}

//       />
//     </InputStyle>
//   )
// }

import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { FileText } from 'react-feather'
import { useHistory } from 'react-router-dom'

import Spinner from '../../../UsefulComponents/Spinner'
import SubHeader from '../SubHeader/SubHeader'
import SectionTitle from '../SubHeader/SectionTitle'
import PerfilSelection from '../SubHeader/PerfilSelection'
import StatusModal from '../StatusNotice'
import BotaoFiltrar from '../Filter/botaoFiltro'
import TabelaBoletim from './tableBulletin'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'

import * as S from '../Style'

const BoletimDiario = inject(
  'BoletimStore',
  'MaquinaDeEstadosStore',
  'DetalhesEditalStore'
)(
  observer(({ BoletimStore, MaquinaDeEstadosStore, DetalhesEditalStore }) => {
    // NECESSÁRIO CHAMAR AS VARIÁVEIS QUE VÃO SER OBSERVADAS AQUI.
    const { toggleModal } = DetalhesEditalStore
    const {
      isFetching,
      fetchEditaisNovo,
      editais,
      total_registros,
      total_paginas,
      pagination,
      crescente,
      perfis,
      totalEditaisProcurados
    } = BoletimStore
    const { id_fornecedor, getStatus } = MaquinaDeEstadosStore
    return (
      <BoletimDiarioComStore
        toggleModal={toggleModal}
        data={{ isFetching, fetchEditaisNovo }}
        idEmpresa={id_fornecedor}
        editais={editais}
        total_registros={total_registros}
        total_paginas={total_paginas}
        pagination={pagination}
        crescente={crescente}
        perfis={perfis}
        getStatus={getStatus}
        totalEditaisProcurados={totalEditaisProcurados}
      />
    )
  })
)

const BoletimDiarioComStore = ({
  data,
  idEmpresa,
  editais,
  total_paginas,
  pagination,
  crescente,
  toggleModal,
  perfis,
  getStatus
}) => {
  const { isFetching, fetchEditaisNovo } = data
  const { state, setPerfilData } = useFiltroEdital()
  const { page, changePage } = pagination

  const { push } = useHistory()

  useEffect(() => {
    getStatus()
    console.log('HElloss')
  }, [])

  useEffect(() => {
    if (idEmpresa) fetchEditaisNovo(state)
  }, [idEmpresa])

  const handlePagina = (event, value) => {
    changePage(value)
    fetchEditaisNovo(state)
  }

  const handleRedirect = () => {
    const { id } = perfis.find(p => p.activated)
    setPerfilData(id)
    push(`/prospeccao/pesquisa/${id}`)
  }

  return (
    <S.Container>
      <SubHeader overflow>
        <SectionTitle title="BOLETIM DIÁRIO" icon={<FileText />} />
        <PerfilSelection />
        <BotaoFiltrar onClick={handleRedirect} noActive />
      </SubHeader>
      <S.Body>
        <S.ContainerTable>
          {/* {!isFetching && <FiltroBoletin />} */}
          {isFetching ? (
            <Spinner />
          ) : (
            <TabelaBoletim
              toggleModal={toggleModal}
              crescente={crescente}
              editais={editais}
              handlePagina={handlePagina}
              page={page}
              total_paginas={total_paginas}
            />
          )}
        </S.ContainerTable>
      </S.Body>
      <StatusModal />
    </S.Container>
  )
}

export default BoletimDiario

import styled from 'styled-components'

export const NoticeContainerThatHasNoNotice = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 20px;
  min-height: 50px;
  justify-content: center;
  box-shadow: 1px 1px 9px #4e4e4e;

  @media (max-height: 780px) {
    padding: 10px;
  }

  .pagination {
    display: flex;
    width: 70%;
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-items: center;
  }

  .MuiPaginationItem-textPrimary.Mui-selected {
    color: #fff;
    background-color: #5b8eb1;
  }
`
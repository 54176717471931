import React, { useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ModalConfirmation from '../../../Components/Modals/ModalConfirmation'
import { useSetting } from './Context/index.js'

import * as S from './Style/styles'

const ActionButtons = () => {
  const {
    settings,
    thereWasChange,
    selectedAreas,
    initialSelectedAreas,
    qualifiedFormat,
    initialQualifiedFormat,
    approvedFormat,
    reprovedFormat,
    initialApprovedFormat,
    initialReproveFormat,
    clean,
    saveData,
    saveOtherData
  } = useSetting()
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const able = useMemo(() => {
    return !(
      selectedAreas.sort().join() === initialSelectedAreas.sort().join() &&
      JSON.stringify(reprovedFormat) === JSON.stringify(initialReproveFormat) &&
      JSON.stringify(qualifiedFormat) ===
        JSON.stringify(initialQualifiedFormat) &&
      JSON.stringify(approvedFormat) === JSON.stringify(initialApprovedFormat)
    )
  }, [selectedAreas, qualifiedFormat, approvedFormat, reprovedFormat])

  const ref = document.getElementById('select-perfil-config')

  if (!ref) return null

  const { value } = ref

  if (!value) return null

  const active = () => {
    return settings.find(item => item.active === true)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    clean()
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleSubmit = async () => {
    let currentComponent = active()
    setLoading(true)
    currentComponent.title !== 'FORMATO PERSONALIZADO' && (await saveData())
    currentComponent.title === 'FORMATO PERSONALIZADO' &&
      (await saveOtherData())
    setLoading(false)
  }

  return (
    <>
      <S.Bottoms>
        <button
          className="limpar"
          onClick={showModal}
          disabled={!able || !thereWasChange}
        >
          DESFAZER ALTERAÇÕES
        </button>
        <button
          className="salvar"
          onClick={handleSubmit}
          disabled={!able || loading || !thereWasChange}
        >
          {loading ? <FontAwesomeIcon spin icon="spinner" /> : 'SALVAR'}
        </button>
      </S.Bottoms>
      {isModalVisible && (
        <ModalConfirmation
          handleOk={handleOk}
          textButtonOk={'Desfazer'}
          message={
            'Tem certeza que deseja desfazer as alterações que não foram salvas?'
          }
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}

export default ActionButtons

import React, { useState } from 'react'

import loginStores from '../../Stores/LoginStores'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as S from './styles'

const ListaDeUsuariosSuporte = ({ listaDeUsuarios }) => {
  const [users, setUsers] = useState(listaDeUsuarios)

  const handleChange = e => {
    const { value } = e.target
    if (!value) setUsers(listaDeUsuarios)
    else setUsers(users.filter(us => us.email.includes(value)))
  }

  const handleClick = user => {
    loginStores.inicializar(user)
  }

  return (
    <S.Overlay>
      <S.Container>
        <FontAwesomeIcon icon="search" />
        <input type="text" onChange={handleChange} />
        {users.map(user => (
          <S.User onClick={() => handleClick(user)}>{user.email}</S.User>
        ))}
      </S.Container>
    </S.Overlay>
  )
}

export default ListaDeUsuariosSuporte

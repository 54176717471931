import React, { useContext } from 'react';
import { format, } from 'date-fns';
import BadegRedondo from '../../../../Components/ReusableComponents/Badeg/badegRedondo';
import MaquinaDeEstadoStore from '../../../../Stores/MaquinaDeEstadosStore'
import  moment from 'moment'
import imprimirPropostaStore from '../../ImprimirProposta/store'
import { Edit, Trash } from 'react-feather';


import BadgeStatus from '../../Components/BadgeStatus';
import ModalConfirmacao from "../../../../Components/NewModal/modalConfirmacao";
import { PropostaEspecificaContext } from '../../../../Components/Modules/Proposta2/CadastrarProposta/PropostaEspecifica/context';
import { API } from '../../../../Components/Modules/Proposta2/CadastrarProposta/ListaDeLicitacoes/api';

const RedirectProposta = ({ edital }) => {
  const { licitacao, uasg } = edital;
  const { buscarManualmente } = useContext(PropostaEspecificaContext);

  const handleClick = () => {
    buscarManualmente({ url: API.buscaManual(licitacao, uasg) });
    MaquinaDeEstadoStore.redireciona_proposta()
  }

  return <BadegRedondo disabled={edital.status === 'Enviada'} onClick={handleClick} autoMargin icon={<Edit style={{ width: '20px', marginLeft: '2px' }} />} background={'#d2a122'} />
}

const formattedDate = (data) => {
  return (format(new Date(data), 'dd/MM/yyyy'))
};

const tratamentoStatus = (type) => {
  switch (type) {
    case 'Não Enviada':
      return { msg: "NÃO ENVIADA", background: '#d2a122' }
    case 'Enviada':
      return { msg: "ENVIADA", background: '#18a908' }
    case 'Processando':
      return { msg: "PROCESSANDO", background: '#085ea9' }
    case 'Erro':
      return { msg: "ERRO AO ENVIAR", background: '#a90808' }
    default:
      return { msg: "indefinido", background: '#d2a122' }
  }
}

export const columns = [
  {
    name: 'PROPOSTA',
    selector: 'proposta',
    grow: 0,
    wrap: true,
  },
  {
    name: 'LICITAÇÃO',
    selector: 'licitacao',
    center: true,
    grow: 0,
    // style: {
    //   fontWeight: 'bold',
    //   marginRight: '9px',

    // },
  },
  {
    name: 'UASG',
    selector: 'uasg',
    center: true,
    grow: 0,
    
  },
  {
    name: 'ORGÃO',
    selector: 'orgao',
    // sortable: true,
    grow: 2,
    wrap: true,
  },
  {
    name: 'DATA DISPUTA',
    selector: 'disputa',
    sortable: true,
    center: true,
    grow: 1,
    cell: ({ disputa }) => {
      return (
        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <span>{formattedDate(disputa)}</span>
        <span>{(moment(disputa).format("HH:mm"))}</span>
        </div>
      )
      
    }
  },
  {
    name: 'STATUS',
    selector: 'status',
    wrap: true,
    center: true,
    grow: 0.8,
    cell: ({ status }) => {
      const { msg, background } = tratamentoStatus(status)
      return (<BadgeStatus status={msg} background={background} />)
    }
  },
  {
    name: 'DATA ENVIO',
    selector: 'data_envio',
    center: true,
    grow: 0.8,
    cell: ({ data_envio }) => {
     return (
      <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      <span>{formattedDate(data_envio)}</span>
      <span>{(moment(data_envio).format("HH:mm"))}</span>
      </div>
     )
    }
  },

  {
    name: 'EDITAR',
    selector: 'id',
    wrap: true,
    center: true,
    grow: 0,

    cell: (edital) => {
      return (
      <div style={{overflow: "initial"}}>
        <RedirectProposta edital={edital} />
      </div>
      )
    }
  },
  {
    name: 'EXCLUIR',
    selector: 'id',
    wrap: true,
    center: true,
    grow: 0,

    cell: ({ proposta }) => {
      return (<ModalConfirmacao
        button={<BadegRedondo autoMargin icon={icon} background={'#d21a09'} />}
        title="EXCLUIR PROPOSTA"
        confirmAction={() => imprimirPropostaStore.excluirProposta(proposta)}
        message={'Deseja realmente excluir essa proposta!'}
      />)
    }
  },

];

const icon = <Trash style={{ width: '20px', marginLeft: '2px' }} />

// <BadegRedondo autoMargin icon={<Trash style={{ width: '20px', marginLeft: '2px' }} onClick={() => redirect(id)}/>} background={'#d21a09'} onClick={() => console.log(id)} />)
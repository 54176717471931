import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { ContainerChat } from './styleMonitoramento'
import { Minus, Send } from 'react-feather'
import TextField from '@material-ui/core/TextField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormHelperText from '@material-ui/core/FormHelperText'
import { ReactTooltip } from '../../globalStyles'

@inject('MonitoramentoStore', 'MenuStores')
@observer
class ChatPregoeiro extends Component {
  constructor() {
    super()
    this.state = {
      value: '',
      id: ''
    }
  }

  handleValue = e => {
    this.setState({ id: this.props.MonitoramentoStore.licitacao.licitacao })
    this.setState({ value: e.target.value })
    if (this.props.MonitoramentoStore.respostaChat) {
      this.props.MonitoramentoStore.respostaChat = ''
    }
  }

  submit = id => {
    let msg = this.state.value
    this.props.MonitoramentoStore.responderChat(id, msg)
    this.setState({ value: '' })
  }

  render() {
    const {
      respostaChatErro,
      respostaChatSuccess,
      statusChat,
      toggleMsgChat,
      isFetting,
      licitacao: { licitacao_codigo, licitacao }
    } = this.props.MonitoramentoStore

    return (
      <>
        <ReactTooltip data-place="left" />
        <ChatMsg toggleMsgChat={toggleMsgChat} />
        {statusChat && (
          <ContainerChat status={statusChat}>
            <div
              style={{
                background: '#3A566E',
                width: '100%',
                boxShadow: '0px 2px 5px 1px #6b6b6b69',
                borderRadius: '8px 8px 0px 0px',
                color: 'white'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px',
                  wight: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    wight: '100%'
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={'comments'} />{' '}
                    <b>&nbsp;&nbsp; Licitação - {licitacao_codigo}</b>
                  </div>
                </div>
                <div className={'icon-scale'} onClick={() => toggleMsgChat()}>
                  <Minus />
                </div>
              </div>
            </div>

            <div
              style={{
                padding: '20px',
                flexDirection: 'column',
                display: 'flex',
                height: '90%',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              {!isFetting ? (
                <>
                  <div style={{ fontWeight: 700, color: '#2e4354' }}>
                    Enviar mensagem para pregoeiro quando solicitado
                  </div>

                  <div style={{ width: '100%', marginTop: '30px' }}>
                    <TextField
                      fullWidth={true}
                      id="outlined-helperText"
                      label="Digite sua mensagem"
                      variant="outlined"
                      value={
                        this.state.id === licitacao ? this.state.value : ''
                      }
                      onChange={e => this.handleValue(e)}
                      multiline
                      rows="11"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />

                    <FormHelperText
                      style={{
                        marginBottom: '10px',
                        color: respostaChatErro ? 'red' : 'green',
                        textAlign: 'center'
                      }}
                      id="component-helper-text"
                    >
                      {this.state.id === licitacao && (
                        <>
                          {respostaChatErro} {respostaChatSuccess}
                        </>
                      )}
                    </FormHelperText>
                  </div>

                  <div style={{ marginTop: '25px' }}>
                    <button
                      onClick={() => this.submit(licitacao)}
                      type="button"
                      className="btn btn-sm btn-outline-success"
                    >
                      ENVIAR&nbsp;&nbsp;
                      <Send size={'15px'} />
                    </button>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    height: '80vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <br />
                </div>
              )}
            </div>
          </ContainerChat>
        )}
      </>
    )
  }
}

export default ChatPregoeiro

const ChatMsg = ({ toggleMsgChat }) => {
  return (
    <div
      className={'icon-scale'}
      data-tip="CHAT PREGOEIRO"
      data-type="info"
      data-place="left"
      data-effect="solid"
      style={{
        background: '#3A566E',
        borderRadius: '100%',
        position: 'absolute',
        zIndex: '88888',
        width: '70px',
        height: '70px',
        right: '-10px',
        bottom: '2vh',
        boxShadow: '0px 2px 5px 1px #6b6b6b69',
        opacity: '0.8'
      }}
      onClick={() => toggleMsgChat()}
    >
      <div style={{ marginTop: '13px', marginLeft: '12px' }}>
        <FontAwesomeIcon
          style={{ fontSize: '38px', color: 'white' }}
          icon={'comments'}
        />
      </div>
    </div>
  )
}

import React from 'react'
import {
  ModalContainer,
  ModalHead,
  ModalTitle,
  BodyMessage,
  ActionButtons
} from './styles/styles'
//"../EditaisSelecionados/ModalDetalhesEdital/styles";
import { AlertCircle } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalOverlay } from '../../Components/NewModal/sytleModal'
import { ReactTooltip } from '../../globalStyles'

export const ModalCnpj = ({ width, title, message, closeModal, status }) => {
  const handleConfirm = () => {
    closeModal()
  }

  return (
    <>
      <ReactTooltip />
      {status && (
        <ModalOverlay>
          <ModalContainer
            width={width || '700px'}
            left="unset"
            right="unset"
            top="unset"
            bottom="unset"
          >
            <ModalHead>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AlertCircle color="#fff" />
                <ModalTitle>{title}</ModalTitle>
              </div>
              <FontAwesomeIcon
                className="icon-scale"
                icon="times"
                color="#fff"
                style={{ cursor: 'pointer', opacity: '0.9', fontSize: '15px' }}
                onClick={handleConfirm}
              />
            </ModalHead>
            <div
              style={{ width: '100%', textAlign: 'center', margin: '2rem 0' }}
            >
              <AlertCircle color={'#3A566E'} size={'5rem'} />
            </div>

            <BodyMessage>{message}</BodyMessage>
            <ActionButtons>
              <button
                className="icon-scale-xs modal-confirm"
                onClick={handleConfirm}
              >
                CONFIRMAR
              </button>
            </ActionButtons>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}

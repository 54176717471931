import React from 'react'
import { inject, observer } from 'mobx-react'
import Spinner from '../../../UsefulComponents/Spinner'
import TabelaEditais from './TableNotice'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'

const Editais = inject(
  'BuscaManualStore',
  'DetalhesEditalStore',
  'MaquinaDeEstadosStore'
)(
  observer(
    ({ BuscaManualStore, DetalhesEditalStore, MaquinaDeEstadosStore }) => {
      const {
        isFetching,
        fetchEditais,
        editais,
        total_registros,
        total_paginas,
        pagination,
        crescente,
        fetchEditaisNovo
      } = BuscaManualStore
      const { id_fornecedor } = MaquinaDeEstadosStore
      const { toggleModal } = DetalhesEditalStore
      return (
        <EditaisComStore
          data={{ isFetching, fetchEditais, fetchEditaisNovo }}
          idEmpresa={id_fornecedor}
          editais={editais}
          total_registros={total_registros}
          total_paginas={total_paginas}
          pagination={pagination}
          crescente={crescente}
          toggleModal={toggleModal}
          fetchEditaisNovo={fetchEditaisNovo}
        />
      )
    }
  )
)

const EditaisComStore = ({
  data,
  editais,
  total_paginas,
  pagination,
  crescente,
  toggleModal
}) => {
  const { isFetching } = data
  const { filtrarManual } = useFiltroEdital()

  const handlePagina = (event, value) => {
    pagination.changePage(value)
    filtrarManual('fechar', true)
  }

  if (isFetching) return <Spinner />

  return (
    <>
      <TabelaEditais
        editais={editais}
        page={pagination.page}
        crescente={crescente}
        total_paginas={total_paginas}
        toggleModal={toggleModal}
        handlePagina={handlePagina}
      />
    </>
  )
}

export default Editais

import React, { useEffect, useState } from 'react'
import { CardMsg, CustomSwitch, BodySwitch } from '../../globalStyles'
import { Star } from 'react-feather'
import { FormControl, FormGroup } from 'react-bootstrap'

export const TratamentoDiferenciado = ({
  empresa_me,
  onChangeFiltro,
  defaultValue,
  handleFiltroMe
}) => {
  const [valorInicial, changeValorInicial] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue !== valorInicial) changeValorInicial(defaultValue)
  }, [defaultValue])

  return (
    <CardMsg
      minHeight={'106px'}
      flexDirection={'row'}
      margin={'10px 8px 10px 0px'}
      justifyContent={'space-between'}
      // fBasis="75%"
    >
      <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <Star />
          &nbsp;&nbsp;
        </div>
        <div style={{ padding: '0px 10px 0px 10px', textAlign: 'start' }}>
          <div>TRATAMENTO DIFERENCIADO</div>
          <div style={{ fontSize: '10px' }}>
            Selecionar as opções de tratamento diferenciado para filtrar novas
            oportunidades encontradas.
          </div>
        </div>
      </div>
      <BodySwitch
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '200px',
          textAlign: 'center',
          marginTop: '12px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FormGroup style={{ width: '100%' }}>
          <FormControl
            name="filtro"
            componentClass="select"
            onChange={e => onChangeFiltro(e)}
            defaultValue={valorInicial}
            style={{ margin: 0, padding: 0 }}
          >
            <option
              key={1}
              id={1}
              value={'Todos'}
              selected={valorInicial === 'Todos'}
            >
              TODOS EDITAIS
            </option>
            <option
              key={2}
              id={2}
              value={'Excluir'}
              selected={valorInicial === 'Excluir'}
            >
              EXCLUIR ME/EPP
            </option>
            <option
              key={3}
              id={3}
              value={'FILTRAR ME/EPP'}
              selected={defaultValue === 'FILTRAR ME/EPP'}
            >
              FILTRAR ME/EPP
            </option>
          </FormControl>
          <div>Selecione o tratamento</div>
        </FormGroup>
      </BodySwitch>
      <div
        style={{
          display: 'flex',
          width: '40%',
          justifyContent: 'space-between'
        }}
      >
        {Array.isArray(empresa_me) && (
          <>
            <BodySwitch
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '30%',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CustomSwitch
                checked={empresa_me.includes('Tipo I')}
                onChange={handleFiltroMe}
                value="Tipo I"
                color="primary"
              />
              <div>
                <b>TIPO I</b>
              </div>
              <div style={{ fontSize: '10px' }}>
                Participação Exclusiva de ME/EPP
              </div>
            </BodySwitch>

            <BodySwitch
              style={{
                display: 'flex',
                width: '30%',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CustomSwitch
                value="Tipo II"
                checked={empresa_me.includes('Tipo II')}
                onChange={handleFiltroMe}
                color="primary"
              />
              <div>
                <b>TIPO II</b>
              </div>
              <div style={{ fontSize: '10px' }}>
                Exigência de subcontratação de ME/EPP
              </div>
            </BodySwitch>

            <BodySwitch
              style={{
                display: 'flex',
                width: '30%',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CustomSwitch
                value="Tipo III"
                checked={empresa_me.includes('Tipo III')}
                onChange={handleFiltroMe}
                color="primary"
              />
              <div>
                <b>TIPO III</b>
              </div>
              <div style={{ fontSize: '10px' }}>
                Cota para participação exclusiva de ME/EPP AÇÕES
              </div>
            </BodySwitch>
          </>
        )}
      </div>
    </CardMsg>
  )
}

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Layers,
  FileText,
  Star,
  Bookmark,
  DownloadCloud,
  Settings,
  Trash2,
  ZoomIn,
  Paperclip,
  MoreVertical
} from 'react-feather'

import SidebarIcon from './SidebarIcon'
import SideItem from './SideItem/SideItem'
import maquinadeestadosstore from '../../Stores/MaquinaDeEstadosStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  MODULO_EDITAIS,
  MODULO_BOLETIM,
  MODULO_PROPOSTA,
  MODULO_MONITORAMENTO
} from '../../Services/contratos'
import { SomSitema } from '../../som/SomSistema'

import { Barra, Sidebar, SidebarHeader } from './SideStyle'
import '../../Themes/side.css'

const logo = '/static/Imagens/logo_wavecode.png'

export const ItensProspeccao = [
  {
    url: '/boletim',
    name: 'BOLETIM DIÁRIO',
    icon: <FileText />
  },
  {
    url: '/qualificados',
    name: 'EDITAIS QUALIFICADOS',
    icon: <Star />
  },
  {
    url: '/aprovados',
    name: 'EDITAIS APROVADOS',
    icon: <Bookmark />
  },
  {
    url: '/reprovados',
    name: 'EDITAIS REPROVADOS',
    icon: <Trash2 />
  },
  {
    url: '/download',
    name: 'BAIXAR EDITAIS',
    icon: <DownloadCloud />
  },
  {
    url: '/pesquisa/0',
    name: 'PESQUISA',
    icon: <ZoomIn />
  },
  {
    url: '/configuracoes',
    name: 'CONFIGURAÇÕES',
    icon: <Settings />
  },
  {
    url: '/demais-configuracoes',
    name: 'OUTRAS CONFIGURAÇÕES',
    icon: <MoreVertical />
  }
]

export const ItensProposta = [
  {
    url: '/cadastrar',
    name: 'CADASTRAR PROPOSTA',
    icon: <FileText />
  },
  {
    url: '/gerenciar',
    name: 'GERENCIAR PROPOSTA',
    icon: <Paperclip />
  },
  {
    url: '/configuracoes',
    name: 'CONFIGURAÇÕES',
    icon: <Settings />
  }
]

@inject(
  'MenuStores',
  'ConfiguracoesMonitoramentoStores',
  'SideStores',
  'MaquinaDeEstadosStore',
  'PainelAvisoStores',
  'OcorrenciasDoDiaStores'
)
@observer
class Side extends Component {
  render() {
    const urlAtual = window.location.pathname

    const url = '/painel_monitoramento'
    const { especifica } = this.props.ConfiguracoesMonitoramentoStores
    const { licitacoes } = this.props.PainelAvisoStores

    const {
      qtd_monitoramento_verde,
      qtd_monitoramento_vermelho,
      qtd_ocorrencias,
      situacao_licitacao_id
    } = this.props.SideStores

    const {
      getContrato,
      getDataValidadeContrato,
      isPushWorking,
      checkIdCombo
    } = this.props.MaquinaDeEstadosStore

    return (
      <Sidebar onClick={() => this.props.MenuStores.consultaTitle()}>
        <SomSitema />

        <SidebarHeader isPushWorking={isPushWorking}>
          <div className="background-logo">
            <img src={logo} alt={'logo wavecode'} />
          </div>
        </SidebarHeader>

        <SidebarIcon
          url="/dashboard"
          title="DASHBOARD"
          ico={'tachometer-alt'}
          acesso={true}
          path={this.props.path}
        />

        <SideItem
          checkIdCombo={checkIdCombo}
          title="PROSPECÇÃO"
          url="/prospeccao"
          icon={<Layers />}
          items={ItensProspeccao}
          acesso={getContrato(MODULO_EDITAIS) || getContrato(MODULO_BOLETIM)}
          dataExpiracao={
            getDataValidadeContrato(MODULO_EDITAIS) ||
            getDataValidadeContrato(MODULO_MONITORAMENTO)
          }
        />

        <SideItem
          title="PROPOSTA"
          url="/proposta"
          icon={
            <FontAwesomeIcon icon="file-upload" style={{ fontSize: '26px' }} />
          }
          items={ItensProposta}
          acesso={getContrato(MODULO_PROPOSTA)}
          dataExpiracao={
            getDataValidadeContrato(MODULO_EDITAIS) ||
            getDataValidadeContrato(MODULO_MONITORAMENTO)
          }
        />

        <SidebarIcon
          url="/licitacoes"
          title="LICITAÇÕES"
          ico={'gavel'}
          // qtdSuccess={situacao.monitoramento && qtd_licitacoes_dia}
          acesso={
            getContrato(MODULO_MONITORAMENTO) ||
            maquinadeestadosstore.comboId === 66 || maquinadeestadosstore.comboId === 70
          }
          dataExpiracao={
            getDataValidadeContrato(MODULO_EDITAIS) ||
            getDataValidadeContrato(MODULO_MONITORAMENTO)
          }
          path={this.props.path}
        />

        <SidebarIcon
          url="/painel_monitoramento"
          title="PAINEL DE LICITAÇÕES"
          ico={'tv'}
          icoTV={'gavel'}
          qtdSuccess={
            especifica.monitoramento && url === urlAtual
              ? licitacoes.length
              : situacao_licitacao_id.length
          }
          acesso={
            getContrato(MODULO_MONITORAMENTO) ||
            maquinadeestadosstore.comboId === 66 || maquinadeestadosstore.comboId === 70
          }
          dataExpiracao={
            getDataValidadeContrato(MODULO_EDITAIS) ||
            getDataValidadeContrato(MODULO_MONITORAMENTO)
          }
          path={this.props.path}
        />

        <SidebarIcon
          url="/monitoramento"
          title="MONITORAMENTO"
          ico={'comments'}
          qtdSuccess={
            getContrato(MODULO_MONITORAMENTO) && qtd_monitoramento_verde
          }
          qtdDanger={
            getContrato(MODULO_MONITORAMENTO) && qtd_monitoramento_vermelho
          }
          acesso={getContrato(MODULO_MONITORAMENTO)}
          dataExpiracao={getDataValidadeContrato(MODULO_MONITORAMENTO)}
          path={this.props.path}
        />

        <SidebarIcon
          url="/ocorrenciasdodia"
          title="PAINEL DE OCORRÊNCIAS"
          ico={'tv'}
          icoTV={'comments'}
          qtdDanger={getContrato(MODULO_MONITORAMENTO) && qtd_ocorrencias}
          acesso={getContrato(MODULO_MONITORAMENTO)}
          dataExpiracao={getDataValidadeContrato(MODULO_MONITORAMENTO)}
          path={this.props.path}
        />
        
        <Barra />

        <SidebarIcon
          url="/configuracoesmonitoramento"
          title="CONFIGURAÇÕES DO MONITORAMENTO"
          ico={'comments'}
          icoTV={'cog'}
          acesso={getContrato(MODULO_MONITORAMENTO)}
          dataExpiracao={getDataValidadeContrato(MODULO_MONITORAMENTO)}
          path={this.props.path}
        />

        <SidebarIcon
          url="/configuracaogeral"
          title="CONFIGURAÇÃO GERAL"
          ico={'cogs'}
          acesso={true}
          path={this.props.path}
        />
      
        <SidebarIcon
          url="/financeiro"
          title="FINANCEIRO"
          ico={'hand-holding-usd'}
          acesso={true}
          path={this.props.path}
        />
      </Sidebar>
    )
  }
}

export default Side;

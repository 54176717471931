import React, { Component, useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Animacao,
  WrapContainer,
  SingleMessageContainer,
  AnimacaoContainer,
  ToolTipActions
} from './styleMonitoramento'
import { ListaDeMensagens, DivFlex, WrapperCol } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import InfinityLoader from '../../UsefulComponents/InfinityLoader'
moment.locale()

const Carregando = PARAM => {
  return (
    <AnimacaoContainer>
      <Animacao>
        <FontAwesomeIcon
          size={'6x'}
          icon={'comments'}
          style={{ color: '#3a566e' }}
        />
      </Animacao>
      <h6 style={{ marginBottom: '0', textAlign: 'center', color: '#517695' }}>
        <FontAwesomeIcon spin icon={'spinner'} style={{ color: '#3a566e' }} />
        &nbsp;&nbsp;CARREGANDO AS MENSAGENS
      </h6>
    </AnimacaoContainer>
  )
}

const Msg = (height, top) => {
  return (
    <WrapContainer>
      <h5
        style={{
          textAlign: 'center',
          color: '#3a566ebf',
          marginBottom: '3rem'
        }}
      >
        <FontAwesomeIcon icon={'comments'} />
        &nbsp;&nbsp; NÃO HÁ MENSAGENS COM O FILTRO SELECIONADO
      </h5>
    </WrapContainer>
  )
}

const MsgMonitorada = ({
  i,
  msg,
  palavras,
  verificada,
  licitacao,
  id,
  verificar
}) => {
  const [showTooltip, toggleTooltip] = useState(false)
  const [screenPosition, changeScreenPos] = useState({ x: null, y: null })
  const actionMenu = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (actionMenu.current && !actionMenu.current.contains(event.target))
        toggleTooltip(false)
    }
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  const color =
    msg.verificada || verificada ? '#dad6d6' : msg.match ? '#CE4747' : '#4BA22C'

  const handleTooltip = e => {
    changeScreenPos({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY
    })
    toggleTooltip(!showTooltip)
  }

  const arrayComRegex = () => {
    if (!palavras.length) return []
    const nomeEmpresa = palavras[palavras.length - 1]
    const regex = nomeEmpresa.split(' ').join(' ?')
    return palavras.map(pal => (pal === nomeEmpresa ? new RegExp(regex) : pal))
  }

  return (
    <SingleMessageContainer
      key={`monitoramento_msg_${i}`}
      id={`monitoramento_msg_${i}`}
      background={color}
      onClick={handleTooltip}
      ref={actionMenu}
    >
      {showTooltip && !verificada && (
        <ToolTipActions
          x={screenPosition.x}
          y={screenPosition.y}
          onClick={e => verificar(e)}
          value={id}
        >
          VERIFICAR
        </ToolTipActions>
      )}

      <FontAwesomeIcon
        icon="comments"
        style={{ fontSize: '1.3rem', color, margin: '1rem' }}
      />
      <div style={{ padding: '0.5rem 0.1rem', fontSize: '0.85rem' }}>
        <Highlighter
          highlightClassName={
            !msg.verificada && !verificada ? 'TagMarkRed' : 'TagMarkSucesses'
          }
          // searchWords={palavras.concat(licitacao.observacao)}
          searchWords={arrayComRegex()}
          autoEscape={false}
          textToHighlight={msg.texto}
        />
      </div>

      <div
        style={{
          width: '140px',
          minWidth: '140px',
          borderLeft: '1px solid #dadada',
          marginLeft: 'auto',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          color: '#5f5f5f',
          padding: '0.5rem'
        }}
      >
        <small style={{ color: '#757575' }}>{`Origem: ${msg.remetente}`}</small>
        <b style={{ fontSize: '0.8rem' }}>{msg.data_hora.substring(0, 10)}</b>
        <b style={{ fontSize: '0.7rem' }}>{msg.data_hora.substring(10, 19)}</b>
      </div>
    </SingleMessageContainer>
  )
}

@inject('MonitoramentoStore')
@observer
class PainelMensagens extends Component {
  render() {
    const {
      palavras,
      mensagens,
      fetchingMessages,
      changeData,
      licitacao,
      isFetching,
      verificaMensagem,
      /* marcar_msgs_como_verificadas,  */ carregando
    } = this.props.MonitoramentoStore
    if (!mensagens)
      return (
        <WrapContainer width={'69%'} style={{ background: '#0000001a' }}>
          <WrapperCol
            background={true}
            style={{ background: '#fff5d1', marginTop: '10%' }}
          >
            <DivFlex justifyContent={'center'}>
              <DivFlex style={{ textAlign: 'center' }}>
                <h6 style={{ marginBottom: '0' }}>
                  <FontAwesomeIcon icon="exclamation-triangle" />
                  &nbsp;&nbsp;NÃO HÁ MENSAGENS
                </h6>
              </DivFlex>
            </DivFlex>
          </WrapperCol>
        </WrapContainer>
      )
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1px',
          height: '100%'
        }}
      >
        {isFetching ? (
          <>
            {!carregando ? (
              mensagens.length > 0 ? (
                <ListaDeMensagens>
                  {mensagens.map((msg, i) => (
                    <MsgMonitorada
                      i={i}
                      msg={msg}
                      key={msg.id}
                      palavras={palavras}
                      verificada={msg.verificada}
                      licitacao={licitacao}
                      id={msg.id}
                      verificar={verificaMensagem}
                    />
                  ))}
                  <InfinityLoader
                    data={mensagens}
                    changeData={changeData}
                    isFetching={fetchingMessages}
                  />
                </ListaDeMensagens>
              ) : (
                Msg('50%', '12%')
              )
            ) : (
              Carregando()
            )}
          </>
        ) : (
          Carregando('100%', '30%')
        )}
      </div>
    )
  }
}

export default PainelMensagens

import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import "../Debug";
import ModalLoadingStore from "./ModalLoadingStore";
import {
  AlertaInfo,
  AlertaSuccess,
  AlertaVermelho
} from "./Funcoes/FuncoesGerais";
import StatusConexaoStores from "./StatusConexaoStores";
import { statusConexao } from ".././StatusConexao/StatusConexao";
import SideStores from "./SideStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import MenuStores from "./MenuStores";
import axios from "axios";
import { FormatDateData } from "./Funcoes/FuncoesGerais";
import LoginStores from "./LoginStores";

const ApiEditaisME = "portal/editais_empresa_me_alterado/";
const ApiEditais = "portal/editais_empresa_me_alterado/";

class NewEditaisStores {
  @observable
  pesquisaDataType = "abertura";
  @observable
  pesquisaDataTypeFavoritos = "ativos";
  @observable
  dataTypeFavoritosME = false;
  @observable
  dataTypeFavoritosAtivo = true;
  @observable
  dataTypeFavoritosVencido = true;

  @observable
  statusDataType = true;

  @observable
  pagina_atual = 1;
  @observable
  editais = null;
  @observable
  editais_total = 0;
  @observable
  configuracaoPalavras = null;
  //ordenação
  @observable
  ordenar = "disputa";
  @observable
  ordenarAbertura = false;

  // Modal mostrar detalhes

  @observable
  modalDetalhesActive = false;
  @observable
  detalhesEdital = null;

  // Modal proposta
  @observable
  modalPropostaActive = false;
  @observable
  dadosProposta = null;

  @observable
  id_excluir = 0;
  @observable
  lista_download = [];
  @observable
  lista_corrida = [];
  @observable
  cont_download_em_andamento = 0;
  @observable
  downloadHashList = [];

  @observable
  favorito = false;
  @observable
  currentEditais = [];
  @observable
  currentPage = null;
  @observable
  totalPages = null;
  @observable
  numero_editais_totais = 0;
  @observable
  mudar_key = "";
  @observable
  pagina_visualizada = "";
  @observable
  itens_edital = [];
  @observable
  flagStatus = true;
  @observable
  statusAtulizar = true;
  @observable
  styleData = "abertura";
  @observable
  filtroMe = false;
  @observable
  flagConsultar = 1;
  @observable
  Tag = null;
  @observable
  isFetching = false;
  @observable
  isActiveEditar = false;

  @observable
  btnFiltrar = false;
  @observable
  btnFiltrarDia = true;
  @observable
  btnFavorito = false;
  @observable
  btnExcluido = false;

  @observable
  editalID = 0;

  // Componente Data

  @observable
  dataInicial = new Date().toLocaleDateString("pt-br");
  @observable
  dataFinal = new Date().toLocaleDateString("pt-br");
  @observable
  qualData = "inicial";
  @observable
  showing = false;

  @action
  onChangeData = ({ target: { value, name } }) => {
    this[name] = value;
  };

  @action
  abreDate = () => {
    this.showing = true;
  };

  @action
  fecharDate = () => {
    this.showing = false;
    if (this.qualData === "final") this.qualData = "inicial";
  };

  @action
  handleSelect = range => {
    this.dataInicial = new Date(range.startDate).toLocaleDateString("pt-br");
    this.dataFinal = new Date(range.endDate).toLocaleDateString("pt-br");
    if (this.qualData === "final")
      setTimeout(() => {
        this.fecharDate();
        this.qualData = "inicial";
      }, 250);
    else this.qualData = "final";
  };

  // *******************

  @action
  consultaEdital = data => {
    this.filtroMe
      ? this.filtrarEditais(ApiEditais, data)
      : this.filtrarEditais(ApiEditaisME, data);
  };

  @action
  loadData = data => {
    let dataAtual = new Date().toLocaleDateString("pt-br");
    if (data) {
      this.dataInicial = dataAtual;
      this.dataFinal = dataAtual;
    }
    return {
      tipo: this.pesquisaDataType,
      dmin: this.dataInicial,
      dmax: this.dataFinal,
      pagina: this.pagina_atual,
      ordenar: this.ordenar,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
    };
  };

  @action
  filtrarEditais = async (url, data) => {
    ModalLoadingStore.handleOpenModal();
    try {
      const response = await Api.post(url, this.loadData(data));
      this.editais_total = response.data.total_registros;
      this.editais = response.data.editais;
      this.firstTime = false;
      this.Tag = response.data.palavras_usadas
        ? String(response.data.palavras_usadas)
        : "";
      this.isFetching = true;
    } catch (e) {
      console.log("err", e);
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action
  loadDataME = () => {
    return {
      tipo: this.pesquisaDataType,
      date: new Date(this.dataSimples).toLocaleDateString("pt-br"),
      pagina: this.pagina_atual
    };
  };

  @action
  sincronizar_Licit_E = id => {
    this.editais = this.editais.map(item => {
      return id === item.id ? { ...item, sincronizado: true } : item;
    });

    Api.post("licitacoes/cadastrar_licitacao_manual/", {
      id_edital: id,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      user_pk: LoginStores.user_id
        ? LoginStores.user_id
        : MaquinaDeEstadosStore.getIdUser()
    })
      .then(response => {
        AlertaSuccess("Edital adicinado as licitações com sucesso!");
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
      });
  };

  // Ações modal detalhes
  @action
  mostrarDetalhes = async edital => {
    const { id } = edital;
    ModalLoadingStore.handleOpenModal();
    try {
      const res = await Api.post("portal/itens_edital/", { id });
      this.detalhesEdital = {
        ...edital,
        dados_singulares: res.data.itens_edital.reverse()
      };
      if (this.modalPropostaActive) this.modalPropostaActive = false;
      this.modalDetalhesActive = true;
    } catch (err) {
      // AlertaVermelho(err.data)
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action
  closeModalDetalhes = () => (this.modalDetalhesActive = false);

  // Ações modal proposta
  @action
  mostrarProposta = async edital => {
    // const { id } = edital;
    // ModalLoadingStore.handleOpenModal();
    // try {
    //     const res = await Api.post("portal/itens_edital/", { id });
    //     this.dadosProposta = { ...edital, dados_singulares: res.data.itens_edital.reverse() };
    if (this.modalDetalhesActive) this.modalDetalhesActive = false;
    this.dadosProposta = edital;
    this.modalPropostaActive = true;
    // } catch (err) {
    //     console.log(err);
    //     // AlertaVermelho(err.data)
    // } finally {
    //     ModalLoadingStore.handleCloseModal();
    // }
  };

  @action
  closeModalProposta = () => (this.modalPropostaActive = false);

  // @action resetarFiltros = () => {
  //     if (this.btnFavorito) return this.handelBtnFiltraFavorito();
  //     if (this.btnFiltrarDia) return this.handelBtnFiltrarDia();
  //     if (this.btnFiltrar) return this.handelBtnFiltrar();
  //     if (this.btnExcluido) return this.handelBtnExcluidos();
  // };

  @action
  resetarFiltros = () => {
    this.btnExcluido = false;
    this.btnFiltrar = false;
    this.btnFiltrarDia = true;
    this.btnFavorito = false;
    this.pagina_atual = 1;
    this.fecharDate();
  };

  @action
  handelBtnExcluidos = () => {
    this.btnExcluido = true;
    this.btnFiltrar = false;
    this.btnFiltrarDia = false;
    this.btnFavorito = false;
    this.pagina_atual = 1;
    this.fecharDate();
    this.listar_excluidos();
  };

  @action
  handelBtnFiltrar = () => {
    this.btnFiltrar = true;
    this.btnFiltrarDia = false;
    this.btnFavorito = false;
    this.btnExcluido = false;
    this.pagina_atual = 1;
    this.fecharDate();
    this.filtrarEditais();
  };

  // @action
  // handelBtnFiltrarME = () => {
  //   this.btnFiltrar = true;
  //   this.btnFiltrarDia = false;
  //   this.btnFavorito = false;
  //   this.btnExcluido = false;
  //   this.pagina_atual = 1;
  //   this.fecharDate();
  //   this.filtrarEditaisME();
  // };

  @action
  handelBtnFiltrarDia = () => {
    this.btnFiltrar = false;
    this.btnFiltrarDia = true;
    this.btnFavorito = false;
    this.btnExcluido = false;
    this.pagina_atual = 1;
    this.fecharDate();
    this.limparfiltro();
  };

  @action
  handelBtnFiltraFavorito = () => {
    this.btnFiltrar = false;
    this.btnFiltrarDia = false;
    this.btnFavorito = true;
    this.btnExcluido = false;
    this.pagina_atual = 1;
    this.listar_favoritos();
  };

  @action
  handleCloseModal = () => {
    this.isActiveEditar = false;
  };

  @action
  handleOpenModal = () => {
    this.isActiveEditar = true;
  };

  @action
  onChangeDataSimples = data => {
    this.dataSimples = data;
  };

  @action
  handleOrdenacao = () => {
    this.ordenar = this.ordenar === "disputa" ? "abertura" : "disputa";
    if (this.btnFavorito) return;
    this.filtroMe ? this.filtrarEditaisME() : this.filtrarEditais();
  };

  @action
  handleStatusFavoritos = ({ target: { value } }) => {
    this.pagina_atual = 1;
    if ("me" === value) {
      this.dataTypeFavoritosME = !this.dataTypeFavoritosME;
    } else {
      this.pesquisaDataTypeFavoritos = value;
    }
    // this.listar_favoritos()
  };

  @action
  handleStatusPesquisa = ({ target: { value, checked } }) => {
    switch (value) {
      case "abertura":
        this.statusDataType = !this.statusDataType;
        this.pesquisaDataType = checked ? "abertura" : "disputa";
        break;
      case "disputa":
        this.statusDataType = !this.statusDataType;
        this.pesquisaDataType = checked ? "disputa" : "abertura";
        break;
      case "me":
        this.filtroMe = !this.filtroMe;
        break;
      default:
    }
  };

  @action
  ChecksCuurentPage = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action
  listar_favoritos = async () => {
    this.isFetching = false;
    this.styleData = this.pesquisaDataType;
    this.flagConsultar = 2;

    let ativo = this.pesquisaDataTypeFavoritos === "ativos";
    let vencido = this.pesquisaDataTypeFavoritos === "vencidos";
    ModalLoadingStore.handleOpenModal();
    try {
      const response = await Api.post("portal/listar_favoritos_novo/", {
        pagina: this.pagina_atual,
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
        ativo: ativo,
        vencido: vencido,
        empresa_me: this.filtroMe
      });
      this.editais_total = response.data.total_registros;
      this.editais = response.data.editais;
    } catch (error) {
      console.log("err", error);
    } finally {
      this.isFetching = true;
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action
  listar_excluidos = async () => {
    this.editais = [];
    this.isFetching = false;
    this.styleData = this.pesquisaDataType;
    this.flagConsultar = 4;
    ModalLoadingStore.handleOpenModal();
    try {
      const response = await Api.post("portal/listar_excluidos/", {
        pagina: this.pagina_atual,
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
      });
      this.editais_total = response.data.total_registros;
      this.editais = response.data.editais;
    } catch (error) {
      console.log("err", error);
    } finally {
      this.isFetching = true;
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action
  onPageChanged = data => {
    const { currentPage } = data;
    this.lista_download = [];
    this.ChecksCuurentPage();
    if (this.pagina_atual !== currentPage) {
      this.pagina_atual = currentPage;
      this.handleConsulta(currentPage);
    }
  };

  @action
  handleConsulta = () => {
    if (this.flagConsultar === 1) this.filtrarEditais();
    if (this.flagConsultar === 2 && this.isFetching) this.listar_favoritos();
    if (this.flagConsultar === 3 && this.isFetching) this.filtrarEditaisME();
    if (this.flagConsultar === 4 && this.isFetching) this.listar_excluidos();
  };

  @action
  excluirEdital = id => {
    Api.post("portal/alterar_excluidos/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      tipo: true
    })
      .then(response => {
        AlertaSuccess("Edital excluído!");
        this.handleConsulta();

        SideStores.consulta_notificacoes();
        // SideStores.consulta_notificacoes_update()
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho("Não foi possível excluir o edital");
      });
  };

  @action
  restaurarEdital = async id => {
    this.isFetching = false;
    try {
      await Api.post("portal/alterar_excluidos/", {
        id_edital: id,
        fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
        tipo: false
      });
      await this.listar_excluidos();
      AlertaSuccess("Edital Restaurado!");
    } catch (error) {
      if (!statusConexao()) {
        StatusConexaoStores.handleOpenModal();
      }
    } finally {
      this.isFetching = true;
    }
  };

  @action
  listar_excluidos = async () => {
    this.editais = [];
    this.isFetching = false;
    this.styleData = this.pesquisaDataType;
    this.flagConsultar = 4;
    ModalLoadingStore.handleOpenModal();
    try {
      const response = await Api.post("portal/listar_excluidos/", {
        pagina: this.pagina_atual,
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
      });
      this.editais_total = response.data.total_registros;
      this.editais = response.data.editais;
    } catch (error) {
      console.log("err", error);
    } finally {
      this.isFetching = true;
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action
  excluirPermanente = id => {
    Api.post("portal/excluir_edital_selecionado/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        AlertaSuccess("Edital excluído permanentemente!");
        this.handleConsulta();
        SideStores.consulta_notificacoes();
        // SideStores.consulta_notificacoes_update()
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho("Não foi possível excluir o edital");
      });
  };

  @action
  excluir_lixeira() {
    let id = MaquinaDeEstadosStore.getIdEmpresa();
    Api.get("portal/limpar_lixeira/" + id, {})
      .then(response => {
        AlertaSuccess("A Lixeira foi limpa com sucesso!");
        this.handleConsulta();
        SideStores.consulta_notificacoes();
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho("Não foi possível excluir o edital");
      });
  }

  @action
  consultaApiEditalID = id => {
    Api.post("portal/itens_edital/", { id: id })
      .then(response => {
        this.itens_edital = response.data.itens_edital;
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
      });
  };

  @action
  favoritar = id => {
    Api.post("portal/marcar_favorito/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        AlertaSuccess("Adicionado aos favoritos com sucesso");
        this.handleConsulta();
      })
      .catch(error => {
        console.log(error);
        AlertaVermelho("Não foi possível favoritar o edital.");
      });
  };
  // @action adicionar_favorito_busca = (id) => {
  //     this.isFetching = false;
  //     const data = new FormData();
  //     data.set("codigo_edital", id);
  //     data.set("id_fornecedor", MaquinaDeEstadosStore.getIdEmpresa());
  //     Api.post("portal/inserir_busca_edital_favorito/", data, {}).then((response) => {
  //         if (response.data.resposta === "SIM") {
  //             AlertaVerde("Adicionado aos favoritos com sucesso!");
  //             SideStores.consulta_notificacoes()
  //         } else {
  //             AlertaVermelho("Erro ao adicionar edital aos favoritos!");
  //         }
  //         this.isFetching = true;

  //     }).catch((error) => {

  //         if (!statusConexao()) {
  //             StatusConexaoStores.handleOpenModal()
  //         }
  //         AlertaVermelho("Erro Ao Adicionar Edital Para os Favoritos!");
  //     })
  // };

  @action
  desfavoritar = id => {
    Api.post("portal/tirar_favorito/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        AlertaInfo("Removido dos favoritos!");
        this.handleConsulta();
      })
      .catch(error => {
        AlertaVermelho("Não foi possível desfavoritar o edital");
      });
  };

  @action
  limparfiltro = () => {
    this.favorito = false;
    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");
    this.filtrarEditais();
  };

  // funções para download

  @action
  adicionar_excluir_edital_lista_download({ target: { checked } }, listItem) {
    let lista = [...this.lista_download];
    if (checked) {
      const incluiEdital = lista.map(item => item.id).includes(listItem.id);
      if (!incluiEdital) lista.push(listItem);
    } else {
      lista = lista.filter(item => item.id !== listItem.id);
    }
    this.lista_download = lista;
  }

  @action
  adicionarEditalLista = id => {
    const incluiEdital = this.lista_download.includes(id);
    if (incluiEdital)
      this.lista_download = this.lista_download.filter(item => item !== id);
    else this.lista_download.push(id);
  };

  statusPortal = nome_portal => {
    if (nome_portal === "Licitações-e" || nome_portal === "ComprasNet") {
      return true;
    }
    return false;
  };

  @action
  marcar_todos_editais = checked => {
    checked
      ? (this.lista_download = [...this.editais]
          .filter(edital => {
            return this.statusPortal(edital.nome_portal) === true && edital.id;
          })
          .map(edital => edital.id))
      : (this.lista_download = []);
  };

  @action
  download_lista = async () => {
    const { empresas, id_fornecedor } = MenuStores;
    const empresaName = empresas.find(
      empresa => Number(empresa.id) === Number(id_fornecedor)
    ).nome_fantasia;
    const date = FormatDateData(new Date());

    const lista = [...this.lista_download];
    const res = await Api.post(
      "portal/consultar_download_zip/",
      { dados: lista },
      { responseType: "blob" }
    );

    const nome_arquivo = `${this.lista_download.length}_Editais_ComprasNet_${empresaName}_${date}.zip`; // Nome da empresa _ data
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", nome_arquivo);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setTimeout(() => {
      this.lista_download = [];
    }, 2500);
  };

  @action
  download_todos = async () => {
    const { empresas, id_fornecedor } = MenuStores;
    const empresaName = empresas.find(
      empresa => Number(empresa.id) === Number(id_fornecedor)
    ).nome_fantasia;
    const dados = {
      publicacao: this.pesquisaDataType === "abertura" ? true : false,
      empresa_me: this.filtroMe,
      dt_fim: this.dataFinal,
      dt_inicio: this.dataInicial,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
    };

    const res = await Api.post("portal/download_zip_todos/", dados, {
      responseType: "blob"
    });
    const nome_arquivo = `${this.editais_total}_Editais_ComprasNet_${empresaName}_${dados.dt_inicio}_${dados.dt_fim}.zip`;
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", nome_arquivo);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  @action
  downloadUnitario = url => {
    this.cont_download_em_andamento++;
    this.download_unico(url);
  };

  downloadUnitario = async hash => {
    let hashList = [...this.downloadHashList];

    const tryDownload = setInterval(async () => {
      if (hashList.length === 0) return clearInterval(tryDownload);
      const { hash_zip, id } = hashList[0];
      const nome_arquivo = `Edital id ${id}.zip`;
      const res = await Api.get("/portal/consultar_download/", {
        params: { hash_zip }
      });
      if (res.data.message) return;
      if (res.data.type === "application/octet-stream") {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nome_arquivo);
        document.body.appendChild(link);
        link.click();
        AlertaSuccess(`Download do edital ${id} concluído!`);
        hashList.shift();
      }
    }, 5000);
  };

  download_unico = urle => {
    let uasg = urle.substr(78, 6);
    let prpcod = urle.substr(92, 5);
    let nome_arquivo = "Edital_UASG_" + uasg + "_PREGAO_" + prpcod + ".zip";
    const id = this.editais.find(ele => ele.url_download === urle).numero;
    const id_edital_selecionado = this.editais.find(
      ele => ele.url_download === urle
    ).edital_selecionado[0].id;
    axios
      .get(`${global.url}/portal/quebra_captcha_novo/`, {
        params: { url: urle, id_edital_selecionado },
        responseType: "blob"
      })
      .then(response => {
        this.cont_download_em_andamento = this.cont_download_em_andamento - 1;
        if (response.data.type === "application/octet-stream") {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", nome_arquivo);
          document.body.appendChild(link);
          link.click();
          // AlertaSuccess("Download do Edital concluido!")
          AlertaSuccess(`Download do edital ${id} concluído!`);
        }
        if (response.data.type === "application/json") {
          AlertaVermelho(`Erro ao fazer o download do edital ${id}`);
        }
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho(`Erro ao fazer o download do edital ${id}`);
        this.cont_download_em_andamento = this.cont_download_em_andamento - 1;
      });
  };

  @action limparVariaveis = () => {
    this.pesquisaDataType = "abertura";
    this.pesquisaDataTypeFavoritos = "ativos";
    this.dataTypeFavoritosME = false;
    this.dataTypeFavoritosAtivo = true;
    this.dataTypeFavoritosVencido = true;

    this.statusDataType = true;

    this.pagina_atual = 1;
    this.editais = null;
    this.editais_total = 0;
    this.configuracaoPalavras = null;
    //ordenação
    this.ordenar = "disputa";
    this.ordenarAbertura = false;

    // Modal mostrar detalhes

    this.modalDetalhesActive = false;
    this.detalhesEdital = null;

    // Modal proposta
    this.modalPropostaActive = false;
    this.dadosProposta = null;

    this.id_excluir = 0;
    this.lista_download = [];
    this.lista_corrida = [];
    this.cont_download_em_andamento = 0;
    this.downloadHashList = [];

    this.favorito = false;
    this.currentEditais = [];
    this.currentPage = null;
    this.totalPages = null;
    this.numero_editais_totais = 0;
    this.mudar_key = "";
    this.pagina_visualizada = "";
    this.itens_edital = [];
    this.flagStatus = true;
    this.statusAtulizar = true;
    this.styleData = "abertura";
    this.filtroMe = false;
    this.flagConsultar = 1;
    this.Tag = null;
    this.isFetching = false;
    this.isActiveEditar = false;

    this.btnFiltrar = false;
    this.btnFiltrarDia = true;
    this.btnFavorito = false;
    this.btnExcluido = false;

    this.editalID = 0;

    // Componente Data

    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");
    this.qualData = "inicial";
    this.showing = false;
  };
}

const newEditaisStores = new NewEditaisStores();
export default newEditaisStores;

import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { ThumbsUp } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectInput } from '../../../../UsefulComponents/Inputs'
import { ModalContext } from '../context/modal'
import { useStatusEdital } from '../context'
import { Overlay, Container } from './style'

const ModalQualificarManual = () => {
  const { statusEditalManual, onChengePrioridade, onChengeUser, edital, userList } = useStatusEdital()
  const { closeModal, modalQualificar } = useContext(ModalContext)


  return ReactDOM.createPortal(
    <>
      {modalQualificar && (
        <Overlay>
          <Container>
            <div className="header">
              <div className="icon background-qualificado">
                <ThumbsUp />
              </div>
              <div className="title color-qualificado">QUALIFICAR</div>
            </div>
            <div className="body">
              <div className="select-user">
                <div className="title">
                  SELECIONE UM USUÁRIO PARA ENCAMINHAR O EDITAL
                </div>
                <SelectInput
                  icon={<FontAwesomeIcon icon="users" />}
                  onChange={onChengeUser}
                >
                  {userList.map(user => (
                    <option value={user.id}>{`${user.username}`}</option>
                  ))}
                </SelectInput>
              </div>
              <div className="nivel-prioridade">
                <div className="title">NÍVEL DE PRIORIDADE</div>
                <SelectInput
                  icon={<FontAwesomeIcon icon="bookmark" />}
                  onChange={e => onChengePrioridade(e.target.value)}
                  defaultValue={edital.prioridade || "media"}
                >
                  <option value="alta">Alta</option>
                  <option value="media">Média</option>
                  <option value="baixa">Baixa</option>
                </SelectInput>
              </div>
            </div>
            <div className="footer">
              <div
                className="confirmar"
                onClick={() => statusEditalManual('qualificado')}>
                CONFIRMAR
              </div>
              <div className="fechar" onClick={closeModal}>
                FECHAR
              </div>
            </div>
          </Container>
        </Overlay>
      )}
    </>,
    document.querySelector('body')
  )
}

export default ModalQualificarManual

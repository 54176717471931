import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'

import * as S from '../Style'

class SelectPortalInputCustomizado extends Component {
  componentDidMount() {
    if (this.props.portalConfigMonitoramento.length === 1)
      this.props.onChange({
        target: {
          name:
            this.props.portalConfigMonitoramento[0] === 5
              ? 'ComprasNet'
              : 'Licitações-e'
        }
      })
  }

  render() {
    const { id, name, onChange, titulo, portalConfigMonitoramento } = this.props

    return (
      <S.CustomInput>
        <span>{titulo}</span>
        <FormControl
          className="select-portal"
          removeClass="form-control"
          id={id}
          name={name}
          onChange={onChange}
          componentClass="select"
          disabled={portalConfigMonitoramento.length === 1}
        >
          {portalConfigMonitoramento.map(id => (
            <option value={id.toString()}>
              {id === 5 ? 'ComprasNet' : 'Licitações-e'}
            </option>
          ))}
        </FormControl>
      </S.CustomInput>
    )
  }
}

export default SelectPortalInputCustomizado
